import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { ICar } from "../../store/ducks/car/types";
import { carSiteRequest } from "../../store/ducks/car/actions";
import "./site.css";
import LogoAuto from "./pdf.png";

const EstoqueSite: React.FC = () => {
  const dispatch = useDispatch();

  const carros: any = useSelector<ApplicationState, ICar[]>(
    (state) => state.car.dataSite
  );

  const getCar = useCallback(() => {
    dispatch(carSiteRequest("?pp=1000&estoque=Sim"));
  }, [dispatch]);
  useEffect(() => {
    getCar();
  }, [getCar]);

  function formatReal(int: any) {
    var tmp = int + "";
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  return (
    <div className="corpo--div">

      <header id="header">
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="topbar-left text-lg-left text-center">
                  <ul className="list-inline">
                    <li> <i className="fa fa-envelope mr-2"> </i>rodrigo@thaimotors.com.br</li>
                    <li> <i className="fa fa-clock"></i> Seg - Sáb 09:00 - 19:00</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="topbar-right text-lg-right text-center">
                  <ul className="list-inline">
                    <li> <i className="fa fa-phone mr-2"></i> 11 94742-5657</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="div__logo--site">
        <img src={LogoAuto} alt="" className="img__logo--site" />
      </div>

      <section className="feature-car white-bg page-section-ptb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <span>Veículos com garantia</span>
                <h2>Carros Em Estoque</h2>
                <div className="separator"></div>
              </div>
            </div>
          </div>

          <div className="row" id="carouselCars">

            {!carros
              ? ""
              : carros
              .filter((data: any) => data.status !== "Vendido")
              .map((row: any) => (

                <div className="item col-12 col-md-3 mb-4" style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                  <div className="car-item car-item-4 text-center">
                    <div className="car-image">
                      <div className="car-overlay-banner">
                      </div>
                    </div>
                    <div className="car-content">
                      <a href="#" style={{ height: "48px" }}>
                        {row.nome}
                      </a>

                      <div className="car-list">
                        <ul className="list-inline">
                          <li><i className="fa fa-registered"></i> {row.ano_fab} / {row.ano_modelo}</li>
                          <li><i className="fa fa-cog"></i> {row.cambio} </li>
                        </ul>
                      </div>

                      <div className="text--carSite">
                        {row.descritivo.substring(0, 77)} ...
                      </div>
                      <div className="price">
                        <span className="new-price">
                          {formatReal(row.preco_venda.replace(".", ""))}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>


    </div>
  );
};

export default EstoqueSite;
