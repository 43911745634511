/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { Select } from "react-functional-select";
import { thirdsRequest } from "../../../store/ducks/thirds/actions";

type BooleanOptionPDF = {
  readonly id: number;
  readonly name: string;
};

const BOOL_OPTIONSPDF: BooleanOptionPDF[] = [
  { id: 0, name: "Sim" },
  { id: 1, name: "Nao" },
];

const SelectTerceiro = (props: any) => {
  const { newTerceiro, setNewTerceiro, form, setSelectedOptionBankTerceiro, newParcauto } = props;
  const dispatch = useDispatch();

  const userData: any = useSelector<ApplicationState>(
    (state) => state.thirds.data
  );


  const handleInputChangeTerceiro = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newTerceiro];
    list[index][name] = value;
    setNewTerceiro(list);
  };

  const handleRemoveTerceiro = (index: any) => {
    const list = [...newTerceiro];
    list.splice(index, 1);
    setNewTerceiro(list);
  };

  const handleAddTerceiro = () => {
    setNewTerceiro([
      ...newTerceiro,
      { idP: "", nome: "", data_lancamento: "", valor: "", pdf: "", fk_banco: "" },
    ]);
  };

  const getOptionLabelUser1 = useCallback(
    (option: any): string => `${option.nome || option.nome}`,
    []
  );
  const getOptionValueUser1 = useCallback(
    (option: any): string => option.nome || option.nome,
    []
  );
  const onOptionChangeUser1 = useCallback((option: any, i: any, x: any, finalData: any): void => {

    let nomeUser = "";
    let idP = "";
    const list: any = [...newTerceiro];

    if (option?.nome === undefined) {
      nomeUser = list[i]["nome"]
      idP = list[i]["idP"]
    } else {
      nomeUser = option?.nome
      idP = option?.id
    }
    //@ts-ignore
    list[i]["nome"] = nomeUser;
    list[i]["idP"] = idP;
    setSelectedOptionBankTerceiro(option);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTerceiro, userData]);

  const getOptionLabelBank1 = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBank1 = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );
  const onOptionChangeBank1 = useCallback((option: any, i: any): void => {

    let nomeBanco = "";
    const list: any = [...newTerceiro];

    if (option?.id === undefined) {
      nomeBanco = list[i]["fk_banco"]
    } else {
      nomeBanco = option?.id
    }
    //@ts-ignore
    list[i]["fk_banco"] = nomeBanco;
    setSelectedOptionBankTerceiro(option);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTerceiro]);

  const getOptionLabelPDF1 = useCallback(
    (option: any): string => `${option.name || option.name}`,
    []
  );
  const getOptionValuePDF1 = useCallback(
    (option: any): string => option.name || option.name,
    []
  );
  const onOptionChangePDF1 = useCallback((option: any, i: any): void => {

    let pdfTrue = "";
    const list: any = [...newTerceiro];

    if (option?.name === undefined) {
      pdfTrue = list[i]["pdf"]
    } else {
      pdfTrue = option?.name
    }
    //@ts-ignore
    list[i]["pdf"] = pdfTrue;

  }, [newTerceiro]);

  const newTerceiroKey = useCallback((e, i) => {

    //Att state terceiro
    const listTerceiro: any = [...newTerceiro];
    const value = e.target.value;

    listTerceiro[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");

    // //soma do valor compra
    // const sumValorNew = newTerceiro
    //   .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
    //   .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

    // const resolveNewNaN = isNaN(sumValorNew) ? 0 : sumValorNew;
    // const inputValueBuyCar: any = $("#valueFixCar").val()
    // const somaFinal = resolveNewNaN + parseFloat(inputValueBuyCar)
    // form.valor_compra = somaFinal

    // $("#valorCompra")
    //   .val(somaFinal.toLocaleString('pt-br', { minimumFractionDigits: 2 }));


    // //soma comissao automarcas
    // const inputValueSellCar: any = $("#valueVendaCar").val()
    // const valueFullComission = parseFloat(inputValueSellCar) - somaFinal

    // const listParcelaComissao: any = [...newParcauto];
    // listParcelaComissao[0]["valor"] = valueFullComission;

    // $("#valorParcauto0")
    // .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    // $("#valorPagtoInformativo")
    // .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));


  }, [newTerceiro])

  let userThird = [{
    id: "",
    nome: "Selecione",
    email: "",
    documento: "",
    telefone: "",
    celular: "",
    RG: "",
    CPF: "",
    CNPJ: "",
    razao_social: "",
    bancos: []
  }]

  let bankThird = [{
    id: "",
    numero_banco: "",
    banco: "Selecione",
    agencia: "",
    conta: "",
    documento: "",
    favorecido: "",
    pix_banco: "",
  }]
  const finalData = userThird.concat(userData)


  const getUser = useCallback(() => {
    dispatch(thirdsRequest("?pp=100000"));
  }, [dispatch]);

  useEffect(() => {
    getUser();

  }, [])

  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Terceiros</h3>
          </div>
        </div>

        <div className="row">

          {newTerceiro.map((x: any, i: any) => {
            return (
              <React.Fragment key={i}>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={finalData}
                    addClassNames={true}
                    placeholder={"Selecione"}
                    onOptionChange={(e) => onOptionChangeUser1(e, i, x, finalData)}
                    getOptionLabel={getOptionLabelUser1}
                    getOptionValue={getOptionValueUser1}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={bankThird.concat(finalData.filter((a: any) => a.id === x.idP)[0]?.bancos)}
                    addClassNames={true}
                    placeholder={"Selecione o Banco"}
                    onOptionChange={(e) => onOptionChangeBank1(e, i)}
                    getOptionLabel={getOptionLabelBank1}
                    getOptionValue={getOptionValueBank1}
                    selectId={`bankTerceiro` + i}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeTerceiro(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control"
                    type="date"
                    name="data_lancamento"
                    placeholder="Data Lançamento"
                    value={x.data_lancamento}
                    onChange={(e) => handleInputChangeTerceiro(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    placeholder="Valor"
                    id={`valorTerceiro` + i}
                    autoComplete="off"
                    onKeyUp={(e) => newTerceiroKey(e, i)}
                  />
                </div>

                <div className="col-xl-6 col-lg-6 col-12 form-group">
                  <Select
                    options={BOOL_OPTIONSPDF}
                    addClassNames={true}
                    placeholder={"Enviar Cobrança para o cliente?"}
                    onOptionChange={(e) => onOptionChangePDF1(e, i)}
                    getOptionLabel={getOptionLabelPDF1}
                    getOptionValue={getOptionValuePDF1}

                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3 col-12">
                  {newTerceiro.length !== 1 ? (
                    <button
                      type="button"
                      className="btn-add btn-remove"
                      onClick={() => handleRemoveTerceiro(i)}
                    >
                      Remover
                    </button>
                  ) : (
                    <button type="button" className="btn-add btn-remove">
                      Remover
                    </button>
                  )}

                </div>
                <div className="col-xl-3 col-lg-3 col-12 form-group"></div>

              </React.Fragment>
            );
          })}
        </div>

        <div className="row">
          <div className="col-lg-3 col-12 form-group mg-t-8">
            <button type="button" className="btn-add" onClick={handleAddTerceiro}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTerceiro;
