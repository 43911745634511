import { action } from "typesafe-actions";
import { MarkTypes, IMark, IpostMark, IputMark } from "./types";

export const markRequest = () =>
  action(MarkTypes.MARK_REQUEST);

export const markSuccess = (data: IMark ) =>
  action(MarkTypes.MARK_SUCCESS, { data });

export const markFailure = () => action(MarkTypes.MARK_FAILURE, {});

export const postMarkRequest = (Ipostmark: IpostMark) =>
  action(MarkTypes.POST_MARK_REQUEST, {Ipostmark});

export const postMarkSuccess = () =>
  action(MarkTypes.POST_MARK_SUCCESS, {});

export const postMarkFailure = () => action(MarkTypes.POST_MARK_FAILURE, {});

export const viewMark = (view: IMark) =>
  action(MarkTypes.VIEW_MARK, {view});

export const cleanViewMark = () =>
  action(MarkTypes.CLEAN_VIEW_MARK, {})
  
export const putMarkRequest = (Iputmark: IputMark) =>
  action(MarkTypes.PUT_MARK_REQUEST, {Iputmark});

export const putMarkSuccess = () =>
  action(MarkTypes.PUT_MARK_SUCCESS, {});

export const putMarkFailure = () => action(MarkTypes.PUT_MARK_FAILURE, {});
 
export const deleteMarkRequest = (id: string) =>
  action(MarkTypes.DELETE_MARK_REQUEST, {id});

export const deleteMarkSuccess = () =>
  action(MarkTypes.DELETE_MARK_SUCCESS, {});

export const deleteMarkFailure = () => action(MarkTypes.DELETE_MARK_FAILURE, {});
 
 