/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { deleteExpensesRequest, expensesRequest, viewExpenses } from "../../store/ducks/expenses/actions";
import { IExpenses } from "../../store/ducks/expenses/types";

const Despesa: React.FC = () => {
  const dispatch = useDispatch();

  const expenses: any = useSelector<ApplicationState, IExpenses[]>(
    (state) => state.expenses.data
  );

  const expensesLoad: any = useSelector<ApplicationState>(
    (state) => state.expenses.loading
  );

  if (localStorage.getItem("despesas") !== null) {
    localStorage.removeItem("despesas");
  }

  const getDespesas = useCallback(() => {
    dispatch(expensesRequest("?pp=10000"));
  }, [dispatch]);

  const setView = useCallback(
    (client) => {
      dispatch(viewExpenses(client));
    },
    [dispatch]
  );

  const deleteClient = useCallback(
    (id) => {
      dispatch(deleteExpensesRequest(id));
      toast.success("Despesas deletada com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    [dispatch]
  );

  useEffect(() => {
    getDespesas();
  }, [getDespesas]);

  setTimeout(() => {
    //@ts-ignore
    $("#bolsa0km").DataTable({
      destroy: true,
      paging: false,
      order: [[1, "asc"]],
    });

    $(".dashboard-content-one").on('click', function () {
      //@ts-ignore
      $(".collapse").removeClass("show");
    });


    $(document).on('click', '.actionStopPropagation', function (e) {
      e.stopPropagation();
    });
  }, 100);

  function formatReal(int: any) {
    var tmp = int + "";
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }
  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Depesas Fixas</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>despesas</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4"></div>
              <div
                className="table-responsive dataTables_wrapper"
                style={{ overflow: "unset" }}
              >
                {expensesLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th style={{ textAlign: "center" }}>Dia Pagto</th>
                        <th style={{ textAlign: "right" }}>Valor</th>
                        <th className="desp__acoes" style={{ textAlign: "center" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!expenses
                        ? ""
                        : expenses.map((row: any) => (

                          <tr
                            key={row.id}
                            style={{
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            <td
                              className="infoCarroCard"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">{row.descricao}</div>
                            </td>
                            <td
                              className="infoCarroCar desp__diaPagto"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}

                            >
                              <div className="nameCarro">{row.dia_vencto}</div>
                            </td>
                            <td
                              className={`infoCarroCard desp__valor`}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              {row.type === "Entrada" ? (
                              <div className="nameCarro valuePositivo"> R$ {parseFloat(row.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>
                              ) : (
                                <div className="nameCarro valueNegativo"> R$ {parseFloat(row.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>
                              )}


                            </td>

                            <td
                              className="infoBolsaAcoes infoBolsaAcoes--estoque"
                              style={{ textAlign: "center" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                              <div
                                id={"folder" + row.id}
                                className="collapse action__collapse  action__collapse--fornecedor"
                                data-parent="#myGroup"
                              >
                                <Link
                                  className="item__action"
                                  to={"/editar-despesa"}
                                  onClick={() => {
                                    setView(row);
                                  }}
                                  style={{
                                    borderBottom: "1.8px solid #1d1d1d",
                                  }}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-orange"
                                    style={{
                                      position: "unset",
                                      bottom: "0",
                                      right: "0",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span>Editar</span>
                                </Link>
                                <Link
                                  className="item__action actionStopPropagation"
                                  to="/despesas"
                                  onClick={() => deleteClient(row.id)}
                                  style={{
                                    borderBottom: "1.8px solid #1d1d1d",
                                  }}
                                >
                                  <i
                                    className="fas fa-trash text-orange"
                                    style={{
                                      position: "unset",
                                      bottom: "0",
                                      right: "0",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span>Excluir</span>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                <ToastContainer autoClose={10000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-despesa">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Despesa;
