import React, { useCallback, useEffect } from "react";
import { Select } from "react-functional-select";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { carRequest } from "../../../store/ducks/car/actions";
import { ICar } from "../../../store/ducks/car/types";

const SelectCar = (props: any) => {
  const { setSelectedOptionCar, setForm, form, selectedOptionCar, handleChange, focusCar, newParcauto } = props;
  const dispatch = useDispatch();

  const carros1: any = useSelector<ApplicationState>((state) => state.car.data);

  const carros = carros1.filter((cars: any) => cars.status !== "Vendido" && cars.status !== "Devolvido")

  const getOptionLabelCar = useCallback(
    (option: ICar): string => `${'#' + option.custom_id + ' - ' + option.nome + " - " + option?.chassi}`,
    []
  );
  const getOptionValueCar = useCallback(
    (option: ICar): string => option.id,
    []
  );
  const onOptionChangeCar = useCallback(
    (option: any): void => {
      setSelectedOptionCar(option);

      //@ts-ignore
      document.getElementById("valorVenda").value = parseFloat(option.preco_venda).toLocaleString('pt-br', { minimumFractionDigits: 2 });
      //@ts-ignore
      document.getElementById("valorCompra").value = parseFloat(option.preco_custo).toLocaleString('pt-br', { minimumFractionDigits: 2 });
      //@ts-ignore
      document.getElementById("pagtoFornecedor").value = parseFloat(option.preco_custo).toLocaleString('pt-br', { minimumFractionDigits: 2 });
      //@ts-ignore
      document.getElementById("valueFixCar").value = option.preco_custo;
      //@ts-ignore
      document.getElementById("valueVendaCar").value = option.preco_venda;

      const valueFullComission = option.preco_venda - option.preco_custo;

      const listParcelaComissao: any = [...newParcauto];

      if (newParcauto.length > 0) {

        listParcelaComissao[0]["valor"] = valueFullComission;

        $("#valorParcauto0")
          .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));
      }


      $("#valorPagtoInformativo")
        .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

      setForm({
        ...form,
        contato: option?.fornecedor.nome
      })
    },
    [selectedOptionCar]
  );


  const getCarros = useCallback(() => {
    dispatch(carRequest("?pp=10000&allEstoque=Sim"));
  }, [dispatch]);

  useEffect(() => {
    getCarros()
  }, [getCarros])

  return (
    <>
      <div className="col-xl-12 col-lg-12 col-12 form-group">
        <label
          className="label-form-input"
          htmlFor="exampleInputPassword1"
        >
          Carro *
        </label>

        <Select
          options={carros}
          addClassNames={true}
          placeholder="Selecione"
          onOptionChange={onOptionChangeCar}
          getOptionLabel={getOptionLabelCar}
          getOptionValue={getOptionValueCar}
          autoFocus={focusCar}
        />
      </div>

      <input
        //@ts-ignore
        value={(form.fk_veiculo = selectedOptionCar?.id)}
        name="fk_veiculo"
        onChange={handleChange}
        type="hidden"
        className="form-control"
        id="testeAnoModelo"
        disabled={true}
      />


      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="id">
          ID
        </label>

        <input
          value={selectedOptionCar?.custom_id}
          type="text"
          className="form-control"
          id="id"
          placeholder="ID"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="marca">
          Marca
        </label>

        <input
          value={selectedOptionCar?.marca}
          type="text"
          className="form-control"
          id="marca"
          placeholder="Marca"
          disabled={true}
        />
      </div>

      <div className="col-xl-6 col-lg-6 col-12 form-group">
        <label
          className="label-form-input"
          htmlFor="exampleInputPassword1"
        >
          Modelo
        </label>
        <input
          value={selectedOptionCar?.nome}
          type="text"
          className="form-control"
          id="marca"
          placeholder="Modelo"
          disabled={true}
        />
      </div>

      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="anoModelo">
          Ano Fabricação
          <input
            value={
              selectedOptionCar?.ano_fab
            }
            type="text"
            className="form-control"
            id="anoModelo"
            placeholder="Ano modelo"
            disabled={true}
          />
        </label>
      </div>

      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="anoModelo">
          Ano Modelo
          <input
            value={
              selectedOptionCar?.ano_modelo
            }
            type="text"
            className="form-control"
            id="anoModelo"
            placeholder="Ano modelo"
            disabled={true}
          />
        </label>
      </div>

      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="precoFipe">
          Preço Compra
        </label>

        <input
          value={parseFloat(selectedOptionCar?.preco_custo).toLocaleString('pt-br', { minimumFractionDigits: 2 })|| ""}
          type="text"
          className="form-control"
          id="precoFipe"
          placeholder="Preço Compra"
          disabled={true}
        />
      </div>

      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="precoFipe">
          Preço Venda
        </label>

        <input
          value={parseFloat(selectedOptionCar?.preco_venda).toLocaleString('pt-br', { minimumFractionDigits: 2 })|| ""}
          type="text"
          className="form-control"
          id="precoFipe"
          placeholder="Preço Venda"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="cor">
          Cor
        </label>
        <input
          //@ts-ignore
          value={selectedOptionCar?.cor}
          type="text"
          className="form-control"
          id="cor"
          placeholder="Cor"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="cambio">
          Cambio
        </label>
        <input
          //@ts-ignore
          value={selectedOptionCar?.cambio}
          type="text"
          className="form-control"
          id="cambio"
          placeholder="Cambio"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="Combustivel">
          Combustivel
        </label>
        <input
          //@ts-ignore
          value={selectedOptionCar?.combustivel}
          type="text"
          className="form-control"
          id="Combustivel"
          placeholder="Combustivel"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="chassi">
          Chassi
        </label>
        <input
          value={selectedOptionCar?.chassi}
          disabled={true}
          className="form-control"
          id="chassi"
          placeholder="Chassi"
        />
      </div>

      <div className="col-xl-12 col-lg-12 col-12 form-group">
        <label
          className="label-form-input"
          htmlFor="opcionaisCarro"
        >
          Opcionais
        </label>
        <textarea
          value={selectedOptionCar?.opcionais}
          name="opcionais"
          className="form-control textarea"
          id="opcionaisCarro"
          rows={6}
          placeholder="Descreva as opções"
          disabled={true}
        />
      </div>
    </>
  );
};

export default SelectCar;
