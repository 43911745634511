import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import DispatchTables from "./dispatchTables";

import "./index.css";
import { toast, ToastContainer } from "react-toastify";

import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

//@ts-ignore
import draftToHtml from 'draftjs-to-html';
import { declarationsRequest, postDeclarationsRequest, putDeclarationsRequest } from "../../store/ducks/declarations/actions";
const CadDeclaracoes: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const data: any = useSelector<ApplicationState>((state) => state.declarations.data);


  const filterVenda = data
    .filter((data: any) => data.type === "1")
    .map((b: any) => b.content);

  const nameVenda = filterVenda.toString();

  const filterCompra = data
    .filter((data: any) => data.type === "2")
    .map((b: any) => b.content);

  const nameCompra = filterCompra.toString();

  const filterConsignacao = data
    .filter((data: any) => data.type === "3")
    .map((b: any) => b.content);

  const nameConsignacao = filterConsignacao.toString();

  const filterDevolucao = data
    .filter((data: any) => data.type === "4")
    .map((b: any) => b.content);

  const nameDevolucao = filterDevolucao.toString();



  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.contracts
  );

  const [isLoading, setIsLoading] = useState(true);



  //IDS
  const filterVendaID = data
    .filter((data: any) => data.type === "1")
    .map((b: any) => b.id);

  const nameVendaID = filterVendaID.toString();

  const filterCompraID = data
    .filter((data: any) => data.type === "2")
    .map((b: any) => b.id);

  const nameCompraID = filterCompraID.toString();

  const filterConsignacaoID = data
    .filter((data: any) => data.type === "3")
    .map((b: any) => b.id);

  const nameConsignacaoID = filterConsignacaoID.toString();

  const filterDevolucaoID = data
    .filter((data: any) => data.type === "4")
    .map((b: any) => b.id);

  const nameDevolucaoID = filterDevolucaoID.toString();


  const [form, setForm] = useState({
    declaracao_venda: "",
    declaracao_compra: "",
    declaracao_consignacao: "",
    declaracao_devolucao: "",
    IDVenda: "",
    IDCompra: "",
    IDConsignacao: "",
    IDDevolucao: "",
  });

  const plainText = "<p>" + nameVenda + "</p>";
  const content = convertFromHTML(plainText);


  const state = ContentState.createFromBlockArray(
    content.contentBlocks,
    content.entityMap,
  );

  const [editorState, setEditorState] = useState(EditorState.createWithContent(state))

  const plainText1 = "<p>" + nameCompra + "</p>";
  const content1 = convertFromHTML(plainText1);

  const state1 = ContentState.createFromBlockArray(
    content1.contentBlocks,
    content1.entityMap,
  );

  const [editorState1, setEditorState1] = useState(EditorState.createWithContent(state1))

  const plainText2 = "<p>" + nameConsignacao + "</p>";
  const content2 = convertFromHTML(plainText2);

  const state2 = ContentState.createFromBlockArray(
    content2.contentBlocks,
    content2.entityMap,
  );

  const [editorState2, setEditorState2] = useState(EditorState.createWithContent(state2))

  const plainText3 = "<p>" + nameDevolucao + "</p>";
  const content3 = convertFromHTML(plainText3);

  const state3 = ContentState.createFromBlockArray(
    content3.contentBlocks,
    content3.entityMap,
  );

  const [editorState3, setEditorState3] = useState(EditorState.createWithContent(state3))

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      // btnDisabled();

      const { IDVenda, IDCompra, IDConsignacao, IDDevolucao } = form;


      if (IDVenda === "") {
        const type = 1;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_venda.getCurrentContent()));
        dispatch(
          postDeclarationsRequest({
            type,
            content,
          })
        );

      } else {
        const type = 1;
        const id = IDVenda;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_venda.getCurrentContent()));
        dispatch(
          putDeclarationsRequest({
            id,
            type,
            content,
          })
        );
      }

      if (IDCompra === "") {
        const type = 2;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_compra.getCurrentContent()));
        dispatch(
          postDeclarationsRequest({
            type,
            content,
          })
        );

      } else {
        const type = 2;
        const id = IDCompra;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_compra.getCurrentContent()));
        dispatch(
          putDeclarationsRequest({
            id,
            type,
            content,
          })
        );
      }

      if (IDConsignacao === "") {
        const type = 3;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_consignacao.getCurrentContent()));

        dispatch(
          postDeclarationsRequest({
            type,
            content,
          })
        );

      } else {
        const type = 3;
        const id = IDConsignacao;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_consignacao.getCurrentContent()));

        dispatch(
          putDeclarationsRequest({
            id,
            type,
            content,
          })
        );
      }

      if (IDDevolucao === "") {
        const type = 4;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_devolucao.getCurrentContent()));
        dispatch(
          postDeclarationsRequest({
            type,
            content,
          })
        );

      } else {
        const type = 4;
        const id = IDDevolucao;
        //@ts-ignore
        const content = draftToHtml(convertToRaw(form.declaracao_devolucao.getCurrentContent()));
        dispatch(
          putDeclarationsRequest({
            id,
            type,
            content,
          })
        );
      }


      toast.success("Declarações alterada com sucesso");
      setTimeout(() => window.location.reload(), 2000);
    },
    [dispatch, form]
  );

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        toast.success("Contrato alterado com sucesso");
        setTimeout(() => history.push("/configurador-declaracao"), 2000);
      }
    },
    [history]
  );



  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const toastLoading = useCallback(() => {
    if (isLoading) {
      toast.info("Carregando informações do carro");
    }
  }, [isLoading]);

  setTimeout(() => {
    setIsLoading(false);
  }, 1800);


  const getDeclar = useCallback(() => {
    dispatch(declarationsRequest("?pp=500"));
  }, [dispatch]);

  useEffect(() => {
    getDeclar();
    setEditorState(EditorState.createWithContent(state));
    setEditorState1(EditorState.createWithContent(state1));
    setEditorState2(EditorState.createWithContent(state2));
    setEditorState3(EditorState.createWithContent(state3));
    if (isLoading) {
      toastLoading();
    }
    notify(responseApi.response.code);
  }, [notify, responseApi.response, isLoading, getDeclar, state, state1, state2, state3]);



  return (
    <div id="wrapper" className="wrapper bg-ash">
      <DispatchTables />
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Configurador Declarações</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Inserir Declarações</li>
            </ul>
          </div>

          <div className="card height__formMaster">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Configurador Declarações</h3>
                  <span className="label-form-input">* Campos obrigatório</span>
                </div>
              </div>
              {isLoading ? (
                ""
              ) : (
                <form onSubmit={submitForm} className="new-added-form">
                  <div className="row">
                    <div
                      className="col-xl-12 col-lg-12 col-12 form-group"
                      id="testando"
                    >
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Declarações venda
                      </label>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbar__className"
                        wrapperClassName="wrapper__className"
                        editorClassName="editor__className"
                        onEditorStateChange={setEditorState}
                        stripPastedStyles={true}
                        toolbar={{
                          options: ['inline', 'textAlign'],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline'],
                          },
                        }}

                      />

                      <input
                        //@ts-ignore
                        value={(form.declaracao_venda = editorState)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />

                      <input
                        //@ts-ignore
                        value={(form.IDVenda = nameVendaID)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                    </div>
                    <div
                      className="col-xl-12 col-lg-12 col-12 form-group"
                      id="testando"
                    >
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Declarações compra
                      </label>
                      <Editor
                        editorState={editorState1}
                        toolbarClassName="toolbar__className"
                        wrapperClassName="wrapper__className"
                        editorClassName="editor__className"
                        onEditorStateChange={setEditorState1}
                        stripPastedStyles={true}
                        toolbar={{
                          options: ['inline', 'textAlign'],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline'],
                          },
                        }}

                      />

                      <input
                        //@ts-ignore
                        value={(form.declaracao_compra = editorState1)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                      <input
                        //@ts-ignore
                        value={(form.IDCompra = nameCompraID)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                    </div>
                    <div
                      className="col-xl-12 col-lg-12 col-12 form-group"
                      id="testando"
                    >
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Declarações consignação
                      </label>
                      <Editor
                        editorState={editorState2}
                        toolbarClassName="toolbar__className"
                        wrapperClassName="wrapper__className"
                        editorClassName="editor__className"
                        onEditorStateChange={setEditorState2}
                        stripPastedStyles={true}
                        toolbar={{
                          options: ['inline', 'textAlign'],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline'],
                          },
                        }}

                      />
                      <input
                        //@ts-ignore
                        value={(form.declaracao_consignacao = editorState2)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                      <input
                        //@ts-ignore
                        value={(form.IDConsignacao = nameConsignacaoID)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                    </div>
                    <div
                      className="col-xl-12 col-lg-12 col-12 form-group"
                      id="testando"
                    >
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Declarações devolução
                      </label>
                      <Editor
                        editorState={editorState3}
                        toolbarClassName="toolbar__className"
                        wrapperClassName="wrapper__className"
                        editorClassName="editor__className"
                        onEditorStateChange={setEditorState3}
                        stripPastedStyles={true}
                        toolbar={{
                          options: ['inline', 'textAlign'],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline'],
                          },
                        }}

                      />

                      <input
                        //@ts-ignore
                        value={(form.declaracao_devolucao = editorState3)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                      <input
                        //@ts-ignore
                        value={(form.IDDevolucao = nameDevolucaoID)}
                        name="declaracao"
                        onChange={handleChange}
                        type="hidden"
                        className="form-control"
                        id="testeAnoModelo"
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 form-group mg-t-8 buttonFlutuante">
                      <button
                        id="btn_submit"
                        type="submit"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default CadDeclaracoes;
