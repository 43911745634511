import { Reducer } from "redux";
import producer from "immer";

import { ColorState, ColorTypes } from "./types";

const INITIAL_STATE: ColorState = {
  data: [],
  view: {
    id: "",
    nome: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<ColorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ColorTypes.COLOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ColorTypes.COLOR_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case ColorTypes.COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ColorTypes.VIEW_COLOR:
      return {
        ...state,
        view: action.payload.view,
      };

      case ColorTypes.POST_COLOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ColorTypes.POST_COLOR_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case ColorTypes.POST_COLOR_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });


    case ColorTypes.CLEAN_VIEW_COLOR:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
        },
      };
    case ColorTypes.PUT_COLOR_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ColorTypes.PUT_COLOR_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.view = {
          id: "",
          nome: "",
        };
      });
    case ColorTypes.PUT_COLOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
