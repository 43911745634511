import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";
import { ToastContainer, toast } from "react-toastify";

import { ApplicationState } from "../../store";
import { IpostClient, IClient } from "../../store/ducks/client/types";
import {
  postClientRequest,
  putClientRequest,
} from "../../store/ducks/client/actions";
import MaskedInput from "react-text-mask";
import { cleanViewAResp } from "../../store/ducks/client/actions";

const CadClientes: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [images, setImages] = useState([] as any);

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const returnApi: any = useSelector<ApplicationState>((state) => state.client);

  const UserLogged = accessLevel.headers["Access-Level"];

  if (UserLogged !== "super_admin") {
    history.push("/carros");
  }

  const view = useSelector<ApplicationState, IClient>(
    (state) => state.client.view
  );

  const [form, setForm] = useState<IpostClient>({
    email: view.email || "",
    cnpj: view.cnpj || "",
    whatsapp: view.whatsapp || "",
    nome: view.nome || "",
    type: view.type || "cliente",
    cep: view.cep || "",
    logradouro: view.logradouro || "",
    complemento: view.complemento || "",
    bairro: view.bairro || "",
    localidade: view.localidade || "",
    uf: view.uf || "",
    endNumero: view.endNumero || "",
    logo: view.logo || "",
  });

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const {
        cnpj,
        whatsapp,
        email,
        nome,
        type,
        cep,
        logradouro,
        complemento,
        bairro,
        localidade,
        uf,
        endNumero,
      } = form;

      const logo = view.logo === form.logo ? "" : form.logo;

      let btn = document.getElementById("btn_submit");
      if (btn) {
        btn.setAttribute("disabled", "{true}");
      }

      if (view.email) {
        dispatch(
          putClientRequest({
            id: view.id,
            nome,
            cnpj,
            whatsapp,
            type,
            cep,
            logradouro,
            complemento,
            bairro,
            localidade,
            uf,
            endNumero,
            logo,
          })
        );
      } else {
        dispatch(
          postClientRequest({
            cnpj,
            whatsapp,
            email,
            nome,
            type,
            cep,
            logradouro,
            complemento,
            bairro,
            localidade,
            uf,
            endNumero,
            logo,
          })
        );
      }
    },
    [dispatch, form, view]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  const handleChangeSelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const target = event.target;
      const type = target.name;

      setForm({
        ...form,
        [type]: target.value,
      });
    },
    [form]
  );

  const notify = useCallback(
    (event) => {
      if (!view.email) {
        if (event === 200) toast.error("Email Já cadastrado!");

        if (event === 400) toast.error("CNPJ informado esta incorreto");

        if (event === 201) {
          toast.success("Cliente criado com sucesso");
          setTimeout(() => history.push("/clientes"), 2000);
        }
      } else {
        if (event === 200) {
          toast.success("Cliente alterado com sucesso");
          setTimeout(() => history.push("/clientes"), 2000);
        }
      }
    },
    [history, view.email]
  );

  function onBlurCep(ev: any) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          email: form.email,
          cnpj: form.cnpj,
          whatsapp: form.whatsapp,
          nome: form.nome,
          type: form.type,
          cep: data.cep,
          logradouro: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          localidade: data.localidade,
          uf: data.uf,
        });
      });
  }

  const cleanView = useCallback(() => {
    dispatch(cleanViewAResp());
  }, [dispatch]);

  const renderImageState = useCallback(() => {
    const arrayFotos = [{ id: view.id, src: view.logo }];

    if (view.logo !== undefined || view.logo === "") {
      setImages(arrayFotos);
    }
  }, [view.id, form.logo, view.logo]);

  useEffect(() => {
    renderImageState();
    if (returnApi.response.code !== "") {
      cleanView();
    }
    notify(returnApi.response.code);
  }, [cleanView, notify, returnApi.response, renderImageState]);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Cliente</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              {view.email ? (
                <li>Alterar cliente</li>
              ) : (
                <li>Adicionar cliente</li>
              )}
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  {view.email ? (
                    <h3>Alterar Cliente</h3>
                  ) : (
                    <h3>Cadastro Cliente</h3>
                  )}
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome
                    </label>
                    <input
                      value={form.nome}
                      name="nome"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome da Loja"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CNPJ
                    </label>
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.cnpj}
                      name="cnpj"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CNPJ"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Email
                    </label>
                    <input
                      value={form.email}
                      disabled={view.email ? true : false}
                      name="email"
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Email"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Whatsapp
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.whatsapp}
                      name="whatsapp"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Whatsapp"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Tipo cliente
                    </label>
                    <select
                      id="inputCarNovo"
                      name="type"
                      onChange={handleChangeSelect}
                      className="form-control rfs-control-container"
                      required={true}
                      value={form.type}
                      style={{ backgroundColor: "#021933" }}
                    >
                      <option value={"bolsa0km"}>Fornecedor bolsa 0km</option>
                      <option value={"cliente"}>Cliente bolsa 0km</option>
                      <option value={"fornecedor"}>Site Completo</option>
                      <option value={"fornecedor2"}>Site</option>
                    </select>
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      CEP
                    </label>
                    <input
                      value={form.cep}
                      name="cep"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="CEP"
                      onBlur={(ev) => onBlurCep(ev)}
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Rua
                    </label>
                    <input
                      value={form.logradouro}
                      name="logradouro"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Rua"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="endNumero">
                      Numero
                    </label>
                    <input
                      value={form.endNumero}
                      name="endNumero"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="endNumero"
                      placeholder="Numero"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Complemento
                    </label>
                    <input
                      value={form.complemento}
                      name="complemento"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Complemento"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Bairro
                    </label>
                    <input
                      value={form.bairro}
                      name="bairro"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="bairro"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Estado
                    </label>
                    <input
                      value={form.uf}
                      name="uf"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Sigla UF"
                      required={true}
                      maxLength={2}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Cidade
                    </label>
                    <input
                      value={form.localidade}
                      name="localidade"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Cidade"
                      required={true}
                    />
                  </div>


                  <div className="col-12 form-group mg-t-8">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      {view.email ? "Alterar" : "Cadastrar"}
                    </button>
                    <ToastContainer autoClose={5000} position="top-right" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadClientes;
