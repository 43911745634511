import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import "./index.css";
import { toast, ToastContainer } from "react-toastify";

import { Select } from "react-functional-select";
import { IExpenses } from "../../store/ducks/expenses/types";
import { ICategoryFin } from "../../store/ducks/categoryFinancial/types";
import { cleanViewARespExpenses, putExpensesRequest } from "../../store/ducks/expenses/actions";
import { categoryFinRequest } from "../../store/ducks/categoryFinancial/actions";

type BooleanOption = {
  readonly id: any;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: "Entrada", name: "Entrada" },
  { id: "Saida", name: "Saida" },
];
const EditDespesa: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.expenses
  );

  const view = useSelector<ApplicationState, IExpenses>(
    (state) => state.expenses.view
  );

  const [isLoading, setIsLoading] = useState(true);

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("despesas", {
    id: view.id,
    nome: view.nome,
    type: view.type,
    descricao: view.descricao,
    valor: view.valor,
    dia_vencto: view.dia_vencto,
    fk_categoria: view.fk_categoria,
    categoria: view.categoria
  });

  const dataSelect =
    form.type === "Entrada"
      ? { id: "Entrada", name: "Entrada" }
      : { id: "Saida", name: "Saida" };

  const filterCategoria = { nome: form.categoria }

  const catFinanceiro = useSelector<ApplicationState, ICategoryFin[]>(
    (state) => state.categoryFinancial.data
  );

  const [SelectednewUsed, setSelectednewUsed] = useState<BooleanOption | null>(
    { id: form.type, name: form.type }
  );

  const [selectedCategoria, setSelectedCateogira] = useState<ICategoryFin | null>(
    null
  );

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();
      toast.info("Carregando, aguarde");

      const {
        id,
        nome,
        descricao,
        dia_vencto,
      } = form;

      //@ts-ignore
      form.valor = document.getElementById("valor").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const valor = document.getElementById("valor").value.replaceAll(".", "").replaceAll(",", ".");

      const type: any = SelectednewUsed?.name;
      const fk_categoria: any = selectedCategoria?.id || form.fk_categoria;



      if (
        !fk_categoria
      ) {
        toast.error("Digite todos os campos obrigatórios");
        btnNoDisabled();
        return;
      }

      dispatch(
        putExpensesRequest({
          id,
          nome,
          type,
          descricao,
          valor,
          dia_vencto,
          fk_categoria,
        })
      );
    },
    [
      dispatch,
      form,
      selectedCategoria,
      SelectednewUsed,
    ]
  );

  const notify = useCallback(
    (event) => {

      if (event === 200) {
        toast.success("Despesa alterada com sucesso");
        setTimeout(() => history.push("/despesas"), 2000);
      }

      if (event === 404) {
        toast.error("Falha ao cadastrar Despesa");
        btnNoDisabled();
      }
    },
    [history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({ 
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  setTimeout(() => {
    $("#valor").val(parseFloat(form.valor.replaceAll(".", "")).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
    setIsLoading(false);
  }, 200);

  const cleanView = useCallback(() => {
    dispatch(cleanViewARespExpenses());
  }, [dispatch]);

  const getCategoria = useCallback(() => {
    dispatch(categoryFinRequest("?pp=10000"));
  }, [dispatch]);


  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    notify(responseApi.response.code);
    getCategoria();
  }, [notify, responseApi.response, cleanView, isLoading, getCategoria]);



  const getNovoUsadoValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onNovoUsadoChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectednewUsed(newUsed),
    []
  );
  const getNovoUsadoLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );

  const getCategoriaValue = useCallback(
    (categoria: any): number => categoria.id,
    []
  );
  const onCategoriaChange = useCallback(
    (categoria: any | null): any => setSelectedCateogira(categoria),
    []
  );
  const getCategoriaLabel = useCallback(
    (categoria: any): string => `${categoria.nome}`,
    []
  );




  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Despesa</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Editar despesa</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Editar Despesa</h3>
                  {/* <span className="label-form-input">* Campos obrigatório</span> */}
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Tipo
                    </label>
                    <Select
                      options={BOOL_OPTIONS}
                      onOptionChange={onNovoUsadoChange}
                      getOptionValue={getNovoUsadoValue}
                      getOptionLabel={getNovoUsadoLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      initialValue={dataSelect}
                    />
                  </div>


                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Categoria
                        </label>
                    <Select
                      options={catFinanceiro}
                      onOptionChange={onCategoriaChange}
                      getOptionValue={getCategoriaValue}
                      getOptionLabel={getCategoriaLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      initialValue={filterCategoria}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Descrição
                    </label>
                    <input
                      value={form.descricao}
                      name="descricao"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Descrição"
                      required={true}
                    />
                  </div>

                  <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                    <label className="label-form-input" htmlFor="valor">
                      Valor*
                    </label>
                    <input
                      name="valor"
                      type="text"
                      className="form-control dinheiro"
                      id="valor"
                      placeholder="Valor"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Dia Vencimento
                    </label>
                    <input
                      value={form.dia_vencto}
                      name="dia_vencto"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Dia Vencimento"
                      required={true}
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditDespesa;
