import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { deleteComissionRequest } from "../../../store/ducks/comission/actions";
import { employeeRequest } from "../../../store/ducks/employee/actions";
import { Select } from "react-functional-select";

const SelectComissionEdit = (props: any) => {
  const { oldComissao, newComissao, setNewComissao, setoldComissao, setForm, form,
    setSelectedOptionUser } = props;
  const dispatch = useDispatch();


  const userData: any = useSelector<ApplicationState>(
    (state) => state.employee.data
  );

  const deleteComissao = useCallback(
    (id) => {
      dispatch(deleteComissionRequest(id));
      // toast.success("Banco deletado com sucesso");
    },
    [dispatch]
  );

  const handleInputChangeComissao = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newComissao];
    list[index][name] = value;
    setNewComissao(list);
  };

  const handleRemoveComissao = (index: any) => {
    const list = [...newComissao];
    list.splice(index, 1);
    setNewComissao(list);
  };

  const handleAddComissao = () => {
    setNewComissao([
      ...newComissao,
      { nome: "", data_lancamento: "", valor: "" },
    ]);
  };

  // Bank handle input change
  const handleInputChangeComissaoOld = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...oldComissao];
    list[index][name] = value;
    setoldComissao(list);
  };

  // Bank handle click event of the Remove button
  const handleRemoveComissaoOld = (index: any, id: any) => {
    const list = [...oldComissao];
    list.splice(index, 1);
    setoldComissao(list);
    deleteComissao(id);
    setForm({
      ...form,
      ["comissao"]: list,
    });
  };

  const getOptionLabelUser = useCallback(
    (option: any): string => `${option.nome || option.nome}`,
    []
  );
  const getOptionValueUser = useCallback(
    (option: any): string => option.nome || option.nome,
    []
  );
  const onOptionChangeUser = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    const list: any = [...oldComissao];

    if (option?.nome === undefined) {
      nomeUser = list[i]["nome"]
    } else {
      nomeUser = option?.nome
    }
    //@ts-ignore
    list[i]["nome"] = nomeUser;
    setSelectedOptionUser(option);


  }, [oldComissao]);

  const getOptionLabelUser1 = useCallback(
    (option: any): string => `${option.nome || option.nome}`,
    []
  );
  const getOptionValueUser1 = useCallback(
    (option: any): string => option.nome || option.nome,
    []
  );
  const onOptionChangeUser1 = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    const list: any = [...newComissao];

    if (option?.nome === undefined) {
      nomeUser = list[i]["nome"]
    } else {
      nomeUser = option?.nome
    }
    //@ts-ignore
    list[i]["nome"] = nomeUser;
    setSelectedOptionUser(option);


  }, [newComissao]);

  const oldComissaoKey = useCallback((e, i) => {

    const list: any = [...oldComissao];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");


  }, [oldComissao])


  const newComissaoKey = useCallback((e, i) => {

    const list: any = [...newComissao];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");


  }, [newComissao])

  const getUser = useCallback(() => {
    dispatch(employeeRequest());
  }, [dispatch]);

  useEffect(() => {
    getUser();

  }, [])


  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Comissões</h3>
          </div>
        </div>

        <div className="row">

          {oldComissao.map((x: any, i: any) => {
            setTimeout(() => {
              $("#oldValor" + i).val(parseFloat(x.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
            }
              , 200)

            return (
              <>
                <input
                  className="form-control"
                  name="id"
                  placeholder="id"
                  value={x.id}
                  onChange={(e) => handleInputChangeComissaoOld(e, i)}
                  type="hidden"
                />


                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={userData}
                    addClassNames={true}
                    placeholder={x.nome}
                    onOptionChange={(e) => onOptionChangeUser(e, i)}
                    getOptionLabel={getOptionLabelUser}
                    getOptionValue={getOptionValueUser}
                    isClearable

                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeComissaoOld(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control"
                    type="date"
                    name="data_lancamento"
                    placeholder="Data Lançamento"
                    value={x.data_lancamento}
                    onChange={(e) => handleInputChangeComissaoOld(e, i)}
                  />
                </div>


                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    name="valor"
                    placeholder="Valor"
                    id={`oldValor` + i}
                    onKeyUp={(e) => oldComissaoKey(e, i)}
                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3 col-12">
                  <button
                    type="button"
                    className="btn-add btn-remove"
                    onClick={() => handleRemoveComissaoOld(i, x.id)}
                  >
                    Remover
                  </button>
                </div>
              </>
            );
          })}


          {newComissao.map((x: any, i: any) => {
            return (
              <>
                <div className={`col-xl-3 col-lg-3 col-12 form-group`}>
                  <Select
                    options={userData}
                    addClassNames={true}
                    placeholder={"Selecione"}
                    onOptionChange={(e) => onOptionChangeUser1(e, i)}
                    getOptionLabel={getOptionLabelUser1}
                    getOptionValue={getOptionValueUser1}

                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeComissao(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control"
                    type="date"
                    name="data_lancamento"
                    placeholder="Data Lançamento"
                    value={x.data_lancamento}
                    onChange={(e) => handleInputChangeComissao(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    name="valor"
                    placeholder="Valor"
                    id={`valor` + i}
                    onKeyUp={(e) => newComissaoKey(e, i)}

                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3 col-12">
                  <button
                    className="btn-add btn-remove"
                    onClick={() => handleRemoveComissao(i)}
                  >
                    Remover
                  </button>
                </div>
              </>
            );
          })}
        </div>

        <div className="row">
          <div className="col-lg-3 col-12 form-group mg-t-8">
            <button type="button" className="btn-add" onClick={handleAddComissao}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectComissionEdit;
