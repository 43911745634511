import { action } from "typesafe-actions";
import { ContractsTypes, IContracts, IpostContracts, IputContracts } from "./types";
import { IResponse } from "../auth/types";

export const contractsRequest = (paremtros: any) =>
  action(ContractsTypes.CONTRACTS_REQUEST, {paremtros});

export const contractsSuccess = (data: IContracts ) =>
  action(ContractsTypes.CONTRACTS_SUCCESS, { data });

export const contractsFailure = () => action(ContractsTypes.CONTRACTS_FAILURE, {});

export const postContractsRequest = (ipostContracts: IpostContracts) =>
  action(ContractsTypes.POST_CONTRACTS_REQUEST, {ipostContracts});

export const postContractsSuccess = (data: IResponse) =>
  action(ContractsTypes.POST_CONTRACTS_SUCCESS, {data});

export const postContractsFailure = (data: IResponse) => action(ContractsTypes.POST_CONTRACTS_FAILURE, {data});

export const viewContracts = (view: IContracts) =>
  action(ContractsTypes.VIEW_CONTRACTS, {view});

export const cleanViewContracts = () =>
  action(ContractsTypes.CLEAN_VIEW_CONTRACTS, {})

export const putContractsRequest = (iputContracts: IputContracts) =>
  action(ContractsTypes.PUT_CONTRACTS_REQUEST, {iputContracts});

export const putContractsSuccess = (data: IResponse) =>
  action(ContractsTypes.PUT_CONTRACTS_SUCCESS, {data});

export const putContractsFailure = () => action(ContractsTypes.PUT_CONTRACTS_FAILURE, {});

export const deleteContractsRequest = (id: string) =>
  action(ContractsTypes.DELETE_CONTRACTS_REQUEST, {id});

export const deleteContractsSuccess = () =>
  action(ContractsTypes.DELETE_CONTRACTS_SUCCESS, {});

export const deleteContractsFailure = () => action(ContractsTypes.DELETE_CONTRACTS_FAILURE, {});



export const cleanViewContractResp = () =>
  action(ContractsTypes.CLEAN_CONTRACTS_RESPONSE, {});

