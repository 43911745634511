import { action } from "typesafe-actions";
import {
  UserTypes,
  IRegisterUser,
  IResponse,
  IProfileUser,
  IPutUser,
  IUserLoja,
  IUpSenhaUser,
} from "./types";

export const userRequest = (data: IRegisterUser) =>
  action(UserTypes.USER_REQUEST, { data });

export const userSuccess = (data: IResponse) =>
  action(UserTypes.USER_SUCCESS, { data });

export const userFailure = (data: IResponse) =>
  action(UserTypes.USER_FAILURE, { data });

export const userProfileRequest = (idUser: string) =>
  action(UserTypes.PROFILE_USER_REQUEST, { idUser });

export const userProfileSuccess = (data: IProfileUser) =>
  action(UserTypes.PROFILE_USER_SUCCESS, { data });

export const userProfileFailure = (data: IResponse) =>
  action(UserTypes.PROFILE_USER_FAILURE, { data });

export const putUserRequest = (Iputuser: IPutUser) =>
  action(UserTypes.PUT_USER_REQUEST, { Iputuser });

export const putUserSuccess = (data: IResponse) =>
  action(UserTypes.PUT_USER_SUCCESS, { data });

export const putUserFailure = (data: IResponse) =>
  action(UserTypes.PUT_USER_FAILURE, { data });

export const userLojaRequest = (idLoja: string) =>
  action(UserTypes.GET_LOJA_REQUEST, { idLoja });

export const userLojaSuccess = (data: IUserLoja) =>
  action(UserTypes.GET_LOJA_SUCCESS, { data });

export const userLojaFailure = (data: IResponse) =>
  action(UserTypes.GET_LOJA_FAILURE, { data });

export const updateSenhaRequest = (data: IUpSenhaUser) =>
  action(UserTypes.UPDATE_SENHA_REQUEST, { data });

export const updateSenhaSuccess = (data: IResponse) =>
  action(UserTypes.UPDATE_SENHA_SUCCESS, { data });

export const updateSenhaFailure = (data: IResponse) =>
  action(UserTypes.UPDATE_SENHA_FAILURE, { data });
