import { action } from "typesafe-actions";
import {
  EmployeeTypes,
  IEmployee,
  IpostEmployee,
  IputEmployee,
  IResponse,
} from "./types";

export const employeeRequest = () => action(EmployeeTypes.EMPLOYEE_REQUEST);

export const employeeSuccess = (data: IEmployee) =>
  action(EmployeeTypes.EMPLOYEE_SUCCESS, { data });

export const employeeFailure = () => action(EmployeeTypes.EMPLOYEE_FAILURE, {});

export const postEmployeeRequest = (ipostEmployee: IpostEmployee) =>
  action(EmployeeTypes.POST_EMPLOYEE_REQUEST, { ipostEmployee });

export const postEmployeeSuccess = (data: IResponse) =>
  action(EmployeeTypes.POST_EMPLOYEE_SUCCESS, { data });

export const postEmployeeFailure = (data: IResponse) =>
  action(EmployeeTypes.POST_EMPLOYEE_FAILURE, { data });

export const viewEmployee = (view: IEmployee) =>
  action(EmployeeTypes.VIEW_EMPLOYEE, { view });

export const cleanViewEmployee = () => action(EmployeeTypes.CLEAN_VIEW_EMPLOYEE, {});

export const putEmployeeRequest = (iputemployee: IputEmployee) =>
  action(EmployeeTypes.PUT_EMPLOYEE_REQUEST, { iputemployee });

export const putEmployeeSuccess = (data: IResponse) =>
  action(EmployeeTypes.PUT_EMPLOYEE_SUCCESS, { data });

export const putEmployeeFailure = (data: IResponse) =>
  action(EmployeeTypes.PUT_EMPLOYEE_FAILURE, { data });

export const deleteEmployeeRequest = (id: string) =>
  action(EmployeeTypes.DELETE_EMPLOYEE_REQUEST, { id });

export const deleteEmployeeSuccess = () =>
  action(EmployeeTypes.DELETE_EMPLOYEE_SUCCESS, {});

export const deleteEmployeeFailure = () =>
  action(EmployeeTypes.DELETE_EMPLOYEE_FAILURE, {});

export const cleanViewAResp = () => action(EmployeeTypes.CLEAN_EMPLOYEE_RESPONSE, {});

