import { action } from "typesafe-actions";
import {
  ThirdsTypes,
  IThirds,
  IpostThirds,
  IputThirds,
  IResponse,
} from "./types";

//client PF
export const thirdsRequest = (data: any) =>
  action(ThirdsTypes.THIRDS_REQUEST, { data });

export const thirdsSuccess = (data: IThirds) =>
  action(ThirdsTypes.THIRDS_SUCCESS, { data });

export const thirdsFailure = () => action(ThirdsTypes.THIRDS_FAILURE, {});

export const postThirdsRequest = (ipostThirds: IpostThirds) =>
  action(ThirdsTypes.POST_THIRDS_REQUEST, { ipostThirds });

export const postThirdsSuccess = (data: IResponse) =>
  action(ThirdsTypes.POST_THIRDS_SUCCESS, { data });

export const postThirdsFailure = (data: IResponse) =>
  action(ThirdsTypes.POST_THIRDS_FAILURE, { data });

export const viewThirds = (view: IThirds) =>
  action(ThirdsTypes.VIEW_THIRDS, { view });

export const cleanViewThirds = () =>
  action(ThirdsTypes.CLEAN_VIEW_THIRDS, {});


export const putThirdsRequest = (iputThirds: IputThirds) =>
  action(ThirdsTypes.PUT_THIRDS_REQUEST, { iputThirds });

export const putThirdsSuccess = (data: IResponse) =>
  action(ThirdsTypes.PUT_THIRDS_SUCCESS, { data });

export const putThirdsFailure = () =>
  action(ThirdsTypes.PUT_THIRDS_FAILURE, {});

export const deleteThirdsRequest = (id: string) =>
  action(ThirdsTypes.DELETE_THIRDS_REQUEST, { id });

export const deleteThirdsSuccess = () =>
  action(ThirdsTypes.DELETE_THIRDS_SUCCESS, {});

export const deleteThirdsFailure = () =>
  action(ThirdsTypes.DELETE_THIRDS_FAILURE, {});

export const cleanViewARespThirds = () =>
  action(ThirdsTypes.CLEAN_THIRDS_RESPONSE, {});

//BANK
export const deleteBankRequest = (id: string) =>
  action(ThirdsTypes.DELETE_BANK_REQUEST, { id });

export const deleteBankSuccess = () =>
  action(ThirdsTypes.DELETE_BANK_SUCCESS, {});

export const deleteBankFailure = () =>
  action(ThirdsTypes.DELETE_BANK_FAILURE, {});
