import { action } from "typesafe-actions";
import { ExpensesTypes, IExpenses, IpostExpenses, IputExpenses, IResponse } from "./types";

export const expensesRequest = (paremtros: any) =>
  action(ExpensesTypes.EXPENSES_REQUEST, { paremtros });

export const expensesSuccess = (data: IExpenses) =>
  action(ExpensesTypes.EXPENSES_SUCCESS, { data });

export const expensesFailure = () => action(ExpensesTypes.EXPENSES_FAILURE, {});

export const postExpensesRequest = (ipostExpenses: IpostExpenses) =>
  action(ExpensesTypes.POST_EXPENSES_REQUEST, { ipostExpenses });

export const postExpensesSuccess = (data: IResponse) =>
  action(ExpensesTypes.POST_EXPENSES_SUCCESS, { data });

export const postExpensesFailure = (data: IResponse) => action(ExpensesTypes.POST_EXPENSES_FAILURE, { data });

export const viewExpenses = (view: IExpenses) =>
  action(ExpensesTypes.VIEW_EXPENSES, { view });

export const cleanViewExpenses = () =>
  action(ExpensesTypes.CLEAN_VIEW_EXPENSES, {})

export const putExpensesRequest = (iputExpenses: IputExpenses) =>
  action(ExpensesTypes.PUT_EXPENSES_REQUEST, { iputExpenses });

export const putExpensesSuccess = (data: IResponse) =>
  action(ExpensesTypes.PUT_EXPENSES_SUCCESS, { data });

export const putExpensesFailure = (data: IResponse) => action(ExpensesTypes.PUT_EXPENSES_FAILURE, { data });

export const deleteExpensesRequest = (id: string) =>
  action(ExpensesTypes.DELETE_EXPENSES_REQUEST, { id });

export const deleteExpensesSuccess = () =>
  action(ExpensesTypes.DELETE_EXPENSES_SUCCESS, {});

export const deleteExpensesFailure = () => action(ExpensesTypes.DELETE_EXPENSES_FAILURE, {});

export const cleanViewARespExpenses = () =>
  action(ExpensesTypes.CLEAN_EXPENSES_RESPONSE, {});

