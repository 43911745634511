import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { IEmployee } from "../../store/ducks/employee/types";
import { ApplicationState } from "../../store";
import {
  deleteEmployeeRequest,
  employeeRequest,
  viewEmployee,
} from "../../store/ducks/employee/actions";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Funcionario: React.FC = () => {
  const dispatch = useDispatch();

  const funcionario = useSelector<ApplicationState, IEmployee[]>(
    (state) => state.employee.data
  );
  const funcionarioLoad = useSelector<ApplicationState>(
    (state) => state.employee.loading
  );

  const setView = useCallback(
    (client) => {
      dispatch(viewEmployee(client));
    },
    [dispatch]
  );

  const getClient = useCallback(() => {
    dispatch(employeeRequest());
  }, [dispatch]);

  const deleteUser = useCallback(
    (id) => {
      dispatch(deleteEmployeeRequest(id));
      setTimeout(() => {
        window.location.reload();
        toast.success("Usuario deletado com sucesso");
        getClient();
      }, 1000);
    },
    [dispatch, getClient]
  );

  if (localStorage.getItem("usuarioFunc") !== null) {
    localStorage.removeItem("usuarioFunc");
  }

  useEffect(() => {
    getClient();
  }, [getClient]);

  setTimeout(() => {
    //@ts-ignore
    $("#bolsa0km").DataTable({
      destroy: true,
      paging: false,
    });


    $(".dashboard-content-one").on('click', function () {
      //@ts-ignore
      $(".collapse").removeClass("show");
    });

    $(document).on('click', '.actionStopPropagation', function (e) {
      e.stopPropagation();
    });
  }, 600);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Usuarios</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Usuarios</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body" id="bolsa0kmCard">
              <div className="heading-layout1 mt-2 mb-4"></div>
              <div className="table-responsive" style={{ overflow: "unset" }}>
                {funcionarioLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Permissao</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!funcionario
                        ? ""
                        : funcionario
                          .map((client) => (
                            <tr
                              key={client.id}
                              data-toggle="collapse"
                              data-target={"#folder" + client.id}
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <td
                                className="infoCarroCard contracs--font"
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >
                                {client.nome}
                              </td>
                              <td
                                className="infoCarroCard contracs--font"
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >
                                {client.email}
                              </td>
                              <td
                                className="infoCarroCard contracs--font"
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >
                                {client.permissao}
                              </td>
                              <td
                                className="infoBolsaAcoes info--contracts"
                                style={{ textAlign: "center" }}
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >
                                <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                                <div
                                  id={"folder" + client.id}
                                  className="collapse action__collapse action__collapse--contracts"
                                  data-parent="#myGroup"
                                >
                                  <Link
                                    className="item__action"
                                    to={"/editar-usuarios"}
                                    onClick={() => {
                                      setView(client);
                                    }}
                                    style={{
                                      borderBottom: "1.8px solid #021933",
                                    }}
                                  >
                                    <i
                                      className="fas fa-pencil-alt text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Editar</span>
                                  </Link>

                                  <Link
                                    className="item__action actionStopPropagation"
                                    to="/usuarios"
                                    onClick={() => deleteUser(client.id)}
                                    style={{
                                      borderBottom: "1.8px solid #1d1d1d",
                                    }}
                                  >
                                    <i
                                      className="fas fa-trash text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Excluir</span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
                <ToastContainer autoClose={10000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-usuarios">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funcionario;
