import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { UserTypes } from "./types";
import {
  userRequest,
  userSuccess,
  userFailure,
  userProfileRequest,
  userProfileSuccess,
  userProfileFailure,
  putUserRequest,
  putUserSuccess,
  putUserFailure,
  userLojaRequest,
  userLojaSuccess,
  userLojaFailure,
  updateSenhaRequest,
  updateSenhaSuccess,
  updateSenhaFailure,
} from "./actions";

function* userRegister({ payload }: ReturnType<typeof userRequest>) {
  const dataRegister = payload.data;
  try {
    const response = yield call(api.post, "/user", dataRegister);
    yield put(userSuccess(response.data.response));
  } catch (error) {
    yield put(
      userFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* userProfile({ payload }: ReturnType<typeof userProfileRequest>) {
  try {
    const iduser = payload.idUser;

    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const response = yield call(api.get, "/user/" + iduser, auth);
    yield put(userProfileSuccess(response.data.params));
  } catch (error) {
    yield put(
      userProfileFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putUser({ payload }: ReturnType<typeof putUserRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputuser = payload.Iputuser;

    const id = payload.Iputuser.id;

    const response = yield call(api.put, "/user/" + id, iputuser, auth);

    yield put(putUserSuccess(response.data.response));
  } catch (error) {
    yield put(
      putUserFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* userLoja({ payload }: ReturnType<typeof userLojaRequest>) {
  try {
    const idloja = payload.idLoja;

    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const response = yield call(api.get, "/lojas/" + idloja, auth);
    yield put(userLojaSuccess(response.data.params));
  } catch (error) {
    yield put(
      userLojaFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* updateSenha({ payload }: ReturnType<typeof updateSenhaRequest>) {
  const dataUpdate = payload.data;

  try {
    const response = yield call(api.patch, "/user", dataUpdate);

    yield put(updateSenhaSuccess(response.data.response));
  } catch (error) {
    yield put(updateSenhaFailure({ ...error.response.data.response }));
  }
}

export default all([
  takeLatest(UserTypes.USER_REQUEST, userRegister),
  takeLatest(UserTypes.PROFILE_USER_REQUEST, userProfile),
  takeLatest(UserTypes.PUT_USER_REQUEST, putUser),
  takeLatest(UserTypes.GET_LOJA_REQUEST, userLoja),
  takeLatest(UserTypes.UPDATE_SENHA_REQUEST, updateSenha),
]);
