import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { DeclarationsTypes } from "./types";
import {
  declarationsRequest,
  declarationsSuccess,
  declarationsFailure,
  postDeclarationsRequest,
  postDeclarationsSuccess,
  postDeclarationsFailure,
  putDeclarationsRequest,
  putDeclarationsSuccess,
  putDeclarationsFailure,
  deleteDeclarationsRequest,
  deleteDeclarationsSuccess,
  deleteDeclarationsFailure,
} from "./actions";

function* getDeclarations({ payload }: ReturnType<typeof declarationsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };
    const params = payload.paremtros;

    const response = yield call(api.get, "/declarations"+params, auth);

    yield put(declarationsSuccess(response.data.params));
  } catch (error) {
    yield put(declarationsFailure());
  }
}

function* postDeclarations({ payload }: ReturnType<typeof postDeclarationsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const IpostContract = payload.ipostDeclarations;

    const response = yield call(api.post, "/declarations", IpostContract, auth);

    yield put(postDeclarationsSuccess(response.data.response));
  } catch (error) {
    yield put(
      postDeclarationsFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putDeclarations({ payload }: ReturnType<typeof putDeclarationsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const IputContract = payload.iputDeclarations;

    const id = payload.iputDeclarations.id;

    const response = yield call(api.put, "/declarations/" + id, IputContract, auth);

    yield put(putDeclarationsSuccess(response.data.response));
  } catch (error) {
    yield put(putDeclarationsFailure());
  }
}

function* deleteDeclarations({ payload }: ReturnType<typeof deleteDeclarationsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/declarations/" + id, auth);

    yield put(deleteDeclarationsSuccess());
  } catch (error) {
    yield put(deleteDeclarationsFailure());
  }
}

export default all([
  takeLatest(DeclarationsTypes.DECLARATION_REQUEST, getDeclarations),
  takeLatest(DeclarationsTypes.POST_DECLARATION_REQUEST, postDeclarations),
  takeLatest(DeclarationsTypes.PUT_DECLARATION_REQUEST, putDeclarations),
  takeLatest(DeclarationsTypes.DELETE_DECLARATION_REQUEST, deleteDeclarations),
]);
