import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";

import { ToastContainer, toast } from "react-toastify";
import { Select } from "react-functional-select";
import { ICategoryFin } from "../../store/ducks/categoryFinancial/types";
import { categoryFinRequest } from "../../store/ducks/categoryFinancial/actions";
import { cleanViewARespFinancial, postFinancialRequest } from "../../store/ducks/financial/actions";

type BooleanOption = {
  readonly id: any;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: "Entrada", name: "Entrada" },
  { id: "Saida", name: "Saida" },
];

const BOOL_OPTIONS1: BooleanOption[] = [
  { id: "Sim", name: "Sim" },
  { id: "Nao", name: "Não" },
];

const CadFinanceiro: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const catFinanceiro = useSelector<ApplicationState, ICategoryFin[]>(
    (state) => state.categoryFinancial.data
  );

  const [SelectednewUsed, setSelectednewUsed] = useState<BooleanOption | null>(
    { id: "Entrada", name: "Entrada" }
  );

  const [SelectedPago, setSelectedPago] = useState<BooleanOption | null>(
    { id: "Nao", name: "Não" }
  );

  const [selectedCategoria, setSelectedCateogira] = useState<ICategoryFin | null>(
    null
  );

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.financial
  );

  const [form, setForm] = useState({
    type: "",
    descricao: "",
    valor: "",
    data_lancamento: "",
    fk_categoria: "",
  });

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      toast.info("Carregando, aguarde");
      btnDisabled();

      const {
        descricao,
        data_lancamento,
      } = form;

      const type: any = SelectednewUsed?.name;
      const fk_categoria: any = selectedCategoria?.id;
      const pago: any = SelectedPago?.id;
      //@ts-ignore
      const valor = document.getElementById("valor").value.replaceAll(".", "").replaceAll(",", ".");

      if (
        !fk_categoria
      ) {
        toast.error("Digite todos os campos obrigatórios");
        btnNoDisabled();
        return;
      }


      dispatch(
        postFinancialRequest({
          type,
          descricao,
          valor,
          data_lancamento,
          pago,
          fk_categoria,
        })
      );
    },
    [dispatch, form, SelectednewUsed, selectedCategoria, SelectedPago]
  );

  const notify = useCallback(
    (event) => {
      if (event === 201) {
        toast.dismiss()
        toast.success("Lançamento cadastrado com sucesso");
        setTimeout(() => history.push("/financeiro"), 2000);
      }

      if (event === 404) {
        toast.error("Falha ao cadastrar lançamento");
        btnNoDisabled();
      }
    },
    [history]
  );

  const cleanView = useCallback(() => {
    dispatch(cleanViewARespFinancial());
  }, [dispatch]);

  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    notify(responseApi.response.code);
  }, [notify, responseApi.response, cleanView]);


  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      })

    },
    [form]
  );


  const getNovoUsadoValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onNovoUsadoChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectednewUsed(newUsed),
    []
  );
  const getNovoUsadoLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );

  const getPagoValue = useCallback(
    (pago: BooleanOption): number => pago.id,
    []
  );
  const onPagoChange = useCallback(
    (pago: BooleanOption | null): any => setSelectedPago(pago),
    []
  );
  const getPagoLabel = useCallback(
    (pago: BooleanOption): string => `${pago.name}`,
    []
  );

  const getCategoriaValue = useCallback(
    (categoria: any): number => categoria.id,
    []
  );
  const onCategoriaChange = useCallback(
    (categoria: any | null): any => setSelectedCateogira(categoria),
    []
  );
  const getCategoriaLabel = useCallback(
    (categoria: any): string => `${categoria.nome}`,
    []
  );

  const getCategoria = useCallback(() => {
    dispatch(categoryFinRequest("?pp=10000"));
  }, [dispatch]);

  useEffect(() => { getCategoria() }, [getCategoria])


  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Lançamentos</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Adicionar lançamento</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Cadastro Lançamentos</h3>
                  <span className="label-form-input">* Campos obrigatório</span>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Tipo *
                    </label>
                    <Select
                      options={BOOL_OPTIONS}
                      onOptionChange={onNovoUsadoChange}
                      getOptionValue={getNovoUsadoValue}
                      getOptionLabel={getNovoUsadoLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      initialValue={BOOL_OPTIONS[0]}
                    />
                  </div>


                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Categoria *
                        </label>
                    <Select
                      options={catFinanceiro}
                      onOptionChange={onCategoriaChange}
                      getOptionValue={getCategoriaValue}
                      getOptionLabel={getCategoriaLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Descrição
                    </label>
                    <input
                      value={form.descricao}
                      name="descricao"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Descrição"
                      required={true}
                    />
                  </div>

                  <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                    <label className="label-form-input" htmlFor="valor">
                      Valor*
                    </label>
                    <input
                      name="valor"
                      type="text"
                      className="form-control dinheiro"
                      id="valor"
                      placeholder="Valor"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Data Lançamento
                    </label>
                    <input
                      value={form.data_lancamento}
                      name="data_lancamento"
                      onChange={handleChange}
                      type="date"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Dia Vencimento"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Pago *
                    </label>
                    <Select
                      options={BOOL_OPTIONS1}
                      onOptionChange={onPagoChange}
                      getOptionValue={getPagoValue}
                      getOptionLabel={getPagoLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      initialValue={BOOL_OPTIONS1[1]}
                    />
                  </div>




                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default CadFinanceiro;
