import { Reducer } from "redux";
import producer from "immer";

import { UserState, UserTypes } from "./types";

const INITIAL_STATE: UserState = {
  data: {
    senha: "",
    nome: "",
    whatsapp: "",
    permissao: "",
    auth_token: "",
  },
  profile: {
    id: "",
    nome: "",
    email: "",
    whatsapp: "",
    permissao: "",
    fk_loja: "",
    fornecdedor: "",
  },
  response: {
    code: "",
    message: "",
  },
  putUser: {
    id: "",
    nome: "",
    whatsapp: "",
  },
  userLoja: {
    id: "",
    nome: "",
    email: "",
    whatsapp: "",
    cnpj: "",
    type: "",
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    endNumero: "",
    created_by: "",
    logo: "",
    telefone: "",
  },
  updateSenha: {
    email: "",
    senha: "",
  },
  loading: false,
  error: false,
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserTypes.USER_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.response = action.payload.data;
      });
    case UserTypes.USER_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case UserTypes.PROFILE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserTypes.PROFILE_USER_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.profile = action.payload.data;
      });
    case UserTypes.PROFILE_USER_FAILURE:
      return producer(state, (draft) => {
        draft.loading = false;
      });

    case UserTypes.PUT_USER_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case UserTypes.PUT_USER_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.putUser = {
          id: "",
          nome: "",
          whatsapp: "",
        };
        draft.response = action.payload.data;
      });
    case UserTypes.PUT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UserTypes.GET_LOJA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserTypes.GET_LOJA_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.userLoja = action.payload.data;
      });
    case UserTypes.GET_LOJA_FAILURE:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case UserTypes.UPDATE_SENHA_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case UserTypes.UPDATE_SENHA_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.updateSenha = {
          email: "",
          senha: "",
        };
        draft.response = action.payload.data;
      });
    case UserTypes.UPDATE_SENHA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
