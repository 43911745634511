import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { authRequest, cleanViewAResp } from "../../store/ducks/auth/actions";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApplicationState } from "../../store";
import logo from "./logo-app.png";
import { Helmet } from "react-helmet";

interface ILogin {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState<ILogin>({ email: "", password: "" });

  const returnApi: any = useSelector<ApplicationState>((state) => state.auth);

  const login = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();

      dispatch(
        authRequest({
          email: form.email,
          password: form.password,
        })
      );
    },
    [dispatch, form]
  );

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  const notify = useCallback((event) => {
    if (event === 402 || event === 404) {
      toast.error("Email e/ou senha inválidos!");
      btnNoDisabled();
    }
  }, []);

  const cleanView = useCallback(() => {
    dispatch(cleanViewAResp());
  }, [dispatch]);

  useEffect(() => {
    if (returnApi.response.code !== "") {
      cleanView();
    }
    notify(returnApi.response.code);
  }, [cleanView, notify, returnApi.response]);

  return (
    <div className="login-page-warp">
      <Helmet>
        <meta
          property="og:url"
          content="https://www.your-domain.com/your-page.html"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Modelo x" />
        <meta property="og:description" content={"teste"} />
        <meta
          property="og:image"
          content="https://www.your-domain.com/path/image.jpg"
        />
      </Helmet>

      <div className="login-page-content">
        <div className="login-box login--alt">
          <div className="item-logo">
            <img src={logo} alt=".." />
          </div>
          <form onSubmit={login} className="login-form">
            <div className="form-group">
              <input
                value={form.email}
                onChange={handleChange}
                name="email"
                type="email"
                className="form-control form--altLogin"
                placeholder="Email"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                required={true}
              />
              <i className="fa fa-envelope icon--altLogin "></i>
            </div>
            <div className="form-group">
              <input
                value={form.password}
                onChange={handleChange}
                name="password"
                type="password"
                placeholder="Senha"
                className="form-control form--altLogin"
                id="exampleInputPassword1"
                required={true}
              />
              <i className="fa fa-lock icon--altLogin"></i>
            </div>
            <div className="form-group">
              <button type="submit" className="login-btn btn--login" id="btn_submit">
                Acessar
              </button>
            </div>
            <ToastContainer autoClose={5000} position="top-right" />
          </form>
          {/* <div className="login-social">
            <p>
              Não tem uma conta?
              <Link to={"/cadastro-loja"}> Cadastre-se</Link>
            </p>
            <p>
              <Link to={"/recuperar"}>Esqueci minha senha</Link>
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
