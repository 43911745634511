/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { Select } from "react-functional-select";
import { bankAutoRequest } from "../../../store/ducks/bankAuto/actions";
import { deletePagtoParcautoRequest } from "../../../store/ducks/pagtoParcauto/actions";


const SelectParcautoEdit = (props: any) => {
  const { oldParcauto, newParcauto, setNewParcauto, setoldParcauto, setForm, form,
    setSelectedOptionUserParcauto, setSelectedEditOptionUserParcauto } = props;
  const dispatch = useDispatch();

  const bancoAutoMarcas: any = useSelector<ApplicationState>(
    (state) => state.bankAuto.data
  );

  const deleteParcauto = useCallback(
    (id) => {
      dispatch(deletePagtoParcautoRequest(id));
      // toast.success("Banco deletado com sucesso");
    },
    [dispatch]
  );

  const handleInputChangeParcauto = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newParcauto];
    list[index][name] = value;
    setNewParcauto(list);
  };

  const handleRemoveParcauto = (index: any) => {
    const list = [...newParcauto];
    list.splice(index, 1);
    setNewParcauto(list);
  };

  const handleAddParcauto = () => {
    setNewParcauto([
      ...newParcauto,
      { idP: "", valor: "", fk_banco: "" },
    ]);
  };

  const getOptionLabelBanco = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBanco = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );
  const onOptionChangeBanco = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    let idP = "";
    const list: any = [...newParcauto];

    if (option?.id === undefined) {
      nomeUser = list[i]["fk_banco"]
      idP = list[i]["idP"]
    } else {
      nomeUser = option?.id
      idP = option?.id
    }
    //@ts-ignore
    list[i]["fk_banco"] = nomeUser;
    list[i]["idP"] = idP;

    setSelectedOptionUserParcauto(option?.id + i)

  }, [newParcauto]);

  // Bank handle input change
  const handleInputChangeParcautoOld = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...oldParcauto];
    list[index][name] = value;
    setoldParcauto(list);
  };

  // Bank handle click event of the Remove button
  const handleRemoveParcautoOld = (index: any, id: any) => {
    const list = [...oldParcauto];
    list.splice(index, 1);
    setoldParcauto(list);
    deleteParcauto(id);
    setForm({
      ...form,
      ["pagtoParcauto"]: list,
    });
  };


  const getOptionLabelBanco1 = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBanco1 = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );
  const onOptionChangeBanco1 = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    let idP = "";
    const list: any = [...oldParcauto];

    if (option?.id === undefined) {
      nomeUser = list[i]["fk_banco"]
      idP = list[i]["idP"]
    } else {
      nomeUser = option?.id
      idP = option?.id
    }
    //@ts-ignore
    list[i]["fk_banco"] = nomeUser;
    list[i]["idP"] = idP;

    setSelectedEditOptionUserParcauto(option?.id + i)

  }, [oldParcauto]);

  const oldParcKey = useCallback((e, i) => {

    const list: any = [...oldParcauto];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");


  }, [oldParcauto])


  const newParcKey = useCallback((e, i) => {

    const list: any = [...newParcauto];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");


  }, [newParcauto])


  const getBankAuto = useCallback(() => {
    dispatch(bankAutoRequest("?pp=100000"));
  }, [dispatch]);

  useEffect(() => {
    getBankAuto();

  }, [])

  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Pagamento Thai Motors</h3>
          </div>
        </div>

        <div className="row">

          {oldParcauto.map((x: any, i: any) => {
            setTimeout(() => {
              $("#oldValorParcauto" + i).val(parseFloat(x.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
            }
              , 200)

            const nameBanco = bancoAutoMarcas.filter((a: any) => a.id === x.fk_banco)[0];

            return (
              <>
                <input
                  className="form-control"
                  name="id"
                  placeholder="id"
                  value={x.id}
                  onChange={(e) => handleInputChangeParcautoOld(e, i)}
                  type="hidden"
                />


                <div className="col-xl-4 col-lg-4 col-12 form-group">
                  <Select
                    options={bancoAutoMarcas}
                    addClassNames={true}
                    placeholder={"Selecione"}
                    onOptionChange={(e) => onOptionChangeBanco1(e, i)}
                    getOptionLabel={getOptionLabelBanco1}
                    getOptionValue={getOptionValueBanco1}
                    initialValue={nameBanco}

                  />
                </div>

                <div className="col-xl-4 col-lg-4 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeParcautoOld(e, i)}
                  />
                </div>

                <div className="col-xl-4 col-lg-4 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    placeholder="Valor"
                    id={`oldValorParcauto` + i}
                    autoComplete="off"
                    onKeyUp={(e) => oldParcKey(e, i)}
                  />
                </div>

                <div className="form-group col-xl-4 col-lg-4 col-12">
                  <button
                    type="button"
                    className="btn-add btn-remove"
                    onClick={() => handleRemoveParcautoOld(i, x.id)}
                  >
                    Remover
                  </button>
                </div>

              </>
            );
          })}

          {newParcauto.map((x: any, i: any) => {
            return (
              <>

                <div className="col-xl-4 col-lg-4 col-12 form-group">
                  <Select
                    options={bancoAutoMarcas}
                    addClassNames={true}
                    placeholder={"Selecione"}
                    onOptionChange={(e) => onOptionChangeBanco(e, i)}
                    getOptionLabel={getOptionLabelBanco}
                    getOptionValue={getOptionValueBanco}
                    isClearable

                  />
                </div>

                <div className="col-xl-4 col-lg-4 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeParcauto(e, i)}
                  />
                </div>


                <div className="col-xl-4 col-lg-4 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    placeholder="Valor"
                    id={`valorParcauto` + i}
                    autoComplete="off"
                    onKeyUp={(e) => newParcKey(e, i)}
                  />
                </div>

                <div className="form-group col-xl-4 col-lg-4 col-12">
                  <button
                    type="button"
                    className="btn-add btn-remove"
                    onClick={() => handleRemoveParcauto(i)}
                  >
                    Remover
                  </button>
                </div>
              </>
            );
          })}
        </div>

        <div className="row">
          <div className="col-lg-3 col-12 form-group mg-t-8">
            <button type="button" className="btn-add" onClick={handleAddParcauto}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectParcautoEdit;
