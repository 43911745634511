import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { MarkTypes } from "./types";
import {  markSuccess, markFailure, postMarkRequest, postMarkSuccess, postMarkFailure,
  putMarkRequest, putMarkSuccess, putMarkFailure, deleteMarkRequest, 
  deleteMarkSuccess, deleteMarkFailure } from "./actions";

function* getMarks() {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const response = yield call(api.get, "/marcas", auth);

    yield put(markSuccess(response.data.params.data));
  } catch (error) {
    yield put(markFailure());
  }
}
 

function* postMarks({ payload }: ReturnType<typeof postMarkRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const ipostmark = payload.Ipostmark

    yield call(api.post, "/marcas", ipostmark, auth );

    yield put(postMarkSuccess());
  } catch (error) {
    yield put(postMarkFailure());
  }
}

function* putMarks({ payload }: ReturnType<typeof putMarkRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const iputMark = payload.Iputmark

    const id = payload.Iputmark.id

    yield call(api.put, "/marcas/"+id, iputMark, auth );

    yield put(putMarkSuccess());
  } catch (error) {
    yield put(putMarkFailure());
  }
}

function* deleteMarks({ payload }: ReturnType<typeof deleteMarkRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const id = payload.id

    yield call(api.delete, "/marcas/"+id, auth );

    yield put(deleteMarkSuccess());
  } catch (error) {
    yield put(deleteMarkFailure());
  }
}

export default all([
  takeLatest(MarkTypes.MARK_REQUEST, getMarks),
  takeLatest(MarkTypes.POST_MARK_REQUEST, postMarks),
  takeLatest(MarkTypes.PUT_MARK_REQUEST, putMarks),
  takeLatest(MarkTypes.DELETE_MARK_REQUEST, deleteMarks),
]);
