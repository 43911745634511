export enum UserTypes {
  USER_REQUEST = "@user/USER_REQUEST",
  USER_SUCCESS = "@user/USER_SUCCESS",
  USER_FAILURE = "@user/USER_FAILURE",

  PROFILE_USER_REQUEST = "@user/PROFILE_USER_REQUEST",
  PROFILE_USER_SUCCESS = "@user/PROFILE_USER_SUCCESS",
  PROFILE_USER_FAILURE = "@user/PROFILE_USER_FAILURE",

  PUT_USER_REQUEST = "@user/PUT_USER_REQUEST",
  PUT_USER_SUCCESS = "@user/PUT_USER_SUCCESS",
  PUT_USER_FAILURE = "@user/PUT_USER_FAILURE",

  GET_LOJA_REQUEST = "@user/GET_LOJA_REQUEST",
  GET_LOJA_SUCCESS = "@user/GET_LOJA_SUCCESS",
  GET_LOJA_FAILURE = "@user/GET_LOJA_FAILURE",

  UPDATE_SENHA_REQUEST = "@user/UPDATE_SENHA_REQUEST",
  UPDATE_SENHA_SUCCESS = "@user/UPDATE_SENHA_SUCCESS",
  UPDATE_SENHA_FAILURE = "@user/UPDATE_SENHA_FAILURE",
}

export interface IRegisterUser {
  senha: any;
  nome: string;
  whatsapp: string;
  permissao: string;
  auth_token: string;
  fk_loja?: string;
}

export interface IProfileUser {
  id: string;
  nome: string;
  email: string;
  whatsapp: string;
  permissao: string;
  fk_loja: string;
  fornecdedor: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface IPutUser {
  id: string;
  nome: string;
  whatsapp: string;
}

export interface IUserLoja {
  id: string;
  nome: string;
  email: string;
  whatsapp: string;
  cnpj: string;
  type: string;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  endNumero?: string;
  logo?: string;
  created_by: string;
  telefone?: string;
}

export interface IUpSenhaUser {
  email: string;
  senha?: string;
}

export interface UserState {
  readonly data: IRegisterUser;
  readonly profile: IProfileUser;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly putUser: IPutUser;
  readonly updateSenha: IUpSenhaUser;
  readonly error: boolean;
  readonly userLoja: IUserLoja;
}
