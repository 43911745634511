import React, { useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { modelRequest } from "../../store/ducks/models/actions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { IModels } from "../../store/ducks/models/types";

interface Column {
  // id: "op" |"modelos" | "acoes";
  id: "modelos";
  label: string;
  minWidth?: number;
  align?: any;
  format?: (value: number) => string;
}

// const columns: Column[] = [{ id: "op", label: "Op.", minWidth: 5 }, { id: "modelos", label: "Modelos", minWidth: 85 }, { id: "acoes", label: "Ações", align: "center", minWidth: 10 },];
const columns: Column[] = [{ id: "modelos", label: "Modelos", minWidth: 85 }];

interface Data {
  fipe_marca: string;
  fipe_name: string;
  id: any;
  key: string;
  marca: string;
  name: string;
}

interface IData {
  data?: Data[];

  filterGlobal?: any;
  filterMarca?: string;
  filterCarroceria?: number;
  filterValorMin?: any;
  filterValorMax?: any;
}

const StickyHeadTable: React.FC<IData> = ({ data = [], filterGlobal }) => {
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(1000);
  const dispatch = useDispatch();

  const models: any = useSelector<ApplicationState, IModels[]>(
    (state) => state.model.data
  );

  const getModelo = useCallback(() => {
    dispatch(modelRequest("?pp=550"));
  }, [dispatch]);

  useEffect(() => {
    getModelo();
  }, [getModelo]);

  return (
    <Paper>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          className="table display data-table dataTable no-footer tableCar"
        >
          <TableHead className="sorting_asc">
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    width: column.minWidth + "%",
                    textAlign: column.align,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

              .filter((searchFilter: any) =>
                filterGlobal === ""
                  ? searchFilter
                  : searchFilter.name
                      .toLowerCase()
                      .includes(filterGlobal.toLowerCase()) ||
                    searchFilter.fipe_marca
                      .toString()
                      .toLowerCase()
                      .includes(filterGlobal.toLowerCase())
              )
              .map((row, index) => {

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {/* <TableCell style={{ textAlign: "center" }}>
                      {
                        postModel.length === 0 ? ( <div
                          className="circuloStory"
                          style={{ backgroundColor: "red", marginLeft: '7px'}}
                        ></div>) : ( <div
                          className="circuloStory"
                          style={{ backgroundColor: "green", marginLeft: '7px' }}
                        ></div>)
                      }
                    </TableCell> */}
                    <TableCell>
                      <div className="nameCarro">{row.name}</div>
                    </TableCell>
                    {/* <TableCell style={{ textAlign: "center" }}>
                      <DropdownAction
                        view={row}

                      ></DropdownAction>
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StickyHeadTable;
