import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { IColor } from "../../store/ducks/color/types";
import { ApplicationState } from "../../store";
import {
  colorRequest,
  viewColor,
  deleteColorRequest,
} from "../../store/ducks/color/actions";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Cores: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  if (UserLogged !== "super_admin") {
    history.push("/carros")
  }

  const cores = useSelector<ApplicationState, IColor[]>(
    (state) => state.color.data
  );

  const colorLoad = useSelector<ApplicationState>(
    (state) => state.color.loading
  );


  const setCor = useCallback(
    (cor) => {
      dispatch(viewColor(cor));
    },
    [dispatch]
  );

  const getCor = useCallback(() => {
    dispatch(colorRequest("?pp=800"));
  }, [dispatch]);

  const deleteCor = useCallback(
    (id) => {
      dispatch(deleteColorRequest(id));
      toast.success("Cor deletada com sucesso");
      setTimeout(() => {
        window.location.reload();
        getCor();
      }, 1000);
    },
    [dispatch, getCor]
  );

  useEffect(() => {
    getCor();
  }, [getCor]);

  setTimeout(() => {
    //@ts-ignore
    $("#table__color").DataTable({
      destroy: true,
      paging: false
    });
  }, 800);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Cores</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Cores</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                </div>
              </div>
              <div className="table-responsive">
              {colorLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                <table id="table__color" className="table display  text-nowrap ">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!cores
                      ? ""
                      : cores.map((cor) => (
                        <tr key={cor.id}>
                          <td>{cor.nome}</td>
                          <td>
                            <div className="dropdown">
                              <a
                                href="# "
                                className="dropdown-toggle dropToggle-options"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span className="flaticon-more-button-of-three-dots"></span>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <Link
                                  className="dropdown-item"
                                  to={"/cadastro-cor"}
                                  onClick={() => {
                                    setCor(cor);
                                  }}
                                >
                                  <i className="fas fa-cogs text-dark-pastel-green" />
                                  <span>
                                    Editar
                                    </span>
                                </Link>
                                <Link className="dropdown-item" to={"/cores"}>
                                  <i className="fas fa-times text-orange-red" />
                                  <span onClick={() => deleteCor(cor.id)}>
                                    Excluir
                                    </span>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                )}
                <ToastContainer autoClose={5000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-cor">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cores;
