export enum ExpensesTypes {
  EXPENSES_REQUEST = "@expenses/EXPENSES_REQUEST",
  EXPENSES_SUCCESS = "@expenses/EXPENSES_SUCCESS",
  EXPENSES_FAILURE = "@expenses/EXPENSES_FAILURE",
  POST_EXPENSES_REQUEST = "@expenses/POST_EXPENSES_REQUEST",
  POST_EXPENSES_SUCCESS = "@expenses/POST_EXPENSES_SUCCESS",
  POST_EXPENSES_FAILURE = "@expenses/POST_EXPENSES_FAILURE",
  PUT_EXPENSES_REQUEST = "@expenses/PUT_EXPENSES_REQUEST",
  PUT_EXPENSES_SUCCESS = "@expenses/PUT_EXPENSES_SUCCESS",
  PUT_EXPENSES_FAILURE = "@expenses/PUT_EXPENSES_FAILURE",
  VIEW_EXPENSES = "@expenses/VEIW_EXPENSES",
  CLEAN_VIEW_EXPENSES = "@expenses/CLEAN_VIEW_EXPENSES",
  DELETE_EXPENSES_REQUEST = "@expenses/DELETE_EXPENSES_REQUEST",
  DELETE_EXPENSES_SUCCESS = "@expenses/DELETE_EXPENSES_SUCCESS",
  DELETE_EXPENSES_FAILURE = "@expenses/DELETE_EXPENSES_FAILURE",
  CLEAN_EXPENSES_RESPONSE = "@expenses/CLEAN_EXPENSES_RESPONSE",
}

export interface IpostExpenses {
  type: string;
  descricao: string;
  valor: string;
  dia_vencto: string;
  nome: string;
  fk_categoria: string;
}

export interface IputExpenses {
  id: string;
  type: string;
  descricao: string;
  valor: string;
  dia_vencto: string;
  nome: string;
  fk_categoria: string;

}

export interface IExpenses {
  id: string;
  type: string;
  descricao: string;
  valor: string;
  dia_vencto: string;
  nome: string;
  fk_categoria: string;
  categoria?: string;
}

export interface IResponse {
  code: string;
  message: string;
}



export interface ExpensesState {
  readonly data: IExpenses[];
  readonly view: IExpenses;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
