import { action } from "typesafe-actions";
import { OptionalTypes, IOptional, IpostOptional, IputOptional, IResponse } from "./types";

export const optionalRequest = (paremtros: any) =>
  action(OptionalTypes.OPTIONAL_REQUEST, {paremtros});

export const optionalSuccess = (data: IOptional ) =>
  action(OptionalTypes.OPTIONAL_SUCCESS, { data });

export const optionalFailure = () => action(OptionalTypes.OPTIONAL_FAILURE, {});

export const postOptionalRequest = (Ipostoptional: IpostOptional) =>
  action(OptionalTypes.POST_OPTIONAL_REQUEST, {Ipostoptional});

export const postOptionalSuccess = (data: IResponse) =>
  action(OptionalTypes.POST_OPTIONAL_SUCCESS, {data});

export const postOptionalFailure = (data: IResponse) => action(OptionalTypes.POST_OPTIONAL_FAILURE, {data});

export const viewOptional = (view: IOptional) =>
  action(OptionalTypes.VIEW_OPTIONAL, {view});

export const cleanViewOptional = () =>
  action(OptionalTypes.CLEAN_VIEW_OPTIONAL, {})

export const putOptionalRequest = (Iputoptional: IputOptional) =>
  action(OptionalTypes.PUT_OPTIONAL_REQUEST, {Iputoptional});

export const putOptionalSuccess = () =>
  action(OptionalTypes.PUT_OPTIONAL_SUCCESS, {});

export const putOptionalFailure = () => action(OptionalTypes.PUT_OPTIONAL_FAILURE, {});

export const deleteOptionalRequest = (id: string) =>
  action(OptionalTypes.DELETE_OPTIONAL_REQUEST, {id});

export const deleteOptionalSuccess = () =>
  action(OptionalTypes.DELETE_OPTIONAL_SUCCESS, {});

export const deleteOptionalFailure = () => action(OptionalTypes.DELETE_OPTIONAL_FAILURE, {});

