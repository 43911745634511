import React from "react";

const MenuTree = ({ path, icon, label, children, ...shared }) => {
  return (
    <li {...shared} className="nav-item sidebar-nav-item">
      <a href=" " className="nav-link">
        <i className={`flaticon-${icon}`}></i>
        <span>{label}</span>
      </a>
      <ul className="nav sub-group-menu">{children}</ul>
    </li>
  );
};
export default MenuTree;
