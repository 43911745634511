import { action } from "typesafe-actions";
import { ColorTypes, IColor, IpostColor, IputColor } from "./types";
import { IResponse } from "../auth/types";

export const colorRequest = (paremtros: any) =>
  action(ColorTypes.COLOR_REQUEST, {paremtros});

export const colorSuccess = (data: IColor ) =>
  action(ColorTypes.COLOR_SUCCESS, { data });

export const colorFailure = () => action(ColorTypes.COLOR_FAILURE, {});

export const postColorRequest = (Ipostcolor: IpostColor) =>
  action(ColorTypes.POST_COLOR_REQUEST, {Ipostcolor});

export const postColorSuccess = (data: IResponse) =>
  action(ColorTypes.POST_COLOR_SUCCESS, {data});

export const postColorFailure = (data: IResponse) => action(ColorTypes.POST_COLOR_FAILURE, {data});

export const viewColor = (view: IColor) =>
  action(ColorTypes.VIEW_COLOR, {view});

export const cleanViewColor = () =>
  action(ColorTypes.CLEAN_VIEW_COLOR, {})

export const putColorRequest = (Iputcolor: IputColor) =>
  action(ColorTypes.PUT_COLOR_REQUEST, {Iputcolor});

export const putColorSuccess = () =>
  action(ColorTypes.PUT_COLOR_SUCCESS, {});

export const putColorFailure = () => action(ColorTypes.PUT_COLOR_FAILURE, {});

export const deleteColorRequest = (id: string) =>
  action(ColorTypes.DELETE_COLOR_REQUEST, {id});

export const deleteColorSuccess = () =>
  action(ColorTypes.DELETE_COLOR_SUCCESS, {});

export const deleteColorFailure = () => action(ColorTypes.DELETE_COLOR_FAILURE, {});

