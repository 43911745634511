import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ModelsTypes } from "./types";
import {  modelSuccess, modelFailure, postModelRequest, postModelSuccess, postModelFailure,
  putModelRequest, putModelSuccess, putModelFailure, deleteModelRequest, deleteModelSuccess, deleteModelFailure, modelRequest } from "./actions";

function* getModels({ payload }: ReturnType<typeof modelRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const params = payload.paremtros;

    const response = yield call(api.get, "/modelos"+params, auth);

    yield put(modelSuccess(response.data.params.data));
  } catch (error) {
    yield put(modelFailure());
  }
}


function* postModels({ payload }: ReturnType<typeof postModelRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const ipostModel = payload.IpostModel

    yield call(api.post, "/modelos", ipostModel, auth );

    yield put(postModelSuccess());
  } catch (error) {
    yield put(postModelFailure());
  }
}

function* putModels({ payload }: ReturnType<typeof putModelRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const iputModel = payload.IputModel

    const id = payload.IputModel.id

    yield call(api.put, "/modelos/"+id, iputModel, auth );

    yield put(putModelSuccess());
  } catch (error) {
    yield put(putModelFailure());
  }
}

function* deleteModels({ payload }: ReturnType<typeof deleteModelRequest>) {
  try {
    const auth = { headers: {"Auth-Token": localStorage.getItem('Auth-Token')} }

    const id = payload.id

    yield call(api.delete, "/modelos/"+id, auth );

    yield put(deleteModelSuccess());
  } catch (error) {
    yield put(deleteModelFailure());
  }
}

export default all([
  takeLatest(ModelsTypes.MODELS_REQUEST, getModels),
  takeLatest(ModelsTypes.POST_MODELS_REQUEST, postModels),
  takeLatest(ModelsTypes.PUT_MODELS_REQUEST, putModels),
  takeLatest(ModelsTypes.DELETE_MODELS_REQUEST, deleteModels),
]);
