import { Reducer } from "redux";
import producer from "immer";

import { ProviderState, ProviderTypes } from "./types";

const INITIAL_STATE: ProviderState = {
  data: [],
  view: {
    id: "",
    nome: "",
    email: "",
    documento: "",
    telefone: "",
    celular: "",
    RG: "",
    CPF: "",
    CNPJ: "",
    razao_social: "",
    bancos: [
      {
        id: "",
        numero_banco: "",
        banco: "",
        agencia: "",
        conta: "",
        documento: "",
        favorecido: "",
        pix_banco: "",
      },
    ],
    enderecos: [
      {
        CEP: "",
        rua: "",
        numero: "",
        bairro: "",
        cidade: "",
        complemento: "",
        estado: "",
      },
    ],
  },

  loading: true,
  error: false,
  response: {
    code: "",
    message: "",
  },
};

const reducer: Reducer<ProviderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //client PF
    case ProviderTypes.PROVIDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ProviderTypes.PROVIDER_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });

    case ProviderTypes.PROVIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ProviderTypes.POST_PROVIDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ProviderTypes.POST_PROVIDER_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {
          id: "",
          nome: "",
          email: "",
          documento: "",
          telefone: "",
          celular: "",
          RG: "",
          CPF: "",
          CNPJ: "",
          razao_social: "",
          bancos: [
            {
              id: "",
              numero_banco: "",
              banco: "",
              agencia: "",
              conta: "",
              documento: "",
              favorecido: "",
              pix_banco: "",
            },
          ],
          enderecos: [
            {
              CEP: "",
              rua: "",
              numero: "",
              bairro: "",
              cidade: "",
              complemento: "",
              estado: "",
            },
          ],
        };
      });

    case ProviderTypes.POST_PROVIDER_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case ProviderTypes.VIEW_PROVIDER:
      return {
        ...state,
        view: action.payload.view,
      };

    case ProviderTypes.CLEAN_VIEW_PROVIDER:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
          email: "",
          documento: "",
          telefone: "",
          celular: "",
          RG: "",
          CPF: "",
          CNPJ: "",
          razao_social: "",
          bancos: [
            {
              id: "",
              numero_banco: "",
              banco: "",
              agencia: "",
              conta: "",
              documento: "",
              favorecido: "",
              pix_banco: "",
            },
          ],
          enderecos: [
            {
              CEP: "",
              rua: "",
              numero: "",
              bairro: "",
              cidade: "",
              complemento: "",
              estado: "",
            },
          ],
        },
      };

    case ProviderTypes.PUT_PROVIDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ProviderTypes.PUT_PROVIDER_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {
          id: "",
          nome: "",
          email: "",
          documento: "",
          telefone: "",
          celular: "",
          RG: "",
          CPF: "",
          CNPJ: "",
          razao_social: "",
          bancos: [
            {
              id: "",
              numero_banco: "",
              banco: "",
              agencia: "",
              conta: "",
              documento: "",
              favorecido: "",
              pix_banco: "",
            },
          ],
          enderecos: [
            {
              CEP: "",
              rua: "",
              numero: "",
              bairro: "",
              cidade: "",
              complemento: "",
              estado: "",
            },
          ],
        };
      });

    case ProviderTypes.PUT_PROVIDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ProviderTypes.CLEAN_PROVIDER_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };

    default:
      return state;
  }
};

export default reducer;
