import { action } from "typesafe-actions";
import { GearshiftTypes, IGearshift, IpostGearshift, IputGearshift, IResponse } from "./types";

export const gearshiftRequest = (paremtros: any) =>
  action(GearshiftTypes.GEARSHIFT_REQUEST, {paremtros});

export const gearshiftSuccess = (data: IGearshift ) =>
  action(GearshiftTypes.GEARSHIFT_SUCCESS, { data });

export const gearshiftFailure = () => action(GearshiftTypes.GEARSHIFT_FAILURE, {});

export const postGearshiftRequest = (Ipostgearshift: IpostGearshift) =>
  action(GearshiftTypes.POST_GEARSHIFT_REQUEST, {Ipostgearshift});

export const postGearshiftSuccess = (data: IResponse) =>
  action(GearshiftTypes.POST_GEARSHIFT_SUCCESS, {data});

export const postGearshiftFailure = (data: IResponse) => action(GearshiftTypes.POST_GEARSHIFT_FAILURE, { data });

export const viewGearshift = (view: IGearshift) =>
  action(GearshiftTypes.VIEW_GEARSHIFT, {view});

export const cleanViewGearshift = () =>
  action(GearshiftTypes.CLEAN_VIEW_GEARSHIFT, {})

export const putGearshiftRequest = (Iputgearshift: IputGearshift) =>
  action(GearshiftTypes.PUT_GEARSHIFT_REQUEST, {Iputgearshift});

export const putGearshiftSuccess = () =>
  action(GearshiftTypes.PUT_GEARSHIFT_SUCCESS, {});

export const putGearshiftFailure = () => action(GearshiftTypes.PUT_GEARSHIFT_FAILURE, {});

export const deleteGearshiftRequest = (id: string) =>
  action(GearshiftTypes.DELETE_GEARSHIFT_REQUEST, {id});

export const deleteGearshiftSuccess = () =>
  action(GearshiftTypes.DELETE_GEARSHIFT_SUCCESS, {});

export const deleteGearshiftFailure = () => action(GearshiftTypes.DELETE_GEARSHIFT_FAILURE, {});

