import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { Select } from "react-functional-select";
import { bankAutoRequest } from "../../../store/ducks/bankAuto/actions";

const SelectParcauto = (props: any) => {
  const { newParcauto, setNewParcauto, setSelectedOptionUserParcauto } = props;
  const dispatch = useDispatch();


  const bancoAutoMarcas: any = useSelector<ApplicationState>(
    (state) => state.bankAuto.data
  );


  const handleRemoveParcauto = (index: any) => {
    const list = [...newParcauto];
    list.splice(index, 1);
    setNewParcauto(list);
  };

  const handleAddParcauto = () => {

    setNewParcauto([
      ...newParcauto,
      { idP: "", valor: "", fk_banco: "" },
    ]);
  };

  const getOptionLabelBanco1 = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBanco1 = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );
  const onOptionChangeBanco1 = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    let idP = "";
    const list: any = [...newParcauto];

    if (option?.id === undefined) {
      nomeUser = list[i]["fk_banco"]
      idP = list[i]["idP"]
    } else {
      nomeUser = option?.id
      idP = option?.id
    }
    //@ts-ignore
    list[i]["fk_banco"] = nomeUser;
    list[i]["idP"] = idP;

    setSelectedOptionUserParcauto(option?.id + i + nomeUser)


  }, [newParcauto]);

  const newParcKey = useCallback((e, i) => {

    const list: any = [...newParcauto];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");

  }, [newParcauto])


  const getBankAuto = useCallback(() => {
    dispatch(bankAutoRequest("?pp=100000"));
  }, [dispatch]);

  useEffect(() => {
    getBankAuto();
  }, [])



  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Pagamento Thai Motors </h3>
          </div>
        </div>

        <div className="row">

          {newParcauto.map((x: any, i: any) => {
            return (
              <React.Fragment key={i}>

                <div className="col-xl-4 col-lg-4 col-12 form-group">
                  <Select
                    options={bancoAutoMarcas}
                    addClassNames={true}
                    placeholder={"Selecione"}
                    onOptionChange={(e) => onOptionChangeBanco1(e, i)}
                    getOptionLabel={getOptionLabelBanco1}
                    getOptionValue={getOptionValueBanco1}
                    isClearable

                  />
                </div>

                <div className="col-xl-4 col-lg-4 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    placeholder="Valor"
                    id={`valorParcauto` + i}
                    autoComplete="off"
                    onKeyUp={(e) => newParcKey(e, i)}

                  />
                </div>

                <div className="form-group col-xl-4 col-lg-4 col-12">

                  {newParcauto.length !== 1 ? (
                    <button
                      type="button"
                      className="btn-add btn-remove"
                      onClick={() => handleRemoveParcauto(i)}
                    >
                      Remover
                    </button>
                  ) : (
                    <button type="button" className="btn-add btn-remove">
                      Remover
                    </button>
                  )}
                </div>

              </React.Fragment>
            );
          })}
        </div>

        <div className="row">
          <div className="col-lg-3 col-12 form-group mg-t-8">
            <button type="button" className="btn-add" onClick={handleAddParcauto}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectParcauto;
