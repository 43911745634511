export enum ProviderTypes {
  //client PF
  PROVIDER_REQUEST = "@provider/PROVIDER_REQUEST",
  PROVIDER_SUCCESS = "@provider/PROVIDER_SUCCESS",
  PROVIDER_FAILURE = "@provider/PROVIDER_FAILURE",

  POST_PROVIDER_REQUEST = "@provider/POST_PROVIDER_REQUEST",
  POST_PROVIDER_SUCCESS = "@provider/POST_PROVIDER_SUCCESS",
  POST_PROVIDER_FAILURE = "@provider/POST_PROVIDER_FAILURE",

  PUT_PROVIDER_REQUEST = "@provider/PUT_PROVIDER_REQUEST",
  PUT_PROVIDER_SUCCESS = "@provider/PUT_PROVIDER_SUCCESS",
  PUT_PROVIDER_FAILURE = "@provider/PUT_PROVIDER_FAILURE",

  VIEW_PROVIDER = "@provider/VEIW_PROVIDER",
  CLEAN_VIEW_PROVIDER = "@provider/CLEAN_VIEW_PROVIDER",

  DELETE_PROVIDER_REQUEST = "@provider/DELETE_PROVIDER_REQUEST",
  DELETE_PROVIDER_SUCCESS = "@provider/DELETE_PROVIDER_SUCCESS",
  DELETE_PROVIDER_FAILURE = "@provider/DELETE_PROVIDER_FAILURE",

  CLEAN_PROVIDER_RESPONSE = "@provider/CLEAN_PROVIDER_RESPONSE",

  //Delete banco
  DELETE_BANK_REQUEST = "@provider/DELETE_BANK_REQUEST",
  DELETE_BANK_SUCCESS = "@provider/DELETE_BANK_SUCCESS",
  DELETE_BANK_FAILURE = "@provider/DELETE_BANK_FAILURE",

  //delete endereço
  DELETE_ADDRESS_REQUEST = "@provider/DELETE_ADDRESS_REQUEST",
  DELETE_ADDRESS_SUCCESS = "@provider/DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_FAILURE = "@provider/DELETE_ADDRESS_FAILURE",
}

export interface IBank {
  id: string;
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  favorecido?: string;
  pix_banco?: string;
}

export interface INewBank {
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  favorecido?: string;
  pix_banco?: string;
}

export interface IAddress {
  id?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
}

export interface INewAddress {
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
}

//client PF
export interface IpostProvider {
  nome: string;
  email?: string;
  documento: string;
  telefone?: string;
  celular?: string;
  RG?: string;
  CPF?: string;
  CNPJ?: string;
  razao_social?: string;
  bancos?: IBank[];
  enderecos?: IAddress[];
}

export interface IputProvider {
  id: string;
  nome: string;
  email?: string;
  documento: string;
  telefone?: string;
  celular?: string;
  RG?: string;
  CPF?: string;
  CNPJ?: string;
  razao_social?: string;
  bancos?: IBank[];
  enderecos?: IAddress[];
  bancos_new?: INewBank[];
  enderecos_new?: INewAddress[];
}

export interface IProvider {
  id: string;
  nome: string;
  email?: string;
  documento: string;
  telefone?: string;
  celular?: string;
  RG?: string;
  CPF?: string;
  CNPJ?: string;
  razao_social?: string;
  bancos?: IBank[];
  enderecos?: IAddress[];
}

export interface IResponse {
  code: string;
  message: string;
}

export interface ProviderState {
  readonly data: IProvider[];
  readonly view: IProvider;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
