import { call, put, all, takeLatest } from "redux-saga/effects";

import fipeApi from "../../../services/fipeApi";

import { FipeTypes } from "./types";
import {
  fmarcasSuccess,
  fmarcasFailure,
  fmodelosRequest,
  fmodelosSuccess,
  fmodelosFailure,
  fanoVeiculoRequest,
  fanoVeiculoSuccess,
  fanoVeiculoFailure,
  fcarroRequest,
  fcarroSuccess,
  fcarroFailure,
} from "./actions";

function* getFMarcas() {
  try {
    const response = yield call(fipeApi.get, "/get-marcas");
    yield put(fmarcasSuccess(response.data));
  } catch (error) {
    yield put(fmarcasFailure());
  }
}

function* getFModelos({ payload }: ReturnType<typeof fmodelosRequest>) {
  try {
    const ipostFMarca = payload.IpostFMarca;

    const response = yield call(
      fipeApi.get,
      "/get-modelos/" + ipostFMarca
    );
    yield put(fmodelosSuccess(response.data));
  } catch (error) {
    yield put(fmodelosFailure());
  }
}

function* getFAnoVeiculo({ payload }: ReturnType<typeof fanoVeiculoRequest>) {
  try {
    const ipostFModelo = payload.IpostFModelo;

    const response = yield call(
      fipeApi.get,
      "/get-anos/" + ipostFModelo
    );
    yield put(fanoVeiculoSuccess(response.data));
  } catch (error) {
    yield put(fanoVeiculoFailure());
  }
}

function* getFCarro({ payload }: ReturnType<typeof fcarroRequest>) {
  try {
    const ipostFCarro = payload.Ipostfcarro;

    const response = yield call(
      fipeApi.get,
      "/get-preco/" + ipostFCarro
    );
    yield put(fcarroSuccess(response.data));
  } catch (error) {
    yield put(fcarroFailure());
  }
}

export default all([
  takeLatest(FipeTypes.FPEMARCA_REQUEST, getFMarcas),
  takeLatest(FipeTypes.FPEMODELO_REQUEST, getFModelos),
  takeLatest(FipeTypes.FPEANOVEICULO_REQUEST, getFAnoVeiculo),
  takeLatest(FipeTypes.FPECARRO_REQUEST, getFCarro),
]);
