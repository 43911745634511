export enum YearTypes {
  YEAR_REQUEST = "@year/YEAR_REQUEST",
  YEAR_SUCCESS = "@year/YEAR_SUCCESS",
  YEAR_FAILURE = "@year/YEAR_FAILURE",
  POST_YEAR_REQUEST = "@year/POST_YEAR_REQUEST",
  POST_YEAR_SUCCESS = "@year/POST_YEAR_SUCCESS",
  POST_YEAR_FAILURE = "@year/POST_YEAR_FAILURE",
  PUT_YEAR_REQUEST = "@year/PUT_YEAR_REQUEST",
  PUT_YEAR_SUCCESS = "@year/PUT_YEAR_SUCCESS",
  PUT_YEAR_FAILURE = "@year/PUT_YEAR_FAILURE",
  VIEW_YEAR = "@year/VEIW_YEAR",
  CLEAN_VIEW_YEAR = "@year/CLEAN_VIEW_YEAR",
  DELETE_YEAR_REQUEST = "@year/DELETE_YEAR_REQUEST",
  DELETE_YEAR_SUCCESS = "@year/DELETE_YEAR_SUCCESS",
  DELETE_YEAR_FAILURE = "@year/DELETE_YEAR_FAILURE",
}

export interface IpostYear {
  ano: string;
}

export interface IputYear {
  id: string;
  ano: string;
}

export interface IYear {
  id: string;
  ano: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface YearState {
  readonly data: IYear[];
  readonly view: IYear;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
