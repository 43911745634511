import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ProviderTypes } from "./types";
import {
  deleteAddressFailure,
  deleteAddressRequest,
  deleteAddressSuccess,
  deleteBankFailure,
  deleteBankRequest,
  deleteBankSuccess,
  deleteProviderFailure,
  deleteProviderRequest,
  deleteProviderSuccess,
  postProviderFailure,
  postProviderRequest,
  postProviderSuccess,
  providerFailure,
  providerRequest,
  providerSuccess,
  putProviderFailure,
  putProviderRequest,
  putProviderSuccess,
} from "./actions";

//client PF
function* getProviders({ payload }: ReturnType<typeof providerRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.data;

    const response = yield call(api.get, "/fornecedores" + params, auth);

    yield put(providerSuccess(response.data.params.data));
  } catch (error) {
    yield put(providerFailure());
  }
}

function* postProvider({ payload }: ReturnType<typeof postProviderRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostProvider = payload.ipostProvider;

    const response = yield call(api.post, "/fornecedores", ipostProvider, auth);
    yield put(postProviderSuccess(response.data.response));
  } catch (error) {
    yield put(
      postProviderFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putProvider({ payload }: ReturnType<typeof putProviderRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputProvider = payload.iputProvider;

    const id = payload.iputProvider.id;

    const response = yield call(
      api.put,
      "/fornecedores/" + id,
      iputProvider,
      auth
    );

    yield put(putProviderSuccess(response.data.response));
  } catch (error) {
    yield put(putProviderFailure());
  }
}

function* deleteProvider({
  payload,
}: ReturnType<typeof deleteProviderRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/fornecedores/" + id, auth);

    yield put(deleteProviderSuccess());
  } catch (error) {
    yield put(deleteProviderFailure());
  }
}

function* deleteBank({ payload }: ReturnType<typeof deleteBankRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/bancos/" + id, auth);

    yield put(deleteBankSuccess());
  } catch (error) {
    yield put(deleteBankFailure());
  }
}

function* deleteAddress({ payload }: ReturnType<typeof deleteAddressRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/enderecos/" + id, auth);

    yield put(deleteAddressSuccess());
  } catch (error) {
    yield put(deleteAddressFailure());
  }
}

export default all([
  takeLatest(ProviderTypes.PROVIDER_REQUEST, getProviders),
  takeLatest(ProviderTypes.POST_PROVIDER_REQUEST, postProvider),
  takeLatest(ProviderTypes.PUT_PROVIDER_REQUEST, putProvider),
  takeLatest(ProviderTypes.DELETE_PROVIDER_REQUEST, deleteProvider),
  takeLatest(ProviderTypes.DELETE_BANK_REQUEST, deleteBank),
  takeLatest(ProviderTypes.DELETE_ADDRESS_REQUEST, deleteAddress),
]);
