export enum DeclarationsTypes {
  DECLARATION_REQUEST = "@declarations/DECLARATION_REQUEST",
  DECLARATION_SUCCESS = "@declarations/DECLARATION_SUCCESS",
  DECLARATION_FAILURE = "@declarations/DECLARATION_FAILURE",
  POST_DECLARATION_REQUEST = "@declarations/POST_DECLARATION_REQUEST",
  POST_DECLARATION_SUCCESS = "@declarations/POST_DECLARATION_SUCCESS",
  POST_DECLARATION_FAILURE = "@declarations/POST_DECLARATION_FAILURE",
  PUT_DECLARATION_REQUEST = "@declarations/PUT_DECLARATION_REQUEST",
  PUT_DECLARATION_SUCCESS = "@declarations/PUT_DECLARATION_SUCCESS",
  PUT_DECLARATION_FAILURE = "@declarations/PUT_DECLARATION_FAILURE",
  VIEW_DECLARATION = "@declarations/VEIW_DECLARATION",
  CLEAN_VIEW_DECLARATION = "@declarations/CLEAN_VIEW_DECLARATION",
  DELETE_DECLARATION_REQUEST = "@declarations/DELETE_DECLARATION_REQUEST",
  DELETE_DECLARATION_SUCCESS = "@declarations/DELETE_DECLARATION_SUCCESS",
  DELETE_DECLARATION_FAILURE = "@declarations/DELETE_DECLARATION_FAILURE",

  CLEAN_DECLARATION_RESPONSE = "@declarations/CLEAN_DECLARATION_RESPONSE",
}

export interface IpostDeclarations {
  type:any;
  content?:any;

}

export interface IputDeclarations {
  id:string;
  type:any;
  content?:any;

}

export interface IDeclarations {
  id:string;
  type:any;
  content?:any;
}

export interface IResponse {
  code: string;
  message: string;
}


export interface DeclarationsState {
  readonly data: IDeclarations[];
  readonly view: IDeclarations;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
