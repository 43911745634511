import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { BankAutoTypes } from "./types";
import {
  bankAutoFailure,
  bankAutoRequest,
  bankAutoSuccess,
  deleteBankAutoFailure,
  deleteBankAutoRequest,
  deleteBankAutoSuccess,
  postBankAutoFailure,
  postBankAutoRequest,
  postBankAutoSuccess,
  putBankAutoFailure,
  putBankAutoRequest,
  putBankAutoSuccess,
} from "./actions";

//client PF
function* getBankAuto({ payload }: ReturnType<typeof bankAutoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.data;

    const response = yield call(api.get, "/banco-auto" + params, auth);

    yield put(bankAutoSuccess(response.data.params.data));
  } catch (error) {
    yield put(bankAutoFailure());
  }
}

function* postBankAuto({ payload }: ReturnType<typeof postBankAutoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostBankAuto = payload.IpostBankAuto;

    const response = yield call(api.post, "/banco-auto", ipostBankAuto, auth);
    yield put(postBankAutoSuccess(response.data.response));
  } catch (error) {
    yield put(
      postBankAutoFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putBankAuto({ payload }: ReturnType<typeof putBankAutoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputBankAuto = payload.IputBankAuto;

    const id = payload.IputBankAuto.id;

    const response = yield call(
      api.put,
      "/banco-auto/" + id,
      iputBankAuto,
      auth
    );

    yield put(putBankAutoSuccess(response.data.response));
  } catch (error) {
    yield put(putBankAutoFailure());
  }
}

function* deleteBankAuto({ payload }: ReturnType<typeof deleteBankAutoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/banco-auto/" + id, auth);

    yield put(deleteBankAutoSuccess());
  } catch (error) {
    yield put(deleteBankAutoFailure());
  }
}


export default all([
  takeLatest(BankAutoTypes.BANKAUTO_REQUEST, getBankAuto),
  takeLatest(BankAutoTypes.POST_BANKAUTO_REQUEST, postBankAuto),
  takeLatest(BankAutoTypes.PUT_BANKAUTO_REQUEST, putBankAuto),
  takeLatest(BankAutoTypes.DELETE_BANKAUTO_REQUEST, deleteBankAuto),
]);
