import { Reducer } from "redux";
import producer from "immer";

import { ContractsState, ContractsTypes } from "./types";

const INITIAL_STATE: ContractsState = {
  data: [],
  view: {
    id: "",
    banco: "",
    agencia: "",
    conta: "",
    documento_banco: "",
    favorecido: "",
    pix_banco: "",
    contato: "",
    documentar_em: "",
    valor_nota: "",
    valor_compra: "",
    pagto_compra: "",
    data_entrega: "",
    valor_venda: "",
    data_venda: "",
    pagto_auto: "",
    pagto_fornecedor: "",
    nome: "",
    nome_contato: "",
    email: "",
    documento_cliente: "",
    telefone: "",
    celular: "",
    rg: "",
    cpf: "",
    cnpj: "",
    razao_social: "",
    cep: "",
    rua: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    nome_motorista: "",
    rg_motorista: "",
    data_retira: "",
    hora_retira: "",
    observacoes: "",
    file_compra: "",
    file_venda: "",
    fk_veiculo: "",
    fk_fornecedor: "",
    fk_banco: "",
    fk_endereco: "",
    fk_cliente: "",
    client_type: "",
    alienado: "",
    client_data: {
      nome: "",
      RG: "",
      CPF: "",
      nome_contato: "",
      razao_social: "",
      CNPJ: "",
      telefone: "",
      celular: "",
      CEP: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      complemento: "",
      estado: "",
      fk_loja: "",
      fk_user: "",
      email: "",
    },
    comissao: [{
      id: "",
      nome: "",
      data_lancamento: "",
      valor: "",
    }],
    terceiro: [{
      id: "",
      nome: "",
      data_lancamento: "",
      valor: "",
      pdf: "",
      fk_banco: "",
    }],
    pagtoParcauto: [{
      id: "",
      valor: "",
      fk_banco: "",
    }]
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<ContractsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContractsTypes.CONTRACTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ContractsTypes.CONTRACTS_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case ContractsTypes.CONTRACTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ContractsTypes.VIEW_CONTRACTS:
      return {
        ...state,
        view: action.payload.view,
      };

    case ContractsTypes.POST_CONTRACTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ContractsTypes.POST_CONTRACTS_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;

      });
    case ContractsTypes.POST_CONTRACTS_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case ContractsTypes.CLEAN_VIEW_CONTRACTS:
      return {
        ...state,
        view: {
          id: "",
          fk_loja: "",
          type: "",
          fk_cliente: "",
          fk_vendedor: "",
          fk_veiculo: "",
          observacoes: "",
          declaracao: "",
          file: "",
          favorecido: "",
          pix_banco: "",
          client_data: {
            nome: "",
            RG: "",
            CPF: "",
            nome_contato: "",
            razao_social: "",
            CNPJ: "",
            telefone: "",
            celular: "",
            CEP: "",
            rua: "",
            numero: "",
            bairro: "",
            cidade: "",
            complemento: "",
            estado: "",
            fk_loja: "",
            fk_user: "",
          },
          comissao: [{
            id: "",
            nome: "",
            data_lancamento: "",
            valor: "",
          }],
          terceiro: [{
            id: "",
            nome: "",
            data_lancamento: "",
            valor: "",
            pdf: "",
            fk_banco: "",
          }],
          pagtoParcauto: [{
            id: "",
            valor: "",
            fk_banco: "",
          }]
        },
      };
    case ContractsTypes.PUT_CONTRACTS_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ContractsTypes.PUT_CONTRACTS_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {
          id: "",
          banco: "",
          agencia: "",
          conta: "",
          documento_banco: "",
          contato: "",
          documentar_em: "",
          valor_nota: "",
          valor_compra: "",
          pagto_compra: "",
          data_entrega: "",
          valor_venda: "",
          data_venda: "",
          pagto_auto: "",
          pagto_fornecedor: "",
          nome: "",
          nome_contato: "",
          email: "",
          documento_cliente: "",
          telefone: "",
          celular: "",
          favorecido: "",
          pix_banco: "",
          rg: "",
          cpf: "",
          cnpj: "",
          razao_social: "",
          cep: "",
          rua: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          estado: "",
          nome_motorista: "",
          rg_motorista: "",
          data_retira: "",
          hora_retira: "",
          observacoes: "",
          file_compra: "",
          file_venda: "",
          fk_veiculo: "",
          fk_fornecedor: "",
          fk_banco: "",
          fk_endereco: "",
          fk_cliente: "",
          client_type: "",
          alienado: "",
          client_data: {
            nome: "",
            RG: "",
            CPF: "",
            nome_contato: "",
            razao_social: "",
            CNPJ: "",
            telefone: "",
            celular: "",
            CEP: "",
            rua: "",
            numero: "",
            bairro: "",
            cidade: "",
            complemento: "",
            estado: "",
            fk_loja: "",
            fk_user: "",
            email: "",
          },
          comissao: [{
            id: "",
            nome: "",
            data_lancamento: "",
            valor: "",
          }],
          terceiro: [{
            id: "",
            nome: "",
            data_lancamento: "",
            valor: "",
            pdf: "",
            fk_banco: "",
          }],
          pagtoParcauto: [{
            id: "",
            valor: "",
            fk_banco: "",
          }]
        };
      });
    case ContractsTypes.PUT_CONTRACTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ContractsTypes.CLEAN_CONTRACTS_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
