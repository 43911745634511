import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { FuelTypes } from "./types";
import {
  fuelSuccess,
  fuelFailure,
  postFuelRequest,
  postFuelSuccess,
  postFuelFailure,
  putFuelRequest,
  putFuelSuccess,
  putFuelFailure,
  deleteFuelRequest,
  deleteFuelSuccess,
  deleteFuelFailure,
  fuelRequest,
} from "./actions";

function* getFuels({ payload }: ReturnType<typeof  fuelRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/combustiveis"+params, auth);


    yield put(fuelSuccess(response.data.params.data));
  } catch (error) {
    yield put(fuelFailure());
  }
}

function* postFuels({ payload }: ReturnType<typeof postFuelRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostfuel = payload.Ipostfuel;

    const response = yield call(api.post, "/combustiveis", ipostfuel, auth);

    yield put(postFuelSuccess(response.data.response));
  } catch (error) {
    yield put(postFuelFailure({ ...error.response.data.response }));
  }
}

function* putFuels({ payload }: ReturnType<typeof putFuelRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputfuel = payload.Iputfuel;

    const id = payload.Iputfuel.id;

    yield call(api.put, "/combustiveis/" + id, iputfuel, auth);

    yield put(putFuelSuccess());
  } catch (error) {
    yield put(putFuelFailure());
  }
}

function* deleteFuels({ payload }: ReturnType<typeof deleteFuelRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/combustiveis/" + id, auth);

    yield put(deleteFuelSuccess());
  } catch (error) {
    yield put(deleteFuelFailure());
  }
}

export default all([
  takeLatest(FuelTypes.FUEL_REQUEST, getFuels),
  takeLatest(FuelTypes.POST_FUEL_REQUEST, postFuels),
  takeLatest(FuelTypes.PUT_FUEL_REQUEST, putFuels),
  takeLatest(FuelTypes.DELETE_FUEL_REQUEST, deleteFuels),
]);
