import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { GearshiftTypes } from "./types";
import {
  gearshiftSuccess,
  gearshiftFailure,
  postGearshiftRequest,
  postGearshiftSuccess,
  postGearshiftFailure,
  putGearshiftRequest,
  putGearshiftSuccess,
  putGearshiftFailure,
  deleteGearshiftRequest,
  deleteGearshiftSuccess,
  deleteGearshiftFailure,
  gearshiftRequest,
} from "./actions";

function* getGearshifts({ payload }: ReturnType<typeof  gearshiftRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/cambios"+params, auth);

    yield put(gearshiftSuccess(response.data.params.data));
  } catch (error) {
    yield put(gearshiftFailure());
  }
}

function* postGearshifts({ payload }: ReturnType<typeof postGearshiftRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostgearshift = payload.Ipostgearshift;

    const response = yield call(api.post, "/cambios", ipostgearshift, auth);

    yield put(postGearshiftSuccess(response.data.response));
  } catch (error) {
    yield put(
      postGearshiftFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putGearshifts({ payload }: ReturnType<typeof putGearshiftRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputgearshift = payload.Iputgearshift;

    const id = payload.Iputgearshift.id;

    yield call(api.put, "/cambios/" + id, iputgearshift, auth);

    yield put(putGearshiftSuccess());
  } catch (error) {
    yield put(putGearshiftFailure());
  }
}

function* deleteGearshifts({
  payload,
}: ReturnType<typeof deleteGearshiftRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/cambios/" + id, auth);

    yield put(deleteGearshiftSuccess());
  } catch (error) {
    yield put(deleteGearshiftFailure());
  }
}

export default all([
  takeLatest(GearshiftTypes.GEARSHIFT_REQUEST, getGearshifts),
  takeLatest(GearshiftTypes.POST_GEARSHIFT_REQUEST, postGearshifts),
  takeLatest(GearshiftTypes.PUT_GEARSHIFT_REQUEST, putGearshifts),
  takeLatest(GearshiftTypes.DELETE_GEARSHIFT_REQUEST, deleteGearshifts),
]);
