import { Reducer } from "redux";
import producer from "immer";

import { FuelState, FuelTypes } from "./types";

const INITIAL_STATE: FuelState = {
  data: [],
  view: {
    id: "",
    descricao: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<FuelState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FuelTypes.FUEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FuelTypes.FUEL_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case FuelTypes.FUEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

      case FuelTypes.POST_FUEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FuelTypes.POST_FUEL_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case FuelTypes.POST_FUEL_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });


    case FuelTypes.VIEW_FUEL:
      return {
        ...state,
        view: action.payload.view,
      };
    case FuelTypes.CLEAN_VIEW_FUEL:
      return {
        ...state,
        view: {
          id: "",
          descricao: "",
        },
      };
    case FuelTypes.PUT_FUEL_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case FuelTypes.PUT_FUEL_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.view = {
          id: "",
          descricao: "",
        };
      });
    case FuelTypes.PUT_FUEL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
