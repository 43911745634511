import React, { useState, useCallback, useEffect } from "react";
import { IpostClient } from "../../store/ducks/client/types";
import { useDispatch, useSelector } from "react-redux";
import { postClientRequest } from "../../store/ducks/client/actions";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { ApplicationState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./logo-app.png";

const CadastroLoja: React.FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [form, setForm] = useState<IpostClient>({
    email: "",
    cnpj: "",
    whatsapp: "",
    nome: "",
    type: "cliente",
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    endNumero: "",
  });

  const returnApi: any = useSelector<ApplicationState>((state) => state.client);

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();

      const {
        cnpj,
        whatsapp,
        email,
        nome,
        type,
        cep,
        logradouro,
        complemento,
        bairro,
        localidade,
        uf,
        endNumero,
      } = form;

      dispatch(
        postClientRequest({
          cnpj,
          whatsapp,
          email,
          nome,
          type,
          cep,
          logradouro,
          complemento,
          bairro,
          localidade,
          uf,
          endNumero,
        })
      );
    },
    [dispatch, form]
  );

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        toast.error("Email Já cadastrado!");
        btnNoDisabled();
      }

      if (event === 400) {
        toast.error("CNPJ informado esta incorreto");
        btnNoDisabled();
      }

      if (event === 201) {
        toast.success("Loja cadastrada com sucesso");
        setTimeout(() => history.push("/confirmacao"), 2000);
      }
    },
    [history]
  );

  function onBlurCep(ev: any) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          email: form.email,
          cnpj: form.cnpj,
          whatsapp: form.whatsapp,
          nome: form.nome,
          type: form.type,
          cep: data.cep,
          logradouro: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          localidade: data.localidade,
          uf: data.uf,
        });
      });
  }

  useEffect(() => {
    notify(returnApi.response.code);
  }, [notify, returnApi.response]);

  return (
    <div>
      <div className="login-page-warp">
        <div className="login-page-content">
          <div
            className="login-box"
            style={{ overflowY: "auto", height: "40em" }}
          >
            <div className="item-logo" style={{ marginTop: "-2em" }}>
              <img src={logo} alt=".." />
            </div>

            <form onSubmit={submitForm} className="login-form">
              <div className="form-group">
                <input
                  value={form.nome}
                  onChange={handleChange}
                  name="nome"
                  type="text"
                  className="form-control"
                  placeholder="Nome da Loja"
                  id="exampleInputSenha1"
                  aria-describedby="emailHelp"
                  required={true}
                />
                <i className="fa fa-warehouse"></i>
              </div>
              <div className="form-group">
                <MaskedInput
                  mask={[
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  value={form.cnpj}
                  onChange={handleChange}
                  name="cnpj"
                  type="text"
                  className="form-control"
                  placeholder="CNPJ"
                  id="exampleInputWhatsapp1"
                  aria-describedby="emailHelp"
                  required={true}
                />
                <i className="fa fa-id-card"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.email}
                  onChange={handleChange}
                  name="email"
                  type="text"
                  placeholder="Email"
                  className="form-control"
                  id="exampleInputPassword1"
                  required={true}
                />
                <i className="fa fa-envelope"></i>
              </div>

              <div className="form-group">
                <MaskedInput
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  value={form.whatsapp}
                  onChange={handleChange}
                  name="whatsapp"
                  type="text"
                  placeholder="Telefone"
                  className="form-control"
                  id="exampleInputPassword1"
                  required={true}
                />
                <i className="fa fa-phone"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.cep}
                  name="cep"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="CEP"
                  onBlur={(ev) => onBlurCep(ev)}
                  required={true}
                />
                <i className="fas fa-window-minimize"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.logradouro}
                  name="logradouro"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Rua"
                  required={true}
                />
                <i className="fas fa-road"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.bairro}
                  name="bairro"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Bairro"
                  required={true}
                />
              </div>

              <div className="form-group">
                <input
                  value={form.localidade}
                  name="localidade"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Cidade"
                  required={true}
                />
                <i className="fas fa-city"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.uf}
                  name="uf"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Sigla UF"
                  required={true}
                  maxLength={2}
                />
                <i className="far fa-flag"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.endNumero}
                  name="endNumero"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="endNumero"
                  placeholder="Numero"
                  required={true}
                />
                <i className="fas fa-home"></i>
              </div>

              <div className="form-group">
                <input
                  value={form.complemento}
                  name="complemento"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Complemento"
                />
                <i className="fas fa-home"></i>
              </div>

              <div className="form-group">
                <button type="submit" className="login-btn" id="btn_submit">
                  Salvar
                </button>
              </div>
              <ToastContainer autoClose={5000} position="top-right" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadastroLoja;
