import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import MaskedInput from "react-text-mask";

import { ToastContainer, toast } from "react-toastify";
import {
  cleanViewARespProvider,
  postProviderRequest,
} from "../../store/ducks/provider/actions";
import { Select } from "react-functional-select";

type BooleanOption = {
  readonly id: number;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: 0, name: "Fisica" },
  { id: 1, name: "Juridica" },
];

const CadFornecedor: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [SelectednewUsed, setSelectednewUsed] = useState<BooleanOption | null>(
    { id: 0, name: "Fisica" }
  );

  const [bancosInput, setBancos] = useState([
    {
      numero_banco: "", banco: "", agencia: "", conta: "", documento: "", favorecido: "",
      pix_banco: "",
    },
  ]);

  const [enderecosInput, setEnderecos] = useState([
    {
      CEP: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      complemento: "",
      estado: "",
    },
  ]);

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.provider
  );

  const [form, setForm] = useState({
    nome: "",
    email: "",
    documento: "",
    telefone: "",
    celular: "",
    RG: "",
    CPF: "",
    CNPJ: "",
    razao_social: "",
  });

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      toast.info("Carregando, aguarde");
      btnDisabled();

      const {
        nome,
        email,
        telefone,
        celular,
        RG,
        CPF,
        CNPJ,
        razao_social,
      } = form;

      const bancos: any = bancosInput;
      const enderecos: any = enderecosInput;
      const documento: any = SelectednewUsed?.name;

      dispatch(
        postProviderRequest({
          nome,
          email,
          documento,
          telefone,
          celular,
          RG,
          CPF,
          CNPJ,
          razao_social,
          bancos,
          enderecos,
        })
      );
    },
    [dispatch, form, bancosInput, enderecosInput, SelectednewUsed]
  );

  const notify = useCallback(
    (event) => {
      if (event === 201) {
        toast.success("Fornecedor cadastrado com sucesso");
        setTimeout(() => history.push("/fornecedor"), 2000);
      }

      if (event === 404) {
        toast.error("Falha ao cadastrar fornecedor");
        btnNoDisabled();
      }
    },
    [history]
  );

  const cleanView = useCallback(() => {
    dispatch(cleanViewARespProvider());
  }, [dispatch]);

  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    notify(responseApi.response.code);
  }, [notify, responseApi.response, cleanView]);

  console.log(responseApi, form)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  // handle input change
  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...bancosInput];
    list[index][name] = value;
    setBancos(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: any) => {
    const list = [...bancosInput];
    list.splice(index, 1);
    setBancos(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setBancos([
      ...bancosInput,
      {
        numero_banco: "", banco: "", agencia: "", conta: "", documento: "", favorecido: "",
        pix_banco: "",
      },
    ]);
  };

  // handle input change
  const handleInputChangeEnd = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...enderecosInput];
    list[index][name] = value;
    setEnderecos(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickEnd = (index: any) => {
    const list = [...enderecosInput];
    list.splice(index, 1);
    setEnderecos(list);
  };

  // handle click event of the Add button
  const handleAddClickEnd = () => {
    setEnderecos([
      ...enderecosInput,
      {
        CEP: "",
        rua: "",
        numero: "",
        bairro: "",
        cidade: "",
        complemento: "",
        estado: "",
      },
    ]);
  };

  function onBlurCep(ev: any, i: any) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }
    const list = [...enderecosInput];

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        list[i]["CEP"] = data.cep;
        list[i]["rua"] = data.logradouro;
        list[i]["bairro"] = data.bairro;
        list[i]["cidade"] = data.localidade;
        list[i]["estado"] = data.uf;
        setEnderecos(list);
      });
  }

  const getNovoUsadoValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onNovoUsadoChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectednewUsed(newUsed),
    []
  );
  const getNovoUsadoLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );


  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Fornecedor</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Adicionar fornecedor</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Cadastro Forncecedor</h3>
                  <span className="label-form-input">* Campos obrigatório</span>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Tipo de pessoa
                    </label>
                    <Select
                      options={BOOL_OPTIONS}
                      onOptionChange={onNovoUsadoChange}
                      getOptionValue={getNovoUsadoValue}
                      getOptionLabel={getNovoUsadoLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      initialValue={BOOL_OPTIONS[0]}
                    />
                  </div>

                  {SelectednewUsed?.name === "Fisica" ||
                    SelectednewUsed?.name === undefined ? (
                    <>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Nome
                        </label>
                        <input
                          value={form.nome}
                          name="nome"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Nome"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputPassword1"
                        >
                          RG
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                          ]}
                          guide={false}
                          value={form.RG}
                          name="RG"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="RG"
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputPassword1"
                        >
                          CPF
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\w/,
                            /\w/,
                          ]}
                          guide={false}
                          value={form.CPF}
                          name="CPF"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="CPF"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Nome contato
                        </label>
                        <input
                          value={form.nome}
                          name="nome"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Nome"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Razão Social
                        </label>
                        <input
                          value={form.razao_social}
                          name="razao_social"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Razão Social"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputPassword1"
                        >
                          CNPJ
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                          ]}
                          guide={false}
                          value={form.CNPJ}
                          name="CNPJ"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="CNPJ"
                        />
                      </div>
                    </>
                  )}

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Email
                    </label>
                    <input
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Email"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Telefone
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.telefone}
                      name="telefone"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Telefone"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Celular
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.celular}
                      name="celular"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Celular"
                      required={true}
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="mt-5">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Bancos</h3>
                  {/* <span className="label-form-input">* Campos obrigatório</span> */}
                </div>
              </div>

              <div className="row">
                {bancosInput.map((x, i) => {
                  return (
                    <>
                      <div className="col-12">Banco {i + 1}</div>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="numero_banco"
                          placeholder="Codigo Banco"
                          value={x.numero_banco}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="banco"
                          placeholder="Nome Banco"
                          value={x.banco}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="agencia"
                          placeholder="Agencia"
                          value={x.agencia}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="conta"
                          placeholder="Conta"
                          value={x.conta}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="documento"
                          placeholder="CPF ou CNPJ"
                          value={x.documento}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="favorecido"
                          placeholder="Favorecido"
                          value={x.favorecido}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="pix_banco"
                          placeholder="Pix banco"
                          value={x.pix_banco}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      <div className="form-group col-xl-3">
                        {bancosInput.length !== 1 ? (
                          <button
                            className="btn-add btn-remove"
                            onClick={() => handleRemoveClick(i)}
                          >
                            Remover
                          </button>
                        ) : (
                          <button className="btn-add btn-remove">
                            Remover
                          </button>
                        )}
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="row">
                <div className="col-lg-3 col-12 form-group mg-t-8">
                  <button className="btn-add" onClick={handleAddClick}>
                    Adicionar
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-5 mb-5">
              <div className="card-body">
                <div className="heading-layout1 mt-2 mb-4">
                  <div className="item-title">
                    <h3>Endereços</h3>
                    {/* <span className="label-form-input">* Campos obrigatório</span> */}
                  </div>
                </div>

                <div className="row">
                  {enderecosInput.map((x, i) => {
                    return (
                      <>
                        <div className="col-12">Endereço {i + 1}</div>
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="CEP"
                            placeholder="CEP"
                            value={x.CEP}
                            onBlur={(ev) => onBlurCep(ev, i)}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="rua"
                            placeholder="Rua"
                            value={x.rua}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="numero"
                            placeholder="Numero"
                            id={"numero" + i}
                            value={x.numero}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="complemento"
                            placeholder="Complemento"
                            value={x.complemento}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="bairro"
                            placeholder="Bairro"
                            value={x.bairro}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="cidade"
                            placeholder="Cidade"
                            value={x.cidade}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="estado"
                            placeholder="Sigla UF"
                            value={x.estado}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="form-group col-xl-3">
                          {enderecosInput.length !== 1 ? (
                            <button
                              className="btn-add btn-remove"
                              onClick={() => handleRemoveClickEnd(i)}
                            >
                              Remover
                            </button>
                          ) : (
                            <button className="btn-add btn-remove">
                              Remover
                            </button>
                          )}
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-lg-3 col-12 form-group mg-t-8">
                    <button className="btn-add" onClick={handleAddClickEnd}>
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default CadFornecedor;
