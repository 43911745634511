/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import jwt from "jwt-decode";
import { signOut as signOutAction } from "../store/ducks/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { userProfileRequest } from "../store/ducks/user/actions";
import { IProfileUser } from "../store/ducks/user/types";
import { ApplicationState } from "../store";
import logo from "./base-app.png";
import 'jquery-mask-plugin/dist/jquery.mask.min';

const Header = () => {
  const dispatch = useDispatch();

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );
  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const signOut = useCallback(() => {
    localStorage.removeItem("persist:AppCAR_System");
    localStorage.removeItem("Auth-Token");
    localStorage.removeItem("Access-Level");
    dispatch(signOutAction());
  }, [dispatch]);

  useEffect(() => {
    getProfile();
  }, []);

  $('.dinheiro').mask('#.##0,00', {
    reverse: true,
    placeholder: '0,00'
  });

  const classToggleDesktop = () => {
    $(".sidebar-toggle-view").on(
      "click",
      ".sidebar-nav-item .nav-link",
      function (e) {
        if (!$(this).parents("body").hasClass("sidebar-collapsed")) {
          var animationSpeed = 300,
            subMenuSelector = ".sub-group-menu",
            $this = $(this),
            checkElement = $this.next();
          if (checkElement.is(subMenuSelector) && checkElement.is(":visible")) {
            checkElement.slideUp(animationSpeed, function () {
              checkElement.removeClass("menu-open");
            });
            checkElement.parent(".sidebar-nav-item").removeClass("active");
          } else if (
            checkElement.is(subMenuSelector) &&
            !checkElement.is(":visible")
          ) {
            var parent = $this.parents("ul").first();
            var ul = parent.find("ul:visible").slideUp(animationSpeed);
            ul.removeClass("menu-open");
            var parent_li = $this.parent("li");
            checkElement.slideDown(animationSpeed, function () {
              checkElement.addClass("menu-open");
              parent.find(".sidebar-nav-item.active").removeClass("active");
              parent_li.addClass("active");
            });
          }
          if (checkElement.is(subMenuSelector)) {
            e.preventDefault();
          }
        } else {
          if ($(this).attr("href") === "#") {
            e.preventDefault();
          }
        }
      }
    );

    /*-------------------------------------
        Sidebar Menu Control
      -------------------------------------*/
    $(".sidebar-toggle").on("click", function () {
      //@ts-ignore
      document.querySelector("body").classList.toggle("sidebar-collapsed");
    });

    /*-------------------------------------
        Sidebar Menu Control Mobile
      -------------------------------------*/
  };

  const classToggleMobile = () => {
    $(".sidebar-toggle-mobile").on("click", function () {
      $("#wrapper").toggleClass("sidebar-collapsed-mobile");
      if ($("#wrapper").hasClass("sidebar-collapsed")) {
        $("#wrapper").removeClass("sidebar-collapsed");
      }
    });
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    classToggleMobile();
    classToggleDesktop();
  }, []);

  useEffect(() => { }, [count]);

  return (
    <div className="navbar navbar-expand-md header-menu-one bg-light">
      <div className="nav-bar-header-one">
        <div className="header-logo">
          <Link to="/"><img src={logo} alt="logo" className="ml-5" style={{width: "110px"}} /></Link>
        </div>
        <div className="toggle-button sidebar-toggle">
          <button
            type="button"
            className="item-link"
            onClick={() => setCount(count + 1)}
          >
            <span className="btn-icon-wrap">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
      </div>

      <div className="d-md-none mobile-nav-bar">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#mobile-navbar"
          aria-expanded="false"
        >
          <i className="flaticon-user"></i>
        </button>

        <button
          type="button"
          className="navbar-toggler sidebar-toggle-mobile"
          onClick={() => setCount(count + 1)}
        >
          <i className="fas fa-bars"></i>
        </button>
      </div>
      <div
        className="header-main-menu collapse navbar-collapse"
        id="mobile-navbar"
      >
        <ul className="navbar-nav">
          <li className="navbar-item header-search-bar">
            <div className="input-group stylish-input-group">
              <span className="input-group-addon"></span>
            </div>
          </li>
        </ul>
        <ul className="navbar-nav">
          <li className="navbar-item dropdown header-admin">
            <a
              className="navbar-nav-link"
              href="/#/"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="admin-title">
                <h5 className="item-title">{profileUser.nome}</h5>
                <span>{profileUser.permissao}</span>
              </div>
              <div className="admin-img">
                <i className="fa fa-angle-down" style={{ color: "#e21c21" }} />
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="item-header">
                <h6 className="item-title">{profileUser.nome}</h6>
              </div>
              <div className="item-content">
                <ul className="settings-list">
                  <li>
                    <Link to={"/editar-perfil"}>
                      <i className="flaticon-user"></i>Meu Perfil
                    </Link>
                  </li>

                  {profileUser.permissao === "super_admin" ?
                    (
                      <li>
                        <Link to={"/editar-loja"}>
                          <i className="flaticon-user"></i>Minha Loja
                        </Link>
                      </li>
                    ) : ""
                  }

                  <li onClick={() => signOut()}>
                    <a href="#">
                      <i className="flaticon-turn-off"></i>Sair
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
