import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./logo-app.png";
import { IUpSenhaUser } from "../../store/ducks/user/types";
import { updateSenhaRequest } from "../../store/ducks/user/actions";

const RecoverPass: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [form, setForm] = useState<IUpSenhaUser>({ email: "", senha: "" });

  const login = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();

      const { email, senha } = form;

      dispatch(
        updateSenhaRequest({
          email,
          senha,
        })
      );
      toast.success("Email enviado com sucesso");
      setTimeout(() => history.push("/"), 5000);
    },
    [dispatch, form, history]
  );

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  return (
    <div className="login-page-warp">
      <div className="login-page-content">
        <div className="login-box">
          <div className="item-logo">
            <img src={logo} alt=".." />
          </div>
          <form onSubmit={login} className="login-form">
            <div className="form-group">
              <input
                value={form.email}
                onChange={handleChange}
                name="email"
                type="email"
                className="form-control"
                placeholder="Email"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                required={true}
              />
              <i className="fa fa-envelope"></i>
            </div>

            <div className="form-group">
              <button type="submit" className="login-btn" id="btn_submit">
                Enviar
              </button>
            </div>
            <ToastContainer autoClose={5000} position="top-right" />
          </form>
          <div className="login-social">
            <p>
              <Link to={"/"}>Voltar a área de Acesso</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecoverPass;
