export enum OptionalTypes {
  OPTIONAL_REQUEST = "@optional/OPTIONAL_REQUEST",
  OPTIONAL_SUCCESS = "@optional/OPTIONAL_SUCCESS",
  OPTIONAL_FAILURE = "@optional/OPTIONAL_FAILURE",
  POST_OPTIONAL_REQUEST = "@optional/POST_OPTIONAL_REQUEST",
  POST_OPTIONAL_SUCCESS = "@optional/POST_OPTIONAL_SUCCESS",
  POST_OPTIONAL_FAILURE = "@optional/POST_OPTIONAL_FAILURE",
  PUT_OPTIONAL_REQUEST = "@optional/PUT_OPTIONAL_REQUEST",
  PUT_OPTIONAL_SUCCESS = "@optional/PUT_OPTIONAL_SUCCESS",
  PUT_OPTIONAL_FAILURE = "@optional/PUT_OPTIONAL_FAILURE",
  VIEW_OPTIONAL = "@optional/VEIW_OPTIONAL",
  CLEAN_VIEW_OPTIONAL = "@optional/CLEAN_VIEW_OPTIONAL",
  DELETE_OPTIONAL_REQUEST = "@optional/DELETE_OPTIONAL_REQUEST",
  DELETE_OPTIONAL_SUCCESS = "@optional/DELETE_OPTIONAL_SUCCESS",
  DELETE_OPTIONAL_FAILURE = "@optional/DELETE_OPTIONAL_FAILURE",
}

export interface IpostOptional {
  opcoes:string;
}

export interface IputOptional {
  id:string;
  opcoes:string;

}

export interface IOptional {
  id:string;
  opcoes:string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface OptionalState {
  readonly data: IOptional[];
  readonly view: IOptional;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
