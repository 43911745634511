import { Reducer } from "redux";
import producer from "immer";

import { FipeState, FipeTypes } from "./types";

const INITIAL_STATE: FipeState = {
  data: [],
  modelo: [],
  carro: [],
  anoVeiculo: [],
  view: {
    fipe_marca: "",
    fipe_name: "",
    id: "",
    key: "",
    marca: "",
    name: "",
  },
  loading: false,
  error: false,
  loaded: false,
};

const reducer: Reducer<FipeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FipeTypes.FPEMARCA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FipeTypes.FPEMARCA_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case FipeTypes.FPEMARCA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FipeTypes.FPEMODELO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FipeTypes.FPEMODELO_SUCCESS:
      return producer(state, (draft) => {
        draft.modelo = action.payload.data;
        draft.loading = false;
        draft.loaded = true;
      });
      case FipeTypes.VIEW_FPEMODELO:
        return {
          ...state,
          view: action.payload.view,
        };

        case FipeTypes.CLEAN_VIEW_FPEMODELO:
          return {
            ...state,
            view: {
              fipe_marca: "",
              fipe_name: "",
              id: "",
              key: "",
              marca: "",
              name: "",
            },
          };


    case FipeTypes.FPEMODELO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case FipeTypes.FPEANOVEICULO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FipeTypes.FPEANOVEICULO_SUCCESS:
      return producer(state, (draft) => {
        draft.anoVeiculo = action.payload.data;
        draft.loading = false;
        draft.loaded = true;
      });
    case FipeTypes.FPEANOVEICULO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case FipeTypes.FPECARRO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FipeTypes.FPECARRO_SUCCESS:
      return producer(state, (draft) => {
        draft.carro = action.payload.data;
        draft.loading = false;
        draft.loaded = true;
      });
    case FipeTypes.FPECARRO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
