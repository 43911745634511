/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { ICar } from "../../store/ducks/car/types";
import { carRequest } from "../../store/ducks/car/actions";
import { toast, ToastContainer } from "react-toastify";

const EstoqueWhats: React.FC = () => {
  const dispatch = useDispatch();

  const carros: any = useSelector<ApplicationState, ICar[]>(
    (state) => state.car.data
  );

  const getCar = useCallback(() => {
    dispatch(carRequest("?pp=10000&estoque=Sim&allEstoque=Sim"));
  }, [dispatch]);


  useEffect(() => {
    getCar();
  }, [getCar]);

  const textAreaRef = useRef(null);

  function copyToClipboard(e: any) {
    //@ts-ignore
    textAreaRef.current.select();
    document.execCommand('copy');

    e.target.focus();
    toast.success("Texto Copiado");
  };

  function nameFaturamento(params: string) {

    let nameFat = ""
    switch (params) {
      case "1":
        nameFat = " AF"
        break;
      case "2":
        nameFat = " FR"
        break;
      case "3":
        nameFat = " Licenciado"
        break;
      default:
        nameFat = " Sem listagem"
        break;
    }
    return nameFat

  }

  const dataCarro = carros
    .filter((data: any) => data.status !== "Vendido")
    .map((row: any) => (
      "*" + row.nome + "* \n" +
      row.ano_fab + " / " + row.ano_modelo + "\n" +
      row.cor + "\n" +
      row.descritivo + "\n" +
      "*R$ " + parseFloat(row.preco_venda).toFixed(2) // casas decimais
        .replace('.', 'MMN')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " /" + nameFaturamento(row.faturamento) + "* \n \n"

    ))
  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Estoque Whatsapp</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Estoque Whatsapp</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="heading-layout1 mt-2 mb-4">
              <button onClick={copyToClipboard}
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">
                Copiar
              </button>
            </div>
            <div className="row">
              <textarea rows={27} readOnly={true} className="col-12 textarea--whatsapp"
                ref={textAreaRef}
                value={
                  "https://linktr.ee/thaimotors\n*Thai Motors*\nNosso Estoque \n \n" +
                  dataCarro.toString().replaceAll(",", "").replaceAll("MMN", ",")}
              >
              </textarea>
              <ToastContainer autoClose={3000} position="top-right" />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default EstoqueWhats;
