import { Reducer } from "redux";
import producer from "immer";

import { CarState, CarTypes } from "./types";

const INITIAL_STATE: CarState = {
  data: [],
  dataBolsa: [],
  dataUnique: [],
  dataSite: [],
  view: {
    id: "",
    nome: "",
    descritivo: "",
    ano_modelo: "",
    ano_contrato: "",
    preco_custo: "",
    preco_fipe: "",
    preco_venda: "",
    chassi: "",
    marca: "",
    faturamento: "",
    fk_marca: 0,
    fk_modelo: 0,
    status: "",
    fk_cor: 0,
    fk_cambio: 0,
    fk_combustivel: 0,
    ano_fab: 0,
    estoque_site: "",
    file_compra: "",
    btnEmail: "",
    opcionais: "",
    preco_entrada: ""
  },
  response: {
    code: "",
    message: "",
  },

  upStatus: {
    id: "",
    status: "",
    estoque_site: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<CarState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CarTypes.CAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarTypes.CAR_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case CarTypes.CAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case CarTypes.CARUNIQUE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CarTypes.CARUNIQUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarTypes.CARUNIQUE_SUCCESS:
      return producer(state, (draft) => {
        draft.dataUnique = action.payload.dataUnique;
        draft.loading = false;
      });
    case CarTypes.CARUNIQUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case CarTypes.POST_CAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarTypes.POST_CAR_SUCCESS:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });
    case CarTypes.POST_CAR_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.error = true;
      });

    case CarTypes.VIEW_CAR:
      return {
        ...state,
        view: action.payload.view,
      };
    case CarTypes.CLEAN_VIEW_CAR:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
          descritivo: "",
          ano_modelo: "",
          ano_contrato: "",
          preco_custo: "",
          preco_fipe: "",
          preco_venda: "",
          chassi: "",
          marca: "",
          faturamento: "",
          fk_marca: 0,
          fk_modelo: 0,
          status: "",
          fk_cor: 0,
          fk_cambio: 0,
          fk_combustivel: 0,
          ano_fab: 0,
          estoque_site: "",
          file_compra: "",
          btnEmail: "",
          opcionais: "",
          preco_entrada: ""
        },
      };

    case CarTypes.PUT_CAR_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case CarTypes.PUT_CAR_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case CarTypes.PUT_CAR_FAILURE:
      return producer(state, (draft) => {
        // ...state,
        draft.response = action.payload.data;
        draft.loading = false;
        draft.error = true;
      });

    case CarTypes.UPSTATUS_FOTOCAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarTypes.UPSTATUS_FOTOCAR_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
      });
    case CarTypes.UPSTATUS_FOTOCAR_FAILURE:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.error = true;
      });

    case CarTypes.CLEAN_CAR_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };

    case CarTypes.CARSITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CarTypes.CARSITE_SUCCESS:
      return producer(state, (draft) => {
        draft.dataSite = action.payload.dataSite;
        draft.loading = false;
      });
    case CarTypes.CARSITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default reducer;
