import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import { IpostFuel, IFuel } from "../../store/ducks/fuel/types";
import {
  postFuelRequest,
  putFuelRequest,
} from "../../store/ducks/fuel/actions";
import { ToastContainer, toast } from "react-toastify";

const CadCombustivel: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  if (UserLogged !== "super_admin") {
    history.push("/carros");
  }

  const view = useSelector<ApplicationState, IFuel>((state) => state.fuel.view);

  const [form, setForm] = useState<IpostFuel>({
    descricao: view.descricao || "",
  });

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { descricao } = form;

      let btn = document.getElementById('btn_submit')
      if (btn) {
        btn.setAttribute("disabled", "{true}")
      }

      if (view.descricao) {
        dispatch(
          putFuelRequest({
            id: view.id,
            descricao,
          })
        );
        toast.success("Combustivel alterado com sucesso");
        setTimeout(() => history.push("/combustiveis"), 2000);
        return;
      }

      dispatch(
        postFuelRequest({
          descricao,
        })
      );
      toast.success("Combustivel criado com sucesso");
      setTimeout(() => history.push("/combustiveis"), 2000);
    },
    [dispatch, form, view, history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Combustivel</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              {view.descricao ? <li>Alterar Combustivel</li> : <li>Adicionar Combustivel</li>}
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  {view.descricao ? (
                    <h3>Alterar Combustivel</h3>
                  ) : (
                      <h3>Cadastro Combustivel</h3>
                    )}
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Combustivel
                    </label>
                    <input
                      value={form.descricao}
                      name="descricao"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Combustivel"
                      required={true}
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      {view.descricao ? "Alterar" : "Cadastrar"}
                    </button>
                  </div>
                  <ToastContainer autoClose={5000} position="top-right" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadCombustivel;
