import { Reducer } from "redux";
import producer from "immer";

import { YearState, YearTypes } from "./types";

const INITIAL_STATE: YearState = {
  data: [],
  view: {
    id: "",
    ano: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: false,
  error: false,
};

const reducer: Reducer<YearState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case YearTypes.YEAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case YearTypes.YEAR_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case YearTypes.YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case YearTypes.POST_YEAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case YearTypes.POST_YEAR_SUCCESS:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });
    case YearTypes.POST_YEAR_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case YearTypes.VIEW_YEAR:
      return {
        ...state,
        view: action.payload.view,
      };
    case YearTypes.CLEAN_VIEW_YEAR:
      return {
        ...state,
        view: {
          id: "",
          ano: "",
        },
      };
    case YearTypes.PUT_YEAR_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case YearTypes.PUT_YEAR_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.view = {
          id: "",
          ano: "",
        };
      });
    case YearTypes.PUT_YEAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
