export enum MarkTypes {
  MARK_REQUEST = "@mark/MARK_REQUEST",
  MARK_SUCCESS = "@mark/MARK_SUCCESS",
  MARK_FAILURE = "@mark/MARK_FAILURE",
  POST_MARK_REQUEST = "@mark/POST_MARK_REQUEST",
  POST_MARK_SUCCESS = "@mark/POST_MARK_SUCCESS",
  POST_MARK_FAILURE = "@mark/POST_MARK_FAILURE",
  PUT_MARK_REQUEST = "@mark/PUT_MARK_REQUEST",
  PUT_MARK_SUCCESS = "@mark/PUT_MARK_SUCCESS",
  PUT_MARK_FAILURE = "@mark/PUT_MARK_FAILURE",
  VIEW_MARK = "@mark/VEIW_MARK",
  CLEAN_VIEW_MARK = "@mark/CLEAN_VIEW_MARK",
  DELETE_MARK_REQUEST = "@mark/DELETE_MARK_REQUEST",
  DELETE_MARK_SUCCESS = "@mark/DELETE_MARK_SUCCESS",
  DELETE_MARK_FAILURE = "@mark/DELETE_MARK_FAILURE",
}

export interface IpostMark {
  nome: any;
} 

export interface IputMark {
  id:number;
  nome:string;
}

export interface IMark {
  id:number;
  nome:string;
} 

export interface MarkState {
  readonly data: IMark[];
  readonly view: IMark;
  readonly loading: boolean;
  readonly error: boolean;
}
