import React, { useCallback } from "react";
import MenuItem from "./menuItem";

import { useDispatch } from "react-redux";

import { signOut as signOutAction } from "../store/ducks/auth/actions";
import { cleanViewCar } from "../store/ducks/car/actions";
import { Link } from "react-router-dom";
import { cleanViewProvider } from "../store/ducks/provider/actions";
import { cleanViewThirds } from "../store/ducks/thirds/actions";
import MenuTree from "./menuTree";
import { cleanViewExpenses } from "../store/ducks/expenses/actions";

const MenuAdmin = () => {
  const dispatch = useDispatch();

  const signOut = useCallback(() => {
    localStorage.removeItem("persist:AppCAR_System");
    localStorage.removeItem("Auth-Token");
    localStorage.removeItem("Access-Level");
    localStorage.removeItem("typeCliente");
    dispatch(signOutAction());
  }, [dispatch]);

  const cleanViewCarro = useCallback(() => {
    dispatch(cleanViewCar());
  }, [dispatch]);

  const cleanFornecedor = useCallback(() => {
    dispatch(cleanViewProvider());
  }, [dispatch]);

  const cleanTerceiro = useCallback(() => {
    dispatch(cleanViewThirds());
  }, [dispatch]);

  const cleanDespesa = useCallback(() => {
    dispatch(cleanViewExpenses());
  }, [dispatch]);


  return (
    <ul className="nav nav-sidebar-menu sidebar-toggle-view">

      <MenuItem
        path="/carros"
        label="Estoque"
        icon="list"
        onClick={cleanViewCarro}
      />

      {/* <li className="nav-item">
        <Link to={"/estoque-whatsapp"} className="nav-link">
          <i className="fab fa-whatsapp"
            style={{ position: "initial", bottom: "0px", right: "none" }}>

          </i>
          <span>Estoque Whatsapp</span>
        </Link>
      </li> */}

      <MenuItem
        path="/clientes-loja"
        label="Clientes"
        icon="user"
        onClick={cleanViewCarro}
      />

      <MenuItem
        path="/fornecedor"
        label="Fornecedor"
        icon="list"
        onClick={cleanFornecedor}
      />

      <MenuItem
        path="/terceiro"
        label="Terceiros"
        icon="list"
        onClick={cleanTerceiro}
      />

      <MenuItem
        path="/confirmacao"
        label="Vendas"
        icon="list"
        onClick={cleanViewCarro}
      />

      <MenuTree path="/" label="Financeiro" icon="technological">
        <li className="nav-item">
          <Link to="/financeiro" className="nav-link" onClick={cleanDespesa}>
            <i className="fas fa-angle-right"></i>Lançamento
          </Link>
        </li>
      </MenuTree>

      <MenuItem label="Sair" path={"/"} icon="turn-off" onClick={signOut} />
    </ul>
  );
};
export default MenuAdmin;
