export enum FipeTypes {
  FPEMARCA_REQUEST = "@fipe/FPEMARCA_REQUEST",
  FPEMARCA_SUCCESS = "@fipe/FPEMARCA_SUCCESS",
  FPEMARCA_FAILURE = "@fipe/FPEMARCA_FAILURE",

  FPEMODELO_REQUEST = "@fipe/FPEMODELO_REQUEST",
  FPEMODELO_SUCCESS = "@fipe/FPEMODELO_SUCCESS",
  FPEMODELO_FAILURE = "@fipe/FPEMODELO_FAILURE",

  FPEANOVEICULO_REQUEST = "@fipe/FPEANOVEICULO_REQUEST",
  FPEANOVEICULO_SUCCESS = "@fipe/FPEANOVEICULO_SUCCESS",
  FPEANOVEICULO_FAILURE = "@fipe/FPEANOVEICULO_FAILURE",

  FPECARRO_REQUEST = "@fipe/FPECARRO_REQUEST",
  FPECARRO_SUCCESS = "@fipe/FPECARRO_SUCCESS",
  FPECARRO_FAILURE = "@fipe/FPECARRO_FAILURE",

  VIEW_FPEMODELO = "@fipe/VEIW_FPEMODELO",
  CLEAN_VIEW_FPEMODELO = "@fipe/CLEAN_VIEW_FPEMODELO",
}

export interface IFipe {
  name: string;
  fipe_name: string;
  order: string;
  key: string;
  id: any;
  codigo_fipe?: any;
}

export interface IFipeModel {
  fipe_marca: string;
  fipe_name: string;
  id: any;
  key: string;
  marca: string;
  name: string;
}

export interface IFipeYearVehicle {
  fipe_marca: string;
  fipe_codigo: string;
  name: any;
  marca: string;
  key: string;
  veiculo?: string;
  id: string;
}

export interface IFipeCar {
  id: string;
  ano_modelo: string;
  marca: string;
  name: string;
  veiculo: string;
  preco: string;
  combustivel: string;
  referencia: string;
  fipe_codigo: string;
  key: string;
}

export interface FipeState {
  readonly data: IFipe[];
  readonly modelo: IFipeModel[];
  readonly carro: IFipeCar[];
  readonly anoVeiculo: IFipeYearVehicle[];
  readonly view: IFipeModel;
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly error: boolean;
}
