import { Reducer } from "redux";
import producer from "immer";

import { FinancialState, FinancialTypes } from "./types";

const INITIAL_STATE: FinancialState = {
  data: [],
  view: {
    id: "",
    type: "",
    descricao: "",
    valor: "",
    data_lancamento: "",
    pago: "",
    fk_categoria: "",
    fk_confirmacao: "",
    fk_carro: "",
    categoria: "",
  },
  response: {
    code: "",
    message: "",
  },
  payment: {
    id: "",
    pago: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<FinancialState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FinancialTypes.FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FinancialTypes.FINANCIAL_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case FinancialTypes.FINANCIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case FinancialTypes.POST_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FinancialTypes.POST_FINANCIAL_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case FinancialTypes.POST_FINANCIAL_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });


    case FinancialTypes.VIEW_FINANCIAL:
      return {
        ...state,
        view: action.payload.view,
      };
    case FinancialTypes.CLEAN_VIEW_FINANCIAL:
      return {
        ...state,
        view: {
          id: "",
          type: "",
          descricao: "",
          valor: "",
          data_lancamento: "",
          pago: "",
          fk_categoria: "",
          fk_confirmacao: "",
          fk_carro: "",
          categoria: "",
        },
      };
    case FinancialTypes.PUT_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case FinancialTypes.PUT_FINANCIAL_SUCCESS:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.view = {
          id: "",
          type: "",
          descricao: "",
          valor: "",
          data_lancamento: "",
          pago: "",
          fk_categoria: "",
          fk_confirmacao: "",
          fk_carro: "",
          categoria: "",
        };
      });
    case FinancialTypes.PUT_FINANCIAL_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.error = true;
      });


    //PUT PAYMENT
    case FinancialTypes.PUTPAYMENT_FINANCIAL_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case FinancialTypes.PUTPAYMENT_FINANCIAL_SUCCESS:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });
    case FinancialTypes.PUTPAYMENT_FINANCIAL_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.error = true;
      });



    case FinancialTypes.CLEAN_FINANCIAL_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };


    default:
      return state;
  }
};

export default reducer;
