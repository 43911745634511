import React, { useCallback, useEffect } from "react";
import { Select } from "react-functional-select";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { clientPFRequest, clientPJRequest } from "../../../store/ducks/clientStore/actions";


const SelectClient = (props: any) => {
  const { form, handleChange, selectedOptionClient, setSelectedOptionClient, focusClient } = props;
  const dispatch = useDispatch();

  const clientsStore: any = useSelector<ApplicationState>(
    (state) => state.clientStore.dataPF
  );

  const clientsPJ = useSelector<ApplicationState>(
    (state) => state.clientStore.dataPJ
  );

  const allClients = clientsStore.concat(clientsPJ);


  const getClientPJ = useCallback(() => {
    dispatch(clientPJRequest("clients-pj?pp=200"));
  }, [dispatch]);

  const getClientPF = useCallback(() => {
    dispatch(clientPFRequest("clients-pf?pp=200"));
  }, [dispatch]);


  const getOptionLabelClient = useCallback(
    (option: any): string => `${option.nome || option.razao_social}`,
    []
  );
  const getOptionValueClient = useCallback(
    (option: any): string => option.nome || option.razao_social,
    []
  );
  const onOptionChangeClient = useCallback((option: any): void => {
    //@ts-ignore
    setSelectedOptionClient(option);
  }, []);


  useEffect(() => {
    getClientPF();
    getClientPJ();
  }, [getClientPJ, getClientPF])

  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Clientes</h3>
          </div>
        </div>
        <div className="new-added-form">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Clientes *
              </label>

              <Select
                options={allClients}
                addClassNames={true}
                placeholder="Selecione"
                onOptionChange={onOptionChangeClient}
                getOptionLabel={getOptionLabelClient}
                getOptionValue={getOptionValueClient}
                autoFocus={focusClient}
              />
            </div>

            {
              //@ts-ignore
              selectedOptionClient?.nome !== undefined ? (
                <>
                  <input
                    //@ts-ignore
                    value={(form.nameClient = selectedOptionClient?.CPF)}
                    name="nameClient"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <input
                    //@ts-ignore
                    value={(form.fk_cliente = selectedOptionClient?.id)}
                    name="fk_cliente"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      RG
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.RG}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="RG"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CPF
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.CPF}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CPF"
                    />
                  </div>
                </>
              ) : (
                <>
                  <input
                    //@ts-ignore
                    value={(form.nameClient = "")}
                    name="nameClient"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <input
                    //@ts-ignore
                    value={(form.fk_cliente = selectedOptionClient?.id)}
                    name="fk_cliente"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome Contato
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.nome_contato}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome Contato"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CNPJ
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.CNPJ}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CNPJ"
                    />
                  </div>
                </>
              )
            }

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Email
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.email}
                name="email"
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Email"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Telefone
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.telefone}
                name="telefone"
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Telefone"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Celular
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.celular}
                name="celular"
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Celular"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                CEP
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.CEP}
                name="CEP"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="CEP"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Rua
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.rua}
                name="rua"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Rua"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label className="label-form-input" htmlFor="endNumero">
                Numero
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.numero}
                name="numero"
                type="text"
                className="form-control"
                id="endNumero"
                placeholder="Numero"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Complemento
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.complemento}
                name="complemento"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Complemento"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Bairro
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.bairro}
                name="bairro"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="bairro"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Cidade
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.cidade}
                name="cidade"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Cidade"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Estado
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.estado}
                name="estado"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Sigla UF"
                disabled={true}
                maxLength={2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectClient;
