export enum FuelTypes {
  FUEL_REQUEST = "@fuel/FUEL_REQUEST",
  FUEL_SUCCESS = "@fuel/FUEL_SUCCESS",
  FUEL_FAILURE = "@fuel/FUEL_FAILURE",
  POST_FUEL_REQUEST = "@fuel/POST_FUEL_REQUEST",
  POST_FUEL_SUCCESS = "@fuel/POST_FUEL_SUCCESS",
  POST_FUEL_FAILURE = "@fuel/POST_FUEL_FAILURE",
  PUT_FUEL_REQUEST = "@fuel/PUT_FUEL_REQUEST",
  PUT_FUEL_SUCCESS = "@fuel/PUT_FUEL_SUCCESS",
  PUT_FUEL_FAILURE = "@fuel/PUT_FUEL_FAILURE",
  VIEW_FUEL = "@fuel/VEIW_FUEL",
  CLEAN_VIEW_FUEL = "@fuel/CLEAN_VIEW_FUEL",
  DELETE_FUEL_REQUEST = "@fuel/DELETE_FUEL_REQUEST",
  DELETE_FUEL_SUCCESS = "@fuel/DELETE_FUEL_SUCCESS",
  DELETE_FUEL_FAILURE = "@fuel/DELETE_FUEL_FAILURE",
}

export interface IpostFuel {
  descricao:string;
}

export interface IputFuel {
  id:string;
  descricao:string;

}

export interface IFuel {
  id:string;
  descricao:string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface FuelState {
  readonly data: IFuel[];
  readonly view: IFuel;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
