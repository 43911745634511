import { Reducer } from "redux";
import producer from "immer";

import { ModelsState, ModelsTypes } from "./types";

const INITIAL_STATE: ModelsState = {
  data: [],
  view: {
    id: "",
    nome: "",
    opcionais: "",
    marca: "",
  },
  loading: false,
  error: false,
};

const reducer: Reducer<ModelsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModelsTypes.MODELS_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ModelsTypes.MODELS_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case ModelsTypes.MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ModelsTypes.VIEW_MODEL:
      return {
        ...state,
        view: action.payload.view,
      };
    case ModelsTypes.CLEAN_VIEW_MODEL:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
          opcionais: "",
          marca: "",
        },
      };
    case ModelsTypes.PUT_MODELS_REQUEST:
      return {
        ...state,
        loading: false,
      };

    case ModelsTypes.PUT_MODELS_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.view = {
          id: "",
          nome: "",
          opcionais: "",
          marca: "",
        };
      });
    case ModelsTypes.PUT_MODELS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
