/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IFinancial } from "../../store/ducks/financial/types";
import { deleteFinancialRequest, financialRequest, viewFinancial } from "../../store/ducks/financial/actions";
import CheckboxFin from "../../components/checkbox";

const Despesa: React.FC = () => {

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  const dispatch = useDispatch();

  const financial: any = useSelector<ApplicationState, IFinancial[]>(
    (state) => state.financial.data
  );

  const financialLoad: any = useSelector<ApplicationState>(
    (state) => state.financial.loading
  );

  if (localStorage.getItem("financeiro") !== null) {
    localStorage.removeItem("financeiro");
  }

  localStorage.setItem("minDateFilter", "");
  localStorage.setItem("maxDateFilter", "");

  const getDespesas = useCallback(() => {
    dispatch(financialRequest("?pp=10000"));
  }, [dispatch]);

  const setView = useCallback(
    (client) => {
      dispatch(viewFinancial(client));
    },
    [dispatch]
  );

  const deleteClient = useCallback(
    (id) => {
      dispatch(deleteFinancialRequest(id));
      toast.success("Lançamento deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    [dispatch]
  );

  var initSearch = localStorage.getItem("chassi") === null ? "" : localStorage.getItem("chassi");

  useEffect(() => {
    getDespesas();

    setTimeout(() => {
      localStorage.removeItem("chassi")
    }, 2000)
  }, [getDespesas]);

  setTimeout(() => {



    $(".dashboard-content-one").on('click', function () {
      //@ts-ignore
      $(".collapse").removeClass("show");
    });

    $(document).on('click', '.actionStopPropagation', function (e) {
      e.stopPropagation();
    });

    //@ts-ignore
    var table = $("#bolsa0km").DataTable({
      destroy: true,
      paging: false,
      search: {
        "search": initSearch
      },
      order: [[1, "asc"]],
      "footerCallback": function (row: any, data: any, start: any, end: any, display: any) {
        var api = this.api(), data;

        // Remove the formatting to get integer data for summation
        var intVal = function (i: any) {
          return typeof i === 'string' ?
            //@ts-ignore
            i?.replace(/[\$,]/g, '') * 1 :
            typeof i === 'number' ?
              i : 0;
        };

        // Total over all pages
        var total = api
          .column(8, { page: 'current' })
          .data()
          .reduce(function (a: any, b: any) {
            console.log(a, b)
            return intVal(parseFloat(a)) + intVal(parseFloat(b));

          }, 0);

        if (total > 0) {
          var mostraTotal = '<t style="color:green;">  ' + total.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL'
          }).substring(3) + '</t>';
        } else {
          var mostraTotal = '<t style="color:red;"> ' + total.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'BRL'
          }).substring(3) + '</t>';
        }

        $("#saldoAtual").html(mostraTotal)

      },
    });

    $("#bolsa0km_filter").addClass("row");
    $("#bolsa0km_filter").addClass("filter--financeiro");
    $("#bolsa0km_filter label").addClass("labelFilter");
    $("#bolsa0km_filter label").addClass("col-12");
    $("#bolsa0km_filter label").addClass("col-md-4");



    //@ts-ignore
    $.fn.dataTableExt.afnFiltering.push(
      function (oSettings: any, aData: any, iDataIndex: any) {

        let minDateFilter: any = localStorage.getItem("minDateFilter");
        let maxDateFilter: any = localStorage.getItem("maxDateFilter");

        var dia = aData[3]?.split("/")[0];
        var mes = aData[3]?.split("/")[1];
        var ano = aData[3]?.split("/")[2];

        var dataConvert = ano + "-" + mes + "-" + dia;


        if (typeof aData._date == 'undefined') {
          aData._date = new Date(dataConvert).getTime();
        }

        if (minDateFilter && !isNaN(minDateFilter)) {
          if (aData._date < minDateFilter) {
            return false;
          }
        }

        if (maxDateFilter && !isNaN(maxDateFilter)) {
          if (aData._date > maxDateFilter) {
            return false;
          }
        }

        return true;
      });

    $("#bolsa0km_filter")
      .append(
        '<select id="dropdown1" class="form-control col-md-2 col-12 selectFilterPag">\
        <option value="">Pago</option>\
        <option value="Sim">Sim</option>\
        <option value="Nao">Nao</option>\
      </select>'
      );

    $("#bolsa0km_filter")
      .append(
        '<select id="dropdown2" class="form-control col-md-2 col-12 selectFilterEnt">\
        <option value="">Entrada / Saida</option>\
        <option value="Entrada">Entrada</option>\
        <option value="Saida">Saida</option>\
      </select>'
      );

    $('#dropdown1').on('change', function () {
      //@ts-ignore
      table.columns(4).search(this.value).draw();
    });

    $('#dropdown2').on('change', function () {
      //@ts-ignore
      table.columns(5).search(this.value).draw();
    });

    $("#bolsa0km_filter").append(
      '<input type="text" placeholder="Data De" class="form-control col-md-2 col-12 dateFilterDe"\
       autocomplete="off" id="dataDe">'
    )

    $("#bolsa0km_filter").append(
      '<input type="text" placeholder="Data Ate" class="form-control col-md-2 col-12 dateFilterAte"\
       autocomplete="off" id="dataAte">'
    )

    $(function () {
      //@ts-ignore
      $("#dataDe").datepicker({
        dateFormat: 'dd/mm/yy'
      });
      //@ts-ignore
      $("#dataAte").datepicker({
        dateFormat: 'dd/mm/yy'
      });
    });

    $("#dataDe").on('keyup change', function () {
      //@ts-ignore
      var dia = this.value.split("/")[0];
      //@ts-ignore
      var mes = this.value.split("/")[1];
      //@ts-ignore
      var ano = this.value.split("/")[2];

      var dataConvert = ano + "-" + mes + "-" + dia;

      //@ts-ignore
      localStorage.setItem("minDateFilter", new Date(dataConvert).getTime());
      // minDateFilter = new Date(dataConvert).getTime();
      table.draw();
      //@ts-ignore

    })

    $("#dataAte").on('keyup change', function () {
      //@ts-ignore
      var dia = this.value.split("/")[0];
      //@ts-ignore
      var mes = this.value.split("/")[1];
      //@ts-ignore
      var ano = this.value.split("/")[2];

      var dataConvert = ano + "-" + mes + "-" + dia;

      //@ts-ignore
      // maxDateFilter = new Date(dataConvert).getTime();
      localStorage.setItem("maxDateFilter", new Date(dataConvert).getTime());
      table.draw();
    })

  }, 100);






  function FormataStringData(data: any) {

    const date = data == "" || data == null ? "2021-07-01" : data;

    var dia = date.split("-")[0];
    var mes = date.split("-")[1];
    var ano = date.split("-")[2];

    // return ano + '/' + ("0" + mes).slice(-2) + '/' + ("0" + dia).slice(-2);
    return ano + '/' + ("0" + mes).slice(-2) + '/' + (dia);

  }

  let saldoAtual = 0;
  var formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }

  financial.filter((dt: any) => dt.pago === "Sim").map((saldo: any) => {

    if (UserLogged === "super_admin") {

      if (saldo.type === "Entrada") {
        saldoAtual += parseFloat(saldo.valor)
      } else {
        saldoAtual -= parseFloat(saldo.valor)
      }
    } else {
      saldoAtual += parseFloat(saldo.valor)
    }

    return saldoAtual
  });


  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Lançamentos</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>lançamentos</li>
            </ul>
          </div>

          <div className="card height-auto">

            <div className="card-body">

              <div className="breadcrumbs-area" style={{ padding: "0px 0 0px" }}>
                <div className="row">
                  <div className="saldoAtual col-lg-2 col-6">

                    <div style={{ fontSize: "13.5px" }}>Saldo Atual</div>
                    <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                      {saldoAtual < 0 ? (
                        <div className="valueNegativo">

                          {saldoAtual.toLocaleString('pt-BR', formato).replace("-", "")}
                        </div>
                      ) : (
                        <div className="valuePositivo">

                          {saldoAtual.toLocaleString('pt-BR', formato).replace("-", "")}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-2 col-6 d-block mb-2">
                    <div style={{ fontSize: "13.5px" }}>Saldo Liquido</div>
                    <div id="saldoAtual" style={{ fontSize: "18px", fontWeight: "bold" }}></div>
                  </div>

                </div>


              </div>


              <div className="heading-layout1 mt-2 mb-4"></div>
              <div
                className="table-responsive dataTables_wrapper"
                style={{ overflow: "unset" }}
              >
                {financialLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap " style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Categoria</th>
                        <th className="headerBolsaKm"></th>
                        <th>Descrição</th>
                        <th style={{ textAlign: "center" }}>Data</th>
                        <th style={{ display: "none" }}>Pago drop</th>
                        <th style={{ display: "none" }}>Entrada</th>
                        <th style={{ textAlign: "center" }}>Pago</th>
                        <th style={{ textAlign: "right" }}>Valor</th>
                        <th style={{ display: "none" }}>Valor</th>
                        <th style={{ textAlign: "center" }}>Ações</th>
                        <th style={{ display: "none" }}>Chassi</th>
                        <th style={{ display: "none" }}>ID</th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!financial
                        ? ""
                        : financial.map((row: any) => (

                          <tr
                            key={row.id}
                            style={{
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >

                            <td
                              className="infoCarroCard categoria--financeiro__mobile"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">{row.categoria}</div>
                            </td>

                            <td
                              className="infoCarroCard valor--financeiro__mobile headerBolsaKm"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">

                                {UserLogged === "super_admin" ? (

                                  row.type === "Entrada" ? (
                                    <div className="nameCarro valuePositivo"> R$ {parseFloat(row.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>
                                  ) : (
                                    <div className="nameCarro valueNegativo"> R$ {parseFloat(row.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>
                                  )


                                ) : (<div className="nameCarro valuePositivo"> R$ {parseFloat(row.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>)}

                              </div>
                            </td>

                            <td
                              className="infoCarroCard descricao--financeiro__mobile"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">{row.descricao.substring(0, 50) + "..."}</div>
                            </td>
                            <td
                              className="infoCarroCard datalanc--financeiro"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">{FormataStringData(row.data_lancamento)}</div>
                            </td>

                            <td style={{ display: "none" }}>
                              {row.pago}
                            </td>

                            <td style={{ display: "none" }}>
                              {row.type}
                            </td>

                            <td
                              className="infoCarroCard pago--financeiro"
                            >
                              <div className="nameCarro">
                                <CheckboxFin
                                  statusPago={row.pago}
                                  onChangeDispatch={row.id}
                                  userLogged={UserLogged}>

                                </CheckboxFin>
                              </div>
                            </td>
                            <td
                              className={`infoCarroCard valor--financeiro`}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                              style={{ textAlign: "right" }}
                            >

                              {UserLogged === "super_admin" ? (

                                row.type === "Entrada" ? (
                                  <div className="nameCarro valuePositivo"> R$ {parseFloat(row.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>
                                ) : (
                                  <div className="nameCarro valueNegativo"> R$ {parseFloat(row.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>
                                )


                              ) : (<div className="nameCarro valuePositivo"> R$ {parseFloat(row.valor)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</div>)}

                            </td>

                            <td
                              className={`infoCarroCard`}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                              style={{ display: "none" }}
                            >

                              {UserLogged === "super_admin" ? (


                                row.type === "Entrada" ? (
                                  parseFloat(row.valor)
                                ) : (
                                  "-" + parseFloat(row.valor)
                                )
                              ) : (
                                parseFloat(row.valor)
                              )}

                            </td>

                            <td
                              className="infoBolsaAcoes infoBolsaAcoes--estoque"
                              style={{ textAlign: "center" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              {row.fk_pagtoAuto !== null ? ("") : (
                                <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                              )}
                              <div
                                id={"folder" + row.id}
                                className="collapse action__collapse  action__collapse--fornecedor"
                                data-parent="#myGroup"
                              >

                                {row.fk_pagtoAuto !== null ? ("") : (

                                  <Link
                                    className="item__action"
                                    to={"/editar-financeiro"}
                                    onClick={() => {
                                      setView(row);
                                    }}
                                    style={{
                                      borderBottom: "1.8px solid #1d1d1d",
                                    }}
                                  >
                                    <i
                                      className="fas fa-pencil-alt text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Editar</span>
                                  </Link>
                                )}

                                {row.fk_pagtoAuto !== null ? ("") : (
                                  <Link
                                    className="item__action actionStopPropagation"
                                    to="/financeiro"
                                    onClick={() => deleteClient(row.id)}
                                    style={{
                                      borderBottom: "1.8px solid #1d1d1d",
                                    }}
                                  >
                                    <i
                                      className="fas fa-trash text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Excluir</span>
                                  </Link>
                                )}

                              </div>
                            </td>
                            <td className="d-none">
                              {row?.carro?.chassi}
                            </td>
                            <td className="d-none">
                              #{row?.carro?.custom_id}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                <ToastContainer autoClose={10000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-financeiro">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Despesa;
