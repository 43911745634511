import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MaskedInput from "react-text-mask";
import jwt from "jwt-decode";

import { userRequest } from "../../store/ducks/user/actions";
import { IRegisterUser } from "../../store/ducks/user/types";
import { ApplicationState } from "../../store";
import { ToastContainer, toast } from "react-toastify";

const CadastroUserFunc: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const responseApi: any = useSelector<ApplicationState>((state) => state.user);

  const token = location.pathname.substring(22);

  const decodeToken: any = jwt(token);

  const [form, setForm] = useState<IRegisterUser>({
    senha: "",
    nome: "",
    whatsapp: "",
    permissao: decodeToken.permissao,
    auth_token: token,
    fk_loja: decodeToken.fk_loja,
  });

  const login = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      dispatch(
        userRequest({
          senha: form.senha,
          nome: form.nome,
          whatsapp: form.whatsapp,
          permissao: form.permissao,
          auth_token: form.auth_token,
          fk_loja: form.fk_loja,
        })
      );
    },
    [
      dispatch,
      form.auth_token,
      form.fk_loja,
      form.nome,
      form.permissao,
      form.senha,
      form.whatsapp,
    ]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );


  const notify = useCallback((event) => {
    if (event === 200) {
      toast.success("Usuario Cadastrado com sucesso");
      setTimeout(() => history.push("/"), 2000);
    }
  }, [history])

  useEffect(() => {
    notify(responseApi.response.code)
  }, [notify, responseApi.response])

  return (
    <div>
      <div className="login-page-warp">
        <div className="login-page-content">
          <div className="login-box">
            <div className="item-logo">
              <h1>
                <b>APP</b>car
              </h1>
            </div>

            <form onSubmit={login} className="login-form">
              <div className="form-group">
                <label htmlFor="validationCustom01">Nome do Usuario</label>
                <input
                  value={form.nome}
                  onChange={handleChange}
                  name="nome"
                  type="name"
                  className="form-control"
                  placeholder="Nome do Usuario"
                  id="validationCustom01"
                  aria-describedby="emailHelp"
                  required={true}
                />
                <i className="fa fa-user"></i>
              </div>
              <div className="form-group">
                <label htmlFor="">Whatsapp</label>
                <MaskedInput
                  mask={[
                    "(",
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  guide={false}
                  value={form.whatsapp}
                  onChange={handleChange}
                  name="whatsapp"
                  type="name"
                  className="form-control"
                  placeholder="Whatsapp"
                  id="exampleInputWhatsapp1"
                  aria-describedby="emailHelp"
                  required={true}
                />
                <i className="fa fa-phone"></i>
              </div>

              <div className="form-group">
                <label htmlFor="">Senha</label>
                <input
                  value={form.senha}
                  onChange={handleChange}
                  name="senha"
                  type="password"
                  placeholder="Senha"
                  className="form-control"
                  id="exampleInputPassword1"
                  required={true}
                />
                <i className="fa fa-lock"></i>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  className="login-btn"
                >
                  Salvar
                </button>
              </div>
              <ToastContainer autoClose={5000} position="top-right" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadastroUserFunc;
