export enum ComissionTypes {
  COMISSION_REQUEST = "@commission/COMISSION_REQUEST",
  COMISSION_SUCCESS = "@commission/COMISSION_SUCCESS",
  COMISSION_FAILURE = "@commission/COMISSION_FAILURE",
  POST_COMISSION_REQUEST = "@commission/POST_COMISSION_REQUEST",
  POST_COMISSION_SUCCESS = "@commission/POST_COMISSION_SUCCESS",
  POST_COMISSION_FAILURE = "@commission/POST_COMISSION_FAILURE",
  PUT_COMISSION_REQUEST = "@commission/PUT_COMISSION_REQUEST",
  PUT_COMISSION_SUCCESS = "@commission/PUT_COMISSION_SUCCESS",
  PUT_COMISSION_FAILURE = "@commission/PUT_COMISSION_FAILURE",
  VIEW_COMISSION = "@commission/VEIW_COMISSION",
  CLEAN_VIEW_COMISSION = "@commission/CLEAN_VIEW_COMISSION",
  DELETE_COMISSION_REQUEST = "@commission/DELETE_COMISSION_REQUEST",
  DELETE_COMISSION_SUCCESS = "@commission/DELETE_COMISSION_SUCCESS",
  DELETE_COMISSION_FAILURE = "@commission/DELETE_COMISSION_FAILURE",
  CLEAN_COMISSION_RESPONSE = "@commission/CLEAN_COMISSION_RESPONSE",
}

export interface IpostComission {
  nome: string;
  data_lancamento: string;
  valor: string;
  fk_confirmacao?: string;
  fk_categoria: string;

}

export interface IputComission {
  id: string;
  nome: string;
  data_lancamento: string;
  valor: string;
  fk_confirmacao?: string;
  fk_categoria: string;

}

export interface IComission {
  id: string;
  nome: string;
  data_lancamento: string;
  valor: string;
  fk_confirmacao?: string;
  fk_categoria: string;
  categoria?: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface ComissionState {
  readonly data: IComission[];
  readonly view: IComission;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
