import React, { useCallback, useEffect } from "react";
import { Select } from "react-functional-select";
import { useDispatch } from "react-redux";

const SelectFornecedor = (props: any) => {
  const { form, handleChange, selectedOptionClient, selectedOptionCar, setForm,
    setSelectedOptionBank, setSelectedOptionAddress, selectedOptionAddress, focusAddressProvider, focusBankProvider } = props;
  const dispatch = useDispatch();

  const getOptionLabelBank = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBank = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );

  const onOptionChangeBank = useCallback((option: any): void => {
    //@ts-ignore
    setSelectedOptionBank(option);
    setForm({
      ...form,
      numero_banco: option?.numero_banco || "",
      agencia: option?.agencia || "",
      banco: option?.banco || "",
      conta: option?.conta || "",
      documento_banco: option?.documento || "",
      favorecido: option?.favorecido || "",
      pix_banco: option?.pix_banco || "",
    })

  }, [selectedOptionCar]);

  const getOptionLabelAddress = useCallback(
    (option: any): string => `${option.rua + ', ' + option.numero + ' - ' + option.estado}`,
    []
  );
  const getOptionValueAddress = useCallback(
    (option: any): string => `${option.rua + ', ' + option.numero + ' - ' + option.estado}`,
    []
  );
  const onOptionChangeAddress = useCallback((option: any): void => {
    //@ts-ignore
    setSelectedOptionAddress({
      CEP: option?.CEP || "",
      rua: option?.rua || "",
      numero: option?.numero || "",
      complemento: option?.complemento || "",
      bairro: option?.bairro || "",
      cidade: option?.cidade || "",
      estado: option?.estado || "",
    });

  }, []);


  useEffect(() => {

  }, [])

  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Fornecedor</h3>
          </div>
        </div>
        <div className="new-added-form">
          <div className="row">

            {
              //@ts-ignore
              selectedOptionCar?.fornecedor.CNPJ === "" || selectedOptionCar?.fornecedor.CNPJ === null ? (
                <>
                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionCar?.fornecedor?.nome}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome"
                    />
                  </div>

                  <input
                    //@ts-ignore
                    value={(form.fk_fornecedor = selectedOptionCar?.fornecedor?.id)}
                    name="fk_fornecedor"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      RG
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionCar?.fornecedor?.RG}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="RG"
                    />
                  </div>
                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CPF
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.fornecedor?.CPF}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CPF"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome contato
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionCar?.fornecedor?.nome}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome"
                    />
                  </div>

                  <input
                    //@ts-ignore
                    value={(form.fk_fornecedor = selectedOptionCar?.fornecedor?.id)}
                    name="fk_fornecedor"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Razão Social
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionCar?.fornecedor.razao_social}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Razão Social"
                    />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CNPJ
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionCar?.fornecedor?.CNPJ}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CNPJ"
                    />
                  </div>
                </>
              )
            }

            <div className="col-xl-4 col-lg-4 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Telefone
              </label>
              <input
                //@ts-ignore
                value={selectedOptionCar?.fornecedor.telefone}
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Telefone"
                disabled={true}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Celular
              </label>
              <input
                //@ts-ignore
                value={selectedOptionCar?.fornecedor.celular}
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Celular"
                disabled={true}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Email
              </label>
              <input
                //@ts-ignore
                value={selectedOptionCar?.fornecedor.email}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Email"
                disabled={true}
              />
            </div>

            <div className="heading-layout1 mt-2 mb-4 col-12">
              <div className="item-title">
                <h3>Banco</h3>
              </div>
            </div>

            <div className="col-xl-3 col-lg-12 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Bancos Existentes 
              </label>

              <Select
                //@ts-ignore
                options={selectedOptionCar?.fornecedor.bancos}
                addClassNames={true}
                placeholder="Selecione"
                onOptionChange={onOptionChangeBank}
                getOptionLabel={getOptionLabelBank}
                getOptionValue={getOptionValueBank}
                autoFocus={focusBankProvider}
                isClearable
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="numero_banco"
              >
                Código (Banco)
              </label>
              <input
                //@ts-ignore
                value={(form.numero_banco)}
                name="numero_banco"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="numero_banco"
                placeholder="Código Banco"
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="banco"
              >
                Nome (Banco)
              </label>
              <input
                //@ts-ignore
                value={(form.banco)}
                name="banco"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="banco"
                placeholder="Banco"
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="agencia"
              >
                Agência
              </label>
              <input
                //@ts-ignore
                value={(form.agencia)}
                name="agencia"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="agencia"
                placeholder="Agência"
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="conta"
              >
                Conta
              </label>
              <input
                //@ts-ignore
                value={(form.conta)}
                name="conta"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="conta"
                placeholder="Conta"
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="documento_banco"
              >
                CNPJ
              </label>
              <input
                //@ts-ignore
                value={(form.documento_banco)}
                name="documento_banco"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="documento_banco"
                placeholder="CPF ou CNPJ"
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="favorecido"
              >
                Favorecido
              </label>
              <input
                //@ts-ignore
                value={(form.favorecido)}
                name="favorecido"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="favorecido"
                placeholder="Favorecido"
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="pix_banco"
              >
                Pix Banco
              </label>
              <input
                //@ts-ignore
                value={(form.pix_banco)}
                name="pix_banco"
                onChange={handleChange}
                type="text"
                className="form-control"
                id="pix_banco"
                placeholder="Pix Banco"
              />
            </div>

            <div className="heading-layout1 mt-2 mb-4 col-12">
              <div className="item-title">
                <h3>Endereço</h3>
              </div>
            </div>

            <div className="col-xl-3 col-lg-12 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Endereço
              </label>

              <Select
                //@ts-ignore
                options={selectedOptionCar?.fornecedor.enderecos}
                addClassNames={true}
                placeholder="Selecione"
                onOptionChange={onOptionChangeAddress}
                getOptionLabel={getOptionLabelAddress}
                getOptionValue={getOptionValueAddress}
                autoFocus={focusAddressProvider}
                isClearable
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="CEP"
              >
                CEP
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.CEP}
                type="text"
                className="form-control"
                id="CEP"
                placeholder="CEP"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="rua"
              >
                Rua
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.rua}
                type="text"
                className="form-control"
                id="rua"
                placeholder="Rua"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="numero"
              >
                Numero
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.numero}
                type="text"
                className="form-control"
                id="numero"
                placeholder="Numero"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="complemento"
              >
                Complemento
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.complemento}
                type="text"
                className="form-control"
                id="complemento"
                placeholder="Complemento"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="bairro"
              >
                Bairro
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.bairro}
                type="text"
                className="form-control"
                id="bairro"
                placeholder="Bairro"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="cidade"
              >
                Cidade
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.cidade}
                type="text"
                className="form-control"
                id="cidade"
                placeholder="Cidade"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="estado"
              >
                Estado
              </label>
              <input
                //@ts-ignore
                value={selectedOptionAddress?.estado}
                type="text"
                className="form-control"
                id="estado"
                placeholder="Estado"
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectFornecedor;
