export enum BankAutoTypes {
  //client PF
  BANKAUTO_REQUEST = "@bankauto/BANKAUTO_REQUEST",
  BANKAUTO_SUCCESS = "@bankauto/BANKAUTO_SUCCESS",
  BANKAUTO_FAILURE = "@bankauto/BANKAUTO_FAILURE",

  POST_BANKAUTO_REQUEST = "@bankauto/POST_BANKAUTO_REQUEST",
  POST_BANKAUTO_SUCCESS = "@bankauto/POST_BANKAUTO_SUCCESS",
  POST_BANKAUTO_FAILURE = "@bankauto/POST_BANKAUTO_FAILURE",

  PUT_BANKAUTO_REQUEST = "@bankauto/PUT_BANKAUTO_REQUEST",
  PUT_BANKAUTO_SUCCESS = "@bankauto/PUT_BANKAUTO_SUCCESS",
  PUT_BANKAUTO_FAILURE = "@bankauto/PUT_BANKAUTO_FAILURE",

  VIEW_BANKAUTO = "@bankauto/VEIW_BANKAUTO",
  CLEAN_VIEW_BANKAUTO = "@bankauto/CLEAN_VIEW_BANKAUTO",


  CLEAN_BANKAUTO_RESPONSE = "@bankauto/CLEAN_BANKAUTO_RESPONSE",

  DELETE_BANKAUTO_REQUEST = "@bankauto/DELETE_BANKAUTO_REQUEST",
  DELETE_BANKAUTO_SUCCESS = "@bankauto/DELETE_BANKAUTO_SUCCESS",
  DELETE_BANKAUTO_FAILURE = "@bankauto/DELETE_BANKAUTO_FAILURE",

}

export interface IBankAuto {
  id: string;
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  pix?: string;
  favorecido?: string;
}

export interface IPostBankAuto {
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  pix?: string;
  favorecido?: string;
}

export interface IPutBankAuto {
  id: string;
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  pix?: string;
  favorecido?: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface BankAutoState {
  readonly data: IBankAuto[];
  readonly view: IBankAuto;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
