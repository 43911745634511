import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { CategoryFinTypes } from "./types";
import {
  categoryFinSuccess,
  categoryFinFailure,
  postCategoryFinRequest,
  postCategoryFinSuccess,
  postCategoryFinFailure,
  putCategoryFinRequest,
  putCategoryFinSuccess,
  putCategoryFinFailure,
  deleteCategoryFinRequest,
  deleteCategoryFinSuccess,
  deleteCategoryFinFailure,
  categoryFinRequest,
} from "./actions";

function* getCategoriesFin({ payload }: ReturnType<typeof categoryFinRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/categoria_financeiro" + params, auth);


    yield put(categoryFinSuccess(response.data.params.data));
  } catch (error) {
    yield put(categoryFinFailure());
  }
}

function* postCategoryFin({ payload }: ReturnType<typeof postCategoryFinRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostCategoryFin = payload.ipostCategoryFin;

    const response = yield call(api.post, "/categoria_financeiro", ipostCategoryFin, auth);

    yield put(postCategoryFinSuccess(response.data.response));
  } catch (error) {
    yield put(postCategoryFinFailure({ ...error.response.data.response }));
  }
}

function* putCategoryFin({ payload }: ReturnType<typeof putCategoryFinRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputCategoryFin = payload.iputCategoryFin;

    const id = payload.iputCategoryFin.id;

   const response = yield call(api.put, "/categoria_financeiro/" + id, iputCategoryFin, auth);

    yield put(putCategoryFinSuccess(response.data.response));
  } catch (error) {
    yield put(putCategoryFinFailure({ ...error.response.data.response }));
  }
}

function* deleteCategoryFin({ payload }: ReturnType<typeof deleteCategoryFinRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/categoria_financeiro/" + id, auth);

    yield put(deleteCategoryFinSuccess());
  } catch (error) {
    yield put(deleteCategoryFinFailure());
  }
}

export default all([
  takeLatest(CategoryFinTypes.CATEGORYFIN_REQUEST, getCategoriesFin),
  takeLatest(CategoryFinTypes.POST_CATEGORYFIN_REQUEST, postCategoryFin),
  takeLatest(CategoryFinTypes.PUT_CATEGORYFIN_REQUEST, putCategoryFin),
  takeLatest(CategoryFinTypes.DELETE_CATEGORYFIN_REQUEST, deleteCategoryFin),
]);
