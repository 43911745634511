import { Reducer } from "redux";
import producer from "immer";

import { ClientState, ClientTypes } from "./types";

const INITIAL_STATE: ClientState = {
  data: [],
  view: {
    id: "",
    nome: "",
    email: "",
    whatsapp: "",
    cnpj: "",
    type: "",
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    endNumero: "",
    deleted_at: "",
    created_by: "",
    telefone: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<ClientState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClientTypes.CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ClientTypes.CLIENT_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case ClientTypes.CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ClientTypes.POST_CLIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ClientTypes.POST_CLIENT_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case ClientTypes.POST_CLIENT_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case ClientTypes.VIEW_CLIENT:
      return {
        ...state,
        view: action.payload.view,
      };
    case ClientTypes.CLEAN_VIEW_CLIENT:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
          email: "",
          whatsapp: "",
          cnpj: "",
          type: "",
          cep: "",
          logradouro: "",
          complemento: "",
          bairro: "",
          localidade: "",
          uf: "",
          endNumero: "",
          deleted_at: "",
          created_by: "",
          telefone: "",
        },
      };
    case ClientTypes.PUT_CLIENT_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ClientTypes.PUT_CLIENT_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {
          id: "",
          nome: "",
          email: "",
          whatsapp: "",
          cnpj: "",
          type: "",
          cep: "",
          logradouro: "",
          complemento: "",
          bairro: "",
          localidade: "",
          uf: "",
          endNumero: "",
          created_by: "",
          telefone: "",
        };
      });
    case ClientTypes.PUT_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ClientTypes.CLEAN_CLIENT_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
