import { action } from "typesafe-actions";
import { DeclarationsTypes, IDeclarations, IpostDeclarations, IputDeclarations } from "./types";
import { IResponse } from "../declarations/types";

export const declarationsRequest = (paremtros: any) =>
  action(DeclarationsTypes.DECLARATION_REQUEST, {paremtros});

export const declarationsSuccess = (data: IDeclarations ) =>
  action(DeclarationsTypes.DECLARATION_SUCCESS, { data });

export const declarationsFailure = () => action(DeclarationsTypes.DECLARATION_FAILURE, {});

export const postDeclarationsRequest = (ipostDeclarations: IpostDeclarations) =>
  action(DeclarationsTypes.POST_DECLARATION_REQUEST, {ipostDeclarations});

export const postDeclarationsSuccess = (data: IResponse) =>
  action(DeclarationsTypes.POST_DECLARATION_SUCCESS, {data});

export const postDeclarationsFailure = (data: IResponse) => action(DeclarationsTypes.POST_DECLARATION_FAILURE, {data});

export const viewDeclarations = (view: IDeclarations) =>
  action(DeclarationsTypes.VIEW_DECLARATION, {view});

export const cleanViewDeclarations = () =>
  action(DeclarationsTypes.CLEAN_VIEW_DECLARATION, {})

export const putDeclarationsRequest = (iputDeclarations: IputDeclarations) =>
  action(DeclarationsTypes.PUT_DECLARATION_REQUEST, {iputDeclarations});

export const putDeclarationsSuccess = (data: IResponse) =>
  action(DeclarationsTypes.PUT_DECLARATION_SUCCESS, {data});

export const putDeclarationsFailure = () => action(DeclarationsTypes.PUT_DECLARATION_FAILURE, {});

export const deleteDeclarationsRequest = (id: string) =>
  action(DeclarationsTypes.DELETE_DECLARATION_REQUEST, {id});

export const deleteDeclarationsSuccess = () =>
  action(DeclarationsTypes.DELETE_DECLARATION_SUCCESS, {});

export const deleteDeclarationsFailure = () => action(DeclarationsTypes.DELETE_DECLARATION_FAILURE, {});

export const cleanViewDeclarationsResp = () =>
  action(DeclarationsTypes.CLEAN_DECLARATION_RESPONSE, {});

