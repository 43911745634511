import { persistStore } from "redux-persist";
import { createStore, compose, applyMiddleware, Store } from "redux";
import createSagaMiddleware from "redux-saga";

import persistReducers from "./persistReducer";

import { AuthState } from "./ducks/auth/types";
import { ClientState } from "./ducks/client/types";
import { ModelsState } from "./ducks/models/types";

import { ColorState } from "./ducks/color/types";
import { GearshiftState } from "./ducks/gearshift/types";
import { YearState } from "./ducks/year/types";
import { FipeState } from "./ducks/fipe/types";
import { MarkState } from "./ducks/mark/types";
import { FuelState } from "./ducks/fuel/types";
import { OptionalState } from "./ducks/optional/types";
import { UserState } from "./ducks/user/types";
import { CarState } from "./ducks/car/types";
import { EmployeeState } from "./ducks/employee/types";
import { ClientStoreState } from "./ducks/clientStore/types";
import { ContractsState } from "./ducks/contracts/types";
import { DeclarationsState } from "./ducks/declarations/types";
import { ProviderState } from "./ducks/provider/types";
import { ExpensesState } from "./ducks/expenses/types";
import { CategoryFinState } from "./ducks/categoryFinancial/types";
import { FinancialState } from "./ducks/financial/types";
import { ComissionState } from "./ducks/comission/types";
import { BankAutoState } from "./ducks/bankAuto/types";
import { ThirdsState } from "./ducks/thirds/types";
import { PagtoThirdState } from "./ducks/pagtoThird/types";
import { PagtoParcautoState } from "./ducks/pagtoParcauto/types";

import rootReducer from "./ducks/rootReducer";
import rootSaga from "./ducks/rootSaga";

export interface ApplicationState {
  auth: AuthState;
  client: ClientState;
  model: ModelsState;
  color: ColorState;
  gearshift: GearshiftState;
  year: YearState;
  fipe: FipeState;
  mark: MarkState;
  fuel: FuelState;
  optional: OptionalState;
  user: UserState;
  car: CarState;
  employee: EmployeeState;
  clientStore: ClientStoreState;
  contracts: ContractsState;
  declarations: DeclarationsState;
  provider: ProviderState;
  expenses: ExpensesState;
  categoryFinancial: CategoryFinState;
  financial: FinancialState;
  comission: ComissionState;
  bankAuto: BankAutoState;
  thirds: ThirdsState;
  pagtoThird: PagtoThirdState;
  PagtoParcautoState: PagtoParcautoState;
}

const sagaMonitor =
  process.env.NODE_ENV === "development"
    ? // @ts-ignore
    // @ts-ignore
    console.tron.createSagaMonitor()
    : null;
const middlewares = [];
const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
});
middlewares.push(sagaMiddleware);

const composer =
  process.env.NODE_ENV === "development"
    ? compose(
      applyMiddleware(...middlewares),
      // @ts-ignore
      console.tron.createEnhancer()
    )
    : applyMiddleware(...middlewares);

//@ts-ignore
const store: Store<ApplicationState> = createStore(
  persistReducers(rootReducer),
  composer
);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
