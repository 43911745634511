/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ICategoryFin } from "../../store/ducks/categoryFinancial/types";
import { categoryFinRequest, deleteCategoryFinRequest, viewCategoryFin } from "../../store/ducks/categoryFinancial/actions";

const CategoriaFin: React.FC = () => {
  const dispatch = useDispatch();

  const catFinanceiro: any = useSelector<ApplicationState, ICategoryFin[]>(
    (state) => state.categoryFinancial.data
  );

  const catFinanceiroLoad: any = useSelector<ApplicationState>(
    (state) => state.categoryFinancial.loading
  );

  if (localStorage.getItem("categoriaFin") !== null) {
    localStorage.removeItem("categoriaFin");
  }

  const getProvider = useCallback(() => {
    dispatch(categoryFinRequest("?pp=10000"));
  }, [dispatch]);

  const setView = useCallback(
    (client) => {
      dispatch(viewCategoryFin(client));
    },
    [dispatch]
  );

  const deleteClient = useCallback(
    (id) => {
      dispatch(deleteCategoryFinRequest(id));
      toast.success("Categoria deletada com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    [dispatch]
  );

  useEffect(() => {
    getProvider();
  }, [getProvider]);

  setTimeout(() => {
    //@ts-ignore
    $("#bolsa0km").DataTable({
      destroy: true,
      paging: false,
      order: [[1, "asc"]],
    });


    $(".dashboard-content-one").on('click', function () {
      //@ts-ignore
      $(".collapse").removeClass("show");
    });


    $(document).on('click', '.actionStopPropagation', function (e) {
      e.stopPropagation();
    });

  }, 100);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Categoria Financeiro</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>financeiro</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4"></div>
              <div
                className="table-responsive dataTables_wrapper"
                style={{ overflow: "unset" }}
              >
                {catFinanceiroLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th style={{ textAlign: "center" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!catFinanceiro
                        ? ""
                        : catFinanceiro.map((row: any) => (
                            <tr
                              key={row.id}
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <td
                                className=""
                                data-toggle="collapse"
                                data-target={"#folder" + row.id}
                              >
                                {row.nome}
                              </td>

                              <td
                                className="infoBolsaAcoes infoBolsaAcoes--estoque"
                                style={{ textAlign: "center" }}
                                data-toggle="collapse"
                                data-target={"#folder" + row.id}
                              >
                                <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                                <div
                                  id={"folder" + row.id}
                                  className="collapse action__collapse  action__collapse--fornecedor"
                                  data-parent="#myGroup"
                                >
                                  <Link
                                    className="item__action"
                                    to={"/editar-categoria-fin"}
                                    onClick={() => {
                                      setView(row);
                                    }}
                                    style={{
                                      borderBottom: "1.8px solid #1d1d1d",
                                    }}
                                  >
                                    <i
                                      className="fas fa-pencil-alt text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Editar</span>
                                  </Link>
                                  <Link
                                    className="item__action actionStopPropagation"
                                    to="/fornecedor"
                                    onClick={() => deleteClient(row.id)}
                                    style={{
                                      borderBottom: "1.8px solid #1d1d1d",
                                    }}
                                  >
                                    <i
                                      className="fas fa-trash text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Excluir</span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
                <ToastContainer autoClose={10000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-categoria-fin">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriaFin;
