import { action } from "typesafe-actions";
import {
  CarTypes,
  ICar,
  IpostCar,
  IputCar,
  IUpStatus,
  IResponse,
} from "./types";

export const carRequest = (paramsUrl: any) =>
  action(CarTypes.CAR_REQUEST, { paramsUrl });

export const carSuccess = (data: ICar) =>
  action(CarTypes.CAR_SUCCESS, { data });

export const carFailure = () => action(CarTypes.CAR_FAILURE, {});

export const carUniqueRequest = (paramsUrl: any) =>
  action(CarTypes.CARUNIQUE_REQUEST, { paramsUrl });

export const carUniqueSuccess = (dataUnique: any) =>
  action(CarTypes.CARUNIQUE_SUCCESS, { dataUnique });

export const carUniqueFailure = () => action(CarTypes.CARUNIQUE_FAILURE, {});

export const postCarRequest = (Ipostcar: IpostCar) =>
  action(CarTypes.POST_CAR_REQUEST, { Ipostcar });

export const postCarSuccess = (data: IResponse) =>
  action(CarTypes.POST_CAR_SUCCESS, { data });

export const postCarFailure = (data: IResponse) =>
  action(CarTypes.POST_CAR_FAILURE, { data });

export const viewCar = (view: ICar) => action(CarTypes.VIEW_CAR, { view });

export const cleanViewCar = () => action(CarTypes.CLEAN_VIEW_CAR, {});

export const putCarRequest = (Iputcar: IputCar) =>
  action(CarTypes.PUT_CAR_REQUEST, { Iputcar });

export const putCarSuccess = (data: IResponse) =>
  action(CarTypes.PUT_CAR_SUCCESS, { data });

export const putCarFailure = (data: IResponse) =>
  action(CarTypes.PUT_CAR_FAILURE, { data });

export const deleteCarRequest = (id: string) =>
  action(CarTypes.DELETE_CAR_REQUEST, { id });

export const deleteCarSuccess = () => action(CarTypes.DELETE_CAR_SUCCESS, {});

export const deleteCarFailure = () => action(CarTypes.DELETE_CAR_FAILURE, {});

export const cleanViewCarResp = () => action(CarTypes.CLEAN_CAR_RESPONSE, {});

export const postUpStatusFotoRequest = (IpostStatus: IUpStatus) =>
  action(CarTypes.UPSTATUS_FOTOCAR_REQUEST, { IpostStatus });

export const postUpStatusFotoSuccess = () =>
  action(CarTypes.UPSTATUS_FOTOCAR_SUCCESS, {});

export const postUpStatusFotoFailure = () =>
  action(CarTypes.UPSTATUS_FOTOCAR_FAILURE, {});

export const carSiteRequest = (paramsUrl: any) =>
  action(CarTypes.CARSITE_REQUEST, { paramsUrl });

export const carSiteSuccess = (dataSite: ICar) =>
  action(CarTypes.CARSITE_SUCCESS, { dataSite });

export const carSiteFailure = () => action(CarTypes.CARSITE_FAILURE, {});
