export enum PagtoThirdTypes {
  PAGTOTHIRD_REQUEST = "@pagtoThird/PAGTOTHIRD_REQUEST",
  PAGTOTHIRD_SUCCESS = "@pagtoThird/PAGTOTHIRD_SUCCESS",
  PAGTOTHIRD_FAILURE = "@pagtoThird/PAGTOTHIRD_FAILURE",
  POST_PAGTOTHIRD_REQUEST = "@pagtoThird/POST_PAGTOTHIRD_REQUEST",
  POST_PAGTOTHIRD_SUCCESS = "@pagtoThird/POST_PAGTOTHIRD_SUCCESS",
  POST_PAGTOTHIRD_FAILURE = "@pagtoThird/POST_PAGTOTHIRD_FAILURE",
  PUT_PAGTOTHIRD_REQUEST = "@pagtoThird/PUT_PAGTOTHIRD_REQUEST",
  PUT_PAGTOTHIRD_SUCCESS = "@pagtoThird/PUT_PAGTOTHIRD_SUCCESS",
  PUT_PAGTOTHIRD_FAILURE = "@pagtoThird/PUT_PAGTOTHIRD_FAILURE",
  VIEW_PAGTOTHIRD = "@pagtoThird/VEIW_PAGTOTHIRD",
  CLEAN_VIEW_PAGTOTHIRD = "@pagtoThird/CLEAN_VIEW_PAGTOTHIRD",
  DELETE_PAGTOTHIRD_REQUEST = "@pagtoThird/DELETE_PAGTOTHIRD_REQUEST",
  DELETE_PAGTOTHIRD_SUCCESS = "@pagtoThird/DELETE_PAGTOTHIRD_SUCCESS",
  DELETE_PAGTOTHIRD_FAILURE = "@pagtoThird/DELETE_PAGTOTHIRD_FAILURE",
  CLEAN_PAGTOTHIRD_RESPONSE = "@pagtoThird/CLEAN_PAGTOTHIRD_RESPONSE",
}

export interface IpostPagtoThird {
  nome: string;
  data_lancamento: string;
  valor: string;
  pdf: string;
  fk_banco?: string;
  fk_confirmacao?: string;
  fk_categoria: string;

}

export interface IputPagtoThird {
  id: string;
  nome: string;
  data_lancamento: string;
  valor: string;
  pdf: string;
  fk_banco?: string;
  fk_confirmacao?: string;
  fk_categoria: string;

}

export interface IPagtoThird {
  id: string;
  nome: string;
  data_lancamento: string;
  valor: string;
  pdf: string;
  fk_banco?: string;
  fk_confirmacao?: string;
  fk_categoria: string;
  categoria?: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface PagtoThirdState {
  readonly data: IPagtoThird[];
  readonly view: IPagtoThird;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
