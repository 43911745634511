/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { IFipe, IFipeModel } from "../../store/ducks/fipe/types";
import { Select } from "react-functional-select";
import {
  fmarcasRequest,
  fmodelosRequest,
} from "../../store/ducks/fipe/actions";
import Table from "../../components/tabelaModelo/index";
const Modelos: React.FC = () => {
  const dispatch = useDispatch();

  const [filterMarca, setfilterMarca] = useState(undefined);
  const [form, setForm] = useState({ search: "" });

  const fmarcas: any = useSelector<ApplicationState>(
    (state) => state.fipe.data
  );

  const modelsFipe: any = useSelector<ApplicationState, IFipeModel[]>(
    (state) => state.fipe.modelo
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  localStorage.removeItem("opModelo");


  const getFMarcas = useCallback(() => {
    dispatch(fmarcasRequest());
  }, [dispatch]);

  const getMark = useCallback(
    (event) => {
      dispatch(fmodelosRequest(event));
    },
    [dispatch]
  );

  const getOptionLabelMarca = useCallback(
    (option): string => `${option.fipe_name}`,
    []
  );
  const getOptionValueMarca = useCallback(
    (option: IFipe): string => option.id,

    []
  );
  const onOptionChangeMarca = useCallback(
    (option: any | null): void => {
      setfilterMarca(option?.id.toString());
      getMark(option?.id);
    },
    [getMark]
  );

  useEffect(() => {
    getFMarcas();
  }, []);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Modelos</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Modelos</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4"></div>
              <div className="row">
              <div className="col-xl-6 col-lg-6 col-12 form-group">
                  <Select
                    isClearable={true}
                    options={fmarcas}
                    addClassNames={true}
                    placeholder="Pesquisar Por Marca"
                    onOptionChange={onOptionChangeMarca}
                    getOptionValue={getOptionValueMarca}
                    getOptionLabel={getOptionLabelMarca}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-12 form-group">
                  <input
                    value={form.search}
                    name="search"
                    onChange={handleChange}
                    type="text"
                    className="form-control"
                    id="inputPesquisaGlobal"
                    placeholder="Pesquisar"
                  />
                </div>

              </div>
              <div
                className="table-responsive dataTables_wrapper"
                style={{ position: "inherit" }}
              >
                <Table data={modelsFipe} filterGlobal={form.search} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modelos;
