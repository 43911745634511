import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { PagtoThirdTypes } from "./types";
import {
  pagtoThirdRequest,
  pagtoThirdSuccess,
  pagtoThirdFailure,
  postPagtoThirdRequest,
  postPagtoThirdSuccess,
  postPagtoThirdFailure,
  putPagtoThirdRequest,
  putPagtoThirdSuccess,
  putPagtoThirdFailure,
  deletePagtoThirdRequest,
  deletePagtoThirdSuccess,
  deletePagtoThirdFailure,
} from "./actions";

function* getPagtoThird({ payload }: ReturnType<typeof pagtoThirdRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/pagto-terceiro" + params, auth);


    yield put(pagtoThirdSuccess(response.data.params.data));
  } catch (error) {
    yield put(pagtoThirdFailure());
  }
}

function* postPagtoThird({ payload }: ReturnType<typeof postPagtoThirdRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostPagtoThird = payload.ipostPagtoThird;

    const response = yield call(api.post, "/pagto-terceiro", ipostPagtoThird, auth);

    yield put(postPagtoThirdSuccess(response.data.response));
  } catch (error) {
    yield put(postPagtoThirdFailure({ ...error.response.data.response }));
  }
}

function* putPagtoThird({ payload }: ReturnType<typeof putPagtoThirdRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputPagtoThird = payload.iputPagtoThird;

    const id = payload.iputPagtoThird.id;

    const response = yield call(api.put, "/pagto-terceiro/" + id, iputPagtoThird, auth);

    yield put(putPagtoThirdSuccess(response.data.response));
  } catch (error) {
    yield put(putPagtoThirdFailure({ ...error.response.data.response }));
  }
}

function* deletePagtoThird({ payload }: ReturnType<typeof deletePagtoThirdRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/pagto-terceiro/" + id, auth);

    yield put(deletePagtoThirdSuccess());
  } catch (error) {
    yield put(deletePagtoThirdFailure());
  }
}

export default all([
  takeLatest(PagtoThirdTypes.PAGTOTHIRD_REQUEST, getPagtoThird),
  takeLatest(PagtoThirdTypes.POST_PAGTOTHIRD_REQUEST, postPagtoThird),
  takeLatest(PagtoThirdTypes.PUT_PAGTOTHIRD_REQUEST, putPagtoThird),
  takeLatest(PagtoThirdTypes.DELETE_PAGTOTHIRD_REQUEST, deletePagtoThird),
]);
