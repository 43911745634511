import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import {
  clientPFRequest,
  viewClientPF,
  deleteClientPFRequest,
  clientPJRequest,
  deleteClientPJRequest,
  viewClientPJ,
  cleanViewClientPF,
  cleanViewClientPJ,
} from "../../store/ducks/clientStore/actions";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IUserLoja } from "../../store/ducks/user/types";


const ClientesLoja: React.FC = () => {
  const dispatch = useDispatch();

  const clientsPF: any = useSelector<ApplicationState>(
    (state) => state.clientStore.dataPF
  );

  const viewLoja = useSelector<ApplicationState, IUserLoja>(
    (state) => state.user.userLoja
  );

  const loadingPF = useSelector<ApplicationState>(
    (state) => state.clientStore.loadingPF
  );

  const clientsPJ: any = useSelector<ApplicationState>(
    (state) => state.clientStore.dataPJ
  );

  const loadingPJ = useSelector<ApplicationState>(
    (state) => state.clientStore.loadingPJ
  );

  localStorage.removeItem("cliente-loja");

  const setViewPF = useCallback(
    (client) => {
      dispatch(viewClientPF(client));
    },
    [dispatch]
  );

  const setViewPJ = useCallback(
    (client) => {
      dispatch(viewClientPJ(client));
    },
    [dispatch]
  );

  const getClientPF = useCallback(() => {
    dispatch(clientPFRequest("clients-pf?pp=300"));
    dispatch(cleanViewClientPF());
  }, [dispatch, viewLoja.id]);

  const getClientPJ = useCallback(() => {
    dispatch(clientPJRequest("clients-pj?pp=300"));
    dispatch(cleanViewClientPJ());
  }, [dispatch, viewLoja.id]);

  const deleteClientPF = useCallback(
    (id) => {
      dispatch(deleteClientPFRequest(id));
      toast.success("Cliente deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    [dispatch, getClientPF]
  );

  const deleteClientPJ = useCallback(
    (id) => {
      dispatch(deleteClientPJRequest(id));
      toast.success("Cliente deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    [dispatch, getClientPF]
  );

  useEffect(() => {
    getClientPF();
    getClientPJ();
  }, [getClientPF, getClientPJ]);

  if (loadingPF === false || loadingPJ === false) {
    setTimeout(() => {
      //@ts-ignore
      $("#bolsa0km").DataTable({
        destroy: true,
        paging: false,
        ordering: true,
      });

      $(".dashboard-content-one").on('click', function () {
        //@ts-ignore
        $(".collapse").removeClass("show");
      });

      $(document).on('click', '.actionStopPropagation', function (e) {
        e.stopPropagation();
      });
    }, 100);
  }

  const allData = clientsPJ.concat(clientsPF);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Clientes</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Clientes</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title"></div>
              </div>
              <div
                className="table-responsive dataTables_wrapper"
                style={{ overflow: "unset" }}
              >
                {loadingPF === true || loadingPJ === true ? (
                  ""
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Numero</th>
                        <th>Cidade</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!allData
                        ? ""
                        : allData.map((client: any) => (

                          <tr
                            key={client.id + client.CPF?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") + client.CNPJ?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")}
                            style={{
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            <td
                              className="infoCarroCard"
                              data-toggle="collapse"
                              data-target={"#folder" + client.id + client.CPF?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") + client.CNPJ?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")}
                            >{client.nome || client.nome_contato}</td>
                            <td
                              className="infoCarroCard"
                              data-toggle="collapse"
                              data-target={"#folder" + client.id + client.CPF?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") + client.CNPJ?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")}
                            >{client.celular}</td>
                            <td
                              className="infoCarroCard"
                              data-toggle="collapse"
                              data-target={"#folder" + client.id + client.CPF?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") + client.CNPJ?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")}
                            >{client.cidade}</td>
                            <td
                              className="infoBolsaAcoes infoBolsaAcoes--estoque"
                              style={{ textAlign: "center" }}
                              data-toggle="collapse"
                              data-target={"#folder" + client.id + client.CPF?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") + client.CNPJ?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")}
                            >
                              <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                              <div
                                id={"folder" + client.id + client.CPF?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "") + client.CNPJ?.replaceAll(" ", "").replaceAll("-", "").replaceAll(".", "").replaceAll("/", "")}
                                className="collapse action__collapse  action__collapse--fornecedor"
                                data-parent="#myGroup"
                              >
                                <Link
                                  className="item__action"
                                  to={"/editar-clientes-loja"}
                                  onClick={() => {
                                    client.nome_contato === undefined
                                      ? setViewPF(client)
                                      : setViewPJ(client);
                                  }}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-orange"
                                    style={{
                                      position: "unset",
                                      bottom: "0",
                                      right: "0",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span>Editar</span>
                                </Link>
                                <Link
                                  className="item__action actionStopPropagation"
                                  to={"/clientes-loja"}
                                  onClick={() =>
                                    client.nome_contato === undefined
                                      ? deleteClientPF(client.id)
                                      : deleteClientPJ(client.id)
                                  }
                                >
                                  <i
                                    className="fas fa-trash text-orange"
                                    style={{
                                      position: "unset",
                                      bottom: "0",
                                      right: "0",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span>Deletar</span>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

                <ToastContainer autoClose={5000} position="top-right" />
              </div>
              <div className="fab">
                <Link to="/cadastro-clientes-loja">
                  <button className="main">+</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ClientesLoja;
