import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ClientTypes } from "./types";
import {
  clientSuccess,
  clientFailure,
  postClientRequest,
  postClientSuccess,
  postClientFailure,
  putClientRequest,
  putClientSuccess,
  putClientFailure,
  deleteClientRequest,
  deleteClientSuccess,
  deleteClientFailure,
  recoveryClientRequest,
  recoveryClientSuccess,
  recoveryClientFailure,
  clientRequest,
} from "./actions";

function* getClients({ payload }: ReturnType<typeof clientRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.data;

    const response = yield call(api.get, "/lojas"+params, auth);

    yield put(clientSuccess(response.data.params.data));
  } catch (error) {
    yield put(clientFailure());
  }
}

function* postClients({ payload }: ReturnType<typeof postClientRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostClient = payload.ipostClient;

    if (auth.headers["Auth-Token"] === null) {
      const response = yield call(api.post, "/lojas", ipostClient);
      yield put(postClientSuccess(response.data.response));
    } else {
      const response = yield call(api.post, "/lojas", ipostClient, auth);
      yield put(postClientSuccess(response.data.response));
    }
  } catch (error) {
    yield put(
      postClientFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putClients({ payload }: ReturnType<typeof putClientRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputClient = payload.iputClient;

    const id = payload.iputClient.id;

    const response = yield call(api.put, "/lojas/" + id, iputClient, auth);

    yield put(putClientSuccess(response.data.response));
  } catch (error) {
    yield put(putClientFailure());
  }
}

function* deleteClients({ payload }: ReturnType<typeof deleteClientRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/lojas/" + id, auth);

    yield put(deleteClientSuccess());
  } catch (error) {
    yield put(deleteClientFailure());
  }
}

function* recoveryClient({ payload }: ReturnType<typeof recoveryClientRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.post, "/lojas/recover/" + id, {}, auth);

    yield put(recoveryClientSuccess());
  } catch (error) {
    yield put(recoveryClientFailure());
  }
}

export default all([
  takeLatest(ClientTypes.CLIENT_REQUEST, getClients),
  takeLatest(ClientTypes.POST_CLIENT_REQUEST, postClients),
  takeLatest(ClientTypes.PUT_CLIENT_REQUEST, putClients),
  takeLatest(ClientTypes.DELETE_CLIENT_REQUEST, deleteClients),
  takeLatest(ClientTypes.RECOVERY_CLIENT_REQUEST, recoveryClient),
]);
