import { action } from "typesafe-actions";
import { CategoryFinTypes, ICategoryFin, IpostCategoryFin, IputCategoryFin, IResponse } from "./types";

export const categoryFinRequest = (paremtros: any) =>
  action(CategoryFinTypes.CATEGORYFIN_REQUEST, { paremtros });

export const categoryFinSuccess = (data: ICategoryFin) =>
  action(CategoryFinTypes.CATEGORYFIN_SUCCESS, { data });

export const categoryFinFailure = () => action(CategoryFinTypes.CATEGORYFIN_FAILURE, {});

export const postCategoryFinRequest = (ipostCategoryFin: IpostCategoryFin) =>
  action(CategoryFinTypes.POST_CATEGORYFIN_REQUEST, { ipostCategoryFin });

export const postCategoryFinSuccess = (data: IResponse) =>
  action(CategoryFinTypes.POST_CATEGORYFIN_SUCCESS, { data });

export const postCategoryFinFailure = (data: IResponse) => action(CategoryFinTypes.POST_CATEGORYFIN_FAILURE, { data });

export const viewCategoryFin = (view: ICategoryFin) =>
  action(CategoryFinTypes.VIEW_CATEGORYFIN, { view });

export const cleanViewCategoryFin = () =>
  action(CategoryFinTypes.CLEAN_VIEW_CATEGORYFIN, {})

export const putCategoryFinRequest = (iputCategoryFin: IputCategoryFin) =>
  action(CategoryFinTypes.PUT_CATEGORYFIN_REQUEST, { iputCategoryFin });

export const putCategoryFinSuccess = (data: IResponse) =>
  action(CategoryFinTypes.PUT_CATEGORYFIN_SUCCESS, { data });

export const putCategoryFinFailure = (data: IResponse) => action(CategoryFinTypes.PUT_CATEGORYFIN_FAILURE, { data });

export const deleteCategoryFinRequest = (id: string) =>
  action(CategoryFinTypes.DELETE_CATEGORYFIN_REQUEST, { id });

export const deleteCategoryFinSuccess = () =>
  action(CategoryFinTypes.DELETE_CATEGORYFIN_SUCCESS, {});

export const deleteCategoryFinFailure = () => action(CategoryFinTypes.DELETE_CATEGORYFIN_FAILURE, {});

export const cleanViewARespCategoryFin = () =>
  action(CategoryFinTypes.CLEAN_CATEGORYFIN_RESPONSE, {});


