export enum FinancialTypes {
  FINANCIAL_REQUEST = "@financial/FINANCIAL_REQUEST",
  FINANCIAL_SUCCESS = "@financial/FINANCIAL_SUCCESS",
  FINANCIAL_FAILURE = "@financial/FINANCIAL_FAILURE",
  POST_FINANCIAL_REQUEST = "@financial/POST_FINANCIAL_REQUEST",
  POST_FINANCIAL_SUCCESS = "@financial/POST_FINANCIAL_SUCCESS",
  POST_FINANCIAL_FAILURE = "@financial/POST_FINANCIAL_FAILURE",
  PUT_FINANCIAL_REQUEST = "@financial/PUT_FINANCIAL_REQUEST",
  PUT_FINANCIAL_SUCCESS = "@financial/PUT_FINANCIAL_SUCCESS",
  PUT_FINANCIAL_FAILURE = "@financial/PUT_FINANCIAL_FAILURE",
  VIEW_FINANCIAL = "@financial/VEIW_FINANCIAL",
  CLEAN_VIEW_FINANCIAL = "@financial/CLEAN_VIEW_FINANCIAL",
  DELETE_FINANCIAL_REQUEST = "@financial/DELETE_FINANCIAL_REQUEST",
  DELETE_FINANCIAL_SUCCESS = "@financial/DELETE_FINANCIAL_SUCCESS",
  DELETE_FINANCIAL_FAILURE = "@financial/DELETE_FINANCIAL_FAILURE",
  CLEAN_FINANCIAL_RESPONSE = "@financial/CLEAN_FINANCIAL_RESPONSE",

  PUTPAYMENT_FINANCIAL_REQUEST = "@financial/PUTPAYMENT_FINANCIAL_REQUEST",
  PUTPAYMENT_FINANCIAL_SUCCESS = "@financial/PUTPAYMENT_FINANCIAL_SUCCESS",
  PUTPAYMENT_FINANCIAL_FAILURE = "@financial/PUTPAYMENT_FINANCIAL_FAILURE",
}

export interface IpostFinancial {
  type: string;
  descricao: string;
  valor: string;
  data_lancamento: any;
  pago: string;
  fk_categoria: string;
  fk_confirmacao?: string;
  fk_carro?: string;
}

export interface IputFinancial {
  id: string;
  type: string;
  descricao: string;
  valor: string;
  data_lancamento: any;
  pago: string;
  fk_categoria: string;
  fk_confirmacao?: string;
  fk_carro?: string;

}

export interface IputPaymentFin {
  id: string;
  pago: string;
}

export interface IFinancial {
  id: string;
  type: string;
  descricao: string;
  valor: string;
  data_lancamento: any;
  pago: string;
  fk_categoria: string;
  fk_confirmacao?: string;
  fk_carro?: string;
  categoria?: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface FinancialState {
  readonly data: IFinancial[];
  readonly view: IFinancial;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
  readonly payment: IputPaymentFin;
}
