import { Reducer } from "redux";
import producer from "immer";

import { MarkState, MarkTypes } from "./types";

const INITIAL_STATE: MarkState = {
  data: [],
  view: {
    id: 0,
    nome: "",
  },
  loading: false,
  error: false,
};


const reducer: Reducer<MarkState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MarkTypes.MARK_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case MarkTypes.MARK_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case MarkTypes.MARK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case MarkTypes.VIEW_MARK:
      return {
        ...state,
        view: action.payload.view,
      };
    case MarkTypes.CLEAN_VIEW_MARK:
      return {
        ...state,
        view: {
          id: 0,
          nome: "",

        }
      };
    case MarkTypes.PUT_MARK_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case MarkTypes.PUT_MARK_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.view = {
          id: 0,
          nome: "",
        };
      });
    case MarkTypes.PUT_MARK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
