import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ColorTypes } from "./types";
import {
  colorSuccess,
  colorFailure,
  postColorRequest,
  postColorSuccess,
  postColorFailure,
  putColorRequest,
  putColorSuccess,
  putColorFailure,
  deleteColorRequest,
  deleteColorSuccess,
  deleteColorFailure,
  colorRequest,
} from "./actions";

function* getColors({ payload }: ReturnType<typeof colorRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };
    const params = payload.paremtros;

    const response = yield call(api.get, "/cores"+params, auth);

    yield put(colorSuccess(response.data.params.data));
  } catch (error) {
    yield put(colorFailure());
  }
}

function* postColors({ payload }: ReturnType<typeof postColorRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const IPostcolor = payload.Ipostcolor;

    const response = yield call(api.post, "/cores", IPostcolor, auth);

    yield put(postColorSuccess(response.data.response));
  } catch (error) {
    yield put(
      postColorFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putColors({ payload }: ReturnType<typeof putColorRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputColor = payload.Iputcolor;

    const id = payload.Iputcolor.id;

    yield call(api.put, "/cores/" + id, iputColor, auth);

    yield put(putColorSuccess());
  } catch (error) {
    yield put(putColorFailure());
  }
}

function* deleteColors({ payload }: ReturnType<typeof deleteColorRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/cores/" + id, auth);

    yield put(deleteColorSuccess());
  } catch (error) {
    yield put(deleteColorFailure());
  }
}

export default all([
  takeLatest(ColorTypes.COLOR_REQUEST, getColors),
  takeLatest(ColorTypes.POST_COLOR_REQUEST, postColors),
  takeLatest(ColorTypes.PUT_COLOR_REQUEST, putColors),
  takeLatest(ColorTypes.DELETE_COLOR_REQUEST, deleteColors),
]);
