import { Reducer } from "redux";
import producer from "immer";

import { ComissionState, ComissionTypes } from "./types";

const INITIAL_STATE: ComissionState = {
  data: [],
  view: {
    id: "",
    nome: "",
    data_lancamento: "",
    valor: "",
    fk_confirmacao: "",
    fk_categoria: "",
    categoria: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<ComissionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ComissionTypes.COMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ComissionTypes.COMISSION_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case ComissionTypes.COMISSION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case ComissionTypes.POST_COMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ComissionTypes.POST_COMISSION_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case ComissionTypes.POST_COMISSION_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });


    case ComissionTypes.VIEW_COMISSION:
      return {
        ...state,
        view: action.payload.view,
      };
    case ComissionTypes.CLEAN_VIEW_COMISSION:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
          data_lancamento: "",
          valor: "",
          fk_confirmacao: "",
          fk_categoria: "",
          categoria: "",
        },
      };
    case ComissionTypes.PUT_COMISSION_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ComissionTypes.PUT_COMISSION_SUCCESS:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.view = {
          id: "",
          nome: "",
          data_lancamento: "",
          valor: "",
          fk_confirmacao: "",
          fk_categoria: "",
          categoria: "",
        };
      });
    case ComissionTypes.PUT_COMISSION_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.error = true;
      });

    case ComissionTypes.CLEAN_COMISSION_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };


    default:
      return state;
  }
};

export default reducer;
