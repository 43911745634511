import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";

import { toast, ToastContainer } from "react-toastify";
import { IFipeModel } from "../../store/ducks/fipe/types";

import {
  postModelRequest,
  putModelRequest,
  modelRequest,
} from "../../store/ducks/models/actions";

const CadModelos: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  if (UserLogged !== "super_admin") {
    history.push("/carros");
  }

  const view = useSelector<ApplicationState, IFipeModel>(
    (state) => state.fipe.view
  );

  const models: any = useSelector<ApplicationState>(
    (state) => state.model.data
  );

  const postModelo = models
    .filter((mdl: any) => mdl.nome === view.fipe_name)
    .map((modelo: any) => modelo.opcionais);

    const idModelo = models
    .filter((mdl: any) => mdl.nome === view.fipe_name)
    .map((modelo: any) => modelo.id);


    function usePersistedState(key: any, defaultValue: any) {
      const [state, setState] = useState(
        () => JSON.parse(localStorage.getItem(key)!) || defaultValue
      );
      useEffect(() => {
        localStorage.setItem(key, JSON.stringify(state));
      }, [key, state]);
      return [state, setState];
    }

    const [form, setForm] = usePersistedState("opModelo", {
      id: idModelo.toString(),
      marca: view.fipe_marca || "",
      nome: view.fipe_name || "",
      opcionais: postModelo.toString(),
    });


  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { marca, nome } = form;

      const opcionais: any = form.opcionais === "" ? " " : form.opcionais;

      let btn = document.getElementById("btn_submit");
      if (btn) {
        btn.setAttribute("disabled", "{true}");
      }

      if (form.id ===  "") {

        dispatch(
          postModelRequest({
            marca,
            nome,
            opcionais,
          })
        );
        toast.success("Opcional cadastrado com sucesso");
        setTimeout(() => history.push("/modelos"), 2000);
        return;
      }

      dispatch(
        putModelRequest({
          id: form.id,
          marca,
          nome,
          opcionais,
        })
      );
      toast.success("Opcional alterado com sucesso");
      setTimeout(() => history.push("/modelos"), 2000);
    },
    [dispatch, form, history]
  );

  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const getModelo = useCallback(() => {
    dispatch(modelRequest("?pp=850"));
  }, [dispatch]);

  useEffect(() => {
    getModelo();
  }, [getModelo]);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Opcional - {form.nome}</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              {<li>Alterar Opcional Modelo</li>}
            </ul>
          </div>
          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title"></div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputdescricao1"
                    >
                      Opcionais
                    </label>
                    <textarea
                      value={form.opcionais}
                      name="opcionais"
                      onChange={handleChangeText}
                      className="form-control textarea"
                      id="descritivoCarro"
                      rows={6}
                      placeholder="Descreva a opções do modelo"
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      {view ? "Alterar" : "Cadastrar"}
                    </button>
                  </div>
                </div>
                <ToastContainer autoClose={5000} position="top-right" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadModelos;
