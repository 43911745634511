/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { ICar } from "../../store/ducks/car/types";
import { carRequest } from "../../store/ducks/car/actions";
import DropdownAction from "../../components/tabela/dropdown";
import ModalDuplicar from "../../components/ModalDuplicar";
import { colorRequest } from "../../store/ducks/color/actions";
import { IColor } from "../../store/ducks/color/types";
import { IProfileUser } from "../../store/ducks/user/types";
import { userProfileRequest } from "../../store/ducks/user/actions";
import jwt from "jwt-decode";

const Carros: React.FC = () => {
  const dispatch = useDispatch();

  const cor: any = useSelector<ApplicationState, IColor[]>(
    (state) => state.color.data
  );

  const accessLevelUser = {
    headers: { typeCliente: localStorage.getItem("typeCliente") },
  };

  const UserLoggedCliente = accessLevelUser.headers["typeCliente"];

  const carros: any = useSelector<ApplicationState, ICar[]>(
    (state) => state.car.data
  );

  const carrosLoad: any = useSelector<ApplicationState>(
    (state) => state.car.loading
  );

  if (localStorage.getItem("carro") !== null) {
    localStorage.removeItem("carro");
  }

  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );

  const getCar = useCallback(() => {
    dispatch(carRequest("?pp=1000000&allEstoque=Sim"));
  }, [dispatch]);

  const getCor = useCallback(() => {
    dispatch(colorRequest("?pp=1000"));
  }, [dispatch]);


  useEffect(() => {
    getCar();
    getCor();
    getProfile();
  }, [getCar, getCor]);

  setTimeout(() => {
    //@ts-ignore
    const table = $("#bolsa0km").DataTable({
      destroy: true,
      paging: false,
      order: [
        [3, "desc"],
        [12, "asc"],
        [5, "asc"],
      ],
    });

    $("#bolsa0km_filter").addClass("row");
    $("#bolsa0km_filter").addClass("filter--financeiro");
    $("#bolsa0km_filter label").addClass("labelFilter");
    $("#bolsa0km_filter label").addClass("col-12");
    $("#bolsa0km_filter label").addClass("col-md-8");

    $("#bolsa0km_filter")
      .append(
        '<select id="dropdown1" class="form-control col-md-2 col-12 mr-2 selectFilterEstoque">\
      <option value="">Estoque</option>\
      <option value="Sim">Thai Motors</option>\
      <option value="Não">Correio Interno</option>\
    </select>'
      );

    $("#bolsa0km_filter")
      .append(
        '<select id="dropdown2" class="form-control col-md-2 col-12 selectFilterEstoque2">\
        <option value="">Todos</option>\
      <option value="1" selected>A Venda</option>\
      <option value="2">Vendido</option>\
    </select>'
      );

    $('#dropdown1').on('change', function () {
      //@ts-ignore
      table.columns(0).search(this.value).draw();
    });

    $('#dropdown2').on('change', function () {
      //@ts-ignore
      table.columns(1).search(this.value).draw();
    });

    $("#dropdown2").trigger('change');

  }, 100);

  $(".dashboard-content-one").on('click', function () {
    //@ts-ignore
    $(".collapse").removeClass("show");
  });

  $(document).on('click', '.actionStopPropagation', function (e) {
    e.stopPropagation();
  });

  function formatReal(int: any) {
    var tmp = int + "";
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  function resumo(string: string, chars: number) {
    if (string.length > chars)
      return string.substring(0, chars) + '...'
    else
      return string;
  }


  function circleStatus(status: any, active: any) {

    if (status === "Vendido" || status === "Devolvido") {

      return <div
        className="circuloStory"
        style={{
          backgroundColor: "red",
          marginLeft: "7px",
        }}
      ></div>

    } else if (status === "A Venda" && active === "Não") {

      return <div
        className="circuloStory"
        style={{
          backgroundColor: "#FCA100",
          marginLeft: "7px",
        }}
      ></div>

    } else {
      return <div
        className="circuloStory"
        style={{
          backgroundColor: "green",
          marginLeft: "7px",
        }}
      ></div>
    }

  }
  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one" data-toggle="collapse">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Estoque</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Estoque</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4"></div>
              <div
                className="table-responsive dataTables_wrapper"
                style={{ overflow: "unset" }}
              >
                {carrosLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap " style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th className="headerBolsaKm"></th>
                        <th className="headerBolsaKm"></th>
                        <th></th>
                        <th>ID</th>
                        <th>Modelo</th>
                        <th>Cor</th>
                        <th style={{ textAlign: "center" }}>Ano</th>
                        <th style={{ textAlign: "right" }}>Valor Venda</th>
                        <th className="headerBolsaKm">Km</th>
                        <th className="headerBolsaKm"></th>
                        <th className="headerBolsaKm"></th>
                        <th className="headerBolsaKm"></th>
                        <th>Fornecedor</th>
                        <th style={{ textAlign: "center" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!carros
                        ? ""
                        : carros.map((row: any) => (
                          <tr
                            key={row.id}
                            style={{
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            <td
                              style={{ display: "none" }}
                            >
                              {row.estoque_site}
                            </td>
                            <td
                              style={{ display: "none" }}
                            >
                              {row.status === "A Venda" ? "1" : "2"}
                            </td>

                            <td className="noneStatus">
                              {circleStatus(row.status, row.estoque_site)}
                            </td>

                            <td
                              className="infoCarroCard"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">#{row.custom_id}</div>

                            </td>
                            <td
                              className="infoCarroCard"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <div className="nameCarro">{row.nome}</div>
                              <div className="descCarro">
                                {row.descritivo?.substring(0, 30) + "..."}
                              </div>
                            </td>
                            <td
                              className="infoBolsaCor"
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              {row.cor}
                            </td>
                            <td
                              className="infoBolsaAnoMod"
                              style={{ textAlign: "center" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              {row.ano_fab.toString().substring(2) + "/" + row.ano_modelo.toString().substring(2)}
                            </td>

                            <td
                              className="infoBolsaValor"
                              style={{ textAlign: "right" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              {parseFloat(row.preco_venda).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                            </td>

                            <td
                              className="infoBolsaKm"
                              style={{ display: "none" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >

                            </td>

                            <td
                              className="infoBolsaValorFipe"
                              style={{ display: "none" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >

                            </td>

                            <td
                              className="infoBolsaCarroc"
                              style={{ display: "none" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >

                            </td>

                            <td className="headerBolsaKm infoBolsaStatusCircle">
                              {circleStatus(row.status, row.estoque_site)}

                            </td>

                            <td className="infoBolsaStatus--Car">
                              <div className="nameFornecedor">{resumo(row?.fornecedor?.nome, 12)}</div>
                              <div className="descChassi">{row.chassi.slice(- 9)}</div>
                              <ModalDuplicar carroEdit={row} dataColor={cor} profileUser={profileUser} />
                            </td>

                            <td
                              className="infoBolsaAcoes infoBolsaAcoes--estoque"
                              style={{ textAlign: "center" }}
                              data-toggle="collapse"
                              data-target={"#folder" + row.id}
                            >
                              <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                              <DropdownAction
                                view={row}
                                deleteCar={row.id}
                                viewDelete={row.status}
                              ></DropdownAction>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

                {UserLoggedCliente === "bolsa0km" ? (
                  <div className="fab">
                    <Link to="/cadastro-carro-bolsa">
                      <button className="main">+</button>
                    </Link>
                  </div>
                ) : (
                  <div className="fab">
                    <Link to="/cadastro-carro">
                      <button className="main">+</button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carros;
