import { action } from "typesafe-actions";
import {
  ClientTypes,
  IClient,
  IpostClient,
  IputClient,
  IResponse,
} from "./types";

export const clientRequest = (data: any) => action(ClientTypes.CLIENT_REQUEST, { data });

export const clientSuccess = (data: IClient) =>
  action(ClientTypes.CLIENT_SUCCESS, { data });

export const clientFailure = () => action(ClientTypes.CLIENT_FAILURE, {});

export const postClientRequest = (ipostClient: IpostClient) =>
  action(ClientTypes.POST_CLIENT_REQUEST, { ipostClient });

export const postClientSuccess = (data: IResponse) =>
  action(ClientTypes.POST_CLIENT_SUCCESS, { data });

export const postClientFailure = (data: IResponse) =>
  action(ClientTypes.POST_CLIENT_FAILURE, { data });

export const viewClient = (view: IClient) =>
  action(ClientTypes.VIEW_CLIENT, { view });

export const cleanViewClient = () => action(ClientTypes.CLEAN_VIEW_CLIENT, {});

export const putClientRequest = (iputClient: IputClient) =>
  action(ClientTypes.PUT_CLIENT_REQUEST, { iputClient });

export const putClientSuccess = (data: IResponse) =>
  action(ClientTypes.PUT_CLIENT_SUCCESS, {data});

export const putClientFailure = () =>
  action(ClientTypes.PUT_CLIENT_FAILURE, {});

export const deleteClientRequest = (id: string) =>
  action(ClientTypes.DELETE_CLIENT_REQUEST, { id });

export const deleteClientSuccess = () =>
  action(ClientTypes.DELETE_CLIENT_SUCCESS, {});

export const deleteClientFailure = () =>
  action(ClientTypes.DELETE_CLIENT_FAILURE, {});

export const cleanViewAResp = () => action(ClientTypes.CLEAN_CLIENT_RESPONSE, {});


export const recoveryClientRequest = (id: string) =>
  action(ClientTypes.RECOVERY_CLIENT_REQUEST, { id });

export const recoveryClientSuccess = () =>
  action(ClientTypes.RECOVERY_CLIENT_SUCCESS, {});

export const recoveryClientFailure = () =>
  action(ClientTypes.RECOVERY_CLIENT_FAILURE, {});

