import { action } from "typesafe-actions";
import { FinancialTypes, IFinancial, IpostFinancial, IputFinancial, IputPaymentFin, IResponse } from "./types";

export const financialRequest = (paremtros: any) =>
  action(FinancialTypes.FINANCIAL_REQUEST, { paremtros });

export const financialSuccess = (data: IFinancial) =>
  action(FinancialTypes.FINANCIAL_SUCCESS, { data });

export const financialFailure = () => action(FinancialTypes.FINANCIAL_FAILURE, {});

export const postFinancialRequest = (ipostFinancial: IpostFinancial) =>
  action(FinancialTypes.POST_FINANCIAL_REQUEST, { ipostFinancial });

export const postFinancialSuccess = (data: IResponse) =>
  action(FinancialTypes.POST_FINANCIAL_SUCCESS, { data });

export const postFinancialFailure = (data: IResponse) => action(FinancialTypes.POST_FINANCIAL_FAILURE, { data });

export const viewFinancial = (view: IFinancial) =>
  action(FinancialTypes.VIEW_FINANCIAL, { view });

export const cleanViewFinancial = () =>
  action(FinancialTypes.CLEAN_VIEW_FINANCIAL, {})

export const putFinancialRequest = (iputFinancial: IputFinancial) =>
  action(FinancialTypes.PUT_FINANCIAL_REQUEST, { iputFinancial });

export const putFinancialSuccess = (data: IResponse) =>
  action(FinancialTypes.PUT_FINANCIAL_SUCCESS, { data });

export const putFinancialFailure = (data: IResponse) => action(FinancialTypes.PUT_FINANCIAL_FAILURE, { data });

export const deleteFinancialRequest = (id: string) =>
  action(FinancialTypes.DELETE_FINANCIAL_REQUEST, { id });

export const deleteFinancialSuccess = () =>
  action(FinancialTypes.DELETE_FINANCIAL_SUCCESS, {});

export const deleteFinancialFailure = () => action(FinancialTypes.DELETE_FINANCIAL_FAILURE, {});

export const cleanViewARespFinancial = () =>
  action(FinancialTypes.CLEAN_FINANCIAL_RESPONSE, {});


export const putPaymentFinancialRequest = (iputPaymentFinancial: IputPaymentFin) =>
  action(FinancialTypes.PUTPAYMENT_FINANCIAL_REQUEST, { iputPaymentFinancial });

export const putPaymentFinancialSuccess = (data: IResponse) =>
  action(FinancialTypes.PUTPAYMENT_FINANCIAL_SUCCESS, { data });

export const putPaymentFinancialFailure = (data: IResponse) =>
action(FinancialTypes.PUTPAYMENT_FINANCIAL_FAILURE, { data });


