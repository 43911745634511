import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { EmployeeTypes } from "./types";
import {
  employeeSuccess,
  employeeFailure,
  postEmployeeRequest,
  postEmployeeSuccess,
  postEmployeeFailure,
  putEmployeeRequest,
  putEmployeeSuccess,
  putEmployeeFailure,
  deleteEmployeeRequest,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
} from "./actions";

function* getEmployees() {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const response = yield call(api.get, "/usuario", auth);

    yield put(employeeSuccess(response.data.params));
  } catch (error) {
    yield put(employeeFailure());
  }
}

function* postEmployees({ payload }: ReturnType<typeof postEmployeeRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostEmployee = payload.ipostEmployee;
    //@ts-ignore
    const response = yield call(
      api.post,
      "/usuario",
      ipostEmployee,
      auth
    );
    yield put(postEmployeeSuccess(response.data.response));
  } catch (error) {
    yield put(
      postEmployeeFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putEmployees({ payload }: ReturnType<typeof putEmployeeRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const dataEmployee = payload.iputemployee;
    const id = payload.iputemployee.id;

    //@ts-ignore
    const response = yield call(
      api.put,
      "/usuario/" + id,
      dataEmployee,
      auth
    );

    yield put(putEmployeeSuccess(response.data.response));
  } catch (error) {
    yield put(putEmployeeFailure({ ...error.response.data.response }));
  }
}

function* deleteEmployees({ payload }: ReturnType<typeof deleteEmployeeRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/usuario/" + id, auth);

    yield put(deleteEmployeeSuccess());
  } catch (error) {
    yield put(deleteEmployeeFailure());
  }
}

export default all([
  takeLatest(EmployeeTypes.EMPLOYEE_REQUEST, getEmployees),
  takeLatest(EmployeeTypes.POST_EMPLOYEE_REQUEST, postEmployees),
  takeLatest(EmployeeTypes.PUT_EMPLOYEE_REQUEST, putEmployees),
  takeLatest(EmployeeTypes.DELETE_EMPLOYEE_REQUEST, deleteEmployees),
]);
