import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "react-functional-select";
import { employeeRequest } from "../../store/ducks/employee/actions";
import { ApplicationState } from "../../store";


const Comissao = (props: any) => {
  const { newComissao, setSelectedOptionUser, setNewComissao } = props;
  const dispatch = useDispatch();

  const userData: any = useSelector<ApplicationState>(
    (state) => state.employee.data
  );

  const getOptionLabelUser = useCallback(
    (option: any): string => `${option.nome || option.nome}`,
    []
  );
  const getOptionValueUser = useCallback(
    (option: any): string => option.nome || option.nome,
    []
  );
  const onOptionChangeUser = useCallback((option: any, i: any): void => {
    //@ts-ignore
    const list: any = [...newComissao];

    let nomeUser = "";

    if (option?.nome === undefined) {
      nomeUser = list[i]["nome"]
    } else {
      nomeUser = option?.nome
    }

    if (option == null) {
      list[i]["nome"] = "";

    }


    list[i]["nome"] = nomeUser;

    setSelectedOptionUser(option);

  }, [newComissao, setSelectedOptionUser]);

  const getEmployee = useCallback(() => {
    dispatch(employeeRequest())
  }, [])



  const handleInputChangeComissao = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newComissao];

    list[index][name] = value;

    setNewComissao(list);

  };

  const handleRemoveComissao = (index: any) => {
    const list = [...newComissao];
    list.splice(index, 1);
    setNewComissao(list);
  };

  const handleAddComissao = () => {
    setNewComissao([
      ...newComissao,
      { nome: "", data_lancamento: "", valor: "" },
    ]);
  };

  const newComissaoKey = useCallback((e, i) => {

    const list: any = [...newComissao];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");


  }, [newComissao])

  useEffect(() => {
    getEmployee();
  }, [getEmployee]);



  let userObject = [{
    id: "",
    email: "",
    permissao: "",
    nome: "Selecione",
    senha: "",
    whatsapp: "",
  }]

  const finalData = userObject.concat(userData)

  return (
    <div className="card-body">
      <div className="heading-layout1 mt-2 mb-4">
        <div className="item-title">
          <h3>Comissões</h3>
        </div>
      </div>

      <div className="row">

        {newComissao.map((x: any, i: any) => {
          return (
            <React.Fragment key={i}>

              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <Select
                  options={userData}
                  addClassNames={true}
                  placeholder="Selecione"
                  onOptionChange={(e) => onOptionChangeUser(e, i)}
                  getOptionLabel={getOptionLabelUser}
                  getOptionValue={getOptionValueUser}
                  isClearable
                />
              </div>

              <div className="col-xl-3 col-lg-3 col-12 form-group d-none">
                <input
                  className="form-control"
                  name="nome"
                  placeholder="Nome"
                  value={x.nome}
                  onChange={(e) => handleInputChangeComissao(e, i)}
                />
              </div>

              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <input
                  className="form-control"
                  type="date"
                  name="data_lancamento"
                  placeholder="Data Lançamento"
                  value={x.data_lancamento}
                  onChange={(e) => handleInputChangeComissao(e, i)}
                />
              </div>

              <div className="col-xl-3 col-lg-3 col-12 form-group">
                <input
                  className="form-control dinheiro"
                  name="valor"
                  placeholder="Valor"
                  autoComplete="off"
                  id={`valor` + i}
                  onKeyUp={(e) => newComissaoKey(e, i)}

                />
              </div>

              <div className="form-group col-xl-3 col-lg-3 col-12">
                <button
                  type="button"
                  className="btn-add btn-remove"
                  onClick={() => handleRemoveComissao(i)}
                >
                  Remover
                </button>
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <div className="row">
        <div className="col-lg-3 col-12 form-group mg-t-8">
          <button type="button" className="btn-add" onClick={handleAddComissao}>
            Adicionar
          </button>
        </div>
      </div>
    </div>

  );
};

export default Comissao;
