export enum CategoryFinTypes {
  CATEGORYFIN_REQUEST = "@categoryFin/CATEGORYFIN_REQUEST",
  CATEGORYFIN_SUCCESS = "@categoryFin/CATEGORYFIN_SUCCESS",
  CATEGORYFIN_FAILURE = "@categoryFin/CATEGORYFIN_FAILURE",
  POST_CATEGORYFIN_REQUEST = "@categoryFin/POST_CATEGORYFIN_REQUEST",
  POST_CATEGORYFIN_SUCCESS = "@categoryFin/POST_CATEGORYFIN_SUCCESS",
  POST_CATEGORYFIN_FAILURE = "@categoryFin/POST_CATEGORYFIN_FAILURE",
  PUT_CATEGORYFIN_REQUEST = "@categoryFin/PUT_CATEGORYFIN_REQUEST",
  PUT_CATEGORYFIN_SUCCESS = "@categoryFin/PUT_CATEGORYFIN_SUCCESS",
  PUT_CATEGORYFIN_FAILURE = "@categoryFin/PUT_CATEGORYFIN_FAILURE",
  VIEW_CATEGORYFIN = "@categoryFin/VEIW_CATEGORYFIN",
  CLEAN_VIEW_CATEGORYFIN = "@categoryFin/CLEAN_VIEW_CATEGORYFIN",
  DELETE_CATEGORYFIN_REQUEST = "@categoryFin/DELETE_CATEGORYFIN_REQUEST",
  DELETE_CATEGORYFIN_SUCCESS = "@categoryFin/DELETE_CATEGORYFIN_SUCCESS",
  DELETE_CATEGORYFIN_FAILURE = "@categoryFin/DELETE_CATEGORYFIN_FAILURE",
  CLEAN_CATEGORYFIN_RESPONSE = "@categoryFin/CLEAN_CATEGORYFIN_RESPONSE",
}

export interface IpostCategoryFin {
  nome: string;
}

export interface IputCategoryFin {
  id: string;
  nome: string;

}

export interface ICategoryFin {
  id: string;
  nome: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface CategoryFinState {
  readonly data: ICategoryFin[];
  readonly view: ICategoryFin;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
