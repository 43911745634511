export enum AuthTypes {
  AUTH_REQUEST = "@auth/AUTH_REQUEST",
  AUTH_SUCCESS = "@auth/AUTH_SUCCESS",
  AUTH_FAILURE = "@auth/AUTH_FAILURE",
  SIGNOUT = "@auth/SIGNOUT",

  CLEAN_AUTH_RESPONSE = "@auth/CLEAN_AUTH_RESPONSE",

}

export interface IParams {
  token: string;
}

export interface IAuth {
  params: IParams;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface AuthState {
  readonly data: IAuth;
  readonly signed: boolean;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
