import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import {
  userProfileRequest,
  putUserRequest,
} from "../../store/ducks/user/actions";
import { IProfileUser } from "../../store/ducks/user/types";
import jwt from "jwt-decode";
import MaskedInput from "react-text-mask";
import { ToastContainer, toast } from "react-toastify";

const EditarPerfil: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );

  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const [form, setForm] = useState({
    nome: "",
    whatsapp: "",
  });

  const setFormProfile = useCallback(() => {
    setForm({
      nome: profileUser.nome,
      whatsapp: profileUser.whatsapp,
    })
  }, [profileUser.nome, profileUser.whatsapp])


  useEffect(() => {
    getProfile();
    setFormProfile();
  }, [setFormProfile, getProfile]);


  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { nome, whatsapp } = form;

      dispatch(
        putUserRequest({
          id: profileUser.id,
          nome,
          whatsapp,
        })
      );
      toast.success("Perfil Alterado com Sucesso");
      setTimeout(() => history.push("/home"), 2000);
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, form, profileUser.id]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Alterar Perfil</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Alterar Perfil</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Alterar Perfil</h3>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputNomePerfil"
                    >
                      Nome
                    </label>
                    <input
                      value={form.nome}
                      name="nome"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputNomePerfil"
                      placeholder="Nome"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputWhatsPerfil"
                    >
                      Whatsapp
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      value={form.whatsapp}
                      name="whatsapp"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputWhatsPerfil"
                      placeholder="WhatsApp"
                      required={true}
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                    >
                      Alterar
                    </button>
                  </div>
                  <ToastContainer autoClose={5000} position="top-right" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditarPerfil;
