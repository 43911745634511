import { Reducer } from "redux";
import producer from "immer";

import { PagtoThirdState, PagtoThirdTypes } from "./types";

const INITIAL_STATE: PagtoThirdState = {
  data: [],
  view: {
    id: "",
    nome: "",
    data_lancamento: "",
    valor: "",
    pdf: "",
    fk_banco: "",
    fk_confirmacao: "",
    fk_categoria: "",
    categoria: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<PagtoThirdState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PagtoThirdTypes.PAGTOTHIRD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PagtoThirdTypes.PAGTOTHIRD_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case PagtoThirdTypes.PAGTOTHIRD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case PagtoThirdTypes.POST_PAGTOTHIRD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PagtoThirdTypes.POST_PAGTOTHIRD_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case PagtoThirdTypes.POST_PAGTOTHIRD_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });


    case PagtoThirdTypes.VIEW_PAGTOTHIRD:
      return {
        ...state,
        view: action.payload.view,
      };
    case PagtoThirdTypes.CLEAN_VIEW_PAGTOTHIRD:
      return {
        ...state,
        view: {
          id: "",
          nome: "",
          data_lancamento: "",
          valor: "",
          pdf: "",
          fk_banco: "",
          fk_confirmacao: "",
          fk_categoria: "",
          categoria: "",
        },
      };
    case PagtoThirdTypes.PUT_PAGTOTHIRD_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case PagtoThirdTypes.PUT_PAGTOTHIRD_SUCCESS:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.view = {
          id: "",
          nome: "",
          data_lancamento: "",
          valor: "",
          pdf: "",
          fk_banco: "",
          fk_confirmacao: "",
          fk_categoria: "",
          categoria: "",
        };
      });
    case PagtoThirdTypes.PUT_PAGTOTHIRD_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
        draft.error = true;
      });

    case PagtoThirdTypes.CLEAN_PAGTOTHIRD_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };


    default:
      return state;
  }
};

export default reducer;
