import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import {
  IFipeModel,
  IFipe,
} from "../../store/ducks/fipe/types";
import { Select } from "react-functional-select";

import {
  fmodelosRequest,
  fmarcasRequest,
  fanoVeiculoRequest,
} from "../../store/ducks/fipe/actions";
import {
  postCarRequest,
  cleanViewCarResp,
} from "../../store/ducks/car/actions";
import { IColor } from "../../store/ducks/color/types";
import { IGearshift } from "../../store/ducks/gearshift/types";
import { IFuel } from "../../store/ducks/fuel/types";
import { colorRequest } from "../../store/ducks/color/actions";
import { gearshiftRequest } from "../../store/ducks/gearshift/actions";
import { fuelRequest } from "../../store/ducks/fuel/actions";

import { ToastContainer, toast } from "react-toastify";
import jwt from "jwt-decode";
import { userProfileRequest } from "../../store/ducks/user/actions";
import { IProvider } from "../../store/ducks/provider/types";
import { providerRequest } from "../../store/ducks/provider/actions";
import { IProfileUser } from "../../store/ducks/user/types";

type BooleanOption = {
  readonly id: any;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: 1, name: "AF" },
  { id: 2, name: "FR" },
  { id: 3, name: "Licenciado" },
];

const BOOL_OPTIONS1: BooleanOption[] = [
  { id: "A Venda", name: "A Venda" },
  { id: "Vendido", name: "Vendido" },
];

const BOOL_OPTIONS2: BooleanOption[] = [
  { id: 2025, name: "2025" },
  { id: 2024, name: "2024" },
  { id: 2023, name: "2023" },
  { id: 2022, name: "2022" },
  { id: 2021, name: "2021" },
  { id: 2020, name: "2020" },
  { id: 2019, name: "2019" },
  { id: 2018, name: "2018" },
];



const CadCarros: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessUser = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLoggedLevel = accessUser.headers["Access-Level"];

  let BOOL_OPTIONS3: BooleanOption[] = [];
  if (UserLoggedLevel === "super_admin") {
    BOOL_OPTIONS3 = [
      { id: "Sim", name: "Thai Motors" },
      { id: "Não", name: "Correio Interno" },
    ];

  } else {
    BOOL_OPTIONS3 = [
      { id: "Sim", name: "Thai Motors" },
      { id: "Não", name: "Correio Interno" },
    ];

  }

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );

  const [focusStatus, setFocusStatus] = useState(false);
  const [focusFornecedor, setFocusFornecedor] = useState(false);

  const [
    selectedFaturamento,
    setSelectedFaturamento,
  ] = useState<BooleanOption | null>(null);

  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const [
    SelectedStatusCar,
    setSelectedStatusCar,
  ] = useState<BooleanOption | null>(null);

  const [SelectedSite, setSelectedSite] = useState<BooleanOption | null>(
    { id: "Não", name: "Não" }
  );

  const [SelectedAnoFab, setSelectedAnoFab] = useState<BooleanOption | null>(
    null
  );

  const [
    selectedOptionVeiculo,
    setSelectedOptionVeiculo,
  ] = useState<BooleanOption | null>(null);

  const [
    selectedOptionProvider,
    setSelectedOptionProvider,
  ] = useState<IProvider | null>(null);

  const [selectedOptionMarca, setSelectedOptionMarca] = useState<IFipe | null>(
    null
  );

  const [
    selectedOptionModelo,
    setSelectedOptionModelo,
  ] = useState<IFipeModel | null>(null);


  const [selectedOptionCor, setSelectedOptionCor] = useState<IColor | null>(
    null
  );

  const [
    selectedOptionCambio,
    setSelectedOptionCambio,
  ] = useState<IGearshift | null>(null);

  const [
    selectedOptionCombustivel,
    setSelectedOptionCombustivel,
  ] = useState<IFuel | null>(null);

  const fmarcas: any = useSelector<ApplicationState>(
    (state) => state.fipe.data
  );

  const modelsFipe = useSelector<ApplicationState, IFipeModel[]>(
    (state) => state.fipe.modelo
  );

  const cor = useSelector<ApplicationState, IColor[]>(
    (state) => state.color.data
  );

  const cambio = useSelector<ApplicationState, IGearshift[]>(
    (state) => state.gearshift.data
  );

  const combustivel = useSelector<ApplicationState, IFuel[]>(
    (state) => state.fuel.data
  );

  const fornecedor = useSelector<ApplicationState, IProvider[]>(
    (state) => state.provider.data
  );

  const responseApi: any = useSelector<ApplicationState>((state) => state.car);

  const [btnEmail, setBtnEmail] = useState("");

  const [form, setForm] = useState({
    nome: "",
    descritivo: "",
    opcionais: "",
    ano_modelo: "",
    ano_contrato: "",
    preco_custo: "",
    preco_fipe: 0,
    preco_venda: "",
    chassi: "",
    marca: "",
    faturamento: "",
    fk_marca: 0,
    fk_modelo: 0,
    status: "",
    fk_cor: 0,
    fk_cambio: 0,
    fk_combustivel: 0,
    anoZero: 0,
    ano_fab: 0,
    preco_entrada: ""
  });

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();
      if (btnEmail === "mailSave") {
        toast.info("Enviando, aguarde");

      } else {

        toast.info("Carregando, aguarde");
      }

      const { descritivo, chassi, preco_fipe, opcionais } = form;

      //@ts-ignore
      const preco_venda = document.getElementById("precoVenda").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const preco_custo = document.getElementById("precoCusto").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const preco_entrada = document.getElementById("precoEntrada").value.replaceAll(".", "").replaceAll(",", ".");
 
      const ano_modelo = selectedOptionVeiculo?.id;
      const nome = selectedOptionModelo?.name;
      const fk_marca = parseFloat(selectedOptionMarca?.id);
      const marca = selectedOptionMarca?.fipe_name;
      const fk_cor = selectedOptionCor?.id;
      const fk_modelo = parseFloat(selectedOptionModelo?.id);
      const fk_cambio = selectedOptionCambio?.id;
      const fk_combustivel = selectedOptionCombustivel?.id;
      const status = SelectedStatusCar?.id;
      const ano_fab = SelectedAnoFab?.id;
      const faturamento = selectedFaturamento?.id;
      const fk_fornecedor = selectedOptionProvider?.id;
      const ano_contrato = new Date().getFullYear();
      const estoque_site = SelectedSite?.id;

      const profileEmail = profileUser.email;
      const profileWhats = profileUser.whatsapp;
      const profileNome = profileUser.nome;

      if (
        !preco_custo ||
        !selectedOptionCor ||
        !selectedOptionMarca ||
        !selectedOptionModelo ||
        !selectedOptionCambio ||
        !selectedOptionVeiculo ||
        !selectedOptionCombustivel
      ) {
        toast.error("Digite todos os campos obrigatórios");
        btnNoDisabled();
        return;
      }

      if (!fk_fornecedor) {
        toast.error("Digite todos os campos obrigatórios");
        setFocusFornecedor(true);
        btnNoDisabled();
        setTimeout(() => { setFocusFornecedor(false) }, 300)
        return;
      }

      if (!status) {
        toast.error("Digite todos os campos obrigatórios");
        setFocusStatus(true);
        btnNoDisabled();
        setTimeout(() => { setFocusStatus(false) }, 300)
        return;
      }

      dispatch(
        postCarRequest({
          nome,
          preco_fipe,
          preco_custo,
          preco_venda,
          descritivo,
          fk_marca,
          fk_cor,
          fk_modelo,
          fk_cambio,
          fk_combustivel,
          ano_modelo,
          ano_contrato,
          chassi,
          marca,
          status,
          faturamento,
          ano_fab,
          fk_fornecedor,
          estoque_site,
          btnEmail,
          profileEmail,
          profileWhats,
          profileNome,
          opcionais,
          preco_entrada
        })
      );
    },
    [
      dispatch,
      form,
      selectedOptionCambio,
      selectedOptionCombustivel,
      selectedOptionCor,
      selectedOptionMarca,
      selectedOptionModelo,
      selectedOptionVeiculo,
      SelectedStatusCar,
      selectedFaturamento,
      SelectedAnoFab,
      selectedOptionProvider,
      SelectedSite,
      btnEmail,
      profileUser
    ]
  );

  const notify = useCallback(
    (event) => {
      if (event === 201) {
        toast.success("Carro cadastrado com sucesso");
        setTimeout(() => history.push("/carros"), 2000);
      }

      if (event === 404) {
        toast.error("Preço maior que o permitido");
        btnNoDisabled();
      }
    },
    [history]
  );

  const cleanView = useCallback(() => {
    dispatch(cleanViewCarResp());
  }, [dispatch]);

  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    notify(responseApi.response.code);
  }, [notify, responseApi.response, cleanView]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  const getMark = useCallback(
    (event) => {
      dispatch(fmodelosRequest(event));
    },
    [dispatch]
  );

  const getVeiculos = useCallback(
    (event) => {
      dispatch(fanoVeiculoRequest(event));
    },
    [dispatch]
  );

  const getOptionLabelMarca = useCallback(
    (option): string => `${option.fipe_name}`,
    []
  );
  const getOptionValueMarca = useCallback(
    (option: IFipe): string => option.id,
    []
  );
  const onOptionChangeMarca = useCallback(
    (option: any | null): void => {
      setSelectedOptionMarca(option);
      getMark(option?.id);
    },
    [getMark]
  );

  const getAnoFabValue = useCallback(
    (anoFab: BooleanOption): number => anoFab.id,
    []
  );
  const onAnoFabChange = useCallback(
    (anoFab: BooleanOption | null): any => setSelectedAnoFab(anoFab),
    []
  );
  const getAnoFabLabel = useCallback(
    (anoFab: BooleanOption): string => `${anoFab.name}`,
    []
  );


  const getOptionLabelVeiculo = useCallback(
    (anoModel: BooleanOption): number => anoModel.id,
    []
  );
  const getOptionValueVeiculo = useCallback(
    (anoModel: BooleanOption): string => anoModel.id,
    []
  );
  const onOptionChangeVeiculo = useCallback(
    (veiculo: BooleanOption | null): void => {
      setSelectedOptionVeiculo(veiculo);
    },
    []
  );

  const geFaturamentoValue = useCallback(
    (faturamento: BooleanOption): number => faturamento.id,
    []
  );
  const onFaturamentoChange = useCallback(
    (faturamento: BooleanOption | null): any =>
      setSelectedFaturamento(faturamento),
    []
  );
  const getFaturamentoLabel = useCallback(
    (faturamento: BooleanOption): string => `${faturamento.name}`,
    []
  );

  const getOptionLabelModelo = useCallback(
    (modelo: IFipeModel): string => `${modelo.name}`,
    []
  );
  const getOptionValueModelo = useCallback(
    (modelo: IFipeModel): string => modelo.id,
    []
  );
  const onOptionChangeModelo = useCallback(
    (modelo: IFipeModel | null): void => {
      setSelectedOptionModelo(modelo);
      getVeiculos(selectedOptionMarca?.id + "/" + modelo?.id);
    },
    [getVeiculos, selectedOptionMarca]
  );


  const getOptionLabelCor = useCallback(
    (color: any): string => `${color.nome}`,
    []
  );
  const getOptionValueCor = useCallback(
    (color: IColor): string => color.id,
    []
  );
  const onOptionChangeCor = useCallback((color: IColor | null): void => {
    setSelectedOptionCor(color);
  }, []);

  const getOptionLabelCambio = useCallback(
    (cambio: IGearshift): string => `${cambio.descricao}`,
    []
  );
  const getOptionValueCambio = useCallback(
    (cambio: IGearshift): string => cambio.id,
    []
  );
  const onOptionChangeCambio = useCallback(
    (cambio: IGearshift | null): void => {
      setSelectedOptionCambio(cambio);
    },
    []
  );

  const getOptionLabelCombustivel = useCallback(
    (fuel: IFuel): string => `${fuel.descricao}`,
    []
  );
  const getOptionValueCombustivel = useCallback(
    (fuel: IFuel): string => fuel.id,
    []
  );
  const onOptionChangeCombustivel = useCallback((fuel: IFuel | null): void => {
    setSelectedOptionCombustivel(fuel);
  }, []);

  const getStatusCarValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onStatusCarChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectedStatusCar(newUsed),
    []
  );
  const getStatusCarLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );

  const getSiteValue = useCallback(
    (site: any): string => site.id,
    []
  );
  const onSiteChange = useCallback(
    (site: any): any => setSelectedSite(site),
    []
  );
  const getSiteLabel = useCallback(
    (site: any): string => `${site.name}`,
    []
  );

  const getProviderValue = useCallback(
    (fornecedor: any): number => fornecedor.id,
    []
  );
  const onProviderChange = useCallback(
    (fornecedor: any): any => setSelectedOptionProvider(fornecedor),
    []
  );
  const getProviderLabel = useCallback(
    (fornecedor: any): string => `${fornecedor.nome}`,
    []
  );

  const getFMarcas = useCallback(() => {
    dispatch(fmarcasRequest());
  }, [dispatch]);

  const getCor = useCallback(() => {
    dispatch(colorRequest("?pp=500"));
  }, [dispatch]);

  const getCambio = useCallback(() => {
    dispatch(gearshiftRequest("?pp=500"));
  }, [dispatch]);

  const getCombustivel = useCallback(() => {
    dispatch(fuelRequest("?pp=500"));
  }, [dispatch]);

  const getProvider = useCallback(() => {
    dispatch(providerRequest("?pp=5000"));
  }, [dispatch]);

  useEffect(() => {
    getFMarcas();
    getCor();
    getCambio();
    getCombustivel();
    getProfile();
    getProvider();
  }, [getFMarcas, getCor, getCambio, getCombustivel, getProfile, getProvider]);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Carro</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Adicionar carro</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Cadastro Carro</h3>
                  <span className="label-form-input">* Campos obrigatório</span>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Marca *
                    </label>
                    <Select
                      options={fmarcas}
                      addClassNames={true}
                      placeholder="Selecione"
                      onOptionChange={onOptionChangeMarca}
                      getOptionValue={getOptionValueMarca}
                      getOptionLabel={getOptionLabelMarca}
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-9 col-lg-9 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Modelo *
                    </label>
                    <Select
                      options={modelsFipe}
                      addClassNames={true}
                      onOptionChange={onOptionChangeModelo}
                      getOptionValue={getOptionValueModelo}
                      getOptionLabel={getOptionLabelModelo}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="ano_fab">
                      Ano Fabricação *
                    </label>
                    <Select
                      options={BOOL_OPTIONS2}
                      onOptionChange={onAnoFabChange}
                      getOptionValue={getAnoFabValue}
                      getOptionLabel={getAnoFabLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Ano Modelo *
                    </label>
                    <Select
                      options={BOOL_OPTIONS2}
                      addClassNames={true}
                      onOptionChange={onOptionChangeVeiculo}
                      getOptionValue={getOptionValueVeiculo}
                      getOptionLabel={getOptionLabelVeiculo}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>


                  <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                    <label className="label-form-input" htmlFor="precoEntrada">
                      Valor Entrada
                    </label>
                    <input
                      name="preco_entrada"
                      type="text"
                      className="form-control dinheiro"
                      id="precoEntrada"
                      placeholder="Valor Entrada"
                    />
                  </div>

                  <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                    <label className="label-form-input" htmlFor="precoVenda">
                      Preço Venda
                    </label>
                    <input
                      name="preco_venda"
                      type="text"
                      className="form-control dinheiro"
                      id="precoVenda"
                      placeholder="Preço Venda"
                    />
                  </div>

                  <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                    <label className="label-form-input" htmlFor="precoCusto">
                      Preço Compra*
                    </label>
                    <input
                      name="preco_custo"
                      type="text"
                      className="form-control dinheiro"
                      id="precoCusto"
                      placeholder="Preço Compra"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Cor *
                    </label>
                    <Select
                      options={cor}
                      addClassNames={true}
                      onOptionChange={onOptionChangeCor}
                      getOptionValue={getOptionValueCor}
                      getOptionLabel={getOptionLabelCor}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={500}
                      menuPosition="auto"
                      menuScrollDuration={800}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Cambio *
                    </label>
                    <Select
                      options={cambio}
                      addClassNames={true}
                      onOptionChange={onOptionChangeCambio}
                      getOptionValue={getOptionValueCambio}
                      getOptionLabel={getOptionLabelCambio}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Combustivel *
                    </label>
                    <Select
                      options={combustivel}
                      addClassNames={true}
                      onOptionChange={onOptionChangeCombustivel}
                      getOptionValue={getOptionValueCombustivel}
                      getOptionLabel={getOptionLabelCombustivel}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="chassi">
                      Chassi
                    </label>
                    <input
                      value={form.chassi}
                      name="chassi"
                      onChange={handleChange}
                      className="form-control"
                      id="chassi"
                      placeholder="Chassi"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Fornecedor *
                    </label>
                    <Select
                      options={fornecedor}
                      onOptionChange={onProviderChange}
                      getOptionValue={getProviderValue}
                      getOptionLabel={getProviderLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      autoFocus={focusFornecedor}
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Opções Faturamento
                    </label>
                    <Select
                      options={BOOL_OPTIONS}
                      onOptionChange={onFaturamentoChange}
                      getOptionValue={geFaturamentoValue}
                      getOptionLabel={getFaturamentoLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Status *
                    </label>
                    <Select
                      options={BOOL_OPTIONS1}
                      onOptionChange={onStatusCarChange}
                      getOptionValue={getStatusCarValue}
                      getOptionLabel={getStatusCarLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      autoFocus={focusStatus}
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Estoque Site
                    </label>
                    <Select
                      options={BOOL_OPTIONS3}
                      onOptionChange={onSiteChange}
                      getOptionValue={getSiteValue}
                      getOptionLabel={getSiteLabel}
                      addClassNames={true}
                      placeholder="Estoque"
                      menuOverscanCount={20}
                      menuMaxHeight={300}
                      menuPosition="bottom"
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="descritivoCarro"
                    >
                      Descrição
                    </label>
                    <textarea
                      value={form.descritivo}
                      name="descritivo"
                      onChange={handleChangeText}
                      className="form-control textarea"
                      id="descritivoCarro"
                      rows={6}
                      placeholder="Descreva o carro poucas palavras"
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="opcionaisCarro"
                    >
                      Opcionais
                    </label>
                    <textarea
                      value={form.opcionais}
                      name="opcionais"
                      onChange={handleChangeText}
                      className="form-control textarea"
                      id="opcionaisCarro"
                      rows={6}
                      placeholder="Descreva as opções"
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      id="btn_submit"
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btn--onlySave"
                      onClick={() => setBtnEmail("onlySave")}
                    >
                      <i className="fa fa-download"></i>
                    </button>

                    <button
                      id="btn_submit"
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btn--save"
                      onClick={() => setBtnEmail("mailSave")}
                      data-toggle="tooltip" data-placement="top" title="Salvar e enviar"
                    >
                      <i className="fas fa-envelope"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default CadCarros;
