import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import "./index.css";
import { toast, ToastContainer } from "react-toastify";
import { IBankAuto } from "../../store/ducks/bankAuto/types";
import { putBankAutoRequest, cleanViewARespBankAuto } from "../../store/ducks/bankAuto/actions";

const EditBancoAuto: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.bankAuto
  );

  const view = useSelector<ApplicationState, IBankAuto>(
    (state) => state.bankAuto.view
  );

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("bankAuto", {
    id: view.id,
    numero_banco: view.numero_banco,
    banco: view.banco,
    agencia: view.agencia,
    conta: view.conta,
    documento: view.documento,
    pix: view.pix,
    favorecido: view.favorecido

  });


  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();
      toast.info("Carregando, aguarde");

      const { id, numero_banco,
        banco,
        agencia,
        conta,
        documento,
        pix,
        favorecido } = form;

      dispatch(
        putBankAutoRequest({
          id,
          numero_banco,
          banco,
          agencia,
          conta,
          documento,
          pix,
          favorecido
        })
      );
    },
    [
      dispatch,
      form,
    ]
  );

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        toast.dismiss();
        toast.success("Banco alterado com sucesso");
        setTimeout(() => history.push("/banco-automarcas"), 2000);
      }

      if (event === 404) {
        toast.error("Falha ao cadastrar Banco");
        btnNoDisabled();
      }
    },
    [history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );


  const cleanView = useCallback(() => {
    dispatch(cleanViewARespBankAuto());
  }, [dispatch]);


  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }

    notify(responseApi.response.code);
  }, [notify, responseApi.response, cleanView]);
  console.log(responseApi.response.code)

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Banco Thai Motors</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Editar banco</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Editar Banco</h3>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Codigo Banco
                    </label>
                    <input
                      value={form.numero_banco}
                      name="numero_banco"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Codigo Banco"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome Banco
                    </label>
                    <input
                      value={form.banco}
                      name="banco"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome Banco"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Agencia
                    </label>
                    <input
                      value={form.agencia}
                      name="agencia"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Agencia"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Conta
                    </label>
                    <input
                      value={form.conta}
                      name="conta"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Conta"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      CNPJ
                    </label>
                    <input
                      value={form.documento}
                      name="documento"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="CNPJ ou CPF"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Pix
                    </label>
                    <input
                      value={form.pix}
                      name="pix"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Pix"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Favorecido
                    </label>
                    <input
                      value={form.favorecido}
                      name="favorecido"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome Favorecido"
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditBancoAuto;
