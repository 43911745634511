import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { IFuel } from "../../store/ducks/fuel/types";
import { ApplicationState } from "../../store";
import {
  fuelRequest,
  viewFuel,
  deleteFuelRequest,
} from "../../store/ducks/fuel/actions";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Combustivel: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  if (UserLogged !== "super_admin") {
    history.push("/carros");
  }

  const combustiveis = useSelector<ApplicationState, IFuel[]>(
    (state) => state.fuel.data
  );

  const combustiveisLoad = useSelector<ApplicationState>(
    (state) => state.fuel.loading
  );

  const setCombustivel = useCallback(
    (combustivel) => {
      dispatch(viewFuel(combustivel));
    },
    [dispatch]
  );

  const getFuel = useCallback(() => {
    dispatch(fuelRequest("?pp=100"));
  }, [dispatch]);

  const deleteCombustivel = useCallback(
    (id) => {
      dispatch(deleteFuelRequest(id));
      toast.success("Combustivel deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
        getFuel();
      }, 1000);
    },
    [dispatch, getFuel]
  );

  setTimeout(() => {
    //@ts-ignore
    $("#example1").DataTable({
      destroy: true,
      paging: false,
    });
  }, 800);

  useEffect(() => {
    getFuel();
  }, [getFuel]);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Combustivel</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Combustivel</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title"></div>
              </div>
              <div className="table-responsive">
                {combustiveisLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="example1" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Combustivel</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!combustiveis
                        ? ""
                        : combustiveis.map((fuel) => (
                            <tr key={fuel.id}>
                              <td>{fuel.descricao}</td>
                              <td>
                                <div className="dropdown">
                                  <a
                                    href="# "
                                    className="dropdown-toggle dropToggle-options"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span className="flaticon-more-button-of-three-dots"></span>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link
                                      className="dropdown-item"
                                      to={"/cadastro-combustivel"}
                                      onClick={() => {
                                        setCombustivel(fuel);
                                      }}
                                    >
                                      <i className="fas fa-cogs text-dark-pastel-green" />
                                      <span>Editar</span>
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to={"/combustiveis"}
                                    >
                                      <i className="fas fa-times text-orange-red" />
                                      <span
                                        onClick={() =>
                                          deleteCombustivel(fuel.id)
                                        }
                                      >
                                        Excluir
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
                <ToastContainer autoClose={5000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-combustivel">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Combustivel;
