import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ClientStoreTypes } from "./types";
import {
  clientPFSuccess,
  clientPFFailure,
  postClientPFRequest,
  postClientPFSuccess,
  postClientPFFailure,
  putClientPFRequest,
  putClientPFSuccess,
  putClientPFFailure,
  deleteClientPFRequest,
  deleteClientPFSuccess,
  deleteClientPFFailure,
  clientPFRequest,
  clientPJSuccess,
  clientPJFailure,
  postClientPJRequest,
  postClientPJSuccess,
  postClientPJFailure,
  putClientPJRequest,
  putClientPJSuccess,
  putClientPJFailure,
  deleteClientPJRequest,
  deleteClientPJSuccess,
  deleteClientPJFailure,
  clientPJRequest,
} from "./actions";


//client PF
function* getClientsPF({ payload }: ReturnType<typeof clientPFRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.dataPF;

    const response = yield call(api.get, params, auth);


    yield put(clientPFSuccess(response.data.params.data));
  } catch (error) {
    yield put(clientPFFailure());
  }
}

function* postClientsPF({ payload }: ReturnType<typeof postClientPFRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostClientPF = payload.ipostClientPF;

    const response = yield call(api.post, "/clients-pf", ipostClientPF, auth);
    yield put(postClientPFSuccess(response.data.response));

  } catch (error) {
    yield put(
      postClientPFFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putClientsPF({ payload }: ReturnType<typeof putClientPFRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputClientPF = payload.iputClientPF;

    const id = payload.iputClientPF.id;

    const response = yield call(api.put, "/clients-pf/" + id, iputClientPF, auth);

    yield put(putClientPFSuccess(response.data.response));
  } catch (error) {
    yield put(putClientPFFailure());
  }
}

function* deleteClientsPF({ payload }: ReturnType<typeof deleteClientPFRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/clients-pf/" + id, auth);

    yield put(deleteClientPFSuccess());
  } catch (error) {
    yield put(deleteClientPFFailure());
  }
}

//client PJ

function* getClientsPJ({ payload }: ReturnType<typeof clientPJRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.dataPJ;

    const response = yield call(api.get, params, auth);

    yield put(clientPJSuccess(response.data.params.data));
  } catch (error) {
    yield put(clientPJFailure());
  }
}

function* postClientsPJ({ payload }: ReturnType<typeof postClientPJRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostClientPJ = payload.ipostClientPJ;

    const response = yield call(api.post, "/clients-pj", ipostClientPJ, auth);
    yield put(postClientPJSuccess(response.data.response));
  } catch (error) {
    yield put(
      postClientPJFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putClientsPJ({ payload }: ReturnType<typeof putClientPJRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputClientPJ = payload.iputClientPJ;

    const id = payload.iputClientPJ.id;

    const response = yield call(api.put, "/clients-pj/" + id, iputClientPJ, auth);

    yield put(putClientPJSuccess(response.data.response));
  } catch (error) {
    yield put(putClientPJFailure());
  }
}

function* deleteClientsPJ({ payload }: ReturnType<typeof deleteClientPJRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/clients-pj/" + id, auth);

    yield put(deleteClientPJSuccess());
  } catch (error) {
    yield put(deleteClientPJFailure());
  }
}

export default all([

  //client PF
  takeLatest(ClientStoreTypes.CLIENTPF_REQUEST, getClientsPF),
  takeLatest(ClientStoreTypes.POST_CLIENTPF_REQUEST, postClientsPF),
  takeLatest(ClientStoreTypes.PUT_CLIENTPF_REQUEST, putClientsPF),
  takeLatest(ClientStoreTypes.DELETE_CLIENTPF_REQUEST, deleteClientsPF),

  //client PJ
  takeLatest(ClientStoreTypes.CLIENTPJ_REQUEST, getClientsPJ),
  takeLatest(ClientStoreTypes.POST_CLIENTPJ_REQUEST, postClientsPJ),
  takeLatest(ClientStoreTypes.PUT_CLIENTPJ_REQUEST, putClientsPJ),
  takeLatest(ClientStoreTypes.DELETE_CLIENTPJ_REQUEST, deleteClientsPJ),
]);
