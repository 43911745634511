import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ContractsTypes } from "./types";
import {
  contractsSuccess,
  contractsFailure,
  postContractsRequest,
  postContractsSuccess,
  postContractsFailure,
  putContractsRequest,
  putContractsSuccess,
  putContractsFailure,
  deleteContractsRequest,
  deleteContractsSuccess,
  deleteContractsFailure,
  contractsRequest,
} from "./actions";

function* getContracts({ payload }: ReturnType<typeof contractsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };
    const params = payload.paremtros;

    const response = yield call(api.get, "/contratos"+params, auth);

    yield put(contractsSuccess(response.data.params.data));
  } catch (error) {
    yield put(contractsFailure());
  }
}

function* postContracts({ payload }: ReturnType<typeof postContractsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const IpostContract = payload.ipostContracts;

    const response = yield call(api.post, "/contratos", IpostContract, auth);

    yield put(postContractsSuccess(response.data.response));
  } catch (error) {
    yield put(
      postContractsFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putContracts({ payload }: ReturnType<typeof putContractsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const IputContract = payload.iputContracts;

    const id = payload.iputContracts.id;

    const response = yield call(api.put, "/contratos/" + id, IputContract, auth);

    yield put(putContractsSuccess(response.data.response));
  } catch (error) {
    yield put(putContractsFailure());
  }
}

function* deleteContracts({ payload }: ReturnType<typeof deleteContractsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/contratos/" + id, auth);

    yield put(deleteContractsSuccess());
  } catch (error) {
    yield put(deleteContractsFailure());
  }
}

export default all([
  takeLatest(ContractsTypes.CONTRACTS_REQUEST, getContracts),
  takeLatest(ContractsTypes.POST_CONTRACTS_REQUEST, postContracts),
  takeLatest(ContractsTypes.PUT_CONTRACTS_REQUEST, putContracts),
  takeLatest(ContractsTypes.DELETE_CONTRACTS_REQUEST, deleteContracts),
]);
