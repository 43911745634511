import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { YearTypes } from "./types";
import {
  yearSuccess,
  yearFailure,
  postYearRequest,
  postYearSuccess,
  postYearFailure,
  putYearRequest,
  putYearSuccess,
  putYearFailure,
  deleteYearRequest,
  deleteYearSuccess,
  deleteYearFailure,
  yearRequest,
} from "./actions";

function* getYears({ payload }: ReturnType<typeof  yearRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/anos"+params, auth);

    yield put(yearSuccess(response.data.params.data));
  } catch (error) {
    yield put(yearFailure());
  }
}

function* postYears({ payload }: ReturnType<typeof postYearRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const postyear = payload.Ipostyear;

    const response = yield call(api.post, "/anos", postyear, auth);
    yield put(postYearSuccess(response.data.response));
  } catch (error) {
    yield put(
      postYearFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putYears({ payload }: ReturnType<typeof putYearRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const putYear = payload.Iputyear;

    const id = payload.Iputyear.id;

    yield call(api.put, "/anos/" + id, putYear, auth);

    yield put(putYearSuccess());
  } catch (error) {
    yield put(putYearFailure());
  }
}

function* deleteYears({ payload }: ReturnType<typeof deleteYearRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/anos/" + id, auth);

    yield put(deleteYearSuccess());
  } catch (error) {
    yield put(deleteYearFailure());
  }
}

export default all([
  takeLatest(YearTypes.YEAR_REQUEST, getYears),
  takeLatest(YearTypes.POST_YEAR_REQUEST, postYears),
  takeLatest(YearTypes.PUT_YEAR_REQUEST, putYears),
  takeLatest(YearTypes.DELETE_YEAR_REQUEST, deleteYears),
]);
