import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";
import { ToastContainer, toast } from "react-toastify";

import { ApplicationState } from "../../store";
import { IClientPF, IClientPJ } from "../../store/ducks/clientStore/types";
import {
  putClientPFRequest,
  putClientPJRequest,
} from "../../store/ducks/clientStore/actions";
import MaskedInput from "react-text-mask";
import { cleanViewARespPF } from "../../store/ducks/clientStore/actions";
import { IProfileUser } from "../../store/ducks/user/types";

const EditClientesLoja: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const returnApi: any = useSelector<ApplicationState>(
    (state) => state.clientStore
  );

  const viewPF = useSelector<ApplicationState, IClientPF>(
    (state) => state.clientStore.viewPF
  );

  const viewPJ = useSelector<ApplicationState, IClientPJ>(
    (state) => state.clientStore.viewPJ
  );

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("cliente-loja", {
    id: viewPF.id || viewPJ.id,
    nome: viewPF.nome || "",
    RG: viewPF.RG || "",
    CPF: viewPF.CPF || "",
    telefone: viewPF.telefone || viewPJ.telefone,
    celular: viewPF.celular || viewPJ.celular,
    CEP: viewPF.CEP || viewPJ.CEP,
    rua: viewPF.rua || viewPJ.rua,
    numero: viewPF.numero || viewPJ.numero,
    bairro: viewPF.bairro || viewPJ.bairro,
    cidade: viewPF.cidade || viewPJ.cidade,
    complemento: viewPF.complemento || viewPJ.complemento,
    estado: viewPF.estado || viewPJ.estado,
    fk_user: "",
    nome_contato: viewPJ.nome_contato || "",
    razao_social: viewPJ.razao_social || "",
    CNPJ: viewPJ.CNPJ || "",
    email: viewPF.email || viewPJ.email,
    iestadual: viewPJ.iestadual,
  });

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const {
        id,
        nome,
        RG,
        CPF,
        telefone,
        celular,
        CEP,
        rua,
        numero,
        bairro,
        cidade,
        complemento,
        estado,
        nome_contato,
        razao_social,
        CNPJ,
        email,
        iestadual
      } = form;

      const fk_user = profileUser.id;

      // let btn = document.getElementById("btn_submit");
      // if (btn) {
      //   btn.setAttribute("disabled", "{true}");
      // }

      if (nome) {
        dispatch(
          putClientPFRequest({
            id,
            nome,
            RG,
            CPF,
            telefone,
            celular,
            CEP,
            rua,
            numero,
            bairro,
            cidade,
            complemento,
            estado,
            fk_user,
            email,
          })
        );
      } else {
        dispatch(
          putClientPJRequest({
            id,
            nome_contato,
            razao_social,
            CNPJ,
            telefone,
            celular,
            CEP,
            rua,
            numero,
            bairro,
            cidade,
            complemento,
            estado,
            fk_user,
            email,
            iestadual
          })
        );
      }
    },
    [dispatch, form, profileUser]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const notify = useCallback(
    (event) => {
      if (event === 404) toast.error("Falha ao cadastrar Cliente");

      if (event === 200) {
        toast.success("Cliente alterado com sucesso");
        setTimeout(() => history.push("/clientes-loja"), 2000);
      }
    },
    [history]
  );

  function onBlurCep(ev: any) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          id: form.id,
          nome: form.nome,
          rg: form.rg,
          CPF: form.CPF,
          telefone: form.telefone,
          celular: form.celular,
          CEP: data.cep,
          rua: data.logradouro,
          numero: form.numero,
          bairro: data.bairro,
          cidade: data.localidade,
          complemento: data.complemento,
          estado: data.uf,
          fk_user: form.fk_user,
          nome_contato: form.nome_contato,
          razao_social: form.razao_social,
          cnpj: form.cnpj,
          email: form.email,
          iestadual: form.iestadual,
        });
      });
  }

  const cleanView = useCallback(() => {
    dispatch(cleanViewARespPF());
  }, [dispatch]);

  useEffect(() => {
    if (returnApi.response.code !== "") {
      cleanView();
    }
    notify(returnApi.response.code);
  }, [cleanView, notify, returnApi.response]);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Cliente</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>

              <li>Editar cliente</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Editar Cliente</h3>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  {form.nome ? (
                    <>
                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Nome
                        </label>
                        <input
                          value={form.nome}
                          name="nome"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Nome"
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputPassword1"
                        >
                          RG
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                          ]}
                          guide={false}
                          value={form.RG}
                          name="RG"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="RG"
                          required={true}
                        />
                      </div>
                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputPassword1"
                        >
                          CPF
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\w/,
                            /\w/,
                          ]}
                          guide={false}
                          value={form.CPF}
                          name="CPF"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="CPF"
                          required={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Nome contato
                        </label>
                        <input
                          value={form.nome_contato}
                          name="nome_contato"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Nome"
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Razão Social
                        </label>
                        <input
                          value={form.razao_social}
                          name="razao_social"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Razão Social"
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputPassword1"
                        >
                          CNPJ
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                          ]}
                          guide={false}
                          value={form.CNPJ}
                          name="CNPJ"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputPassword1"
                          placeholder="CNPJ"
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-6 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Inscrição Estadual
                        </label>
                        <input
                          value={form.iestadual}
                          name="iestadual"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="IE"
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail"
                    >
                      Email
                    </label>
                    <input
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail"
                      placeholder="Email"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Telefone
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.telefone}
                      name="telefone"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Telefone"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Celular
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.celular}
                      name="celular"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Celular"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      CEP
                    </label>
                    <input
                      value={form.CEP}
                      name="CEP"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="CEP"
                      onBlur={(ev) => onBlurCep(ev)}
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Rua
                    </label>
                    <input
                      value={form.rua}
                      name="rua"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Rua"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="endNumero">
                      Numero
                    </label>
                    <input
                      value={form.numero}
                      name="numero"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="endNumero"
                      placeholder="Numero"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Complemento
                    </label>
                    <input
                      value={form.complemento}
                      name="complemento"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Complemento"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Bairro
                    </label>
                    <input
                      value={form.bairro}
                      name="bairro"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="bairro"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Cidade
                    </label>
                    <input
                      value={form.cidade}
                      name="cidade"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Cidade"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Estado
                    </label>
                    <input
                      value={form.estado}
                      name="estado"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Sigla UF"
                      required={true}
                      maxLength={2}
                    />
                  </div>

                  <div className="col-12 form-group buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditClientesLoja;
