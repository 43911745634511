import { action } from "typesafe-actions";
import { ComissionTypes, IComission, IpostComission, IputComission, IResponse } from "./types";

export const comissionRequest = (paremtros: any) =>
  action(ComissionTypes.COMISSION_REQUEST, { paremtros });

export const comissionSuccess = (data: IComission) =>
  action(ComissionTypes.COMISSION_SUCCESS, { data });

export const comissionFailure = () => action(ComissionTypes.COMISSION_FAILURE, {});

export const postComissionRequest = (ipostComission: IpostComission) =>
  action(ComissionTypes.POST_COMISSION_REQUEST, { ipostComission });

export const postComissionSuccess = (data: IResponse) =>
  action(ComissionTypes.POST_COMISSION_SUCCESS, { data });

export const postComissionFailure = (data: IResponse) => action(ComissionTypes.POST_COMISSION_FAILURE, { data });

export const viewComission = (view: IComission) =>
  action(ComissionTypes.VIEW_COMISSION, { view });

export const cleanViewComission = () =>
  action(ComissionTypes.CLEAN_VIEW_COMISSION, {})

export const putComissionRequest = (iputComission: IputComission) =>
  action(ComissionTypes.PUT_COMISSION_REQUEST, { iputComission });

export const putComissionSuccess = (data: IResponse) =>
  action(ComissionTypes.PUT_COMISSION_SUCCESS, { data });

export const putComissionFailure = (data: IResponse) => action(ComissionTypes.PUT_COMISSION_FAILURE, { data });

export const deleteComissionRequest = (id: string) =>
  action(ComissionTypes.DELETE_COMISSION_REQUEST, { id });

export const deleteComissionSuccess = () =>
  action(ComissionTypes.DELETE_COMISSION_SUCCESS, {});

export const deleteComissionFailure = () => action(ComissionTypes.DELETE_COMISSION_FAILURE, {});

export const cleanViewARespComission = () =>
  action(ComissionTypes.CLEAN_COMISSION_RESPONSE, {});


