import React from "react";
import MaskedInput from "react-text-mask";

const SelectClientEdit = (props: any) => {
  const { selectedOptionClient, form, handleChange } = props;

  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Cliente</h3>
          </div>
        </div>
        <div className="new-added-form">
          <div className="row">
            {
              //@ts-ignore
              form.client_data.nome !== "" ? (
                <>
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.nome || form.client_data.nome}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome"
                    />
                  </div>

                  <input
                    //@ts-ignore
                    value={(form.nameClient = selectedOptionClient?.nome)}
                    name="nameClient"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      RG
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.RG || form.client_data.RG}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="RG"
                    />
                  </div>
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CPF
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.CPF || form.client_data.CPF}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CPF"
                    />
                  </div>
                </>
              ) : (
                <>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Razão Social
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.razao_social || form.client_data.razao_social}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Razão Social"
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome contato
                    </label>
                    <input
                      //@ts-ignore
                      value={selectedOptionClient?.nome_contato || form.client_data.nome_contato}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome"
                    />
                  </div>

                  <input
                    //@ts-ignore
                    value={(form.nameClient = "")}
                    name="nameClient"
                    onChange={handleChange}
                    type="hidden"
                    className="form-control"
                    id="testeAnoModelo"
                    disabled={true}
                  />

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      CNPJ
                    </label>
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      //@ts-ignore
                      value={selectedOptionClient?.CNPJ || form.client_data.CNPJ}
                      disabled={true}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="CNPJ"
                    />
                  </div>
                </>
              )
            }

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Email
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.email || form.client_data.email}
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Email"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Telefone
              </label>
              <MaskedInput
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                //@ts-ignore
                value={selectedOptionClient?.telefone || form.client_data.telefone}
                name="telefone"
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Telefone"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputPassword1"
              >
                Celular
              </label>
              <MaskedInput
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                //@ts-ignore
                value={selectedOptionClient?.celular || form.client_data.celular}
                name="celular"
                type="text"
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Celular"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                CEP
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.CEP || form.client_data.CEP}
                name="CEP"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="CEP"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Rua
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.rua || form.client_data.rua}
                name="rua"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Rua"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label className="label-form-input" htmlFor="endNumero">
                Numero
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.numero || form.client_data.numero}
                name="numero"
                type="text"
                className="form-control"
                id="endNumero"
                placeholder="Numero"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Complemento
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.complemento || form.client_data.complemento}
                name="complemento"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Complemento"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Bairro
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.bairro || form.client_data.bairro}
                name="bairro"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="bairro"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Cidade
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.cidade || form.client_data.cidade}
                name="cidade"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Cidade"
                disabled={true}
              />
            </div>

            <div className="col-xl-3 col-lg-6 col-12 form-group">
              <label
                className="label-form-input"
                htmlFor="exampleInputEmail1"
              >
                Estado
              </label>
              <input
                //@ts-ignore
                value={selectedOptionClient?.estado || form.client_data.estado}
                name="estado"
                type="text"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Sigla UF"
                disabled={true}
                maxLength={2}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectClientEdit;
