import { action } from "typesafe-actions";
import {
  ClientStoreTypes,
  IClientPF,
  IpostClientPF,
  IputClientPF,
  IClientPJ,
  IpostClientPJ,
  IputClientPJ,
  IResponse,
} from "./types";

//client PF
export const clientPFRequest = (dataPF: any) =>
  action(ClientStoreTypes.CLIENTPF_REQUEST, { dataPF });

export const clientPFSuccess = (dataPF: IClientPF) =>
  action(ClientStoreTypes.CLIENTPF_SUCCESS, { dataPF });

export const clientPFFailure = () =>
  action(ClientStoreTypes.CLIENTPF_FAILURE, {});

export const postClientPFRequest = (ipostClientPF: IpostClientPF) =>
  action(ClientStoreTypes.POST_CLIENTPF_REQUEST, { ipostClientPF });

export const postClientPFSuccess = (data: IResponse) =>
  action(ClientStoreTypes.POST_CLIENTPF_SUCCESS, { data });

export const postClientPFFailure = (data: IResponse) =>
  action(ClientStoreTypes.POST_CLIENTPF_FAILURE, { data });

export const viewClientPF = (view: IClientPF) =>
  action(ClientStoreTypes.VIEW_CLIENTPF, { view });

export const cleanViewClientPF = () =>
  action(ClientStoreTypes.CLEAN_VIEW_CLIENTPF, {});

export const putClientPFRequest = (iputClientPF: IputClientPF) =>
  action(ClientStoreTypes.PUT_CLIENTPF_REQUEST, { iputClientPF });

export const putClientPFSuccess = (data: IResponse) =>
  action(ClientStoreTypes.PUT_CLIENTPF_SUCCESS, { data });

export const putClientPFFailure = () =>
  action(ClientStoreTypes.PUT_CLIENTPF_FAILURE, {});

export const deleteClientPFRequest = (id: string) =>
  action(ClientStoreTypes.DELETE_CLIENTPF_REQUEST, { id });

export const deleteClientPFSuccess = () =>
  action(ClientStoreTypes.DELETE_CLIENTPF_SUCCESS, {});

export const deleteClientPFFailure = () =>
  action(ClientStoreTypes.DELETE_CLIENTPF_FAILURE, {});

export const cleanViewARespPF = () =>
  action(ClientStoreTypes.CLEAN_CLIENTPF_RESPONSE, {});

//client PJ

export const clientPJRequest = (dataPJ: any) =>
  action(ClientStoreTypes.CLIENTPJ_REQUEST, { dataPJ });

export const clientPJSuccess = (dataPJ: IClientPJ) =>
  action(ClientStoreTypes.CLIENTPJ_SUCCESS, { dataPJ });

export const clientPJFailure = () =>
  action(ClientStoreTypes.CLIENTPJ_FAILURE, {});

export const postClientPJRequest = (ipostClientPJ: IpostClientPJ) =>
  action(ClientStoreTypes.POST_CLIENTPJ_REQUEST, { ipostClientPJ });

export const postClientPJSuccess = (data: IResponse) =>
  action(ClientStoreTypes.POST_CLIENTPJ_SUCCESS, { data });

export const postClientPJFailure = (data: IResponse) =>
  action(ClientStoreTypes.POST_CLIENTPJ_FAILURE, { data });

export const viewClientPJ = (view: IClientPJ) =>
  action(ClientStoreTypes.VIEW_CLIENTPJ, { view });

export const cleanViewClientPJ = () =>
  action(ClientStoreTypes.CLEAN_VIEW_CLIENTPJ, {});

export const putClientPJRequest = (iputClientPJ: IputClientPJ) =>
  action(ClientStoreTypes.PUT_CLIENTPJ_REQUEST, { iputClientPJ });

export const putClientPJSuccess = (data: IResponse) =>
  action(ClientStoreTypes.PUT_CLIENTPJ_SUCCESS, { data });

export const putClientPJFailure = () =>
  action(ClientStoreTypes.PUT_CLIENTPJ_FAILURE, {});

export const deleteClientPJRequest = (id: string) =>
  action(ClientStoreTypes.DELETE_CLIENTPJ_REQUEST, { id });

export const deleteClientPJSuccess = () =>
  action(ClientStoreTypes.DELETE_CLIENTPJ_SUCCESS, {});

export const deleteClientPJFailure = () =>
  action(ClientStoreTypes.DELETE_CLIENTPJ_FAILURE, {});

export const cleanViewARespPJ = () =>
  action(ClientStoreTypes.CLEAN_CLIENTPJ_RESPONSE, {});
