export enum ThirdsTypes {

  THIRDS_REQUEST = "@thirds/THIRDS_REQUEST",
  THIRDS_SUCCESS = "@thirds/THIRDS_SUCCESS",
  THIRDS_FAILURE = "@thirds/THIRDS_FAILURE",

  POST_THIRDS_REQUEST = "@thirds/POST_THIRDS_REQUEST",
  POST_THIRDS_SUCCESS = "@thirds/POST_THIRDS_SUCCESS",
  POST_THIRDS_FAILURE = "@thirds/POST_THIRDS_FAILURE",

  PUT_THIRDS_REQUEST = "@thirds/PUT_THIRDS_REQUEST",
  PUT_THIRDS_SUCCESS = "@thirds/PUT_THIRDS_SUCCESS",
  PUT_THIRDS_FAILURE = "@thirds/PUT_THIRDS_FAILURE",

  VIEW_THIRDS = "@thirds/VEIW_THIRDS",
  CLEAN_VIEW_THIRDS = "@thirds/CLEAN_VIEW_THIRDS",

  DELETE_THIRDS_REQUEST = "@thirds/DELETE_THIRDS_REQUEST",
  DELETE_THIRDS_SUCCESS = "@thirds/DELETE_THIRDS_SUCCESS",
  DELETE_THIRDS_FAILURE = "@thirds/DELETE_THIRDS_FAILURE",

  CLEAN_THIRDS_RESPONSE = "@thirds/CLEAN_THIRDS_RESPONSE",

  //Delete banco
  DELETE_BANK_REQUEST = "@thirds/DELETE_BANK_REQUEST",
  DELETE_BANK_SUCCESS = "@thirds/DELETE_BANK_SUCCESS",
  DELETE_BANK_FAILURE = "@thirds/DELETE_BANK_FAILURE",
}

export interface IBankThirds {
  id: string;
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  favorecido?: string;
  pix_banco?: string;
}

export interface INewBankThirds {
  numero_banco?: string;
  banco: string;
  agencia: string;
  conta: string;
  documento?: string;
  favorecido?: string;
  pix_banco?: string;
}


export interface IpostThirds {
  nome: string;
  email?: string;
  documento: string;
  telefone?: string;
  celular?: string;
  RG?: string;
  CPF?: string;
  CNPJ?: string;
  razao_social?: string;
  bancos?: IBankThirds[];
}

export interface IputThirds {
  id: string;
  nome: string;
  email?: string;
  documento: string;
  telefone?: string;
  celular?: string;
  RG?: string;
  CPF?: string;
  CNPJ?: string;
  razao_social?: string;
  bancos?: IBankThirds[];
  bancos_new?: INewBankThirds[];
}

export interface IThirds {
  id: string;
  nome: string;
  email?: string;
  documento: string;
  telefone?: string;
  celular?: string;
  RG?: string;
  CPF?: string;
  CNPJ?: string;
  razao_social?: string;
  bancos?: IBankThirds[];
}

export interface IResponse {
  code: string;
  message: string;
}

export interface ThirdsState {
  readonly data: IThirds[];
  readonly view: IThirds;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
