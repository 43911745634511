import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { IGearshift } from "../../store/ducks/gearshift/types";
import { ApplicationState } from "../../store";
import {
  gearshiftRequest,
  viewGearshift,
  deleteGearshiftRequest,
} from "../../store/ducks/gearshift/actions";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Cambios: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  if (UserLogged !== "super_admin") {
    history.push("/carros");
  }

  const cambios = useSelector<ApplicationState, IGearshift[]>(
    (state) => state.gearshift.data
  );

  const cambioLoad = useSelector<ApplicationState>(
    (state) => state.gearshift.loading
  );

  const setCambio = useCallback(
    (cambio) => {
      dispatch(viewGearshift(cambio));
    },
    [dispatch]
  );

  const getCambio = useCallback(() => {
    dispatch(gearshiftRequest("?pp=300"));
  }, [dispatch]);

  const deleteCambio = useCallback(
    (id) => {
      dispatch(deleteGearshiftRequest(id));
      toast.success("Cambio deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
        getCambio();
      }, 1000);
    },
    [dispatch, getCambio]
  );

  useEffect(() => {
    getCambio();
  }, [getCambio]);

  setTimeout(() => {
    //@ts-ignore
    $("#table__cambio").DataTable({
      destroy: true,
      paging: false,
    });
  }, 800);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Cambios</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Cambios</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title"></div>
              </div>
              <div className="table-responsive">
                {cambioLoad === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="table__cambio" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Descrição</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!cambios
                        ? ""
                        : cambios.filter((cm: any) => cm.id !== 6).map((cambio) => (
                            <tr key={cambio.id}>
                              <td>{cambio.descricao}</td>
                              <td>
                                <div className="dropdown">
                                  <a
                                    href="# "
                                    className="dropdown-toggle dropToggle-options"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span className="flaticon-more-button-of-three-dots"></span>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link
                                      className="dropdown-item"
                                      to={"/cadastro-cambio"}
                                      onClick={() => {
                                        setCambio(cambio);
                                      }}
                                    >
                                      <i className="fas fa-cogs text-dark-pastel-green" />
                                      <span>Editar</span>
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to={"/cambios"}
                                    >
                                      <i className="fas fa-times text-orange-red" />
                                      <span
                                        onClick={() => deleteCambio(cambio.id)}
                                      >
                                        Excluir
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}
                <ToastContainer autoClose={5000} position="top-right" />
                <div className="fab">
                  <Link to="/cadastro-cambio">
                    <button className="main">+</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cambios;
