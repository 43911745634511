import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { IFipe } from "../../store/ducks/fipe/types";
import { fmarcasRequest } from "../../store/ducks/fipe/actions";
import { Link, useHistory } from "react-router-dom";

const Marcas: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  if(UserLogged !== "super_admin"){
    history.push("/carros")
  }

  const fmarcas = useSelector<ApplicationState, IFipe[]>(
    (state) => state.fipe.data
  );

  const getFMarcas = useCallback(() => {
    dispatch(fmarcasRequest());
  }, [dispatch]);

  useEffect(() => {
    getFMarcas();
  }, [getFMarcas]);

  setTimeout(() => {
    //@ts-ignore
    $("#example1").DataTable({
      destroy: true,
      paging: false,
      ordering: false,
    });
  }, 1000);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Marcas</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Marcas</li>
            </ul>

          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                </div>
              </div>
              <div className="table-responsive">
                <table id="example1" className="table display  text-nowrap ">
                  <thead>
                    <tr>
                      <th>Nome</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fmarcas.map((marca) => (
                      <tr key={marca.id}>
                        <td>{marca.fipe_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marcas;
