import { Reducer } from "redux";
import producer from "immer";

import { BankAutoState, BankAutoTypes } from "./types";

const INITIAL_STATE: BankAutoState = {
  data: [],
  view: {
    id: "",
    numero_banco: "",
    banco: "",
    agencia: "",
    conta: "",
    documento: "",
    pix: "",
    favorecido: ""
  },
  loading: true,
  error: false,
  response: {
    code: "",
    message: "",
  },
};

const reducer: Reducer<BankAutoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //client PF
    case BankAutoTypes.BANKAUTO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case BankAutoTypes.BANKAUTO_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });

    case BankAutoTypes.BANKAUTO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case BankAutoTypes.POST_BANKAUTO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case BankAutoTypes.POST_BANKAUTO_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {

          id: "",
          numero_banco: "",
          banco: "",
          agencia: "",
          conta: "",
          documento: "",
          pix: "",
          favorecido: ""
        }
      });

    case BankAutoTypes.POST_BANKAUTO_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case BankAutoTypes.VIEW_BANKAUTO:
      return {
        ...state,
        view: action.payload.view,
      };

    case BankAutoTypes.CLEAN_VIEW_BANKAUTO:
      return {
        ...state,
        view: {
          id: "",
          numero_banco: "",
          banco: "",
          agencia: "",
          conta: "",
          documento: "",
          pix: "",
          favorecido: ""
        },
      };

    case BankAutoTypes.PUT_BANKAUTO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case BankAutoTypes.PUT_BANKAUTO_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {
          id: "",
          numero_banco: "",
          banco: "",
          agencia: "",
          conta: "",
          documento: "",
          pix: "",
          favorecido: ""
        };
      });

    case BankAutoTypes.PUT_BANKAUTO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case BankAutoTypes.CLEAN_BANKAUTO_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };

    default:
      return state;
  }
};

export default reducer;
