import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { CarTypes } from "./types";
import {
  carSuccess,
  carFailure,
  postCarRequest,
  postCarSuccess,
  postCarFailure,
  putCarRequest,
  putCarSuccess,
  putCarFailure,
  deleteCarRequest,
  deleteCarSuccess,
  deleteCarFailure,
  carRequest,
  carUniqueRequest,
  carUniqueSuccess,
  carUniqueFailure,
  postUpStatusFotoRequest,
  postUpStatusFotoSuccess,
  postUpStatusFotoFailure,
  carSiteRequest,
  carSiteSuccess,
  carSiteFailure,
} from "./actions";

function* getCars({ payload }: ReturnType<typeof carRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const paramsurl = payload.paramsUrl;

    const response = yield call(api.get, "/carros" + paramsurl, auth);

    yield put(carSuccess(response.data.params.data));
  } catch (error) {
    yield put(carFailure());
  }
}

function* getCarsUnique({ payload }: ReturnType<typeof carUniqueRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const paramsurl = payload.paramsUrl;

    const response = yield call(api.get, "/carros" + paramsurl, auth);

    yield put(carUniqueSuccess(response.data.params));
  } catch (error) {
    yield put(carUniqueFailure());
  }
}

function* postCars({ payload }: ReturnType<typeof postCarRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const postCar = payload.Ipostcar;

    const response = yield call(api.post, "/carros", postCar, auth);
    yield put(postCarSuccess(response.data.response));
  } catch (error) {
    yield put(
      postCarFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putCars({ payload }: ReturnType<typeof putCarRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const putCar = payload.Iputcar;

    const id = payload.Iputcar.id;

    const response = yield call(api.put, "/carros/" + id, putCar, auth);

    yield put(putCarSuccess(response.data.response));
  } catch (error) {
    yield put(putCarFailure({
      ...error.response.data.response,
    }));
  }
}

function* putCarsStatus({ payload }: ReturnType<typeof postUpStatusFotoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const putCar = payload.IpostStatus;

    const id = payload.IpostStatus.id;

    yield call(api.put, "/carros/" + id, putCar, auth);

    yield put(postUpStatusFotoSuccess());
  } catch (error) {
    yield put(postUpStatusFotoFailure());
  }
}

function* deleteCars({ payload }: ReturnType<typeof deleteCarRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/carros/" + id, auth);

    yield put(deleteCarSuccess());
  } catch (error) {
    yield put(deleteCarFailure());
  }
}

function* getSiteCars({ payload }: ReturnType<typeof carSiteRequest>) {
  try {

    const paramsurl = payload.paramsUrl;
    const response = yield call(api.get, "/carSite" + paramsurl);
    console.log(response)

    yield put(carSiteSuccess(response.data.params.data));
  } catch (error) {
    yield put(carSiteFailure());
  }
}


export default all([
  takeLatest(CarTypes.CAR_REQUEST, getCars),
  takeLatest(CarTypes.CARSITE_REQUEST, getSiteCars),
  takeLatest(CarTypes.CARUNIQUE_REQUEST, getCarsUnique),
  takeLatest(CarTypes.POST_CAR_REQUEST, postCars),
  takeLatest(CarTypes.PUT_CAR_REQUEST, putCars),
  takeLatest(CarTypes.DELETE_CAR_REQUEST, deleteCars),
  takeLatest(CarTypes.UPSTATUS_FOTOCAR_REQUEST, putCarsStatus),
]);
