import { action } from "typesafe-actions";
import { ModelsTypes, IModels, IpostModels, IputModels } from "./types";

export const modelRequest = (paremtros: any) =>
  action(ModelsTypes.MODELS_REQUEST, {paremtros});

export const modelSuccess = (data: IModels ) =>
  action(ModelsTypes.MODELS_SUCCESS, { data });

export const modelFailure = () => action(ModelsTypes.MODELS_FAILURE, {});

export const postModelRequest = (IpostModel: IpostModels) =>
  action(ModelsTypes.POST_MODELS_REQUEST, {IpostModel});

export const postModelSuccess = () =>
  action(ModelsTypes.POST_MODELS_SUCCESS, {});

export const postModelFailure = () => action(ModelsTypes.POST_MODELS_FAILURE, {});

export const viewModel = (view: IModels) =>
  action(ModelsTypes.VIEW_MODEL, {view});

export const cleanViewModel = () =>
  action(ModelsTypes.CLEAN_VIEW_MODEL, {})

export const putModelRequest = (IputModel: IputModels) =>
  action(ModelsTypes.PUT_MODELS_REQUEST, {IputModel});

export const putModelSuccess = () =>
  action(ModelsTypes.PUT_MODELS_SUCCESS, {});

export const putModelFailure = () => action(ModelsTypes.PUT_MODELS_FAILURE, {});

export const deleteModelRequest = (id: string) =>
  action(ModelsTypes.DELETE_MODELS_REQUEST, {id});

export const deleteModelSuccess = () =>
  action(ModelsTypes.DELETE_MODELS_SUCCESS, {});

export const deleteModelFailure = () => action(ModelsTypes.DELETE_MODELS_FAILURE, {});

