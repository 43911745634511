import React from "react";

const SelectCarEdit = (props: any) => {
  const { carros1 } = props;

  return (
    <>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="id">
          ID
        </label>

        <input
          value={carros1.custom_id}
          type="text"
          className="form-control"
          id="id"
          placeholder="id"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="marca">
          Marca
        </label>

        <input
          value={carros1.marca}
          type="text"
          className="form-control"
          id="marca"
          placeholder="Marca"
          disabled={true}
        />
      </div>

      <div className="col-xl-6 col-lg-6 col-12 form-group">
        <label
          className="label-form-input"
          htmlFor="exampleInputPassword1"
        >
          Modelo
        </label>
        <input
          value={carros1.nome + " - " + carros1?.chassi}
          type="text"
          className="form-control"
          id="marca"
          placeholder="Modelo"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="marca">
          Ano Fabricação
        </label>

        <input
          value={carros1.ano_fab}
          type="text"
          className="form-control"
          id="marca"
          placeholder="Marca"
          disabled={true}
        />
      </div>


      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="anoModelo">
          Ano Modelo
          <input
            value={carros1.ano_modelo}
            type="text"
            className="form-control"
            id="anoModelo"
            placeholder="Ano modelo"
            disabled={true}
          />
        </label>
      </div>

      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="precoCompra">
          Preço Compra
        </label>

        <input
          type="text"
          className="form-control"
          id="precoCompra"
          placeholder="Preço Compra"
          disabled={true}
        />
      </div>

      <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
        <label className="label-form-input" htmlFor="precoVenda">
          Preço Venda
        </label>

        <input
          type="text"
          className="form-control"
          id="precoVenda"
          placeholder="Preço Venda"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="cor">
          Cor
        </label>
        <input
          value={carros1.cor}
          type="text"
          className="form-control"
          id="cor"
          placeholder="Cor"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="cambio">
          Cambio
        </label>
        <input
          value={carros1.cambio}
          type="text"
          className="form-control"
          id="cambio"
          placeholder="Cambio"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="Combustivel">
          Combustivel
        </label>
        <input
          value={carros1.combustivel}
          type="text"
          className="form-control"
          id="Combustivel"
          placeholder="Combustivel"
          disabled={true}
        />
      </div>

      <div className="col-xl-3 col-lg-6 col-12 form-group">
        <label className="label-form-input" htmlFor="chassi">
          Chassi
        </label>
        <input
          value={carros1.chassi}
          disabled={true}
          className="form-control"
          id="chassi"
          placeholder="Chassi"
        />
      </div>

      <div className="col-xl-12 col-lg-12 col-12 form-group">
        <label
          className="label-form-input"
          htmlFor="opcionaisCarro"
        >
          Opcionais
        </label>
        <textarea
          value={carros1?.opcionais}
          name="opcionais"
          className="form-control textarea"
          id="opcionaisCarro"
          rows={6}
          placeholder="Descreva as opções"
          disabled={true}
        />
      </div>
    </>
  );
};

export default SelectCarEdit;
