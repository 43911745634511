import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { PagtoParcautoTypes } from "./types";
import {
  pagtoParcautoRequest,
  pagtoParcautoSuccess,
  pagtoParcautoFailure,
  postPagtoParcautoRequest,
  postPagtoParcautoSuccess,
  postPagtoParcautoFailure,
  putPagtoParcautoRequest,
  putPagtoParcautoSuccess,
  putPagtoParcautoFailure,
  deletePagtoParcautoRequest,
  deletePagtoParcautoSuccess,
  deletePagtoParcautoFailure,
} from "./actions";

function* getPagtoParcauto({ payload }: ReturnType<typeof pagtoParcautoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/pagto-parcauto" + params, auth);


    yield put(pagtoParcautoSuccess(response.data.params.data));
  } catch (error) {
    yield put(pagtoParcautoFailure());
  }
}

function* postPagtoParcauto({ payload }: ReturnType<typeof postPagtoParcautoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostPagtoParcauto = payload.ipostPagtoParcauto;

    const response = yield call(api.post, "/pagto-parcauto", ipostPagtoParcauto, auth);

    yield put(postPagtoParcautoSuccess(response.data.response));
  } catch (error) {
    yield put(postPagtoParcautoFailure({ ...error.response.data.response }));
  }
}

function* putPagtoParcauto({ payload }: ReturnType<typeof putPagtoParcautoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputPagtoParcauto = payload.iputPagtoParcauto;

    const id = payload.iputPagtoParcauto.id;

    const response = yield call(api.put, "/pagto-parcauto/" + id, iputPagtoParcauto, auth);

    yield put(putPagtoParcautoSuccess(response.data.response));
  } catch (error) {
    yield put(putPagtoParcautoFailure({ ...error.response.data.response }));
  }
}

function* deletePagtoParcauto({ payload }: ReturnType<typeof deletePagtoParcautoRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/pagto-parcauto/" + id, auth);

    yield put(deletePagtoParcautoSuccess());
  } catch (error) {
    yield put(deletePagtoParcautoFailure());
  }
}

export default all([
  takeLatest(PagtoParcautoTypes.PAGTOPARCAUTO_REQUEST, getPagtoParcauto),
  takeLatest(PagtoParcautoTypes.POST_PAGTOPARCAUTO_REQUEST, postPagtoParcauto),
  takeLatest(PagtoParcautoTypes.PUT_PAGTOPARCAUTO_REQUEST, putPagtoParcauto),
  takeLatest(PagtoParcautoTypes.DELETE_PAGTOPARCAUTO_REQUEST, deletePagtoParcauto),
]);
