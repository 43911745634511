import axios from "axios";


// const fipeApi = axios.create({
//   baseURL: "http://localhost:8000/fipe",
// })

const fipeApi = axios.create({
  baseURL: "https://api.automarcasintermediacoes.com.br/fipe",
});

export default fipeApi;
