import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import client from "./client/sagas";
import models from "./models/sagas";
import gearshift from "./gearshift/sagas";
import color from "./color/sagas";
import year from "./year/sagas";
import fipe from "./fipe/sagas";
import mark from "./mark/sagas";
import fuel from "./fuel/sagas";
import optional from "./optional/sagas";
import user from "./user/sagas";
import car from "./car/sagas";
import employee from "./employee/sagas";
import clientStore from "./clientStore/sagas";
import contracts from "./contracts/sagas";
import declarations from "./declarations/sagas";
import provider from "./provider/sagas";
import categoryFinancial from "./categoryFinancial/sagas";
import expenses from "./expenses/sagas";
import financial from "./financial/sagas";
import comission from "./comission/sagas";
import bankAuto from "./bankAuto/sagas";
import thirds from "./thirds/sagas";
import pagtoThird from "./pagtoThird/sagas";
import pagtoParcauto from "./pagtoParcauto/sagas";

export default function* rootSaga() {
  return yield all([
    auth,
    client,
    models,
    gearshift,
    color,
    year,
    fipe,
    mark,
    fuel,
    optional,
    user,
    car,
    employee,
    clientStore,
    contracts,
    declarations,
    provider,
    categoryFinancial,
    expenses,
    financial,
    comission,
    bankAuto,
    thirds,
    pagtoThird,
    pagtoParcauto
  ]);
}
