import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import MaskedInput from "react-text-mask";
import "./index.css";
import { toast, ToastContainer } from "react-toastify";

import {
  cleanViewARespProvider,
  deleteAddressRequest,
  deleteBankRequest,
  putProviderRequest,
} from "../../store/ducks/provider/actions";
import { IProvider } from "../../store/ducks/provider/types";
import { Select } from "react-functional-select";

type BooleanOption = {
  readonly id: number;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: 0, name: "Fisica" },
  { id: 1, name: "Juridica" },
];

const EditFornecedor: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.provider
  );

  const view = useSelector<ApplicationState, IProvider>(
    (state) => state.provider.view
  );

  const [isLoading, setIsLoading] = useState(true);

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("fornecedor", {
    id: view.id,
    nome: view.nome,
    email: view.email,
    documento: view.documento,
    telefone: view.telefone,
    celular: view.celular,
    bancos: view.bancos,
    enderecos: view.enderecos,
    RG: view.RG,
    CPF: view.CPF,
    CNPJ: view.CNPJ,
    razao_social: view.razao_social,
  });

  const dataSelect =
    form.documento === "Fisica"
      ? { id: 0, name: "Fisica" }
      : { id: 1, name: "Juridica" };

  const [SelectednewUsed, setSelectednewUsed] = useState<BooleanOption | null>(
    dataSelect
  );

  const [bancosInput, setBancos] = useState([...form.bancos]);
  const [enderecosInput, setEnderecos] = useState([...form.enderecos]);

  const [newBancosInput, setNewBancos] = useState([
    {
      numero_banco: "", banco: "", agencia: "", conta: "", documento: "", favorecido: "",
      pix_banco: "",
    },
  ]);

  const [newEnderecosInput, setNewEnderecos] = useState([
    {
      CEP: "",
      rua: "",
      numero: "",
      bairro: "",
      cidade: "",
      complemento: "",
      estado: "",
    },
  ]);

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();
      toast.info("Carregando, aguarde");

      const {
        id,
        nome,
        email,
        telefone,
        celular,
        RG,
        CPF,
        CNPJ,
        razao_social,
      } = form;

      const bancos: any = bancosInput;
      const enderecos: any = enderecosInput;

      const bancos_new: any = newBancosInput;
      const enderecos_new: any = newEnderecosInput;
      const documento: any = SelectednewUsed?.name;


      dispatch(
        putProviderRequest({
          id,
          nome,
          email,
          documento,
          telefone,
          celular,
          RG,
          CPF,
          CNPJ,
          razao_social,
          bancos,
          enderecos,
          bancos_new,
          enderecos_new,
        })
      );
    },
    [
      dispatch,
      form,
      bancosInput,
      enderecosInput,
      newBancosInput,
      newEnderecosInput,
      SelectednewUsed,
    ]
  );

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        toast.success("Fornecedor alterado com sucesso");
        setTimeout(() => history.push("/fornecedor"), 2000);
      }

      if (event === 404) {
        toast.error("Falha ao cadastrar Fornecedor");
        btnNoDisabled();
      }
    },
    [history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const toastLoading = useCallback(() => {
    if (isLoading) {
      toast.info("Carregando informações do fornecedor");
    }
  }, [isLoading]);

  setTimeout(() => {
    setIsLoading(false);
    toast.dismiss();
  }, 600);

  const cleanView = useCallback(() => {
    dispatch(cleanViewARespProvider());
  }, [dispatch]);

  const deleteBank = useCallback(
    (id) => {
      dispatch(deleteBankRequest(id));
      // toast.success("Banco deletado com sucesso");
    },
    [dispatch]
  );

  const deleteAddress = useCallback(
    (id) => {
      dispatch(deleteAddressRequest(id));
      // toast.success("Endereço deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    [dispatch]
  );

  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    if (isLoading) {
      toastLoading();
    }
    notify(responseApi.response.code);
  }, [notify, responseApi.response, cleanView, isLoading]);

  // Bank handle input change
  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...bancosInput];
    list[index][name] = value;
    setBancos(list);
  };

  // Bank handle click event of the Remove button
  const handleRemoveClick = (index: any, id: any) => {
    const list = [...bancosInput];
    list.splice(index, 1);
    setBancos(list);
    deleteBank(id);
    setForm({
      ...form,
      ["bancos"]: list,
    });
  };

  // New Bank handle input change
  const handleInputChangeBank = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newBancosInput];
    list[index][name] = value;
    setNewBancos(list);
  };

  // New Bank handle click event of the Remove button
  const handleRemoveBank = (index: any) => {
    const list = [...newBancosInput];
    list.splice(index, 1);
    setNewBancos(list);
  };

  // New Bank handle click event of the Add button
  const handleAddBank = () => {
    setNewBancos([
      ...newBancosInput,
      {
        numero_banco: "", banco: "", agencia: "", conta: "", documento: "", favorecido: "",
        pix_banco: "",
      },
    ]);
  };

  //  Enderecos handle input change
  const handleInputChangeEnd = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...enderecosInput];
    list[index][name] = value;
    setEnderecos(list);
  };

  //  Enderecos handle click event of the Remove button
  const handleRemoveClickEnd = (index: any, id: any) => {
    const list = [...enderecosInput];
    list.splice(index, 1);
    setEnderecos(list);
    deleteAddress(id);
    setForm({
      ...form,
      ["enderecos"]: list,
    });
  };

  // Enderecos handle input change
  const handleInputChangeEndNew = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newEnderecosInput];
    list[index][name] = value;
    setNewEnderecos(list);
  };

  // Enderecos handle click event of the Remove button
  const handleRemoveClickEndNew = (index: any) => {
    const list = [...newEnderecosInput];
    list.splice(index, 1);
    setNewEnderecos(list);
  };

  // Enderecos handle click event of the Add button
  const handleAddClickEndNew = () => {
    setNewEnderecos([
      ...newEnderecosInput,
      {
        CEP: "",
        rua: "",
        numero: "",
        bairro: "",
        cidade: "",
        complemento: "",
        estado: "",
      },
    ]);
  };

  function onBlurCep(ev: any, i: any) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }
    const list = [...enderecosInput];

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        list[i]["CEP"] = data.cep;
        list[i]["rua"] = data.logradouro;
        list[i]["bairro"] = data.bairro;
        list[i]["cidade"] = data.localidade;
        list[i]["estado"] = data.uf;
        setEnderecos(list);
      });
  }

  function onBlurCepNew(ceps: any, i: any) {
    const { value } = ceps.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }
    const list = [...newEnderecosInput];

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        list[i]["CEP"] = data.cep;
        list[i]["rua"] = data.logradouro;
        list[i]["bairro"] = data.bairro;
        list[i]["cidade"] = data.localidade;
        list[i]["estado"] = data.uf;
        setNewEnderecos(list);
      });

    console.log(list);
  }

  const getNovoUsadoValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onNovoUsadoChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectednewUsed(newUsed),
    []
  );
  const getNovoUsadoLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Fornecedor</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Editar fornecedor</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Editar Forncecedor</h3>
                  {/* <span className="label-form-input">* Campos obrigatório</span> */}
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Tipo de pessoa
                    </label>
                    <Select
                      options={BOOL_OPTIONS}
                      onOptionChange={onNovoUsadoChange}
                      getOptionValue={getNovoUsadoValue}
                      getOptionLabel={getNovoUsadoLabel}
                      addClassNames={true}
                      placeholder="Selecione"
                      initialValue={dataSelect}
                    />
                  </div>

                  {SelectednewUsed?.name === "Fisica" ||
                    SelectednewUsed?.name === undefined ? (
                    <>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="nomeFisico"
                        >
                          Nome
                        </label>
                        <input
                          value={form.nome}
                          name="nome"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="nomeFisico"
                          placeholder="Nome"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="rg"
                        >
                          RG
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                          ]}
                          guide={false}
                          value={form.RG}
                          name="RG"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="rg"
                          placeholder="RG"
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="CPF"
                        >
                          CPF
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\w/,
                            /\w/,
                          ]}
                          guide={false}
                          value={form.CPF}
                          name="CPF"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="CPF"
                          placeholder="CPF"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="nomeContato"
                        >
                          Nome contato
                        </label>
                        <input
                          value={form.nome}
                          name="nome"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="nomeContato"
                          placeholder="Nome"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="razaoSocial"
                        >
                          Razão Social
                        </label>
                        <input
                          value={form.razao_social}
                          name="razao_social"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="razaoSocial"
                          placeholder="Razão Social"
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="CNPJ"
                        >
                          CNPJ
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "/",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                          ]}
                          guide={false}
                          value={form.CNPJ}
                          name="CNPJ"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="CNPJ"
                          placeholder="CNPJ"
                        />
                      </div>
                    </>
                  )}

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required={true}
                    />
                  </div>

                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="mt-5">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Bancos</h3>
                  {/* <span className="label-form-input">* Campos obrigatório</span> */}
                </div>
              </div>

              <div className="row">
                {bancosInput.map((x, i) => {
                  return (
                    <>
                      <div className="col-12">Banco {i + 1}</div>
                      <input
                        className="form-control"
                        name="id"
                        placeholder="id"
                        value={x.id}
                        onChange={(e) => handleInputChange(e, i)}
                        type="hidden"
                      />
                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="numero_banco"
                          placeholder="Codigo Banco"
                          value={x.numero_banco}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="banco"
                          placeholder="Nome Banco"
                          value={x.banco}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="agencia"
                          placeholder="Agencia"
                          value={x.agencia}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="conta"
                          placeholder="Conta"
                          value={x.conta}
                          onChange={(e) => handleInputChange(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="documento"
                          placeholder="CPF ou CNPJ"
                          value={x.documento}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="favorecido"
                          placeholder="Favorecido"
                          value={x.favorecido}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="pix_banco"
                          placeholder="Pix banco"
                          value={x.pix_banco}
                          onChange={(e) => handleInputChange(e, i)}
                        />
                      </div>

                      <div className="form-group col-xl-3">
                        <button
                          className="btn-add btn-remove"
                          onClick={() => handleRemoveClick(i, x.id)}
                        >
                          Remover
                        </button>
                      </div>
                    </>
                  );
                })}

                {newBancosInput.map((x, i) => {
                  return (
                    <>
                      <div className="col-12">
                        Banco{" "}
                        {i === 0
                          ? bancosInput.length + 1
                          : bancosInput.length + i + 1}
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="numero_banco"
                          placeholder="Codigo Banco"
                          value={x.numero_banco}
                          onChange={(e) => handleInputChangeBank(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="banco"
                          placeholder="Nome Banco"
                          value={x.banco}
                          onChange={(e) => handleInputChangeBank(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="agencia"
                          placeholder="Agencia"
                          value={x.agencia}
                          onChange={(e) => handleInputChangeBank(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="conta"
                          placeholder="Conta"
                          value={x.conta}
                          onChange={(e) => handleInputChangeBank(e, i)}
                          required={true}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="documento"
                          placeholder="CPF ou CNPJ"
                          value={x.documento}
                          onChange={(e) => handleInputChangeBank(e, i)}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="favorecido"
                          placeholder="Favorecido"
                          value={x.favorecido}
                          onChange={(e) => handleInputChangeBank(e, i)}
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <input
                          className="form-control"
                          name="pix_banco"
                          placeholder="Pix banco"
                          value={x.pix_banco}
                          onChange={(e) => handleInputChangeBank(e, i)}
                        />
                      </div>

                      <div className="form-group col-xl-3">
                        <button
                          className="btn-add btn-remove"
                          onClick={() => handleRemoveBank(i)}
                        >
                          Remover
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="row">
                <div className="col-lg-3 col-12 form-group mg-t-8">
                  <button className="btn-add" onClick={handleAddBank}>
                    Adicionar
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-5 mb-5">
              <div className="card-body">
                <div className="heading-layout1 mt-2 mb-4">
                  <div className="item-title">
                    <h3>Endereços</h3>
                  </div>
                </div>

                <div className="row">
                  {enderecosInput.map((x, i) => {
                    return (
                      <>
                        <div className="col-12">Endereço {i + 1}</div>
                        <input
                          className="form-control"
                          name="id"
                          placeholder="id"
                          value={x.id}
                          onChange={(e) => handleInputChangeEnd(e, i)}
                          type="hidden"
                        />
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="CEP"
                            placeholder="CEP"
                            value={x.CEP}
                            onBlur={(ev) => onBlurCep(ev, i)}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="rua"
                            placeholder="Rua"
                            value={x.rua}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="numero"
                            placeholder="Numero"
                            id={"numero" + i}
                            value={x.numero}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="complemento"
                            placeholder="Complemento"
                            value={x.complemento}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="bairro"
                            placeholder="Bairro"
                            value={x.bairro}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="cidade"
                            placeholder="Cidade"
                            value={x.cidade}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="estado"
                            placeholder="Sigla UF"
                            value={x.estado}
                            onChange={(e) => handleInputChangeEnd(e, i)}
                            required={true}
                          />
                        </div>

                        <div className="form-group col-xl-3">
                          <button
                            className="btn-add btn-remove"
                            onClick={() => handleRemoveClickEnd(i, x.id)}
                          >
                            Remover
                          </button>
                        </div>
                      </>
                    );
                  })}

                  {newEnderecosInput.map((x, j) => {
                    return (
                      <>
                        <div className="col-12">
                          Endereço{" "}
                          {j === 0
                            ? enderecosInput.length + 1
                            : enderecosInput.length + j + 1}
                        </div>
                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="CEP"
                            placeholder="CEP"
                            value={x.CEP}
                            onBlur={(cep) => onBlurCepNew(cep, j)}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="rua"
                            placeholder="Rua"
                            value={x.rua}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="numero"
                            placeholder="Numero"
                            id={"numero" + j}
                            value={x.numero}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="complemento"
                            placeholder="Complemento"
                            value={x.complemento}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="bairro"
                            placeholder="Bairro"
                            value={x.bairro}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="cidade"
                            placeholder="Cidade"
                            value={x.cidade}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="col-xl-3 col-lg-3 col-12 form-group">
                          <input
                            className="form-control"
                            name="estado"
                            placeholder="Sigla UF"
                            value={x.estado}
                            onChange={(e) => handleInputChangeEndNew(e, j)}
                            required={true}
                          />
                        </div>

                        <div className="form-group col-xl-3">
                          <button
                            className="btn-add btn-remove"
                            onClick={() => handleRemoveClickEndNew(j)}
                          >
                            Remover
                          </button>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-lg-3 col-12 form-group mg-t-8">
                    <button className="btn-add" onClick={handleAddClickEndNew}>
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditFornecedor;
