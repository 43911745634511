import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { viewCar } from "../../store/ducks/car/actions";
import { useDispatch } from "react-redux";
import { deleteCarRequest } from "../../store/ducks/car/actions";
import { toast, ToastContainer } from "react-toastify";

const DropdownAction = ({ view, deleteCar, viewDelete }: any) => {
  const dispatch = useDispatch();

  const setView = useCallback(
    (client) => {
      dispatch(viewCar(client));
    },
    [dispatch]
  );

  const deleteClient = useCallback(
    (id) => {
      dispatch(deleteCarRequest(id));
      toast.success("Carro deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    [dispatch]
  );

  function formatReal(int: any) {
    var tmp = int + "";
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  const accessUser = {
    headers: { "fk_user": localStorage.getItem("fk_user"), "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLoggedId = accessUser.headers["fk_user"];
  const UserLoggedLevel = accessUser.headers["Access-Level"];


  // 🚙 KICKS Advance 1.6 16V Flex Aut.
  // 🗓️ 2021 / 2022
  // 🎨 Branco Diamond
  // 🖊️ Descritivo do veículo
  // 💰 85.000, 00 / FR

  // let mensage =
  //   "https://api.whatsapp.com/send?text=" +
  //   "%F0%9F%9A%99 " + view.nome +
  //   "%0A%F0%9F%97%93%EF%B8%8F " + view.ano_fab + "/" + view.ano_modelo +
  //   "%0A%20%F0%9F%8E%A8 "+ view.cor +
  //   "%0A%F0%9F%96%8A%EF%B8%8F " + view.descritivo +
  //   "%0A%F0%9F%92%B0 " + formatReal(view.preco_venda.replace(".", "")) + " / FR"
  //   + "";


  function nameFaturamento(params: string) {

    let nameFat = ""
    switch (params) {
      case "1":
        nameFat = " AF"
        break;
      case "2":
        nameFat = " FR"
        break;
      case "3":
        nameFat = " Licenciado"
        break;
      default:
        nameFat = " Sem listagem"
        break;
    }
    return nameFat

  }

  let mensage =
    "https://api.whatsapp.com/send?text=" +
    view.nome +
    "%0A" + view.ano_fab + "/" + view.ano_modelo +
    "%0A" + view.cor +
    "%0A" + view.descritivo +
    "%0A" + formatReal(view.preco_venda.replace(".", "")) + " / " + nameFaturamento(view.faturamento)
    + "";
  return (
    <div>
      <div
        id={"folder" + deleteCar}
        className="collapse action__collapse  action__collapse--estoque"
        data-parent="#myGroup"
      >

        {
          UserLoggedId == view.fk_usuario || UserLoggedLevel === "super_admin" ? (
            <Link
              className="item__action"
              to={"/edita-carro"}
              onClick={() => {
                setView(view);
              }}
              style={{ borderBottom: "1.8px solid #1d1d1d" }}
            >
              <i
                className="fas fa-pencil-alt text-orange"
                style={{
                  position: "unset",
                  bottom: "0",
                  right: "0",
                  fontSize: "17px",
                }}
              />
              <span>Editar</span>
            </Link>
          ) : ("")
        }

        {viewDelete === "A Venda" || viewDelete === null && (UserLoggedId == view.fk_usuario || UserLoggedLevel === "super_admin") ? (
          <Link
            className="item__action actionStopPropagation"
            to={"/carros"}
            onClick={() => deleteClient(deleteCar)}
            style={{ borderBottom: "1.8px solid #1d1d1d" }}
          >
            <i
              className="fas fa-trash text-orange"
              style={{
                position: "unset",
                bottom: "0",
                right: "0",
                fontSize: "17px",
              }}
            />
            <span>Excluir</span>
          </Link>
        ) : (
          ""
        )}

        <a
          className="item__action"
          target="_blank"
          href={
            "https://apigestor.thaimotors.com.br/contractsCompra/" +
            view.file_compra
          }
          // href={
          //   "http://localhost:8000/contractsCompra/" +
          //   view.file_compra
          // }
          style={{
            borderBottom: "1.8px solid #021933",
          }}
        >
          <i
            className="far fa-file text-orange"
            style={{
              position: "unset",
              bottom: "0",
              right: "0",
              fontSize: "17px",
            }}
          />
          <span>Confirmação Compra</span>
        </a>

        {UserLoggedId == view.fk_usuario || UserLoggedLevel === "super_admin" ? (
          <a
            className="item__action actionStopPropagation"
            style={{ cursor: "pointer" }}
            data-toggle="modal"
            data-target={"#confirm" + deleteCar}
          >
            <i className="far fa-copy text-orange" />
            <span style={{ color: "#cccccc" }}>Duplicar</span>
          </a>) : ("")
        }

        <a
          className="item__action actionStopPropagation"
          style={{ cursor: "pointer" }}
          href={mensage}
          target="_blank"
        >
          <i
            className="fab fa-whatsapp text-orange"
            style={{ position: "initial", bottom: "0px", right: "none" }}
          />
          <span>Whatsapp</span>
        </a>

      </div>
    </div>
  );
};

export default DropdownAction;
