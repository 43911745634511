import { action } from "typesafe-actions";
import { AuthTypes, IResponse } from "./types";

interface IReq {
  email: string;
  password: string;
}

export const authRequest = (req: IReq) =>
  action(AuthTypes.AUTH_REQUEST, { req });

export const authSuccess = (data: any) =>
  action(AuthTypes.AUTH_SUCCESS, { data });

export const authFailure = (data: IResponse) =>
  action(AuthTypes.AUTH_FAILURE, { data });

export const cleanViewAResp = () => action(AuthTypes.CLEAN_AUTH_RESPONSE, {});

export const signOut = () => action(AuthTypes.SIGNOUT, {});
