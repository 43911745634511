import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IContracts } from "../../store/ducks/contracts/types";
import {
  cleanViewContractResp,
  cleanViewContracts,
  contractsRequest,
  deleteContractsRequest,
  viewContracts,
} from "../../store/ducks/contracts/actions";
import { employeeRequest } from "../../store/ducks/employee/actions";

const ContratoVenda: React.FC = () => {
  const dispatch = useDispatch();

  const contratoVenda = useSelector<ApplicationState, IContracts[]>(
    (state) => state.contracts.data
  );

  const loadingCV = useSelector<ApplicationState>(
    (state) => state.contracts.loading
  );

  localStorage.removeItem("contrato");

  const setViewPF = useCallback(
    (cv) => {
      dispatch(viewContracts(cv));
    },
    [dispatch]
  );

  const getContratoVenda = useCallback(() => {
    dispatch(contractsRequest("?pp=1000"));
    dispatch(cleanViewContracts());
    dispatch(cleanViewContractResp());
  }, [dispatch]);

  const deleteCV = useCallback(
    (id) => {
      dispatch(deleteContractsRequest(id));
      toast.success("Contrato deletado com sucesso");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    [dispatch]
  );

  const getVend = useCallback(() => {
    dispatch(employeeRequest());
  }, [dispatch]);

  const searchCar = useCallback((e) => {
    localStorage.setItem("chassi", e)
  }, []);

  useEffect(() => {
    getContratoVenda();
    getVend();
  }, [getContratoVenda]);

  if (loadingCV === false) {
    setTimeout(() => {
      //@ts-ignore
      $("#bolsa0km").DataTable({
        destroy: true,
        paging: false,
        ordering: true,
      });

      $(".dashboard-content-one").on('click', function () {
        //@ts-ignore
        $(".collapse").removeClass("show");
      });

      $(document).on('click', '.actionStopPropagation', function (e) {
        e.stopPropagation();
      });
    }, 200);
  }

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Vendas</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Vendas</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body" id="bolsa0kmCard">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title"></div>
              </div>
              <div className="table-responsive" style={{ overflow: "unset" }}>
                {loadingCV === true ? (
                  "carregando, aguarde..."
                ) : (
                  <table id="bolsa0km" className="table display  text-nowrap ">
                    <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Veiculo</th>
                        <th>Chassi</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="myGroup">
                      {!contratoVenda
                        ? ""
                        : contratoVenda
                          .map((client) => (
                            <tr
                              key={client.id}
                              data-toggle="collapse"
                              data-target={"#folder" + client.id}
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                            >
                              <td
                                className="infoCarroCard contracs--font"
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >
                                {client.client_data?.nome ||
                                  client.client_data?.nome_contato}
                              </td>


                              <td
                                className="infoCarroCard contracs--font"
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >

                                #{//@ts-ignore
                                  client.carro?.custom_id + ' - ' + client.carro?.nome}
                              </td>

                              <td
                                className="infoCarroCard contracs--font"
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >

                                {//@ts-ignore
                                  client.carro?.chassi.slice(- 9)}
                              </td>


                              <td
                                className="infoBolsaAcoes info--contracts"
                                style={{ textAlign: "center" }}
                                data-toggle="collapse"
                                data-target={"#folder" + client.id}
                              >
                                <span className="flaticon-more-button-of-three-dots icon__action icon__action--estoque"></span>
                                <div
                                  id={"folder" + client.id}
                                  className="collapse action__collapse action__collapse--contracts"
                                  data-parent="#myGroup"
                                >
                                  <Link
                                    className="item__action"
                                    to={"/financeiro"}
                                    onClick={() => {
                                      searchCar(client.carro?.chassi)
                                    }}
                                    style={{
                                      borderBottom: "1.8px solid #021933",
                                    }}
                                  >
                                    <i
                                      className="fas fa-dollar-sign text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Pagamentos</span>
                                  </Link>
                                  <a
                                    className="item__action"
                                    target="_blank"
                                    href={
                                      "https://apigestor.thaimotors.com.br/contracts/" +
                                      client.file_venda
                                    }
                                    // href={
                                    //   "http://localhost:8000/contracts/" +
                                    //   client.file_venda
                                    // }
                                    style={{
                                      borderBottom: "1.8px solid #021933",
                                    }}
                                  >
                                    <i
                                      className="far fa-file text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Confirmação Venda</span>
                                  </a>

                                  <Link
                                    className="item__action"
                                    to={"/editar-confirmacao"}
                                    onClick={() => {
                                      setViewPF(client);
                                    }}
                                    style={{
                                      borderBottom: "1.8px solid #021933",
                                    }}
                                  >
                                    <i
                                      className="fas fa-pencil-alt text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Editar</span>
                                  </Link>
                                  <Link
                                    className="item__action actionStopPropagation"
                                    to={"/confirmacao"}
                                    onClick={() => deleteCV(client.id)}
                                  >
                                    <i
                                      className="fas fa-trash text-orange"
                                      style={{
                                        position: "unset",
                                        bottom: "0",
                                        right: "0",
                                        fontSize: "17px",
                                      }}
                                    />
                                    <span>Deletar</span>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                )}

                <ToastContainer autoClose={5000} position="top-right" />
              </div>
              <div className="fab">
                <Link to="/cadastro-confirmacao">
                  <button className="main">+</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContratoVenda;
