import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { deleteComissionRequest } from "../../../store/ducks/comission/actions";
import { Select } from "react-functional-select";
import { thirdsRequest } from "../../../store/ducks/thirds/actions";
import { deletePagtoThirdRequest } from "../../../store/ducks/pagtoThird/actions";



type BooleanOptionPDF = {
  readonly id: number;
  readonly name: string;
};

const BOOL_OPTIONSPDF: BooleanOptionPDF[] = [
  { id: 0, name: "Sim" },
  { id: 1, name: "Nao" },
];


const SelectTerceiroEdit = (props: any) => {
  const { oldTerceiro, newTerceiro, setNewTerceiro, setoldTerceiro, setForm, form,
    setSelectedOptionUser, setSelectedOptionUserTerceiro, setSelectedOptionBankTerceiro, value_compra } = props;
  const dispatch = useDispatch();


  const userData: any = useSelector<ApplicationState>(
    (state) => state.thirds.data
  );

  const deleteTerceiro = useCallback(
    (id) => {
      dispatch(deletePagtoThirdRequest(id));
      // toast.success("Banco deletado com sucesso");
    },
    [dispatch]
  );

  const handleInputChangeTerceiro = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...newTerceiro];
    list[index][name] = value;
    setNewTerceiro(list);
  };

  const handleRemoveTerceiro = (index: any) => {
    const list = [...newTerceiro];
    list.splice(index, 1);
    setNewTerceiro(list);
  };

  const handleAddTerceiro = () => {
    setNewTerceiro([
      ...newTerceiro,
      { idP: "", nome: "", data_lancamento: "", valor: "", pdf: "", fk_banco: "" },
    ]);
  };

  // Bank handle input change
  const handleInputChangeTerceiroOld = (e: any, index: any) => {
    const { name, value } = e.target;
    const list: any = [...oldTerceiro];
    list[index][name] = value;
    setoldTerceiro(list);
  };

  // Bank handle click event of the Remove button
  const handleRemoveTerceiroOld = (index: any, id: any) => {
    const list = [...oldTerceiro];
    list.splice(index, 1);
    setoldTerceiro(list);
    deleteTerceiro(id);
    setForm({
      ...form,
      ["terceiro"]: list,
    });
  };

  const getOptionLabelUser = useCallback(
    (option: any): string => `${option.nome || option.nome}`,
    []
  );
  const getOptionValueUser = useCallback(
    (option: any): string => option.nome || option.nome,
    []
  );
  const onOptionChangeUser = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    const list: any = [...oldTerceiro];

    if (option?.nome === undefined) {
      nomeUser = list[i]["nome"]
    } else {
      nomeUser = option?.nome
    }
    //@ts-ignore
    list[i]["nome"] = nomeUser;
    setSelectedOptionUserTerceiro(option);


  }, [oldTerceiro]);

  const getOptionLabelBank = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBank = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );
  const onOptionChangeBank = useCallback((option: any, i: any): void => {

    let nomeBanco = "";
    const list: any = [...oldTerceiro];

    if (option?.id === undefined) {
      nomeBanco = list[i]["fk_banco"]
    } else {
      nomeBanco = option?.id
    }
    //@ts-ignore
    list[i]["fk_banco"] = nomeBanco;
    setSelectedOptionBankTerceiro(option);

  }, [oldTerceiro]);

  const getOptionLabelPDF = useCallback(
    (option: any): string => `${option.name || option.name}`,
    []
  );
  const getOptionValuePDF = useCallback(
    (option: any): string => option.name || option.name,
    []
  );
  const onOptionChangePDF = useCallback((option: any, i: any): void => {

    let pdfTrue = "";
    const list: any = [...oldTerceiro];

    if (option?.name === undefined) {
      pdfTrue = list[i]["pdf"]
    } else {
      pdfTrue = option?.name
    }
    //@ts-ignore
    list[i]["pdf"] = pdfTrue;

  }, [oldTerceiro]);

  const getOptionLabelUser1 = useCallback(
    (option: any): string => `${option.nome || option.nome}`,
    []
  );
  const getOptionValueUser1 = useCallback(
    (option: any): string => option.nome || option.nome,
    []
  );
  const onOptionChangeUser1 = useCallback((option: any, i: any): void => {

    let nomeUser = "";
    let idP = "";
    const list: any = [...newTerceiro];

    if (option?.nome === undefined) {
      nomeUser = list[i]["nome"]
      idP = list[i]["idP"]
    } else {
      nomeUser = option?.nome
      idP = option?.id
    }
    //@ts-ignore
    list[i]["nome"] = nomeUser;
    list[i]["idP"] = idP;
    setSelectedOptionBankTerceiro(option);


  }, [newTerceiro]);

  const getOptionLabelBank1 = useCallback(
    (option: any): string => `${option.banco || option.banco}`,
    []
  );
  const getOptionValueBank1 = useCallback(
    (option: any): string => option.banco || option.banco,
    []
  );
  const onOptionChangeBank1 = useCallback((option: any, i: any): void => {

    let nomeBanco = "";
    const list: any = [...newTerceiro];

    if (option?.id === undefined) {
      nomeBanco = list[i]["fk_banco"]
    } else {
      nomeBanco = option?.id
    }
    //@ts-ignore
    list[i]["fk_banco"] = nomeBanco;
    setSelectedOptionBankTerceiro(option);

  }, [newTerceiro]);

  const getOptionLabelPDF1 = useCallback(
    (option: any): string => `${option.name || option.name}`,
    []
  );
  const getOptionValuePDF1 = useCallback(
    (option: any): string => option.name || option.name,
    []
  );
  const onOptionChangePDF1 = useCallback((option: any, i: any): void => {

    let pdfTrue = "";
    const list: any = [...newTerceiro];

    if (option?.name === undefined) {
      pdfTrue = list[i]["pdf"]
    } else {
      pdfTrue = option?.name
    }
    //@ts-ignore
    list[i]["pdf"] = pdfTrue;

  }, [newTerceiro]);

  const oldTerceiroKey = useCallback((e, i) => {

    const list: any = [...oldTerceiro];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");

    // const sumValorOld = oldTerceiro
    //   .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
    //   .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

    // const sumValorNew = newTerceiro
    //   .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
    //   .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

    // const resolveOldNaN = isNaN(sumValorOld) ? 0 : sumValorOld;
    // const resolveNewNaN = isNaN(sumValorNew) ? 0 : sumValorNew;

    // const somaFinal = resolveOldNaN + resolveNewNaN + parseFloat(value_compra)

    // //soma comissao automarcas
    // const inputValueSellCar: any = $("#precoVenda").val()?.toString().replaceAll(".", "").replaceAll(",", ".")
    // const valueFullComission = parseFloat(inputValueSellCar) - somaFinal


    // $("#valorCompra")
    //   .val(somaFinal.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    // $("#valorPagtoInformativo")
    //   .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

  }, [oldTerceiro])

  const newTerceiroKey = useCallback((e, i) => {

    const list: any = [...newTerceiro];
    const value = e.target.value;

    list[i]["valor"] = value.replaceAll(".", "").replaceAll(",", ".");


    // var sumValorOld: any = [];

    // if (oldTerceiro.length === 0) {
    //   sumValorOld = 0;

    // } else {
    //   sumValorOld = oldTerceiro
    //     .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
    //     .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

    // }

    // const sumValorNew = newTerceiro
    //   .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
    //   .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

    // const resolveOldNaN = isNaN(sumValorOld) ? 0 : sumValorOld;
    // const resolveNewNaN = isNaN(sumValorNew) ? 0 : sumValorNew;

    // const somaFinal = resolveOldNaN + resolveNewNaN + parseFloat(value_compra)

    // //soma comissao automarcas
    // const inputValueSellCar: any = $("#precoVenda").val()?.toString().replaceAll(".", "").replaceAll(",", ".")
    // const valueFullComission = parseFloat(inputValueSellCar) - somaFinal

    // $("#valorCompra")
    //   .val(somaFinal.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    // $("#valorPagtoInformativo")
    //   .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    // form.valor_compra = somaFinal
  }, [newTerceiro])

  const getUser = useCallback(() => {
    dispatch(thirdsRequest("?pp=100000"));
  }, [dispatch]);

  useEffect(() => {
    getUser();
  }, [])

  let userThird = [{
    id: "",
    nome: "Selecione",
    email: "",
    documento: "",
    telefone: "",
    celular: "",
    RG: "",
    CPF: "",
    CNPJ: "",
    razao_social: "",
    bancos: []
  }]
  let bankThird = [{
    id: "",
    numero_banco: "",
    banco: "Selecione",
    agencia: "",
    conta: "",
    documento: "",
    favorecido: "",
    pix_banco: "",
  }]

  const finalData = userThird.concat(userData)


  return (
    <>
      <div className="card-body">
        <div className="heading-layout1 mt-2 mb-4">
          <div className="item-title">
            <h3>Terceiros</h3>
          </div>
        </div>

        <div className="row">

          {oldTerceiro.map((x: any, i: any) => {
            setTimeout(() => {
              $("#oldValorTerceiro" + i).val(parseFloat(x.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
            }
              , 200)

            return (
              <>
                <input
                  className="form-control"
                  name="id"
                  placeholder="id"
                  value={x.id}
                  onChange={(e) => handleInputChangeTerceiroOld(e, i)}
                  type="hidden"
                />

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={userData}
                    addClassNames={true}
                    placeholder={x.nome}
                    onOptionChange={(e) => onOptionChangeUser(e, i)}
                    getOptionLabel={getOptionLabelUser}
                    getOptionValue={getOptionValueUser}

                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={userData.filter((a: any) => a.nome === x.nome)[0]?.bancos}
                    addClassNames={true}
                    placeholder={userData
                      .filter((a: any) => a.nome === x.nome)[0]?.bancos
                      .filter((b: any) => b.id === x.fk_banco)[0]?.banco}
                    onOptionChange={(e) => onOptionChangeBank(e, i)}
                    getOptionLabel={getOptionLabelBank}
                    getOptionValue={getOptionValueBank}

                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeTerceiroOld(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control"
                    type="date"
                    name="data_lancamento"
                    placeholder="Data Lançamento"
                    value={x.data_lancamento}
                    onChange={(e) => handleInputChangeTerceiroOld(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    placeholder="Valor"
                    id={`oldValorTerceiro` + i}
                    autoComplete="off"
                    onKeyUp={(e) => oldTerceiroKey(e, i)}
                  />
                </div>

                <div className="col-xl-6 col-lg-6 col-12 form-group">
                  <Select
                    options={BOOL_OPTIONSPDF}
                    addClassNames={true}
                    placeholder={x.pdf}
                    onOptionChange={(e) => onOptionChangePDF(e, i)}
                    getOptionLabel={getOptionLabelPDF}
                    getOptionValue={getOptionValuePDF}

                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3 col-12">
                  <button
                    type="button"
                    className="btn-add btn-remove"
                    onClick={() => handleRemoveTerceiroOld(i, x.id)}
                  >
                    Remover
                  </button>
                </div>
                <div className="col-xl-3 col-lg-3 col-12 form-group"></div>

              </>
            );
          })}


          {newTerceiro.map((x: any, i: any) => {
            return (
              <>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={finalData}
                    addClassNames={true}
                    placeholder={"Selecione"}
                    onOptionChange={(e) => onOptionChangeUser1(e, i)}
                    getOptionLabel={getOptionLabelUser1}
                    getOptionValue={getOptionValueUser1}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <Select
                    options={bankThird.concat(finalData.filter((a: any) => a.id === x.idP)[0]?.bancos || "")}
                    addClassNames={true}
                    placeholder={"Selecione o Banco"}
                    onOptionChange={(e) => onOptionChangeBank1(e, i)}
                    getOptionLabel={getOptionLabelBank1}
                    getOptionValue={getOptionValueBank1}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group d-none">
                  <input
                    className="form-control"
                    name="nome"
                    placeholder="Nome"
                    value={x.nome}
                    onChange={(e) => handleInputChangeTerceiro(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control"
                    type="date"
                    name="data_lancamento"
                    placeholder="Data Lançamento"
                    value={x.data_lancamento}
                    onChange={(e) => handleInputChangeTerceiro(e, i)}
                  />
                </div>

                <div className="col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    className="form-control dinheiro"
                    placeholder="Valor"
                    id={`valorTerceiro` + i}
                    autoComplete="off"
                    onKeyUp={(e) => newTerceiroKey(e, i)}
                  />
                </div>

                <div className="col-xl-6 col-lg-6 col-12 form-group">
                  <Select
                    options={BOOL_OPTIONSPDF}
                    addClassNames={true}
                    placeholder={"Enviar Cobrança para o cliente?"}
                    onOptionChange={(e) => onOptionChangePDF1(e, i)}
                    getOptionLabel={getOptionLabelPDF1}
                    getOptionValue={getOptionValuePDF1}

                  />
                </div>

                <div className="form-group col-xl-3 col-lg-3 col-12">
                  <button
                    type="button"
                    className="btn-add btn-remove"
                    onClick={() => handleRemoveTerceiro(i)}
                  >
                    Remover
                  </button>

                </div>
                <div className="col-xl-3 col-lg-3 col-12 form-group"></div>

              </>
            );
          })}
        </div>

        <div className="row">
          <div className="col-lg-3 col-12 form-group mg-t-8">
            <button type="button" className="btn-add" onClick={handleAddTerceiro}>
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTerceiroEdit;
