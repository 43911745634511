export enum PagtoParcautoTypes {
  PAGTOPARCAUTO_REQUEST = "@pagtoParcauto/PAGTOPARCAUTO_REQUEST",
  PAGTOPARCAUTO_SUCCESS = "@pagtoParcauto/PAGTOPARCAUTO_SUCCESS",
  PAGTOPARCAUTO_FAILURE = "@pagtoParcauto/PAGTOPARCAUTO_FAILURE",
  POST_PAGTOPARCAUTO_REQUEST = "@pagtoParcauto/POST_PAGTOPARCAUTO_REQUEST",
  POST_PAGTOPARCAUTO_SUCCESS = "@pagtoParcauto/POST_PAGTOPARCAUTO_SUCCESS",
  POST_PAGTOPARCAUTO_FAILURE = "@pagtoParcauto/POST_PAGTOPARCAUTO_FAILURE",
  PUT_PAGTOPARCAUTO_REQUEST = "@pagtoParcauto/PUT_PAGTOPARCAUTO_REQUEST",
  PUT_PAGTOPARCAUTO_SUCCESS = "@pagtoParcauto/PUT_PAGTOPARCAUTO_SUCCESS",
  PUT_PAGTOPARCAUTO_FAILURE = "@pagtoParcauto/PUT_PAGTOPARCAUTO_FAILURE",
  VIEW_PAGTOPARCAUTO = "@pagtoParcauto/VEIW_PAGTOPARCAUTO",
  CLEAN_VIEW_PAGTOPARCAUTO = "@pagtoParcauto/CLEAN_VIEW_PAGTOPARCAUTO",
  DELETE_PAGTOPARCAUTO_REQUEST = "@pagtoParcauto/DELETE_PAGTOPARCAUTO_REQUEST",
  DELETE_PAGTOPARCAUTO_SUCCESS = "@pagtoParcauto/DELETE_PAGTOPARCAUTO_SUCCESS",
  DELETE_PAGTOPARCAUTO_FAILURE = "@pagtoParcauto/DELETE_PAGTOPARCAUTO_FAILURE",
  CLEAN_PAGTOPARCAUTO_RESPONSE = "@pagtoParcauto/CLEAN_PAGTOPARCAUTO_RESPONSE",
}

export interface IpostPagtoParcauto {
  valor: string;
  fk_banco?: string;
  fk_confirmacao?: string;

}

export interface IputPagtoParcauto {
  id: string;
  valor: string;
  fk_banco?: string;
  fk_confirmacao?: string;
}

export interface IPagtoParcauto {
  id: string;
  valor: string;
  fk_banco?: string;
  fk_confirmacao?: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface PagtoParcautoState {
  readonly data: IPagtoParcauto[];
  readonly view: IPagtoParcauto;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
