export enum ClientTypes {
  CLIENT_REQUEST = "@client/CLIENT_REQUEST",
  CLIENT_SUCCESS = "@client/CLIENT_SUCCESS",
  CLIENT_FAILURE = "@client/CLIENT_FAILURE",
  POST_CLIENT_REQUEST = "@client/POST_CLIENT_REQUEST",
  POST_CLIENT_SUCCESS = "@client/POST_CLIENT_SUCCESS",
  POST_CLIENT_FAILURE = "@client/POST_CLIENT_FAILURE",
  PUT_CLIENT_REQUEST = "@client/PUT_CLIENT_REQUEST",
  PUT_CLIENT_SUCCESS = "@client/PUT_CLIENT_SUCCESS",
  PUT_CLIENT_FAILURE = "@client/PUT_CLIENT_FAILURE",
  VIEW_CLIENT = "@client/VEIW_CLIENT",
  CLEAN_VIEW_CLIENT = "@client/CLEAN_VIEW_CLIENT",
  DELETE_CLIENT_REQUEST = "@client/DELETE_CLIENT_REQUEST",
  DELETE_CLIENT_SUCCESS = "@client/DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_FAILURE = "@client/DELETE_CLIENT_FAILURE",

  CLEAN_CLIENT_RESPONSE = "@client/CLEAN_CLIENT_RESPONSE",

  RECOVERY_CLIENT_REQUEST = "@client/RECOVERY_CLIENT_REQUEST",
  RECOVERY_CLIENT_SUCCESS = "@client/RECOVERY_CLIENT_SUCCESS",
  RECOVERY_CLIENT_FAILURE = "@client/RECOVERY_CLIENT_FAILURE",
}

export interface IpostClient {
  nome: string;
  email: string;
  whatsapp: string;
  cnpj: string;
  type: string;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  endNumero?: string;
  logo?: any;
  telefone?: any;
}

export interface IputClient {
  id: string;
  nome: string;
  email?: string;
  whatsapp: string;
  cnpj: string;
  type: string;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  endNumero?: string;
  logo?: any;
  telefone?: any;
}

export interface IClient {
  id: string;
  nome: string;
  email: string;
  whatsapp: string;
  cnpj: string;
  type: string;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  endNumero?: string;
  logo?: any;
  deleted_at?: string;
  created_by: string;
  telefone?: any;
}
export interface IResponse {
  code: string;
  message: string;
}

export interface ClientState {
  readonly data: IClient[];
  readonly view: IClient;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
