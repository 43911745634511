import { action } from "typesafe-actions";
import {
  BankAutoTypes,
  IBankAuto,
  IPostBankAuto,
  IPutBankAuto,
  IResponse,
} from "./types";

//client PF
export const bankAutoRequest = (data: any) =>
  action(BankAutoTypes.BANKAUTO_REQUEST, { data });

export const bankAutoSuccess = (data: IBankAuto) =>
  action(BankAutoTypes.BANKAUTO_SUCCESS, { data });

export const bankAutoFailure = () => action(BankAutoTypes.BANKAUTO_FAILURE, {});

export const postBankAutoRequest = (IpostBankAuto: IPostBankAuto) =>
  action(BankAutoTypes.POST_BANKAUTO_REQUEST, { IpostBankAuto });

export const postBankAutoSuccess = (data: IResponse) =>
  action(BankAutoTypes.POST_BANKAUTO_SUCCESS, { data });

export const postBankAutoFailure = (data: IResponse) =>
  action(BankAutoTypes.POST_BANKAUTO_FAILURE, { data });

export const viewBankAuto = (view: IBankAuto) =>
  action(BankAutoTypes.VIEW_BANKAUTO, { view });

export const cleanViewBankAuto = () =>
  action(BankAutoTypes.CLEAN_VIEW_BANKAUTO, {});


export const putBankAutoRequest = (IputBankAuto: IPutBankAuto) =>
  action(BankAutoTypes.PUT_BANKAUTO_REQUEST, { IputBankAuto });

export const putBankAutoSuccess = (data: IResponse) =>
  action(BankAutoTypes.PUT_BANKAUTO_SUCCESS, { data });

export const putBankAutoFailure = () =>
  action(BankAutoTypes.PUT_BANKAUTO_FAILURE, {});

export const deleteBankAutoRequest = (id: string) =>
  action(BankAutoTypes.DELETE_BANKAUTO_REQUEST, { id });

export const deleteBankAutoSuccess = () =>
  action(BankAutoTypes.DELETE_BANKAUTO_SUCCESS, {});

export const deleteBankAutoFailure = () =>
  action(BankAutoTypes.DELETE_BANKAUTO_FAILURE, {});

export const cleanViewARespBankAuto = () =>
  action(BankAutoTypes.CLEAN_BANKAUTO_RESPONSE, {});
