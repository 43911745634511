export enum ClientStoreTypes {
  //client PF
  CLIENTPF_REQUEST = "@clientStore/CLIENTPF_REQUEST",
  CLIENTPF_SUCCESS = "@clientStore/CLIENTPF_SUCCESS",
  CLIENTPF_FAILURE = "@clientStore/CLIENTPF_FAILURE",

  POST_CLIENTPF_REQUEST = "@clientStore/POST_CLIENTPF_REQUEST",
  POST_CLIENTPF_SUCCESS = "@clientStore/POST_CLIENTPF_SUCCESS",
  POST_CLIENTPF_FAILURE = "@clientStore/POST_CLIENTPF_FAILURE",

  PUT_CLIENTPF_REQUEST = "@clientStore/PUT_CLIENTPF_REQUEST",
  PUT_CLIENTPF_SUCCESS = "@clientStore/PUT_CLIENTPF_SUCCESS",
  PUT_CLIENTPF_FAILURE = "@clientStore/PUT_CLIENTPF_FAILURE",

  VIEW_CLIENTPF = "@clientStore/VEIW_CLIENTPF",
  CLEAN_VIEW_CLIENTPF = "@clientStore/CLEAN_VIEW_CLIENTPF",

  DELETE_CLIENTPF_REQUEST = "@clientStore/DELETE_CLIENTPF_REQUEST",
  DELETE_CLIENTPF_SUCCESS = "@clientStore/DELETE_CLIENTPF_SUCCESS",
  DELETE_CLIENTPF_FAILURE = "@clientStore/DELETE_CLIENTPF_FAILURE",

  CLEAN_CLIENTPF_RESPONSE = "@clientStore/CLEAN_CLIENTPF_RESPONSE",

  //client PJ
  CLIENTPJ_REQUEST = "@clientStore/CLIENTPJ_REQUEST",
  CLIENTPJ_SUCCESS = "@clientStore/CLIENTPJ_SUCCESS",
  CLIENTPJ_FAILURE = "@clientStore/CLIENTPJ_FAILURE",

  POST_CLIENTPJ_REQUEST = "@clientStore/POST_CLIENTPJ_REQUEST",
  POST_CLIENTPJ_SUCCESS = "@clientStore/POST_CLIENTPJ_SUCCESS",
  POST_CLIENTPJ_FAILURE = "@clientStore/POST_CLIENTPJ_FAILURE",

  PUT_CLIENTPJ_REQUEST = "@clientStore/PUT_CLIENTPJ_REQUEST",
  PUT_CLIENTPJ_SUCCESS = "@clientStore/PUT_CLIENTPJ_SUCCESS",
  PUT_CLIENTPJ_FAILURE = "@clientStore/PUT_CLIENTPJ_FAILURE",

  VIEW_CLIENTPJ = "@clientStore/VEIW_CLIENT",
  CLEAN_VIEW_CLIENTPJ = "@clientStore/CLEAN_VIEW_CLIENT",

  DELETE_CLIENTPJ_REQUEST = "@clientStore/DELETE_CLIENTPJ_REQUEST",
  DELETE_CLIENTPJ_SUCCESS = "@clientStore/DELETE_CLIENTPJ_SUCCESS",
  DELETE_CLIENTPJ_FAILURE = "@clientStore/DELETE_CLIENTPJ_FAILURE",

  CLEAN_CLIENTPJ_RESPONSE = "@clientStore/CLEAN_CLIENTPJ_RESPONSE",

}

//client PF
export interface IpostClientPF {
  nome: string;
  RG: string;
  CPF: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_user: string;
  email?: string;
}

export interface IputClientPF {
  id: string;
  nome: string;
  RG: string;
  CPF: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_user?: string;
  email?: string;
}

export interface IClientPF {
  id: string;
  nome: string;
  RG: string;
  CPF: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_user: string;
  email?: string;
}

//client PJ
export interface IpostClientPJ {
  nome_contato: string;
  razao_social: string;
  CNPJ: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_user: string;
  email?: string;
  iestadual?: string;
}

export interface IputClientPJ {
  id: string;
  nome_contato: string;
  razao_social: string;
  CNPJ: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_user?: string;
  email?: string;
  iestadual?: string;
}

export interface IClientPJ {
  id: string;
  nome_contato: string;
  razao_social: string;
  CNPJ: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_user?: string;
  email?: string;
  iestadual?: string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface ClientStoreState {
  readonly dataPF: IClientPF[];
  readonly viewPF: IClientPF;
  readonly dataPJ: IClientPJ[];
  readonly viewPJ: IClientPJ;
  readonly response: IResponse;
  readonly loadingPF: boolean;
  readonly loadingPJ: boolean;
  readonly errorPF: boolean;
  readonly errorPJ: boolean;
}
