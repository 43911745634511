import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ComissionTypes } from "./types";
import {
  comissionRequest,
  comissionSuccess,
  comissionFailure,
  postComissionRequest,
  postComissionSuccess,
  postComissionFailure,
  putComissionRequest,
  putComissionSuccess,
  putComissionFailure,
  deleteComissionRequest,
  deleteComissionSuccess,
  deleteComissionFailure,
} from "./actions";

function* getComission({ payload }: ReturnType<typeof comissionRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/pagto-confirmacao" + params, auth);


    yield put(comissionSuccess(response.data.params.data));
  } catch (error) {
    yield put(comissionFailure());
  }
}

function* postComission({ payload }: ReturnType<typeof postComissionRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostComission = payload.ipostComission;

    const response = yield call(api.post, "/pagto-confirmacao", ipostComission, auth);

    yield put(postComissionSuccess(response.data.response));
  } catch (error) {
    yield put(postComissionFailure({ ...error.response.data.response }));
  }
}

function* putComission({ payload }: ReturnType<typeof putComissionRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputComission = payload.iputComission;

    const id = payload.iputComission.id;

   const response = yield call(api.put, "/pagto-confirmacao/" + id, iputComission, auth);

    yield put(putComissionSuccess(response.data.response));
  } catch (error) {
    yield put(putComissionFailure({ ...error.response.data.response }));
  }
}

function* deleteComission({ payload }: ReturnType<typeof deleteComissionRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/pagto-confirmacao/" + id, auth);

    yield put(deleteComissionSuccess());
  } catch (error) {
    yield put(deleteComissionFailure());
  }
}

export default all([
  takeLatest(ComissionTypes.COMISSION_REQUEST, getComission),
  takeLatest(ComissionTypes.POST_COMISSION_REQUEST, postComission),
  takeLatest(ComissionTypes.PUT_COMISSION_REQUEST, putComission),
  takeLatest(ComissionTypes.DELETE_COMISSION_REQUEST, deleteComission),
]);
