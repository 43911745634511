/* eslint-disable array-callback-return */
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";

import { Select } from "react-functional-select";

import {
  cleanViewCarResp,
  carUniqueRequest,
  postUpStatusFotoRequest,
} from "../../store/ducks/car/actions";
import MaskedInput from "react-text-mask";
import { toast, ToastContainer } from "react-toastify";
import { putContractsRequest, cleanViewContractResp } from "../../store/ducks/contracts/actions";
import { IContracts } from "../../store/ducks/contracts/types";

import { EditorState, convertToRaw, ContentState, convertFromHTML, ContentBlock } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

//@ts-ignore
import draftToHtml from 'draftjs-to-html';
import { ICategoryFin } from "../../store/ducks/categoryFinancial/types";
import { bankAutoRequest } from "../../store/ducks/bankAuto/actions";
import SelectCarEdit from "./selectCarEdit";
import SelectClientEdit from "./selectClientEdit";
import SelectFornecedorEdit from "./selectFornecedorEdit";
import SelectComissionEdit from "./selectComissaoEdit";
import SelectTerceiroEdit from "./selectTerceiroEdit";
import SelectParcautoEdit from "./selectParcautoEdit";
import { IProfileUser } from "../../store/ducks/user/types";
import jwt from "jwt-decode";
import { userProfileRequest } from "../../store/ducks/user/actions";


type BooleanOption = {
  readonly id: number;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: 0, name: "Fisica" },
  { id: 1, name: "Juridica" },
];

const EditContratoVenda: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );
  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const view = useSelector<ApplicationState, IContracts>((state) => state.contracts.view);

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.contracts
  );

  const carros1: any = useSelector<ApplicationState>((state) => state.car.dataUnique);

  const [selectedOptionUser, setSelectedOptionUser] = useState<null>(null);
  const [selectedOptionUserTerceiro, setSelectedOptionUserTerceiro] = useState<null>(null);
  const [selectedOptionBankTerceiro, setSelectedOptionBankTerceiro] = useState<null>(null);
  const [selectedOptionUserParcauto, setSelectedOptionUserParcauto] = useState<null>(null);
  const [selectedOptionEditUserParcauto, setSelectedEditOptionUserParcauto] = useState<null>(null);


  const [isLoading, setIsLoading] = useState(true);

  const [selectedOptionClient] = useState<null>(null);
  const [selectedOptionAddress, setSelectedOptionAddress] = useState({
    id: "",
    CEP: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade: "",
    complemento: "",
    estado: "",
  });

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("contrato", {
    id: view.id,
    numero_banco: view.numero_banco,
    banco: view.banco,
    agencia: view.agencia,
    conta: view.conta,
    documento_banco: view.documento_banco,
    contato: view.contato,
    documentar_em: view.documentar_em,
    valor_nota: view.valor_nota,
    valor_compra: view.valor_compra,
    pagto_compra: view.pagto_compra,
    data_entrega: view.data_entrega,
    valor_venda: view.valor_venda,
    data_venda: view.data_venda,
    pagto_auto: view.pagto_auto,
    pagto_fornecedor: view.pagto_fornecedor,
    nome: view.nome,
    nome_contato: view.nome_contato,
    email: view.email,
    documento_cliente: view.documento_cliente,
    telefone: view.telefone,
    celular: view.celular,
    rg: view.rg,
    cpf: view.cpf,
    cnpj: view.cnpj,
    razao_social: view.razao_social,
    cep: view.cep,
    rua: view.rua,
    numero: view.numero,
    complemento: view.complemento,
    bairro: view.bairro,
    cidade: view.cidade,
    estado: view.estado,
    nome_motorista: view.nome_motorista,
    rg_motorista: view.rg_motorista,
    data_retira: view.data_retira,
    hora_retira: view.hora_retira,
    observacoes: view.observacoes,
    file_compra: view.file_compra,
    file_venda: view.file_venda,
    fk_veiculo: view.fk_veiculo,
    fk_fornecedor: view.fk_fornecedor,
    fk_banco: view.fk_banco,
    fk_endereco: view.fk_endereco,
    fk_cliente: view.fk_cliente,
    client_type: view.client_type,
    alienado: view.alienado,
    client_data: {
      nome: view.client_data?.nome || "",
      RG: view.client_data?.RG || "",
      CPF: view.client_data?.CPF || "",
      nome_contato: view.client_data?.nome_contato || "",
      email: view.client_data?.email || "",
      razao_social: view.client_data?.razao_social || "",
      CNPJ: view.client_data?.CNPJ || "",
      telefone: view.client_data?.telefone || "",
      celular: view.client_data?.celular || "",
      CEP: view.client_data?.CEP || "",
      rua: view.client_data?.rua || "",
      numero: view.client_data?.numero || "",
      bairro: view.client_data?.bairro || "",
      cidade: view.client_data?.cidade || "",
      complemento: view.client_data?.complemento || "",
      estado: view.client_data?.estado || "",
      fk_loja: view.client_data?.fk_loja || "",
      fk_user: view.client_data?.fk_user || "",
    },
    endereco: {
      id: view.endereco?.id || "",
      CEP: view.endereco?.CEP || "",
      rua: view.endereco?.rua || "",
      numero: view.endereco?.numero || "",
      bairro: view.endereco?.bairro || "",
      cidade: view.endereco?.cidade || "",
      complemento: view.endereco?.complemento || "",
      estado: view.endereco?.estado || "",
    },
    obs: view.observacoes,
    comissao: view.comissao,
    terceiro: view.terceiro,
    fk_bancoAuto: view.fk_bancoAuto,
    favorecido: view.favorecido,
    pix_banco: view.pix_banco,
    pagtoParcauto: view.pagtoParcauto

  });

  const [selectedBankAuto, setSelectedBankAuto] = useState<ICategoryFin | null>(
    null
  );

  const pessoaDadosFat = form.cnpj === "" || form.cnpj === undefined ? { id: 0, name: "Fisica" } : { id: 1, name: "Juridica" };

  const [selectedOptionBank, setSelectedOptionBank] = useState(
    { id: "", numero_banco: form.numero_banco, banco: form.banco, agencia: form.agencia, conta: form.conta, documento: form.documento_banco });

  const [SelectednewUsed, setSelectednewUsed] = useState<BooleanOption | null>(
    pessoaDadosFat
  );

  const plainText1 = "<p>" + form.obs + "</p>";
  const content1 = convertFromHTML(plainText1);

  const state1 = ContentState.createFromBlockArray(
    content1.contentBlocks,
    content1.entityMap,
  );

  const [editorState1, setEditorState1] = useState(EditorState.createWithContent(state1))
  const [btnEmail, setBtnEmail] = useState("");
  const [oldComissao, setoldComissao] = useState([...form.comissao]);
  const [newComissao, setNewComissao] = useState([]);

  const [oldTerceiro, setoldTerceiro] = useState([...form.terceiro]);
  const [newTerceiro, setNewTerceiro] = useState([]);

  const [oldParcauto, setoldParcauto] = useState([...form.pagtoParcauto]);
  const [newParcauto, setNewParcauto] = useState([]);

  const [focusBankProvider, setFocusBankProvider] = useState(false);
  const [focusAddressProvider, setFocusAddressProvider] = useState(false);

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();

      if (btnEmail === "fatSave" || btnEmail === "libSave") {
        toast.info("Enviando, aguarde");

      } else {

        toast.info("Carregando, aguarde");
      }

      const {
        id,
        banco,
        numero_banco,
        agencia,
        conta,
        documento_banco,
        contato,
        documentar_em,
        pagto_compra,
        data_entrega,
        data_venda,
        nome,
        nome_contato,
        email,
        documento_cliente,
        telefone,
        celular,
        rg,
        cpf,
        cnpj,
        razao_social,
        nome_motorista,
        rg_motorista,
        data_retira,
        hora_retira,
        file_compra,
        file_venda,
        fk_veiculo,
        fk_fornecedor,
        fk_cliente,
        cep,
        rua,
        numero,
        complemento,
        bairro,
        cidade,
        estado,
        nameClient,
        favorecido,
        pix_banco,
        alienado
      } = form;

      //@ts-ignore
      form.valor_compra = document.getElementById("valorCompra").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      form.valor_nota = document.getElementById("valorNota").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      form.valor_venda = document.getElementById("valorVenda").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      form.pagto_fornecedor = document.getElementById("pagtoFornecedor").value.replaceAll(".", "").replaceAll(",", ".");

      //@ts-ignore
      const valor_compra = document.getElementById("valorCompra").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const valor_nota = document.getElementById("valorNota").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const valor_venda = document.getElementById("valorVenda").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const pagto_fornecedor = document.getElementById("pagtoFornecedor").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const pagto_auto = "1000.00";

      const client_type = nameClient !== "" ? "PF" : "PJ";
      const fk_banco = selectedOptionBank?.id || form.fk_banco;
      const fk_endereco = selectedOptionAddress?.id || form.fk_endereco;

      const observacoes = draftToHtml(convertToRaw(form.observacoes.getCurrentContent())).trim();

      if (contato === "") {
        toast.dismiss();
        $("#contato").focus();
        toast.error("Insira o contato");
        btnNoDisabled();

        return;
      }

      if (valor_nota === "") {
        toast.dismiss();
        $("#valorNota").focus();
        toast.error("Insira o valor da nota");
        btnNoDisabled();

        return;
      }

      if (valor_compra === "") {
        toast.dismiss();
        $("#valorCompra").focus();
        toast.error("Insira o valor da compra");
        btnNoDisabled();

        return;
      }

      if (valor_venda === "") {
        toast.dismiss();
        $("#valorVenda").focus();
        toast.error("Insira o valor da venda");
        btnNoDisabled();
        return;
      }

      if (pagto_compra === "") {
        toast.dismiss();
        $("#pagto_compra").focus();
        toast.error("Insira a data de pagamento");
        btnNoDisabled();

        return;
      }

      if (valor_venda === "") {
        toast.dismiss();
        $("#valorVenda").focus();
        toast.error("Insira o valor de venda");
        btnNoDisabled();

        return;
      }

      const breakParcela = newParcauto.filter((data: any) => !data.fk_banco && data.valor !== "");

      if (breakParcela.length > 0) {
        toast.error("Selecione o Banco em parcelas");
        btnNoDisabled();
        return;
      }

      const breakTerceiro = newTerceiro.filter((data: any) => !data?.nome && data?.valor !== "");

      if (breakTerceiro.length > 0) {
        toast.error("Selecione o Terceiro");
        btnNoDisabled();
        return;
      }

      //correcao valores = "" p/ valores = 0
      newParcauto
        .filter((data: any) => data.fk_banco && data.valor === "")
        .map((d: any) => d.valor = 0);

      oldParcauto
        .filter((data) => data.fk_banco && data.valor === "")
        .map((d: any) => d.valor = 0);

      newTerceiro
        .filter((data: any) => data?.nome && data?.valor === "")
        .map((d: any) => d.valor = 0);

      newTerceiro
        .filter((data: any) => data?.pdf === "")
        .map((d: any) => d.pdf = "Nao");

      oldTerceiro
        .filter((data) => data.nome && data.valor === "")
        .map((d: any) => d.valor = 0);

      // const diffValueBuyAndSell = form.valor_venda - form.valor_compra;

      // let sumValorOld: any = 0;
      // let sumValorNew: any = 0;

      // console.log(oldParcauto.length === 0 && newParcauto.length === 0)

      // if (oldParcauto.length === 0 && newParcauto.length === 0) {
      //   sumValorOld = 0;
      //   sumValorNew = 0;
      // } else {
      //   sumValorOld = oldParcauto
      //     ?.map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
      //     .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

      //   sumValorNew = newParcauto
      //     .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
      //     .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))
      // }





      // const parcelaAutoMarcas = sumValorNew + sumValorOld;
      // const finalValor = diffValueBuyAndSell - parcelaAutoMarcas;


      // if (finalValor > 0 || finalValor < 0) {
      //   toast.error("Valor de Pagamento Thai Motors diferente da comissão");
      //   $("#oldValorParcauto0").focus();
      //   btnNoDisabled();
      //   return;
      // }

      const terceiro_new: any = newTerceiro;
      const terceiro_old: any = oldTerceiro;
      const comissao_new: any = newComissao;
      const comissao_old: any = oldComissao;
      const fk_bancoAuto: any = 1;
      const parcauto_new: any = newParcauto;
      const parcauto_old: any = oldParcauto;

      const profileEmail = profileUser.email;
      const profileWhats = profileUser.whatsapp;
      const profileNome = profileUser.nome;

      const preco_venda = valor_venda;
      const status = "Vendido";

      dispatch(
        putContractsRequest({
          id,
          observacoes,
          client_type,
          banco,
          agencia,
          conta,
          documento_banco,
          contato,
          documentar_em,
          valor_nota,
          valor_compra,
          pagto_compra,
          data_entrega,
          valor_venda,
          data_venda,
          pagto_auto,
          pagto_fornecedor,
          nome,
          nome_contato,
          email,
          documento_cliente,
          telefone,
          celular,
          rg,
          cpf,
          cnpj,
          razao_social,
          cep,
          rua,
          numero,
          complemento,
          bairro,
          cidade,
          estado,
          nome_motorista,
          rg_motorista,
          data_retira,
          hora_retira,
          file_compra,
          file_venda,
          fk_veiculo,
          fk_fornecedor,
          fk_banco,
          fk_endereco,
          fk_cliente,
          btnEmail,
          numero_banco,
          comissao_new,
          comissao_old,
          fk_bancoAuto,
          favorecido,
          pix_banco,
          terceiro_new,
          terceiro_old,
          parcauto_new,
          parcauto_old,
          profileEmail,
          profileWhats,
          profileNome,
          alienado
        })
      );

      dispatch(
        postUpStatusFotoRequest({
          id: fk_veiculo,
          status,
          preco_venda,
          descritivo: carros1?.descritivo,
          opcionais: carros1?.opcionais,
          profileEmail,
          profileWhats,
          profileNome,
        })
      )

    },
    [dispatch, form, selectedOptionAddress, selectedOptionBank, selectedOptionClient, btnEmail, newComissao, oldComissao,
      selectedBankAuto, newTerceiro, oldTerceiro, newParcauto, oldParcauto, profileUser, carros1]
  );


  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        btnNoDisabled();
        if (btnEmail === "fatSave") {
          toast.success("Dados de faturamento enviado!");
        } else if (btnEmail === "libSave") {
          toast.success("Dados de liberação envidado!");
        } else {
          toast.success("Confirmação alterado com sucesso");
          setTimeout(() => history.push("/confirmacao"), 2000);

        }

      }

      if (event === 404) {
        toast.error("Erro ao inserir confirmação");
        btnNoDisabled();
      }
    },
    [history, btnEmail]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );


  const getCarUnique = useCallback(
    (event) => {
      dispatch(carUniqueRequest(event));
    },
    [dispatch]
  );

  const getNovoUsadoValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onNovoUsadoChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectednewUsed(newUsed),
    []
  );
  const getNovoUsadoLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );

  const toastLoading = useCallback(() => {
    if (isLoading) {
      toast.info("Carregando informações, aguarde");
    }
  }, []);


  // var sumValorOld: any = [];

  // if (oldTerceiro.length === 0) {
  //   sumValorOld = 0;

  // } else {
  //   sumValorOld = oldTerceiro
  //     .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
  //     .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

  // }

  // const sumValorNew = newTerceiro
  //   .map((test: any) => isNaN(parseFloat(test.valor)) ? 0 : parseFloat(test.valor))
  //   .reduce((a: any, b: any) => parseFloat(a) + parseFloat(b))

  // const resolveOldNaN = isNaN(sumValorOld) ? 0 : sumValorOld;
  // const resolveNewNaN = isNaN(sumValorNew) ? 0 : sumValorNew;

  const [value_compra, setValueCompra] = useState(parseFloat(form.valor_compra));

  const somaFinal = value_compra;

  const somaFinalComissao = form.valor_venda - form.valor_compra;

  setTimeout(() => {
    $("#precoFipe").val(parseFloat(carros1.preco_fipe).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#precoVenda").val(parseFloat(carros1.preco_venda).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#precoCompra").val(parseFloat(carros1.preco_custo).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    $("#valueFixCar").val(parseFloat(carros1.preco_custo).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
    $("#valueVendaCar").val(parseFloat(carros1.preco_venda).toLocaleString('pt-br', { minimumFractionDigits: 2 }))

    $("#valorPagtoInformativo").val(somaFinalComissao.toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#valorCompra").val(somaFinal.toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#valorVenda").val(parseFloat(form.valor_venda).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#valorNota").val(parseFloat(form.valor_nota).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#pagtoAuto").val(parseFloat(form.pagto_auto).toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    $("#pagtoFornecedor").val(parseFloat(form.pagto_fornecedor).toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    setIsLoading(false);

  }, 1800);

  const getBankAuto = useCallback(() => {
    dispatch(bankAutoRequest("?pp=10000"));
  }, [dispatch]);

  const cleanView = useCallback(() => {
    dispatch(cleanViewCarResp());
    dispatch(cleanViewContractResp());
  }, [dispatch]);

  useEffect(() => {
    getCarUnique("/edit/" + form.fk_veiculo);

    if (responseApi.response.code !== "") {
      cleanView();
    }
    if (isLoading) {
      toastLoading();
    } else {
      toast.dismiss();
    }

    getBankAuto();

    notify(responseApi.response.code);

    getProfile();

  }, [notify, responseApi.response, cleanView, isLoading]);


  function onBlurCep(ev: any) {
    const { value } = ev.target;

    const cep = value?.replaceAll(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          ...form,
          cep: data.cep,
          rua: data.logradouro,
          numero: form.numero,
          bairro: data.bairro,
          cidade: data.localidade,
          complemento: data.complemento,
          estado: data.uf,
        });
      });
  }


  const valorCompraKey = useCallback((e) => {

    //soma do valor compra
    const inputValueBuyCar: any = e.target.value.replaceAll(".", "").replaceAll(",", ".")
    const somaFinal = parseFloat(inputValueBuyCar)

    $("#valueFixCar").val(parseFloat(inputValueBuyCar));

    //soma comissao automarcas
    const inputValueSellCar: any = $("#valueVendaCar").val()
    const valueFullComission = parseFloat(inputValueSellCar) - somaFinal

    const listParcelaComissao: any = [...newParcauto];
    if (newParcauto.length > 0) {

      listParcelaComissao[0]["valor"] = valueFullComission;

      $("#valorParcauto0")
        .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));
    }

    $("#valorPagtoInformativo")
      .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

  }, [newParcauto])

  const valorVendaKey = useCallback((e) => {

    const inputValueBuyCar: any = $("#valueFixCar").val()
    const somaFinal = parseFloat(inputValueBuyCar.replaceAll(".", "").replaceAll(",", "."))

    //soma comissao automarcas
    const inputValueSellCar: any = e.target.value.replaceAll(".", "").replaceAll(",", ".");
    const valueFullComission = parseFloat(inputValueSellCar) - somaFinal

    const listParcelaComissao: any = [...newParcauto];

    if (newParcauto.length > 0) {

      listParcelaComissao[0]["valor"] = valueFullComission;

      $("#valorParcauto0")
        .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    }

    $("#valorPagtoInformativo")
      .val(valueFullComission.toLocaleString('pt-br', { minimumFractionDigits: 2 }));

    $("#valueVendaCar")
      .val(parseFloat(inputValueSellCar));

  }, [newParcauto])

  const accessUser = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLoggedLevel = accessUser.headers["Access-Level"];


  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Vendas</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Editar venda</li>
            </ul>
          </div>
          <form onSubmit={submitForm} className="new-added-form">

            <div className="mb-5">
              <div className="card-body">
                <div className="heading-layout1 mt-2 mb-4">
                  <div className="item-title">
                    <h3>Editar Venda</h3>
                    <span className="label-form-input">* Campos obrigatório</span>
                  </div>
                </div>
                {isLoading ? (
                  ""
                ) : (
                  <div className="row">

                    <SelectCarEdit carros1={carros1} />

                    <input
                      name="valueFixCar"
                      type="text"
                      className="form-control d-none"
                      id="valueFixCar"
                    />

                    <input
                      name="valueVendaCar"
                      type="text"
                      className="form-control d-none"
                      id="valueVendaCar"
                    />


                    <div className="col-12 form-group mg-t-8 buttonFlutuante">
                      <button
                        id="btn_submit"
                        type="submit"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btn--onlySave"
                        onClick={() => setBtnEmail("onlySave")}
                        data-toggle="tooltip" data-placement="top" title="Salvar"
                      >
                        <i className="fa fa-download"></i>
                      </button>

                      <button
                        id="btn_submit"
                        type="submit"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btn--save"
                        onClick={() => setBtnEmail("mailSave")}
                        data-toggle="tooltip" data-placement="top" title="Salvar e enviar"
                      >
                        <i className="fas fa-envelope"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {isLoading ? (
              ""
            ) : (
              <div
                className=""
                style={{
                  paddingBottom: "30px",
                  border: "none",
                  background: "initial",
                }}
              >
                <SelectClientEdit selectedOptionClient={selectedOptionClient} form={form}
                  handleChange={handleChange} />
              </div>
            )}
            {isLoading ? ("") :
              (
                <div
                  className=""
                  style={{
                    paddingBottom: "30px",
                    border: "none",
                    background: "initial",
                  }}
                >
                  <SelectFornecedorEdit
                    form={form}
                    handleChange={handleChange}
                    carros1={carros1}
                    setForm={setForm}
                    setSelectedOptionBank={setSelectedOptionBank}
                    setSelectedOptionAddress={setSelectedOptionAddress}
                    selectedOptionAddress={selectedOptionAddress}
                    focusAddressProvider={focusAddressProvider}
                    focusBankProvider={focusBankProvider}
                  />


                </div>

              )}

            {/* terceiros */}
            {isLoading ? ("") :
              (
                <div
                  className=""
                  style={{
                    paddingBottom: "30px",
                    border: "none",
                    background: "initial",
                  }}
                >

                  <SelectTerceiroEdit
                    oldTerceiro={oldTerceiro}
                    newTerceiro={newTerceiro}
                    setNewTerceiro={setNewTerceiro}
                    setoldTerceiro={setoldTerceiro}
                    setForm={setForm}
                    value_compra={value_compra}
                    form={form}
                    setSelectedOptionUser={setSelectedOptionUser}
                    setSelectedOptionUserTerceiro={setSelectedOptionUserTerceiro}
                    setSelectedOptionBankTerceiro={setSelectedOptionBankTerceiro}
                  />
                </div>
              )
            }

            {isLoading ? (
              ""
            ) : (
              <div
                className=""
                style={{
                  paddingBottom: "30px",
                  border: "none",
                  background: "initial",
                }}
              >
                <div className="card-body">
                  <div className="heading-layout1 mt-2 mb-4">
                    <div className="item-title">
                      <h3>Informações Adicionais</h3>
                    </div>
                  </div>
                  <div className="new-added-form">
                    <div className="row">

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="contato"
                        >
                          Contato *
                        </label>
                        <input
                          value={form.contato}
                          name="contato"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="contato"
                          placeholder="Contato"
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="documentar_em"
                        >
                          Documentar em
                        </label>
                        <input
                          value={form.documentar_em}
                          name="documentar_em"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="documentar_em"
                          placeholder="Documentar em"
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="valorNota"
                        >
                          Valor da Nota *
                        </label>
                        <input
                          name="valor_nota"
                          type="text"
                          className="form-control dinheiro"
                          id="valorNota"
                          placeholder="R$ 0,00"

                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="valorCompra"
                        >
                          Valor Compra *
                        </label>
                        <input
                          name="valor_compra"
                          type="text"
                          className="form-control dinheiro"
                          id="valorCompra"
                          placeholder="R$ 0,00"
                          onKeyUp={(e) => valorCompraKey(e)}
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="valorVenda"
                        >
                          Valor de Venda *
                        </label>
                        <input
                          name="valor_venda"
                          type="text"
                          className="form-control dinheiro"
                          id="valorVenda"
                          placeholder="R$ 0,00"
                          onKeyUp={(e) => valorVendaKey(e)}
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="valorVenda"
                        >
                          Pagto total THAIMOTORS (Informativo)
                        </label>
                        <input
                          type="text"
                          className="form-control dinheiro"
                          id="valorPagtoInformativo"
                          placeholder="R$ 0,00"
                          readOnly={true}
                          style={{ backgroundColor: "#ccc" }}
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="pagto_compra"
                        >
                          Data Pagamento *
                        </label>
                        <input
                          value={form.pagto_compra}
                          name="pagto_compra"
                          onChange={handleChange}
                          type="date"
                          className="form-control"
                          id="pagto_compra"
                          placeholder="01/01/21"
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="data_entrega"
                        >
                          Data da Entrega do Veiculo
                        </label>
                        <input
                          value={form.data_entrega}
                          name="data_entrega"
                          onChange={handleChange}
                          type="date"
                          className="form-control"
                          id="data_entrega"
                          placeholder="01/01/21"
                        />
                      </div>


                      {/* <div className="col-xl-4 col-lg-4 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="pagtoAuto"
                        >
                          Pagamento Thai Motors *
                        </label>
                        <input
                          name="pagto_auto"
                          type="text"
                          className="form-control dinheiro"
                          id="pagtoAuto"
                          placeholder="R$ 0,00"
                          required={true}
                        />
                      </div> */}

                      <div className="col-xl-4 col-lg-4 col-12 form-group d-none">
                        <label
                          className="label-form-input"
                          htmlFor="pagtoFornecedor"
                        >
                          Pagamento Fornecedor *
                        </label>
                        <input
                          name="pagto_fornecedor"
                          type="text"
                          className="form-control dinheiro"
                          id="pagtoFornecedor"
                          placeholder="R$ 0,00"
                        />
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* terceiros */}
            {isLoading ? ("") :
              (
                <div
                  className=""
                  style={{
                    paddingBottom: "30px",
                    border: "none",
                    background: "initial",
                  }}
                >

                  <SelectParcautoEdit
                    oldParcauto={oldParcauto}
                    newParcauto={newParcauto}
                    setNewParcauto={setNewParcauto}
                    setoldParcauto={setoldParcauto}
                    setForm={setForm}
                    form={form}
                    setSelectedOptionUserParcauto={setSelectedOptionUserParcauto}
                    setSelectedEditOptionUserParcauto={setSelectedEditOptionUserParcauto}
                  />
                </div>
              )
            }

            {/* comissoes */}
            {isLoading ? ("") :
              (
                <div
                  className=""
                  style={{
                    paddingBottom: "30px",
                    border: "none",
                    background: "initial",
                  }}
                >

                  <SelectComissionEdit
                    oldComissao={oldComissao}
                    newComissao={newComissao}
                    setNewComissao={setNewComissao}
                    setoldComissao={setoldComissao}
                    setForm={setForm}
                    form={form}
                    setSelectedOptionUser={setSelectedOptionUser}
                  />
                </div>
              )
            }

            {isLoading ? ("") : (
              <div
                className=""
                style={{
                  paddingBottom: "30px",
                  border: "none",
                  background: "initial",
                }}
              >
                <div className="card-body">
                  <div className="heading-layout1 mt-2 mb-4">
                    <div className="item-title">
                      <h3>Dados Faturamento</h3>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label className="label-form-input" htmlFor="inputCarNovo">
                        Tipo de pessoa
                      </label>
                      <Select
                        options={BOOL_OPTIONS}
                        onOptionChange={onNovoUsadoChange}
                        getOptionValue={getNovoUsadoValue}
                        getOptionLabel={getNovoUsadoLabel}
                        addClassNames={true}
                        placeholder="Selecione"
                        initialValue={pessoaDadosFat}
                      />
                    </div>

                    {SelectednewUsed?.name === "Fisica" ||
                      SelectednewUsed?.name === undefined ? (
                      <>
                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                          <label
                            className="label-form-input"
                            htmlFor="exampleInputEmail1"
                          >
                            Nome
                          </label>
                          <input
                            value={form.nome}
                            name="nome"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Nome"
                          />
                        </div>

                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                          <label
                            className="label-form-input"
                            htmlFor="exampleInputPassword1"
                          >
                            RG
                          </label>
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                            ]}
                            guide={false}
                            value={form.rg}
                            name="rg"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="RG"
                          />
                        </div>
                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                          <label
                            className="label-form-input"
                            htmlFor="exampleInputPassword1"
                          >
                            CPF
                          </label>
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\w/,
                              /\w/,
                            ]}
                            guide={false}
                            value={form.cpf}
                            name="cpf"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="CPF"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                          <label
                            className="label-form-input"
                            htmlFor="exampleInputEmail1"
                          >
                            Nome contato
                          </label>
                          <input
                            value={form.nome_contato}
                            name="nome_contato"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Nome"
                          />
                        </div>

                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                          <label
                            className="label-form-input"
                            htmlFor="exampleInputEmail1"
                          >
                            Razão Social
                          </label>
                          <input
                            value={form.razao_social}
                            name="razao_social"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder="Razão Social"
                          />
                        </div>

                        <div className="col-xl-3 col-lg-6 col-12 form-group">
                          <label
                            className="label-form-input"
                            htmlFor="exampleInputPassword1"
                          >
                            CNPJ
                          </label>
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "/",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                            ]}
                            guide={false}
                            value={form.cnpj}
                            name="cnpj"
                            onChange={handleChange}
                            type="text"
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="CNPJ"
                          />
                        </div>
                      </>
                    )}

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail"
                      >
                        Email
                      </label>
                      <input
                        value={form.email}
                        name="email"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail"
                        placeholder="Email"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputPassword1"
                      >
                        Telefone
                      </label>
                      <MaskedInput
                        mask={[
                          "(",
                          /\d/,
                          /\d/,
                          ")",
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={false}
                        value={form.telefone}
                        name="telefone"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Telefone"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputPassword1"
                      >
                        Celular
                      </label>
                      <MaskedInput
                        mask={[
                          "(",
                          /\d/,
                          /\d/,
                          ")",
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        guide={false}
                        value={form.celular}
                        name="celular"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Celular"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        CEP
                      </label>
                      <input
                        value={form.cep}
                        name="cep"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="CEP"
                        onBlur={(ev) => onBlurCep(ev)}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Rua
                      </label>
                      <input
                        value={form.rua}
                        name="rua"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Rua"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label className="label-form-input" htmlFor="endNumero">
                        Numero
                      </label>
                      <input
                        value={form.numero}
                        name="numero"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="endNumero"
                        placeholder="Numero"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Complemento
                      </label>
                      <input
                        value={form.complemento}
                        name="complemento"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Complemento"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Bairro
                      </label>
                      <input
                        value={form.bairro}
                        name="bairro"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="bairro"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Cidade
                      </label>
                      <input
                        value={form.cidade}
                        name="cidade"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Cidade"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Estado
                      </label>
                      <input
                        value={form.estado}
                        name="estado"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        placeholder="Sigla UF"
                        maxLength={2}
                      />
                    </div>


                    <div className="col-xl-6 col-lg-6 col-12 form-group">
                      <label className="label-form-input" htmlFor="alienado">
                        Alienação
                      </label>
                      <input
                        value={form.alienado}
                        name="alienado"
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        id="alienado"
                        placeholder="Alienação"
                      />
                    </div>

                    <div className="col-12 form-group mg-t-8">
                      <button
                        type="submit"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                        id="btn_submit"
                        onClick={() => setBtnEmail("fatSave")}
                      >
                        Enviar Dados
                      </button>
                    </div>
                  </div>


                </div>
              </div>
            )}

            {isLoading ? (
              ""
            ) : (
              <div
                className=""
                style={{
                  paddingBottom: "30px",
                  border: "none",
                  background: "initial",
                }}
              >
                <div className="card-body">
                  <div className="heading-layout1 mt-2 mb-4">
                    <div className="item-title">
                      <h3>Dados Motorista</h3>
                    </div>
                  </div>
                  <div className="new-added-form">
                    <div className="row">

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Nome
                        </label>
                        <input
                          value={form.nome_motorista}
                          name="nome_motorista"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Nome "
                        />
                      </div>

                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          RG
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            ".",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                          ]}
                          guide={false}
                          value={form.rg_motorista}
                          name="rg_motorista"
                          onChange={handleChange}
                          type="text"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="RG"
                        />
                      </div>


                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Data Retira
                        </label>
                        <input
                          value={form.data_retira}
                          name="data_retira"
                          onChange={handleChange}
                          type="date"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Data"
                        />
                      </div>


                      <div className="col-xl-3 col-lg-3 col-12 form-group">
                        <label
                          className="label-form-input"
                          htmlFor="exampleInputEmail1"
                        >
                          Hora
                        </label>
                        <input
                          value={form.hora_retira}
                          name="hora_retira"
                          onChange={handleChange}
                          type="time"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Hora"
                        />
                      </div>

                      <div className="col-12 form-group mg-t-8">
                        <button
                          type="submit"
                          className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                          id="btn_submit"
                          onClick={() => setBtnEmail("libSave")}
                        >
                          Enviar Dados
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isLoading ? (
              ""
            ) : (
              <div
                className=""
                style={{
                  paddingBottom: "30px",
                  border: "none",
                  background: "initial",
                }}
              >
                <div className="card-body">
                  <div className="heading-layout1 mt-2 mb-4">
                    <div className="item-title">
                      <h3>Observações</h3>
                    </div>
                  </div>
                  <div className="new-added-form">
                    <div className="row">


                      <div className="col-xl-12 col-lg-12 col-12 form-group">

                        <Editor
                          editorState={editorState1}
                          toolbarClassName="toolbar__className"
                          wrapperClassName="wrapper__className"
                          editorClassName="editor__className"
                          onEditorStateChange={setEditorState1}
                          stripPastedStyles={true}
                          toolbar={{
                            options: ['inline', 'textAlign'],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ['bold', 'italic', 'underline'],
                            },
                          }}

                        />

                        <input
                          //@ts-ignore
                          value={(form.observacoes = editorState1)}
                          name="observacoes"
                          onChange={handleChange}
                          type="hidden"
                          className="form-control"
                          id="testeAnoModelo"
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <ToastContainer autoClose={8000} position="top-right" />
          </form>

        </div >
      </div >
    </div >
  );
};

export default EditContratoVenda;
