import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import { IUserLoja } from "../../store/ducks/user/types";
import {
  userProfileRequest,
  userLojaRequest,
} from "../../store/ducks/user/actions";

import { putClientRequest } from "../../store/ducks/client/actions";
import jwt from "jwt-decode";
import MaskedInput from "react-text-mask";
import { ToastContainer, toast } from "react-toastify";

// import update from "immutability-helper";
// import cuid from "cuid";

const EditarPerfil: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [images, setImages] = useState([] as any);

  // const onDrop = useCallback((acceptedFiles) => {
  //   let handleDropImages;
  //   if (acceptedFiles.length === 0) {
  //     return;
  //   } else if (acceptedFiles.length > 5) {
  //     // here i am checking on the number of files
  //     return toast.error("maxImages"); // here i am using react toaster to get some notifications. don't need to use it
  //   } else {
  //     // do what ever you want
  //   }

  //   acceptedFiles.map((file: any) => {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       setImages((prevState: any) => [
  //         ...prevState,
  //         { id: cuid(), src: e.target?.result },
  //       ]);
  //     };
  //     reader.readAsDataURL(file);
  //     return file;
  //   });
  // }, []);

  // const moveImage = (dragIndex: any, hoverIndex: any) => {
  //   const draggedImage = images[dragIndex];

  //   setImages(
  //     update(images, {
  //       $splice: [
  //         [dragIndex, 1],
  //         [hoverIndex, 0, draggedImage],
  //       ],
  //     })
  //   );
  // };

  // const removeFile = (image: any) => () => {
  //   const newFiles = [...images];
  //   newFiles.splice(newFiles.indexOf(image), 1);
  //   setImages(newFiles);
  // };

  // const returnApi: any = useSelector<ApplicationState>((state) => state.client);

  const view = useSelector<ApplicationState, IUserLoja>(
    (state) => state.user.userLoja
  );

  const [form, setForm] = useState({
    email: "",
    cnpj: "",
    whatsapp: "",
    nome: "",
    type: "",
    cep: "",
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
    endNumero: "",
    logo: "",
    telefone: ""
  });

  const getFile = useCallback(() => {
    setForm({
      email: view.email,
      cnpj: view.cnpj,
      whatsapp: view.whatsapp,
      telefone: view.telefone || "",
      nome: view.nome,
      type: view.type,
      logo: view.logo || "",
      cep: view.cep || "",
      logradouro: view.logradouro || "",
      complemento: view.complemento || "",
      bairro: view.bairro || "",
      localidade: view.localidade || "",
      uf: view.uf || "",
      endNumero: view.endNumero || "",
    });
  }, [
    view.email,
    view.cnpj,
    view.whatsapp,
    view.nome,
    view.type,
    view.cep,
    view.localidade,
    view.logradouro,
    view.bairro,
    view.uf,
    view.endNumero,
    view.complemento,
    view.telefone,
  ]);

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const {
        cnpj,
        whatsapp,
        nome,
        type,
        cep,
        logradouro,
        complemento,
        bairro,
        localidade,
        uf,
        endNumero,
        logo,
        email,
        telefone
      } = form;

      let btn = document.getElementById("btn_submit");
      if (btn) {
        btn.setAttribute("disabled", "{true}");
      }

      if (view.email) {
        dispatch(
          putClientRequest({
            id: view.id,
            nome,
            email,
            cnpj,
            whatsapp,
            type,
            cep,
            logradouro,
            complemento,
            bairro,
            localidade,
            uf,
            endNumero,
            logo,
            telefone
          })
        );
        toast.success("Dados alterado com sucesso");
        setTimeout(() => history.push("/editar-loja"), 2000);
      }
    },
    [dispatch, form, view, history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const getLoja = useCallback(
    (event) => {
      dispatch(userLojaRequest(event));
    },
    [dispatch]
  );

  function onBlurCep(ev: any) {
    const { value } = ev.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setForm({
          logo: form.logo,
          email: form.email,
          cnpj: form.cnpj,
          whatsapp: form.whatsapp,
          nome: form.nome,
          type: form.type,
          cep: data.cep,
          logradouro: data.logradouro,
          complemento: data.complemento,
          bairro: data.bairro,
          localidade: data.localidade,
          uf: data.uf,
          endNumero: form.endNumero,
          telefone: form.telefone
        });
      });
  }

  // const renderImageState = useCallback(() => {
  //   const arrayFotos = [{ id: view.id, src: view.logo }];

  //   if (view.logo !== "") {
  //     setImages(arrayFotos);

  //   }
  // }, [view.id, form.logo]);

  // const test = images.map((a: any) => a.src);
  useEffect(() => {
    // renderImageState();
    getProfile();
    getFile();
    getLoja("1");
  }, [getProfile, getFile, getLoja]);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Alterar Perfil Loja</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Alterar Perfil Loja</li>
            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Alterar Perfil Loja</h3>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputNomePerfil"
                    >
                      Nome
                    </label>
                    <input
                      value={form.nome}
                      name="nome"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputNomePerfil"
                      placeholder="Nome"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputNomePerfil"
                    >
                      Email
                    </label>
                    <input
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputNomePerfil"
                      placeholder="Email"

                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputWhatsPerfil"
                    >
                      Telefone 1
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      value={form.telefone}
                      name="telefone"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputWhatsPerfil"
                      placeholder="Telefone"
                      required={true}
                    />
                  </div>


                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputWhatsPerfil"
                    >
                      Whatsapp
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/, 
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      value={form.whatsapp}
                      name="whatsapp"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputWhatsPerfil"
                      placeholder="WhatsApp"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="inputWhatsPerfil"
                    >
                      CNPJ
                    </label>
                    <MaskedInput
                      mask={[
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        ".",
                        /\d/,
                        /\d/,
                        /\d/,
                        "/",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                      ]}
                      value={form.cnpj}
                      name="cnpj"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="inputWhatsPerfil"
                      placeholder="CNPJ"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      CEP
                    </label>
                    <input
                      value={form.cep}
                      name="cep"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="CEP"
                      onBlur={(ev) => onBlurCep(ev)}
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Rua
                    </label>
                    <input
                      value={form.logradouro}
                      name="logradouro"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Rua"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Bairro
                    </label>
                    <input
                      value={form.bairro}
                      name="bairro"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="bairro"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Cidade
                    </label>
                    <input
                      value={form.localidade}
                      name="localidade"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Cidade"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Estado
                    </label>
                    <input
                      value={form.uf}
                      name="uf"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Sigla Estado"
                      required={true}
                      maxLength={2}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="endNumero">
                      Numero
                    </label>
                    <input
                      value={form.endNumero}
                      name="endNumero"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="endNumero"
                      placeholder="Numero"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Complemento
                    </label>
                    <input
                      value={form.complemento}
                      name="complemento"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Complemento"

                    />
                  </div>



                  <div className="col-12 form-group mg-t-8">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      Alterar
                    </button>
                  </div>
                  <ToastContainer autoClose={5000} position="top-right" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditarPerfil;
