import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { putPaymentFinancialRequest } from "../../store/ducks/financial/actions";


const CheckboxFin = (props: any) => {
  const { statusPago, onChangeDispatch, userLogged } = props;
  const dispatch = useDispatch();

  const pago = statusPago === "Nao" ? false : true;
  const permissionLogin = userLogged === "super_admin" ? false : true;

  const [checked, setChecked] = useState(pago);
  const handleChange = (nextChecked: any) => {
    setChecked(nextChecked);

    const id = onChangeDispatch;
    const pago: any = nextChecked === true ? "Sim" : "Nao";
    dispatch(putPaymentFinancialRequest({
      id,
      pago
    }));
    setTimeout(() => window.location.reload(), 700);
  };



  return (
    <div className="example ajustCheckbox--financeiro">
      <label>
        <Switch
          onChange={handleChange}
          checked={checked}
          offColor="#EC5D3E"
          uncheckedIcon={false}
          checkedIcon={false}
          className="react-switch"
          disabled={permissionLogin}
        />
      </label>
    </div>
  );
};

export default CheckboxFin;
