export enum ContractsTypes {
  CONTRACTS_REQUEST = "@contracts/CONTRACTS_REQUEST",
  CONTRACTS_SUCCESS = "@contracts/CONTRACTS_SUCCESS",
  CONTRACTS_FAILURE = "@contracts/CONTRACTS_FAILURE",
  POST_CONTRACTS_REQUEST = "@contracts/POST_CONTRACTS_REQUEST",
  POST_CONTRACTS_SUCCESS = "@contracts/POST_CONTRACTS_SUCCESS",
  POST_CONTRACTS_FAILURE = "@contracts/POST_CONTRACTS_FAILURE",
  PUT_CONTRACTS_REQUEST = "@contracts/PUT_CONTRACTS_REQUEST",
  PUT_CONTRACTS_SUCCESS = "@contracts/PUT_CONTRACTS_SUCCESS",
  PUT_CONTRACTS_FAILURE = "@contracts/PUT_CONTRACTS_FAILURE",
  VIEW_CONTRACTS = "@contracts/VEIW_CONTRACTS",
  CLEAN_VIEW_CONTRACTS = "@contracts/CLEAN_VIEW_CONTRACTS",
  DELETE_CONTRACTS_REQUEST = "@contracts/DELETE_CONTRACTS_REQUEST",
  DELETE_CONTRACTS_SUCCESS = "@contracts/DELETE_CONTRACTS_SUCCESS",
  DELETE_CONTRACTS_FAILURE = "@contracts/DELETE_CONTRACTS_FAILURE",

  CLEAN_CONTRACTS_RESPONSE = "@contracts/CLEAN_CONTRACTS_RESPONSE",
}

export interface IpostContracts {
  banco?: any;
  agencia?: any;
  conta?: any;
  documento_banco?: any;
  contato?: any;
  documentar_em?: any;
  valor_nota?: any;
  valor_compra?: any;
  pagto_compra?: any;
  data_entrega?: any;
  valor_venda?: any;
  data_venda?: any;
  pagto_auto?: any;
  pagto_fornecedor?: any;
  nome?: any;
  nome_contato?: any;
  email?: any;
  documento_cliente?: any;
  telefone?: any;
  celular?: any;
  rg?: any;
  cpf?: any;
  cnpj?: any;
  razao_social?: any;
  cep?: any;
  rua?: any;
  numero?: any;
  complemento?: any;
  bairro?: any;
  cidade?: any;
  estado?: any;
  nome_motorista?: any;
  rg_motorista?: any;
  data_retira?: any;
  hora_retira?: any;
  observacoes?: any;
  file_compra?: any;
  file_venda?: any;
  fk_veiculo?: any;
  fk_fornecedor?: any;
  fk_banco?: any;
  fk_endereco?: any;
  fk_cliente?: any;
  client_type?: any;
  btnEmail?: any;
  numero_banco?: any;
  comissao_new?: any;
  fk_bancoAuto?: any;
  favorecido?: string;
  pix_banco?: string;
  terceiro_new?: any;
  parcauto_new?: any;
  profileEmail?: any;
  profileWhats?: any;
  profileNome?: any;
  comissao_old?: any;
  terceiro_old?: any;
  parcauto_old?: any;
  alienado?: any;
}

export interface IputContracts {
  id: string;
  banco?: any;
  agencia?: any;
  conta?: any;
  documento_banco?: any;
  contato?: any;
  documentar_em?: any;
  valor_nota?: any;
  valor_compra?: any;
  pagto_compra?: any;
  data_entrega?: any;
  valor_venda?: any;
  data_venda?: any;
  pagto_auto?: any;
  pagto_fornecedor?: any;
  nome?: any;
  nome_contato?: any;
  email?: any;
  documento_cliente?: any;
  telefone?: any;
  celular?: any;
  rg?: any;
  cpf?: any;
  cnpj?: any;
  razao_social?: any;
  cep?: any;
  rua?: any;
  numero?: any;
  complemento?: any;
  bairro?: any;
  cidade?: any;
  estado?: any;
  nome_motorista?: any;
  rg_motorista?: any;
  data_retira?: any;
  hora_retira?: any;
  observacoes?: any;
  file_compra?: any;
  file_venda?: any;
  fk_veiculo?: any;
  fk_fornecedor?: any;
  fk_banco?: any;
  fk_endereco?: any;
  fk_cliente?: any;
  client_type?: any;
  btnEmail?: any;
  numero_banco?: any;
  comissao_old?: any;
  comissao_new?: any;
  fk_bancoAuto?: any;
  favorecido?: string;
  pix_banco?: string;
  terceiro_old?: any;
  terceiro_new?: any;
  parcauto_old?: any;
  parcauto_new?: any;
  profileEmail?: any;
  profileWhats?: any;
  profileNome?: any;
  alienado?: any;
}

export interface IClinetContract {
  nome?: string;
  RG?: string;
  CPF?: string;
  nome_contato?: string;
  razao_social?: string;
  CNPJ?: string;
  telefone?: string;
  celular?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
  fk_loja: string;
  fk_user: string;
  email: string;
}



interface ICarro {
  id: string;
  nome?: string;
  descritivo?: string;
  ano_modelo?: any;
  ano_contrato?: any;
  preco_custo: any;
  preco_fipe: any;
  preco_venda: any;
  chassi?: any;
  marca?: any;
  faturamento?: any;
  fk_marca: any;
  fk_modelo: any;
  status?: any;
  fk_cor: any;
  fk_cambio: any;
  fk_combustivel: any;
  ano_fab?: any;
  fk_fornecedor?: any;
  custom_id?: any;
}

interface IAddress {
  id?: string;
  CEP?: string;
  rua?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  complemento?: string;
  estado?: string;
}

export interface IComission1 {
  id: string;
  nome: string;
  data_lancamento: string;
  valor: string;
}


export interface IContracts {
  id: string;
  banco?: any;
  agencia?: any;
  conta?: any;
  documento_banco?: any;
  contato?: any;
  documentar_em?: any;
  valor_nota?: any;
  valor_compra?: any;
  pagto_compra?: any;
  data_entrega?: any;
  valor_venda?: any;
  data_venda?: any;
  pagto_auto?: any;
  pagto_fornecedor?: any;
  nome?: any;
  nome_contato?: any;
  email?: any;
  documento_cliente?: any;
  telefone?: any;
  celular?: any;
  rg?: any;
  cpf?: any;
  cnpj?: any;
  razao_social?: any;
  cep?: any;
  rua?: any;
  numero?: any;
  complemento?: any;
  bairro?: any;
  cidade?: any;
  estado?: any;
  nome_motorista?: any;
  rg_motorista?: any;
  data_retira?: any;
  hora_retira?: any;
  observacoes?: any;
  file_compra?: any;
  file_venda?: any;
  fk_veiculo?: any;
  fk_fornecedor?: any;
  fk_banco?: any;
  fk_endereco?: any;
  fk_cliente?: any;
  client_type?: any;
  client_data?: IClinetContract;
  carro?: ICarro;
  endereco?: IAddress;
  numero_banco?: IContracts;
  comissao?: [{
    id: string;
    nome: string;
    data_lancamento: string;
    valor: string;
  }];
  terceiro?: [{
    id: string;
    nome: string;
    data_lancamento: string;
    valor: string;
    pdf: string;
    fk_banco: string;
  }];
  pagtoParcauto?: [{
    id: string;
    valor: string;
    fk_banco: string;
  }];
  fk_bancoAuto?: any;
  favorecido?: string;
  pix_banco?: string;
  alienado?: any;
}

export interface IResponse {
  code: string;
  message: string;
}


export interface ContractsState {
  readonly data: IContracts[];
  readonly view: IContracts;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
