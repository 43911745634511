import { action } from "typesafe-actions";
import { FuelTypes, IFuel, IpostFuel, IputFuel } from "./types";
import { IResponse } from "../auth/types";

export const fuelRequest = (paremtros: any) =>
  action(FuelTypes.FUEL_REQUEST, {paremtros});

export const fuelSuccess = (data: IFuel ) =>
  action(FuelTypes.FUEL_SUCCESS, { data });

export const fuelFailure = () => action(FuelTypes.FUEL_FAILURE, {});

export const postFuelRequest = (Ipostfuel: IpostFuel) =>
  action(FuelTypes.POST_FUEL_REQUEST, {Ipostfuel});

export const postFuelSuccess = (data: IResponse) =>
  action(FuelTypes.POST_FUEL_SUCCESS, {data});

export const postFuelFailure = (data: IResponse) => action(FuelTypes.POST_FUEL_FAILURE, {data});

export const viewFuel = (view: IFuel) =>
  action(FuelTypes.VIEW_FUEL, {view});

export const cleanViewFuel = () =>
  action(FuelTypes.CLEAN_VIEW_FUEL, {})

export const putFuelRequest = (Iputfuel: IputFuel) =>
  action(FuelTypes.PUT_FUEL_REQUEST, {Iputfuel});

export const putFuelSuccess = () =>
  action(FuelTypes.PUT_FUEL_SUCCESS, {});

export const putFuelFailure = () => action(FuelTypes.PUT_FUEL_FAILURE, {});

export const deleteFuelRequest = (id: string) =>
  action(FuelTypes.DELETE_FUEL_REQUEST, {id});

export const deleteFuelSuccess = () =>
  action(FuelTypes.DELETE_FUEL_SUCCESS, {});

export const deleteFuelFailure = () => action(FuelTypes.DELETE_FUEL_FAILURE, {});

