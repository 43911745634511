import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { FinancialTypes } from "./types";
import {
  financialRequest,
  financialSuccess,
  financialFailure,
  postFinancialRequest,
  postFinancialSuccess,
  postFinancialFailure,
  putFinancialRequest,
  putFinancialSuccess,
  putFinancialFailure,
  deleteFinancialRequest,
  deleteFinancialSuccess,
  deleteFinancialFailure,
  putPaymentFinancialRequest,
  putPaymentFinancialSuccess,
  putPaymentFinancialFailure,
} from "./actions";

function* getFinancial({ payload }: ReturnType<typeof financialRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/lancamentos" + params, auth);


    yield put(financialSuccess(response.data.params.data));
  } catch (error) {
    yield put(financialFailure());
  }
}

function* postFinancial({ payload }: ReturnType<typeof postFinancialRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostFinancial = payload.ipostFinancial;

    const response = yield call(api.post, "/lancamentos", ipostFinancial, auth);

    yield put(postFinancialSuccess(response.data.response));
  } catch (error) {
    yield put(postFinancialFailure({ ...error.response.data.response }));
  }
}

function* putFinancial({ payload }: ReturnType<typeof putFinancialRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputFinancial = payload.iputFinancial;

    const id = payload.iputFinancial.id;

   const response = yield call(api.put, "/lancamentos/" + id, iputFinancial, auth);

    yield put(putFinancialSuccess(response.data.response));
  } catch (error) {
    yield put(putFinancialFailure({ ...error.response.data.response }));
  }
}

function* putPaymentFinancial({ payload }: ReturnType<typeof putPaymentFinancialRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputPaymentFinancial = payload.iputPaymentFinancial;

    const id = payload.iputPaymentFinancial.id;

   const response = yield call(api.put, "/lancamentos/pago/" + id, iputPaymentFinancial, auth);

    yield put(putPaymentFinancialSuccess(response.data.response));
  } catch (error) {
    yield put(putPaymentFinancialFailure({ ...error.response.data.response }));
  }
}

function* deleteFinancial({ payload }: ReturnType<typeof deleteFinancialRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/lancamentos/" + id, auth);

    yield put(deleteFinancialSuccess());
  } catch (error) {
    yield put(deleteFinancialFailure());
  }
}

export default all([
  takeLatest(FinancialTypes.FINANCIAL_REQUEST, getFinancial),
  takeLatest(FinancialTypes.POST_FINANCIAL_REQUEST, postFinancial),
  takeLatest(FinancialTypes.PUT_FINANCIAL_REQUEST, putFinancial),
  takeLatest(FinancialTypes.DELETE_FINANCIAL_REQUEST, deleteFinancial),
  takeLatest(FinancialTypes.PUTPAYMENT_FINANCIAL_REQUEST, putPaymentFinancial),
]);
