import React from "react";
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

import { ApplicationState } from "../store";

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isRestrict?: boolean;
  component: any;
}
const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isRestrict = false,
  component: Component,
  ...rest
}) => {
  const signed = useSelector<ApplicationState>((state) => state.auth.signed);

  const validLogin = (event: any) => {
    if (isPrivate === signed) {
     return <Component />;
    } else if(isRestrict) {
      return <Component />;
    }
     else {
     return <Redirect
        exact
        to={{
          pathname: isPrivate ? "/" : "/carros",
          state: { from: event },
        }}
      />;
    }
  };

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return validLogin(location)
      }}
    />
  );
};

export default Route;
