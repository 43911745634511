/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../store";
import { Link } from "react-router-dom";
import { ICar } from "../../store/ducks/car/types";
import { IProfileUser, IUserLoja } from "../../store/ducks/user/types";
import { userLojaRequest } from "../../store/ducks/user/actions";

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const carrosEstoque: any = useSelector<ApplicationState, ICar[]>(
    (state) => state.car.data
  );

  localStorage.removeItem("carro");

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );

  const view = useSelector<ApplicationState, IUserLoja>(
    (state) => state.user.userLoja
  );

  const getLoja = useCallback(
    (event) => {
      dispatch(userLojaRequest(event));
    },
    [dispatch]
  );

  useEffect(() => {
    if (profileUser.fk_loja) {
      getLoja(profileUser.fk_loja);
    }
  }, [profileUser.fk_loja, getLoja]);

  localStorage.setItem("typeCliente", view.type);

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Dashboard</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Dashboard</li>
            </ul>
          </div>

          <div className="row gutters-20">
            {/* <div className="col-xl-3 col-sm-6 col-12">
              <Link to="carros-0km">
                <div className="dashboard-summery-one mg-b-20 cards__dashboardIntro">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <i className="fas fa-car"></i>
                    </div>
                    <div className="col-8">
                      <div className="item-content">
                        <div className="item-title cardDashboard__title">
                          Bolsa 0km
                        </div>
                        <div className="item-number cardDashboard__number">
                          <span className="counter" data-num="150000">
                            {carrosZero.length}
                          </span>
                        </div>
                        <div className="item-title cardDashboard__footer">
                          Carros
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-3 col-sm-6 col-12">
              <Link to="repasses">
                <div className="dashboard-summery-one mg-b-20 cards__dashboardIntro">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <i className="fas fa-sync-alt"></i>
                    </div>
                    <div className="col-8">
                      <div className="item-content">
                        <div className="item-title cardDashboard__title">
                          Repasses
                        </div>
                        <div className="item-number cardDashboard__number">
                          <span className="counter" data-num="150000">
                            {carros.length}
                          </span>
                        </div>
                        <div className="item-title cardDashboard__footer">
                          Carros
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {view.type === "fornecedor" || view.type === "fornecedor2" || view.type ===  "bolsa0km" ? (
              <div className="col-xl-3 col-sm-6 col-12">
                <Link to="carros">
                  <div className="dashboard-summery-one mg-b-20 cards__dashboardIntro">
                    <div className="row align-items-center">
                      <div className="col-4">
                        <i className="fas fa-car-side"></i>
                      </div>
                      <div className="col-8">
                        <div className="item-content">
                          <div className="item-title cardDashboard__title">
                            Estoque
                          </div>
                          <div className="item-number cardDashboard__number">
                            <span className="counter" data-num="150000">
                              {carrosEstoque.length}
                            </span>
                          </div>
                          <div className="item-title cardDashboard__footer">
                            Carros
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              ""
            )}
            {/* <div className="col-xl-3 col-sm-6 col-12">
              <Link to="cadastro-repasse">
                <div className="dashboard-summery-one mg-b-20 cards__dashboardIntro">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <i className="fas fa-edit"></i>
                    </div>
                    <div className="col-8">
                      <div className="item-content">
                        <div className="item-title cardDashboard__title--repasse">
                          Cadastrar
                        </div>
                        <div className="item-number cardDashboard__number">
                          <span
                            className="counter"
                            style={{ fontSize: "13px" }}
                          >
                            Repasse
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* final */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
