import { Reducer } from "redux";
import producer from "immer";

import { GearshiftState, GearshiftTypes } from "./types";

const INITIAL_STATE: GearshiftState = {
  data: [],
  view: {
    id: "",
    descricao: "",
  },
  response: {
    code: "",
    message: ""
  },
  loading: true,
  error: false,
};


const reducer: Reducer<GearshiftState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GearshiftTypes.GEARSHIFT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GearshiftTypes.GEARSHIFT_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case GearshiftTypes.GEARSHIFT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

      case GearshiftTypes.POST_GEARSHIFT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GearshiftTypes.POST_GEARSHIFT_SUCCESS:
        return producer(state, (draft) => {
          draft.loading = false;
          draft.response = action.payload.data;
        });
      case GearshiftTypes.POST_GEARSHIFT_FAILURE:
        return producer(state, (draft) => {
          draft.response = action.payload.data;
          draft.loading = false;
        });
    case GearshiftTypes.VIEW_GEARSHIFT:
      return {
        ...state,
        view: action.payload.view,
      };
    case GearshiftTypes.CLEAN_VIEW_GEARSHIFT:
      return {
        ...state,
        view: {
          id: "",
          descricao: "",
        }
      };
    case GearshiftTypes.PUT_GEARSHIFT_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case GearshiftTypes.PUT_GEARSHIFT_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.view = {
          id: "",
          descricao: "",
        };
      });
    case GearshiftTypes.PUT_GEARSHIFT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
