import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ExpensesTypes } from "./types";
import {
  expensesRequest,
  expensesSuccess,
  expensesFailure,
  postExpensesRequest,
  postExpensesSuccess,
  postExpensesFailure,
  putExpensesRequest,
  putExpensesSuccess,
  putExpensesFailure,
  deleteExpensesRequest,
  deleteExpensesSuccess,
  deleteExpensesFailure,
} from "./actions";

function* getExpenses({ payload }: ReturnType<typeof expensesRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/despesas" + params, auth);

    yield put(expensesSuccess(response.data.params.data));
  } catch (error) {
    yield put(expensesFailure());
  }
}

function* postExpenses({ payload }: ReturnType<typeof postExpensesRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostExpenses = payload.ipostExpenses;

    const response = yield call(api.post, "/despesas", ipostExpenses, auth);

    yield put(postExpensesSuccess(response.data.response));
  } catch (error) {
    yield put(postExpensesFailure({ ...error.response.data.response }));
  }
}

function* putExpenses({ payload }: ReturnType<typeof putExpensesRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputExpenses = payload.iputExpenses;

    const id = payload.iputExpenses.id;

    const response = yield call(api.put, "/despesas/" + id, iputExpenses, auth);

    yield put(putExpensesSuccess(response.data.response));
  } catch (error) {
    yield put(putExpensesFailure({ ...error.response.data.response }));
  }
}

function* deleteExpenses({ payload }: ReturnType<typeof deleteExpensesRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/despesas/" + id, auth);

    yield put(deleteExpensesSuccess());
  } catch (error) {
    yield put(deleteExpensesFailure());
  }
}

export default all([
  takeLatest(ExpensesTypes.EXPENSES_REQUEST, getExpenses),
  takeLatest(ExpensesTypes.POST_EXPENSES_REQUEST, postExpenses),
  takeLatest(ExpensesTypes.PUT_EXPENSES_REQUEST, putExpenses),
  takeLatest(ExpensesTypes.DELETE_EXPENSES_REQUEST, deleteExpenses),
]);
