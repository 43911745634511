import { Reducer } from "redux";
import producer from "immer";

import { AuthState, AuthTypes } from "./types";

const INITIAL_STATE: AuthState = {
  data: {
    params: { token: "" },
  },
  response: {
    code: "",
    message: "",
  },
  signed: false,
  loading: false,
  error: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.AUTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AuthTypes.AUTH_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.data = action.payload.data;
        draft.signed = true;
      });
    case AuthTypes.AUTH_FAILURE:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.signed = false;
      });
    case AuthTypes.SIGNOUT:
      return producer(state, (draft) => {
        draft.signed = false;
      });
    case AuthTypes.CLEAN_AUTH_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };

    default:
      return state;
  }
};

export default reducer;
