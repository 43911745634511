import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { ThirdsTypes } from "./types";
import {
  deleteBankFailure,
  deleteBankRequest,
  deleteBankSuccess,
  deleteThirdsFailure,
  deleteThirdsRequest,
  deleteThirdsSuccess,
  postThirdsFailure,
  postThirdsRequest,
  postThirdsSuccess,
  thirdsFailure,
  thirdsRequest,
  thirdsSuccess,
  putThirdsFailure,
  putThirdsRequest,
  putThirdsSuccess,
} from "./actions";

//client PF
function* getThirds({ payload }: ReturnType<typeof thirdsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.data;

    const response = yield call(api.get, "/terceiros" + params, auth);

    yield put(thirdsSuccess(response.data.params.data));
  } catch (error) {
    yield put(thirdsFailure());
  }
}

function* postThirds({ payload }: ReturnType<typeof postThirdsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostThirds = payload.ipostThirds;

    const response = yield call(api.post, "/terceiros", ipostThirds, auth);
    yield put(postThirdsSuccess(response.data.response));
  } catch (error) {
    yield put(
      postThirdsFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putThirds({ payload }: ReturnType<typeof putThirdsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputThirds = payload.iputThirds;

    const id = payload.iputThirds.id;

    const response = yield call(
      api.put,
      "/terceiros/" + id,
      iputThirds,
      auth
    );

    yield put(putThirdsSuccess(response.data.response));
  } catch (error) {
    yield put(putThirdsFailure());
  }
}

function* deleteThirds({
  payload,
}: ReturnType<typeof deleteThirdsRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/terceiros/" + id, auth);

    yield put(deleteThirdsSuccess());
  } catch (error) {
    yield put(deleteThirdsFailure());
  }
}

function* deleteBank({ payload }: ReturnType<typeof deleteBankRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/bancos-terceiro/" + id, auth);

    yield put(deleteBankSuccess());
  } catch (error) {
    yield put(deleteBankFailure());
  }
}

export default all([
  takeLatest(ThirdsTypes.THIRDS_REQUEST, getThirds),
  takeLatest(ThirdsTypes.POST_THIRDS_REQUEST, postThirds),
  takeLatest(ThirdsTypes.PUT_THIRDS_REQUEST, putThirds),
  takeLatest(ThirdsTypes.DELETE_THIRDS_REQUEST, deleteThirds),
  takeLatest(ThirdsTypes.DELETE_BANK_REQUEST, deleteBank),
]);
