export enum ColorTypes {
  COLOR_REQUEST = "@color/COLOR_REQUEST",
  COLOR_SUCCESS = "@color/COLOR_SUCCESS",
  COLOR_FAILURE = "@color/COLOR_FAILURE",
  POST_COLOR_REQUEST = "@color/POST_COLOR_REQUEST",
  POST_COLOR_SUCCESS = "@color/POST_COLOR_SUCCESS",
  POST_COLOR_FAILURE = "@color/POST_COLOR_FAILURE",
  PUT_COLOR_REQUEST = "@color/PUT_COLOR_REQUEST",
  PUT_COLOR_SUCCESS = "@color/PUT_COLOR_SUCCESS",
  PUT_COLOR_FAILURE = "@color/PUT_COLOR_FAILURE",
  VIEW_COLOR = "@color/VEIW_COLOR",
  CLEAN_VIEW_COLOR = "@color/CLEAN_VIEW_COLOR",
  DELETE_COLOR_REQUEST = "@color/DELETE_COLOR_REQUEST",
  DELETE_COLOR_SUCCESS = "@color/DELETE_COLOR_SUCCESS",
  DELETE_COLOR_FAILURE = "@color/DELETE_COLOR_FAILURE",
}

export interface IpostColor {
  nome:string;
}

export interface IputColor {
  id:string;
  nome:string;

}

export interface IColor {
  id:string;
  nome:string;
}

export interface IResponse {
  code: string;
  message: string;
}


export interface ColorState {
  readonly data: IColor[];
  readonly view: IColor;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
