import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { OptionalTypes } from "./types";
import {
  optionalSuccess,
  optionalFailure,
  postOptionalRequest,
  postOptionalSuccess,
  postOptionalFailure,
  putOptionalRequest,
  putOptionalSuccess,
  putOptionalFailure,
  deleteOptionalRequest,
  deleteOptionalSuccess,
  deleteOptionalFailure,
  optionalRequest,
} from "./actions";

function* getOptionals({ payload }: ReturnType<typeof  optionalRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const params = payload.paremtros

    const response = yield call(api.get, "/opcionais"+params, auth);

    yield put(optionalSuccess(response.data.params.data));
  } catch (error) {
    yield put(optionalFailure());
  }
}

function* postOptionals({ payload }: ReturnType<typeof postOptionalRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const ipostOptional = payload.Ipostoptional;

    const response = yield call(api.post, "/opcionais", ipostOptional, auth);

    yield put(postOptionalSuccess(response.data.response));
  } catch (error) {
    yield put(
      postOptionalFailure({
        ...error.response.data.response,
      })
    );
  }
}

function* putOptionals({ payload }: ReturnType<typeof putOptionalRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const iputoptional = payload.Iputoptional;

    const id = payload.Iputoptional.id;

    yield call(api.put, "/opcionais/" + id, iputoptional, auth);

    yield put(putOptionalSuccess());
  } catch (error) {
    yield put(putOptionalFailure());
  }
}

function* deleteOptionals({
  payload,
}: ReturnType<typeof deleteOptionalRequest>) {
  try {
    const auth = {
      headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
    };

    const id = payload.id;

    yield call(api.delete, "/opcionais/" + id, auth);

    yield put(deleteOptionalSuccess());
  } catch (error) {
    yield put(deleteOptionalFailure());
  }
}

export default all([
  takeLatest(OptionalTypes.OPTIONAL_REQUEST, getOptionals),
  takeLatest(OptionalTypes.POST_OPTIONAL_REQUEST, postOptionals),
  takeLatest(OptionalTypes.PUT_OPTIONAL_REQUEST, putOptionals),
  takeLatest(OptionalTypes.DELETE_OPTIONAL_REQUEST, deleteOptionals),
]);
