import { action } from "typesafe-actions";
import { PagtoThirdTypes, IPagtoThird, IpostPagtoThird, IputPagtoThird, IResponse } from "./types";

export const pagtoThirdRequest = (paremtros: any) =>
  action(PagtoThirdTypes.PAGTOTHIRD_REQUEST, { paremtros });

export const pagtoThirdSuccess = (data: IPagtoThird) =>
  action(PagtoThirdTypes.PAGTOTHIRD_SUCCESS, { data });

export const pagtoThirdFailure = () => action(PagtoThirdTypes.PAGTOTHIRD_FAILURE, {});

export const postPagtoThirdRequest = (ipostPagtoThird: IpostPagtoThird) =>
  action(PagtoThirdTypes.POST_PAGTOTHIRD_REQUEST, { ipostPagtoThird });

export const postPagtoThirdSuccess = (data: IResponse) =>
  action(PagtoThirdTypes.POST_PAGTOTHIRD_SUCCESS, { data });

export const postPagtoThirdFailure = (data: IResponse) => action(PagtoThirdTypes.POST_PAGTOTHIRD_FAILURE, { data });

export const viewPagtoThird = (view: IPagtoThird) =>
  action(PagtoThirdTypes.VIEW_PAGTOTHIRD, { view });

export const cleanViewPagtoThird = () =>
  action(PagtoThirdTypes.CLEAN_VIEW_PAGTOTHIRD, {})

export const putPagtoThirdRequest = (iputPagtoThird: IputPagtoThird) =>
  action(PagtoThirdTypes.PUT_PAGTOTHIRD_REQUEST, { iputPagtoThird });

export const putPagtoThirdSuccess = (data: IResponse) =>
  action(PagtoThirdTypes.PUT_PAGTOTHIRD_SUCCESS, { data });

export const putPagtoThirdFailure = (data: IResponse) => action(PagtoThirdTypes.PUT_PAGTOTHIRD_FAILURE, { data });

export const deletePagtoThirdRequest = (id: string) =>
  action(PagtoThirdTypes.DELETE_PAGTOTHIRD_REQUEST, { id });

export const deletePagtoThirdSuccess = () =>
  action(PagtoThirdTypes.DELETE_PAGTOTHIRD_SUCCESS, {});

export const deletePagtoThirdFailure = () => action(PagtoThirdTypes.DELETE_PAGTOTHIRD_FAILURE, {});

export const cleanViewARespPagtoThird = () =>
  action(PagtoThirdTypes.CLEAN_PAGTOTHIRD_RESPONSE, {});


