import { action } from "typesafe-actions";
import { YearTypes, IYear, IpostYear, IputYear, IResponse } from "./types";

export const yearRequest = (paremtros: any) => action(YearTypes.YEAR_REQUEST, {paremtros});

export const yearSuccess = (data: IYear) =>
  action(YearTypes.YEAR_SUCCESS, { data });

export const yearFailure = () => action(YearTypes.YEAR_FAILURE, {});

export const postYearRequest = (Ipostyear: IpostYear) =>
  action(YearTypes.POST_YEAR_REQUEST, { Ipostyear });

export const postYearSuccess = (data: IResponse) =>
  action(YearTypes.POST_YEAR_SUCCESS, { data });

export const postYearFailure = (data: IResponse) =>
  action(YearTypes.POST_YEAR_FAILURE, { data });

export const viewYear = (view: IYear) => action(YearTypes.VIEW_YEAR, { view });

export const cleanViewYear = () => action(YearTypes.CLEAN_VIEW_YEAR, {});

export const putYearRequest = (Iputyear: IputYear) =>
  action(YearTypes.PUT_YEAR_REQUEST, { Iputyear });

export const putYearSuccess = () => action(YearTypes.PUT_YEAR_SUCCESS, {});

export const putYearFailure = () => action(YearTypes.PUT_YEAR_FAILURE, {});

export const deleteYearRequest = (id: string) =>
  action(YearTypes.DELETE_YEAR_REQUEST, { id });

export const deleteYearSuccess = () =>
  action(YearTypes.DELETE_YEAR_SUCCESS, {});

export const deleteYearFailure = () =>
  action(YearTypes.DELETE_YEAR_FAILURE, {});
