export enum ModelsTypes {
  MODELS_REQUEST = "@model/MODELS_REQUEST",
  MODELS_SUCCESS = "@model/MODELS_SUCCESS",
  MODELS_FAILURE = "@model/MODELS_FAILURE",
  POST_MODELS_REQUEST = "@model/POST_MODELS_REQUEST",
  POST_MODELS_SUCCESS = "@model/POST_MODELS_SUCCESS",
  POST_MODELS_FAILURE = "@model/POST_MODELS_FAILURE",
  PUT_MODELS_REQUEST = "@model/PUT_MODELS_REQUEST",
  PUT_MODELS_SUCCESS = "@model/PUT_MODELS_SUCCESS",
  PUT_MODELS_FAILURE = "@model/PUT_MODELS_FAILURE",
  VIEW_MODEL = "@model/VEIW_MODEL",
  CLEAN_VIEW_MODEL = "@model/CLEAN_VIEW_MODEL",
  DELETE_MODELS_REQUEST = "@model/DELETE_MODELS_REQUEST",
  DELETE_MODELS_SUCCESS = "@model/DELETE_MODELS_SUCCESS",
  DELETE_MODELS_FAILURE = "@model/DELETE_MODELS_FAILURE",
}

export interface IpostModels {
  nome: any;
  opcionais?: any;
  marca: any;
}

export interface IputModels {
  id: string;
  nome: any;
  opcionais?: any;
  marca: any;
}

export interface IModels {
  id: string;
  nome: any;
  opcionais?: any;
  marca: any;
}

export interface ModelsState {
  readonly data: IModels[];
  readonly view: IModels;
  readonly loading: boolean;
  readonly error: boolean;
}
