import React from "react";
import { Switch } from "react-router-dom";

import Router from "./Router";
import { createBrowserHistory } from "history";

import SignIn from "../pages/SignIn";
import Home from "../pages/Home";
import Clientes from "../pages/Clientes";
import CadClientes from "../pages/cadClientes";
import Modelos from "../pages/Modelos";
import CadModelos from "../pages/cadModelos";

import Cores from "../pages/Cores";
import CadCores from "../pages/cadCores";
import Cambios from "../pages/Cambios";
import CadCambios from "../pages/cadCambios";
import Marcas from "../pages/Marcas";
import Combustiveis from "../pages/Combustiveis";
import CadCombustiveis from "../pages/cadCombustiveis";

import CadastroSenha from "../pages/Cadastro";
import CadastroUserFunc from "../pages/CadastroUFunc";
import ConfCadastro from "../pages/ConfCadastro";
import CadastroLoja from "../pages/CadastroLoja";
import Carros from "../pages/Carros";

import CadCarros from "../pages/cadCarros";
import EditCarros from "../pages/editCarros";
// import Detalhes from "../pages/detalheCarro";
import Funcionario from "../pages/Funcionario";
import CadFunc from "../pages/cadFuncionario";
import EditFunc from "../pages/editFuncionario";
import EditarPerfil from "../pages/editPerfil";
import EditarLoja from "../pages/editLoja";
import RecoverPass from "../pages/recuperar";
import RecuperarSenha from "../pages/recuperarSenha";

import ClientesLoja from "../pages/ClientesLoja";
import CadClientesLoja from "../pages/cadClientesLoja";
import EditClientesLoja from "../pages/editClientesLoja";


import contratoVenda from "../pages/ContratoVenda";
import CadContratoVenda from "../pages/cadContratoVenda";
import EditContratoVenda from "../pages/editContratoVenda";

import CadDeclaracoes from "../pages/cadDeclaracoes";

import Fornecedor from "../pages/fornecedor";
import CadFornecedor from "../pages/cadFornecedor";
import EditFornecedor from "../pages/editFornecedor";

import Despesa from "../pages/despesas";
import CadDespesa from "../pages/cadDespesa";
import EditDespesa from "../pages/editDespesa";

import CategoriaFin from "../pages/categoriaFinanceiro";
import CadCategoriaFin from "../pages/cadCategoriaFin";
import EditCategoriaFin from "../pages/editCategoriaFin";

import Financeiro from "../pages/financeiro";
import CadFinanceiro from "../pages/cadFinanceiro";
import EditFinanceiro from "../pages/editFinanceiro";

import BancoAuto from "../pages/bancoAuto";
import CadBancoAuto from "../pages/cadBancoAuto";
import EditBancoAuto from "../pages/editBancoAuto";

import EstoqueWhats from "../pages/estoqueWhats";
import EstoqueSite from "../pages/estoque-site";


import Terceiro from "../pages/terceiro";
import CadTerceiro from "../pages/cadTerceiro";
import EditTerceiro from "../pages/editTerceiro";

export const appHistory = createBrowserHistory();

const Routes: React.FC = () => (
  <Switch>
    <Router exact path="/estoque-site" component={EstoqueSite} isRestrict />
    <Router path="/" component={SignIn} exact/>
    <Router path="/cadastro/:token" component={CadastroSenha} />
    <Router path="/cadastro-funcionario/:token" component={CadastroUserFunc} />
    <Router path="/recuperar" component={RecoverPass} isRestrict />
    <Router path="/recuperar-senha" component={RecuperarSenha} />
    <Router path="/cadastro-loja" component={CadastroLoja} />
    <Router path="/home" component={Home} isPrivate />
    <Router path="/clientes" component={Clientes} isPrivate />
    <Router path="/cadastro-clientes" component={CadClientes} isPrivate />
    <Router path="/modelos" component={Modelos} isPrivate />
    <Router path="/cadastro-modelos" component={CadModelos} isPrivate />
    <Router path="/cores" component={Cores} isPrivate />
    <Router path="/cadastro-cor" component={CadCores} isPrivate />
    <Router path="/cambios" component={Cambios} isPrivate />
    <Router path="/cadastro-cambio" component={CadCambios} isPrivate />
    <Router path="/marcas" component={Marcas} isPrivate />
    <Router path="/combustiveis" component={Combustiveis} isPrivate />
    <Router path="/estoque" component={Combustiveis} isPrivate />
    <Router
      path="/cadastro-combustivel"
      component={CadCombustiveis}
      isPrivate
    />

    <Router path="/carros" component={Carros} isPrivate />
    <Router path="/cadastro-carro" component={CadCarros} isPrivate />
    <Router path="/edita-carro" component={EditCarros} isPrivate />
    {/* <Router path="/detalhes" component={Detalhes} isPrivate /> */}

    <Router path="/usuarios" component={Funcionario} isPrivate />
    <Router path="/cadastro-usuarios" component={CadFunc} isPrivate />
    <Router path="/editar-usuarios" component={EditFunc} isPrivate />

    <Router path="/editar-perfil" component={EditarPerfil} isPrivate />
    <Router path="/editar-loja" component={EditarLoja} isPrivate />

    <Router path="/clientes-loja" component={ClientesLoja} isPrivate />
    <Router path="/cadastro-clientes-loja" component={CadClientesLoja} isPrivate />
    <Router path="/editar-clientes-loja" component={EditClientesLoja} isPrivate />

    <Router path="/fornecedor" component={Fornecedor} isPrivate />
    <Router path="/cadastro-fornecedor" component={CadFornecedor} isPrivate />
    <Router path="/editar-fornecedor" component={EditFornecedor} isPrivate />

    <Router path="/confirmacao" component={contratoVenda} isPrivate />
    <Router path="/cadastro-confirmacao" component={CadContratoVenda} isPrivate />
    <Router path="/editar-confirmacao" component={EditContratoVenda} isPrivate />

    <Router path="/despesas" component={Despesa} isPrivate />
    <Router path="/cadastro-despesa" component={CadDespesa} isPrivate />
    <Router path="/editar-despesa" component={EditDespesa} isPrivate />

    <Router path="/categoria-financeiro" component={CategoriaFin} isPrivate />
    <Router path="/cadastro-categoria-fin" component={CadCategoriaFin} isPrivate />
    <Router path="/editar-categoria-fin" component={EditCategoriaFin} isPrivate />


    <Router path="/financeiro" component={Financeiro} isPrivate />
    <Router path="/cadastro-financeiro" component={CadFinanceiro} isPrivate />
    <Router path="/editar-financeiro" component={EditFinanceiro} isPrivate />

    <Router path="/banco-automarcas" component={BancoAuto} isPrivate />
    <Router path="/cadastro-bancoAuto" component={CadBancoAuto} isPrivate />
    <Router path="/editar-bancoAuto" component={EditBancoAuto} isPrivate />

    <Router path="/estoque-whatsapp" component={EstoqueWhats} isPrivate />
    <Router path="/configurador-declaracao" component={CadDeclaracoes} isPrivate />

    <Router path="/terceiro" component={Terceiro} isPrivate />
    <Router path="/cadastro-terceiro" component={CadTerceiro} isPrivate />
    <Router path="/editar-terceiro" component={EditTerceiro} isPrivate />

  </Switch>
);

export default Routes;
