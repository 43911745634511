import { combineReducers } from "redux";

import auth from "./auth";
import client from "./client";
import model from "./models";
import gearshift from "./gearshift";
import color from "./color";
import year from "./year";
import fipe from "./fipe";
import mark from "./mark";
import fuel from "./fuel";
import optional from "./optional";
import user from "./user";
import car from "./car";
import employee from "./employee";
import clientStore from "./clientStore";
import contracts from "./contracts";
import declarations from "./declarations";
import provider from "./provider";
import categoryFinancial from "./categoryFinancial";
import expenses from "./expenses";
import financial from "./financial";
import comission from "./comission";
import bankAuto from "./bankAuto";
import thirds from "./thirds";
import pagtoThird from "./pagtoThird";
import pagtoParcauto from "./pagtoParcauto";


export default combineReducers({
  auth,
  client,
  model,
  gearshift,
  color,
  year,
  fipe,
  mark,
  fuel,
  optional,
  user,
  car,
  employee,
  clientStore,
  contracts,
  declarations,
  provider,
  categoryFinancial,
  expenses,
  financial,
  comission,
  bankAuto,
  thirds,
  pagtoThird,
  pagtoParcauto
});
