import React from "react";
import { Link } from "react-router-dom";

const MenuItem = ({ path, icon, label, ...shared }: any) => {
  return (
    <li {...shared} className="nav-item">
      <Link to={path} className="nav-link">
        <i className={`flaticon-${icon}`}></i>
        <span>{label}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
