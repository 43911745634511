export enum CarTypes {
  CAR_REQUEST = "@car/CAR_REQUEST",
  CAR_SUCCESS = "@car/CAR_SUCCESS",
  CAR_FAILURE = "@car/CAR_FAILURE",

  CARUNIQUE_REQUEST = "@car/CARUNIQUE_REQUEST",
  CARUNIQUE_SUCCESS = "@car/CARUNIQUE_SUCCESS",
  CARUNIQUE_FAILURE = "@car/CARUNIQUE_FAILURE",

  POST_CAR_REQUEST = "@car/POST_CAR_REQUEST",
  POST_CAR_SUCCESS = "@car/POST_CAR_SUCCESS",
  POST_CAR_FAILURE = "@car/POST_CAR_FAILURE",
  PUT_CAR_REQUEST = "@car/PUT_CAR_REQUEST",
  PUT_CAR_SUCCESS = "@car/PUT_CAR_SUCCESS",
  PUT_CAR_FAILURE = "@car/PUT_CAR_FAILURE",
  VIEW_CAR = "@car/VEIW_CAR",
  CLEAN_VIEW_CAR = "@car/CLEAN_VIEW_CAR",
  CLEAN_VIEW_FOTO = "@car/CLEAN_VIEW_FOTO",

  DELETE_CAR_REQUEST = "@car/DELETE_CAR_REQUEST",
  DELETE_CAR_SUCCESS = "@car/DELETE_CAR_SUCCESS",
  DELETE_CAR_FAILURE = "@car/DELETE_CAR_FAILURE",

  UPSTATUS_FOTOCAR_REQUEST = "@car/UPSTATUS_FOTOCAR_REQUEST",
  UPSTATUS_FOTOCAR_SUCCESS = "@car/UPSTATUS_FOTOCAR_SUCCESS",
  UPSTATUS_FOTOCAR_FAILURE = "@car/UPSTATUS_FOTOCAR_FAILURE",

  CLEAN_CAR_RESPONSE = "@car/CLEAN_CAR_RESPONSE",

  CARSITE_REQUEST = "@car/CARSITE_REQUEST",
  CARSITE_SUCCESS = "@car/CARSITE_SUCCESS",
  CARSITE_FAILURE = "@car/CARSITE_FAILURE",
}

export interface IpostCar {
  nome?: string;
  descritivo?: string;
  ano_modelo?: any;
  ano_contrato?: any;
  preco_custo: any;
  preco_fipe: any;
  preco_venda: any;
  chassi?: any;
  marca?: any;
  faturamento?: any;
  fk_marca: any;
  fk_modelo: any;
  status?: any;
  fk_cor: any;
  fk_cambio: any;
  fk_combustivel: any;
  ano_fab?: any;
  fk_fornecedor?: any;
  estoque_site?: any;
  file_compra?: any;
  btnEmail?: any;
  profileEmail?: any;
  profileWhats?: any;
  profileNome?: any;
  opcionais?: any;
  preco_entrada?: any;
}

export interface IputCar {
  id: any;
  nome?: string;
  descritivo?: string;
  ano_modelo?: any;
  ano_contrato?: any;
  preco_custo: any;
  preco_fipe: any;
  preco_venda: any;
  chassi?: any;
  marca?: any;
  faturamento?: any;
  fk_marca: any;
  fk_modelo: any;
  status?: any;
  fk_cor: any;
  fk_cambio: any;
  fk_combustivel: any;
  ano_fab?: any;
  fk_fornecedor?: any;
  estoque_site?: any;
  file_compra?: any;
  btnEmail?: any;
  profileEmail?: any;
  profileWhats?: any;
  profileNome?: any;
  opcionais?: any;
  preco_entrada?: any;
}

export interface ICar {
  id: string;
  nome?: string;
  descritivo?: string;
  ano_modelo?: any;
  ano_contrato?: any;
  preco_custo: any;
  preco_fipe: any;
  preco_venda: any;
  chassi?: any;
  marca?: any;
  faturamento?: any;
  fk_marca: any;
  fk_modelo: any;
  status?: any;
  fk_cor: any;
  fk_cambio: any;
  fk_combustivel: any;
  ano_fab?: any;
  fk_fornecedor?: any;
  estoque_site?: any;
  file_compra?: any;
  btnEmail?: any;
  opcionais?: any;
  custom_id?: any;
  preco_entrada?: any;

}

export interface ICarUnique {
  id: string;
  nome?: string;
  descritivo?: string;
  ano_modelo?: any;
  ano_contrato?: any;
  preco_custo: any;
  preco_fipe: any;
  preco_venda: any;
  chassi?: any;
  marca?: any;
  faturamento?: any;
  fk_marca: any;
  fk_modelo: any;
  status?: any;
  fk_cor: any;
  fk_cambio: any;
  fk_combustivel: any;
  ano_fab?: any;
  fk_fornecedor?: any;
  estoque_site?: any;
  file_compra?: any;
  btnEmail?: any;
  opcionais?: any;
  preco_entrada?: any;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface IUpStatus {
  id: any;
  status: any;
  estoque_site?: any;
  preco_venda?: any;
  descritivo?: any;
  opcionais?: any;
  profileEmail?: any;
  profileWhats?: any;
  profileNome?: any;
}

export interface CarState {
  readonly data: ICar[];
  readonly dataSite: ICar[];
  readonly dataBolsa: ICar[];
  readonly dataUnique: any;
  readonly view: ICar;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly upStatus: IUpStatus;
  readonly error: boolean;
}
