import { action } from "typesafe-actions";
import {
  FipeTypes,
  IFipe,
  IFipeModel,
  IFipeYearVehicle,
  IFipeCar,
} from "./types";

export const fmarcasRequest = () => action(FipeTypes.FPEMARCA_REQUEST);

export const fmarcasSuccess = (data: IFipe[]) =>
  action(FipeTypes.FPEMARCA_SUCCESS, { data });

export const fmarcasFailure = () => action(FipeTypes.FPEMARCA_FAILURE, {});

export const fmodelosRequest = (IpostFMarca: any) =>
  action(FipeTypes.FPEMODELO_REQUEST, { IpostFMarca });

export const fmodelosSuccess = (data: IFipeModel[]) =>
  action(FipeTypes.FPEMODELO_SUCCESS, { data });

export const fmodelosFailure = () => action(FipeTypes.FPEMODELO_FAILURE, {});

export const fanoVeiculoRequest = (IpostFModelo: any) =>
  action(FipeTypes.FPEANOVEICULO_REQUEST, { IpostFModelo });

export const fanoVeiculoSuccess = (data: IFipeYearVehicle[]) =>
  action(FipeTypes.FPEANOVEICULO_SUCCESS, { data });

export const fanoVeiculoFailure = () =>
  action(FipeTypes.FPEANOVEICULO_FAILURE, {});

export const fcarroRequest = (Ipostfcarro: any) =>
  action(FipeTypes.FPECARRO_REQUEST, { Ipostfcarro });

export const fcarroSuccess = (data: IFipeCar[]) =>
  action(FipeTypes.FPECARRO_SUCCESS, { data });

export const fcarroFailure = () => action(FipeTypes.FPECARRO_FAILURE, {});

export const viewFPEModel = (view: IFipeModel) =>
  action(FipeTypes.VIEW_FPEMODELO, {view});

export const cleanViewFPEModel = () =>
  action(FipeTypes.CLEAN_VIEW_FPEMODELO, {})

