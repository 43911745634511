import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Select } from "react-functional-select";
import { IColor } from "../../store/ducks/color/types";
import { postCarRequest } from "../../store/ducks/car/actions";


const ModalDuplicar = (props: any) => {
  const { carroEdit, dataColor, profileUser } = props;
  const dispatch = useDispatch();

  function formatReal(int: any) {
    var tmp = int + "";
    tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
    if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

    return tmp;
  }

  const [selectedOptionCor, setSelectedOptionCor] = useState<IColor | null>(
    null
  );


  const [precoStr, setPreco] = useState({
    descritivo: carroEdit.descritivo || "",
    fk_cor: carroEdit.fk_cor.toString().split(",")[0] || "",
    chassi: carroEdit.chassi || "",
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setPreco({
        ...precoStr,
        [name]: target.value,
      });
    },
    [precoStr, setPreco]
  );


  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const target = event.target;
      const name = target.name;

      setPreco({
        ...precoStr,
        [name]: target.value,
      });
    },
    [precoStr, setPreco]
  );

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const preco_fipe = carroEdit.preco_fipe;
      //@ts-ignore
      precoStr.preco_venda = document.getElementById("precoVenda-" + carroEdit.id).value.replace(".", "").replace(",", ".");
      //@ts-ignore
      precoStr.preco_custo = document.getElementById("precoCusto-" + carroEdit.id).value.replace(".", "").replace(",", ".");
      //@ts-ignore
      const preco_venda = document.getElementById("precoVenda-" + carroEdit.id).value.replace(".", "").replace(",", ".");
      //@ts-ignore
      const preco_custo = document.getElementById("precoCusto-" + carroEdit.id).value.replace(".", "").replace(",", ".");

      const ano_modelo = carroEdit.ano_modelo;
      const nome = carroEdit.nome;
      const ano_contrato = carroEdit.ano_contrato;
      const faturamento = carroEdit.faturamento;
      const fk_fornecedor = carroEdit.fk_fornecedor;
      const fk_marca = carroEdit.fk_marca;
      const fk_cor =
        selectedOptionCor?.id || carroEdit.fk_cor.toString().split(",")[0];
      const fk_modelo = carroEdit.fk_modelo;
      const fk_cambio = carroEdit.fk_cambio;
      const fk_combustivel = carroEdit.fk_combustivel;
      const ano_fab = carroEdit.ano_fab;
      const descritivo = precoStr.descritivo;
      const chassi = precoStr.chassi;
      const marca = carroEdit.marca;
      const status = carroEdit.status;
      const estoque_site = carroEdit.estoque_site;

      const profileEmail = profileUser.email;
      const profileWhats = profileUser.whatsapp;
      const profileNome = profileUser.nome;

      dispatch(
        postCarRequest({
          nome,
          preco_fipe,
          preco_custo,
          preco_venda,
          descritivo,
          fk_marca,
          fk_cor,
          fk_modelo,
          fk_cambio,
          fk_combustivel,
          ano_modelo,
          ano_contrato,
          chassi,
          marca,
          status,
          faturamento,
          ano_fab,
          fk_fornecedor,
          estoque_site,
          profileEmail,
          profileWhats,
          profileNome
        })
      );
      toast.success("Carro duplicado com sucesso");
      // window.location.reload()
      setTimeout(() => window.location.reload(), 700);
    },
    [
      dispatch,
      precoStr,
      carroEdit,
      selectedOptionCor,
      profileUser
    ]
  );

  const nameCor = { nome: carroEdit.cor };

  const getOptionLabelCor = useCallback(
    (color: any): string => `${color.nome}`,
    []
  );
  const getOptionValueCor = useCallback(
    (color: IColor): string => color.id,
    []
  );

  const onOptionChangeCor = useCallback((color: IColor | null): void => {
    setSelectedOptionCor(color);
  }, []);

  const [count, setCount] = useState(0);

  const countMore = useCallback(() => {
    setCount(count + 1);

  }, [])
  useEffect(() => {
    countMore();
    if (count === 0) {
      $("#precoCusto-" + carroEdit.id).val(formatReal(carroEdit.preco_custo.replace(".", "")))
      $("#precoVenda-" + carroEdit.id).val(formatReal(carroEdit.preco_venda.replace(".", "")))
    }
  }, [countMore]);

  $('.dinheiro1').mask('#.##0,00', {
    reverse: true,
    placeholder: '0,00'
  });

  return (
    <div
      className={`modal fade`}
      id={"confirm" + carroEdit.id}
      aria-hidden="true"
      tabIndex={-1}
      role="dialog"
      style={{ paddingRight: "17px" }}
    >
      <div className="modal-dialog">
        <div className="modal-content" style={{ backgroundColor: "#e8e8e8" }}>
          <div
            className="modal-header"
            style={{
              justifyContent: "center",
              display: "block",
              textAlign: "center",
              padding: "10px 20px 0px"
            }}
          >
            <h3
              className="modal-title"
              style={{ fontWeight: "bold", color: "#000" }}
            >
              Duplicar carro
            </h3>
            <p style={{ color: "#000" }}>{carroEdit.nome}</p>
          </div>

          <div className="modal-body" style={{ padding: "0px 20px" }}>
            <form onSubmit={submitForm} className="new-added-form">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-12 form-group">
                  <label
                    className="label-form-input"
                    htmlFor="exampleInputEmail1"
                    style={{ textAlign: "left" }}
                  >
                    Cor *
                  </label>
                  <Select
                    options={dataColor}
                    addClassNames={true}
                    onOptionChange={onOptionChangeCor}
                    getOptionValue={getOptionValueCor}
                    getOptionLabel={getOptionLabelCor}
                    placeholder="Selecione"
                    initialValue={nameCor}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-6 form-group">
                  <label
                    className="label-form-input"
                    htmlFor="precoCusto"
                    style={{ textAlign: "left" }}
                  >
                    Preço Custo
                  </label>
                  <input
                    name="preco_custo"
                    type="text"
                    className="form-control dinheiro1"
                    id={`precoCusto-${carroEdit.id}`}
                    placeholder="Preço Custo"
                    required={true}
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-6 form-group">
                  <label
                    className="label-form-input"
                    htmlFor="precoVenda"
                    style={{ textAlign: "left" }}
                  >
                    Preço Venda
                  </label>
                  <input
                    name="preco_venda"
                    type="text"
                    className="form-control dinheiro1"
                    id={`precoVenda-${carroEdit.id}`}
                    placeholder="Preço Venda"
                    required={true}
                  />
                </div>

                <div className="col-xl-12 col-lg-12 col-12 form-group">
                  <label
                    className="label-form-input"
                    htmlFor="precoCusto"
                    style={{ textAlign: "left" }}
                  >
                    Chassi
                  </label>
                  <input
                    value={precoStr?.chassi}
                    onChange={handleChange}
                    name="chassi"
                    type="text"
                    className="form-control"
                    id={`chassi-${carroEdit.id}`}
                    placeholder="Chassi"
                    required={true}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-12 form-group">
                  <label
                    className="label-form-input"
                    htmlFor={`descritivoCarro-${carroEdit.id}`}
                    style={{ textAlign: "left" }}
                  >
                    Descrição
                  </label>
                  <textarea
                    value={precoStr.descritivo}
                    name="descritivo"
                    onChange={handleChangeText}
                    className="form-control textarea"
                    id={`descritivoCarro-${carroEdit.id}`}
                    rows={6}
                    placeholder="Descreva o carro poucas palavras"
                  />
                </div>
              </div>

              <div
                className="modal-footer"
                style={{ padding: "15px 0px", borderTop: "0px" }}
              >
                <button
                  type="submit"
                  className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                // id={`btn_submit-${carroEdit.id}`}
                >
                  Enviar
                </button>
              </div>
              {/* <ToastContainer autoClose={5000} position="top-right" /> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDuplicar;
