import React, { useCallback } from "react";
import MenuItem from "./menuItem";

import { useDispatch } from "react-redux";

import { signOut as signOutAction } from "../store/ducks/auth/actions";
import { cleanViewClient } from "../store/ducks/client/actions";
import { cleanViewModel } from "../store/ducks/models/actions";
import { cleanViewColor } from "../store/ducks/color/actions";
import { cleanViewGearshift } from "../store/ducks/gearshift/actions";
import { cleanViewMark } from "../store/ducks/mark/actions";
import { cleanViewFuel } from "../store/ducks/fuel/actions";
import { cleanViewCar } from "../store/ducks/car/actions";
import MenuTree from "./menuTree";
import { Link } from "react-router-dom";
import { cleanViewProvider } from "../store/ducks/provider/actions";
import { cleanViewExpenses } from "../store/ducks/expenses/actions";
import { cleanViewCategoryFin } from "../store/ducks/categoryFinancial/actions";
import { cleanViewEmployee } from "../store/ducks/employee/actions";
import { cleanViewThirds } from "../store/ducks/thirds/actions";

const Menu = () => {
  const dispatch = useDispatch();

  const signOut = useCallback(() => {
    localStorage.removeItem("persist:AppCAR_System");
    localStorage.removeItem("Auth-Token");
    localStorage.removeItem("Access-Level");
    localStorage.removeItem("typeCliente");
    dispatch(signOutAction());
  }, [dispatch]);

  const cleanViewCarro = useCallback(() => {
    dispatch(cleanViewCar());
  }, [dispatch]);

  const cleanModelo = useCallback(() => {
    dispatch(cleanViewModel());
  }, [dispatch]);

  const cleanCor = useCallback(() => {
    dispatch(cleanViewColor());
  }, [dispatch]);

  const cleanCambio = useCallback(() => {
    dispatch(cleanViewGearshift());
  }, [dispatch]);

  const cleanMarca = useCallback(() => {
    dispatch(cleanViewMark());
  }, [dispatch]);

  const cleanCombustivel = useCallback(() => {
    dispatch(cleanViewFuel());
  }, [dispatch]);

  const cleanFornecedor = useCallback(() => {
    dispatch(cleanViewProvider());
  }, [dispatch]);

  const cleanDespesa = useCallback(() => {
    dispatch(cleanViewExpenses());
  }, [dispatch]);

  const cleanCategoriaFin = useCallback(() => {
    dispatch(cleanViewCategoryFin());
  }, [dispatch]);

  const cleanViewFuncionario = useCallback(() => {
    dispatch(cleanViewEmployee());
  }, [dispatch]);

  const cleanTerceiro = useCallback(() => {
    dispatch(cleanViewThirds());
  }, [dispatch]);

  const accessLevelUser = {
    headers: { typeCliente: localStorage.getItem("typeCliente") },
  };

  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];


  return (
    <ul className="nav nav-sidebar-menu sidebar-toggle-view">

      <MenuItem
        path="/carros"
        label="Estoque"
        icon="list"
        onClick={cleanViewCarro}
      />

      {/* <li className="nav-item">
        <Link to={"/estoque-whatsapp"} className="nav-link">
          <i className="fab fa-whatsapp"
            style={{ position: "initial", bottom: "0px", right: "none" }}>

          </i>
          <span>Estoque Whatsapp</span>
        </Link>
      </li> */}

      <MenuItem
        path="/clientes-loja"
        label="Clientes"
        icon="user"
        onClick={cleanViewCarro}
      />

      <MenuItem
        path="/fornecedor"
        label="Fornecedor"
        icon="list"
        onClick={cleanFornecedor}
      />

      <MenuItem
        path="/terceiro"
        label="Terceiros"
        icon="list"
        onClick={cleanTerceiro}
      />

      <MenuItem
        path="/confirmacao"
        label="Vendas"
        icon="list"
        onClick={cleanViewCarro}
      />

      <MenuTree path="/" label="Financeiro" icon="technological">
        <li className="nav-item">
          <Link to="/financeiro" className="nav-link" onClick={cleanDespesa}>
            <i className="fas fa-angle-right"></i>Lançamento
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/despesas" className="nav-link" onClick={cleanDespesa}>
            <i className="fas fa-angle-right"></i>Despesas Fixas
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/categoria-financeiro" className="nav-link" onClick={cleanCategoriaFin}>
            <i className="fas fa-angle-right"></i>Categorias
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/banco-automarcas" className="nav-link" onClick={cleanCombustivel}>
            <i className="fas fa-angle-right"></i>Bancos
          </Link>
        </li>

      </MenuTree>

      <MenuTree path="/" label="Configurações" icon="settings">
        <li className="nav-item">
          <Link to="/marcas" className="nav-link" onClick={cleanMarca}>
            <i className="fas fa-angle-right"></i>Marcas
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/modelos" className="nav-link" onClick={cleanModelo}>
            <i className="fas fa-angle-right"></i>Modelos
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/cores" className="nav-link" onClick={cleanCor}>
            <i className="fas fa-angle-right"></i>Cores
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/cambios" className="nav-link" onClick={cleanCambio}>
            <i className="fas fa-angle-right"></i>Cambios
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/combustiveis" className="nav-link" onClick={cleanCombustivel}>
            <i className="fas fa-angle-right"></i>Combustiveis
          </Link>
        </li>

      </MenuTree>

      <MenuItem
        path="/usuarios"
        label="Usuarios"
        icon="multiple-users-silhouette"
        onClick={cleanViewFuncionario}
      />

      <MenuItem label="Sair" path={"/"} icon="turn-off" onClick={signOut} />
    </ul>
  );
};

export default Menu;
