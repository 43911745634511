import { action } from "typesafe-actions";
import { PagtoParcautoTypes, IPagtoParcauto, IpostPagtoParcauto, IputPagtoParcauto, IResponse } from "./types";

export const pagtoParcautoRequest = (paremtros: any) =>
  action(PagtoParcautoTypes.PAGTOPARCAUTO_REQUEST, { paremtros });

export const pagtoParcautoSuccess = (data: IPagtoParcauto) =>
  action(PagtoParcautoTypes.PAGTOPARCAUTO_SUCCESS, { data });

export const pagtoParcautoFailure = () => action(PagtoParcautoTypes.PAGTOPARCAUTO_FAILURE, {});

export const postPagtoParcautoRequest = (ipostPagtoParcauto: IpostPagtoParcauto) =>
  action(PagtoParcautoTypes.POST_PAGTOPARCAUTO_REQUEST, { ipostPagtoParcauto });

export const postPagtoParcautoSuccess = (data: IResponse) =>
  action(PagtoParcautoTypes.POST_PAGTOPARCAUTO_SUCCESS, { data });

export const postPagtoParcautoFailure = (data: IResponse) => action(PagtoParcautoTypes.POST_PAGTOPARCAUTO_FAILURE, { data });

export const viewPagtoParcauto = (view: IPagtoParcauto) =>
  action(PagtoParcautoTypes.VIEW_PAGTOPARCAUTO, { view });

export const cleanViewPagtoParcauto = () =>
  action(PagtoParcautoTypes.CLEAN_VIEW_PAGTOPARCAUTO, {})

export const putPagtoParcautoRequest = (iputPagtoParcauto: IputPagtoParcauto) =>
  action(PagtoParcautoTypes.PUT_PAGTOPARCAUTO_REQUEST, { iputPagtoParcauto });

export const putPagtoParcautoSuccess = (data: IResponse) =>
  action(PagtoParcautoTypes.PUT_PAGTOPARCAUTO_SUCCESS, { data });

export const putPagtoParcautoFailure = (data: IResponse) => action(PagtoParcautoTypes.PUT_PAGTOPARCAUTO_FAILURE, { data });

export const deletePagtoParcautoRequest = (id: string) =>
  action(PagtoParcautoTypes.DELETE_PAGTOPARCAUTO_REQUEST, { id });

export const deletePagtoParcautoSuccess = () =>
  action(PagtoParcautoTypes.DELETE_PAGTOPARCAUTO_SUCCESS, {});

export const deletePagtoParcautoFailure = () => action(PagtoParcautoTypes.DELETE_PAGTOPARCAUTO_FAILURE, {});

export const cleanViewARespPagtoParcauto = () =>
  action(PagtoParcautoTypes.CLEAN_PAGTOPARCAUTO_RESPONSE, {});


