import React from "react";
import Menu from "./menu";
import MenuAdmin from "./menuAdmin";
import "./style.css";

const Sidebar = () => {
  const accessLevel = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLogged = accessLevel.headers["Access-Level"];

  return (
    <div
      className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color"
      style={{ minHeight: "919px" }}
    >
      <div className="mobile-sidebar-header d-md-none">
        <div className="header-logo">
          <a href="index.html">
            {/* <img src="img/logo1.png" alt="logo" /> */}
          </a>
        </div>
      </div>
      <div className="sidebar-menu-content">
        {UserLogged === "super_admin" ? <Menu /> : <MenuAdmin />}
      </div>
    </div>
  );
};

export default Sidebar;
