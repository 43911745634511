import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import "./index.css";
import { toast, ToastContainer } from "react-toastify";
import { ICategoryFin } from "../../store/ducks/categoryFinancial/types";
import { cleanViewARespCategoryFin, putCategoryFinRequest } from "../../store/ducks/categoryFinancial/actions";

const EditCategoriaFin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const responseApi: any = useSelector<ApplicationState>(
    (state) => state.categoryFinancial
  );

  const view = useSelector<ApplicationState, ICategoryFin>(
    (state) => state.categoryFinancial.view
  );

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("categoriaFin", {
    id: view.id,
    nome: view.nome,
  });


  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();
      toast.info("Carregando, aguarde");

      const { id, nome } = form;

      dispatch(
        putCategoryFinRequest({
          id,
          nome,
        })
      );
    },
    [
      dispatch,
      form,
    ]
  );

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        toast.dismiss();
        toast.success("Categoria alterada com sucesso");
        setTimeout(() => history.push("/categoria-financeiro"), 2000);
      }

      if (event === 404) {
        toast.error("Falha ao cadastrar Categoria");
        btnNoDisabled();
      }
    },
    [history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );


  const cleanView = useCallback(() => {
    dispatch(cleanViewARespCategoryFin());
  }, [dispatch]);


  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }

    notify(responseApi.response.code);
  }, [notify, responseApi.response, cleanView]);
  console.log(responseApi.response.code)

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Categoria Financeiro</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Editar categoria financeiro</li>
            </ul>
          </div>

          <div className="mb-3">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  <h3>Editar Categoria Financeiro</h3>
                  {/* <span className="label-form-input">* Campos obrigatório</span> */}
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">

                  <div className="col-xl-3 col-lg-3 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputEmail1"
                    >
                      Nome
                        </label>
                    <input
                      value={form.nome}
                      name="nome"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Nome"
                      required={true}
                    />
                  </div>


                  <div className="col-12 form-group mg-t-8 buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditCategoriaFin;
