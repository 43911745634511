import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import {
  IFipeModel,
  IFipe,
} from "../../store/ducks/fipe/types";
import { Select } from "react-functional-select";

import {
  fmodelosRequest,
  fanoVeiculoRequest,
  fcarroRequest,
} from "../../store/ducks/fipe/actions";
import { ICar } from "../../store/ducks/car/types";
import { putCarRequest, cleanViewCarResp } from "../../store/ducks/car/actions";
import { IColor } from "../../store/ducks/color/types";
import { IGearshift } from "../../store/ducks/gearshift/types";
import { IFuel } from "../../store/ducks/fuel/types";
import DispatchTables from "./dispatchTables";
import "./index.css";
import { toast, ToastContainer } from "react-toastify";
import jwt from "jwt-decode";
import {
  userProfileRequest,
  userLojaRequest,
} from "../../store/ducks/user/actions";
import { IProvider } from "../../store/ducks/provider/types";
import { IProfileUser } from "../../store/ducks/user/types";

type BooleanOption = {
  readonly id: any;
  readonly name: string;
};

const BOOL_OPTIONS: BooleanOption[] = [
  { id: 1, name: "AF" },
  { id: 2, name: "FR" },
  { id: 3, name: "Licenciado" },
];

const BOOL_OPTIONS1: BooleanOption[] = [
  { id: "A Venda", name: "A Venda" },
  { id: "Vendido", name: "Vendido" },
];

const BOOL_OPTIONS2: BooleanOption[] = [
  { id: 2025, name: "2025" },
  { id: 2024, name: "2024" },
  { id: 2023, name: "2023" },
  { id: 2022, name: "2022" },
  { id: 2021, name: "2021" },
  { id: 2020, name: "2020" },
  { id: 2019, name: "2019" },
  { id: 2018, name: "2018" },
];

const EditCarros: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessUser = {
    headers: { "Access-Level": localStorage.getItem("Access-Level") },
  };

  const UserLoggedLevel = accessUser.headers["Access-Level"];

  let BOOL_OPTIONS3: BooleanOption[] = [];
  if (UserLoggedLevel === "super_admin") {
    BOOL_OPTIONS3 = [
      { id: "Sim", name: "Thai Motors" },
      { id: "Não", name: "Correio Interno" },
    ];

  } else {
    BOOL_OPTIONS3 = [
      { id: "Sim", name: "Thai Motors" },
      { id: "Não", name: "Correio Interno" },
    ];

  }

  const profileUser = useSelector<ApplicationState, IProfileUser>(
    (state) => state.user.profile
  );

  const [focusStatus, setFocusStatus] = useState(false);
  const [focusFornecedor, setFocusFornecedor] = useState(false);

  const auth = {
    headers: { "Auth-Token": localStorage.getItem("Auth-Token") },
  };

  const UserLogged: any = auth.headers["Auth-Token"];

  const decodeToken: any = jwt(UserLogged);

  const getProfile = useCallback(() => {
    dispatch(userProfileRequest(decodeToken.sub));
  }, [decodeToken.sub, dispatch]);

  const getLoja = useCallback(
    (event) => {
      dispatch(userLojaRequest(event));
    },
    [dispatch]
  );

  const responseApi: any = useSelector<ApplicationState>((state) => state.car);

  const fmarcas: any = useSelector<ApplicationState>(
    (state) => state.fipe.data
  );

  const modelsFipe: any = useSelector<ApplicationState, IFipeModel[]>(
    (state) => state.fipe.modelo
  );

  const cor: any = useSelector<ApplicationState, IColor[]>(
    (state) => state.color.data
  );

  const cambio: any = useSelector<ApplicationState, IGearshift[]>(
    (state) => state.gearshift.data
  );

  const combustivel: any = useSelector<ApplicationState, IFuel[]>(
    (state) => state.fuel.data
  );

  const view = useSelector<ApplicationState, ICar>((state) => state.car.view);

  const [SelectedAnoFab, setSelectedAnoFab] = useState<BooleanOption | null>(
    null
  );

  const fornecedor = useSelector<ApplicationState, IProvider[]>(
    (state) => state.provider.data
  );

  const [
    selectedOptionProvider,
    setSelectedOptionProvider,
  ] = useState<IProvider | null>(null);

  const [
    selectedFaturamento,
    setSelectedFaturamento,
  ] = useState<BooleanOption | null>(null);

  const [
    SelectedStatusCar,
    setSelectedStatusCar,
  ] = useState<BooleanOption | null>(null);

  const [selectedOptionMarca, setSelectedOptionMarca] = useState<IFipe | null>(
    null
  );

  const [isLoading, setIsLoading] = useState(true);

  const [
    selectedOptionModelo,
    setSelectedOptionModelo,
  ] = useState<IFipeModel | null>(null);

  const [
    selectedOptionVeiculo,
    setSelectedOptionVeiculo,
  ] = useState<BooleanOption | null>(null);

  const [selectedOptionCor, setSelectedOptionCor] = useState<IColor | null>(
    null
  );

  const [
    selectedOptionCambio,
    setSelectedOptionCambio,
  ] = useState<IGearshift | null>(null);

  const [
    selectedOptionCombustivel,
    setSelectedOptionCombustivel,
  ] = useState<IFuel | null>(null);

  const [btnEmail, setBtnEmail] = useState("");

  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("carro", {
    id: view.id,
    nome: view.nome,
    ano_modelo: view.ano_modelo,
    ano_contrato: view.ano_contrato,
    descritivo: view.descritivo,
    opcionais: view.opcionais,
    fk_cambio: view.fk_cambio,
    fk_combustivel: view.fk_combustivel,
    fk_cor: view.fk_cor,
    fk_marca: view.fk_marca,
    fk_modelo: view.fk_modelo,
    preco_venda: view.preco_venda,
    preco_custo: view.preco_custo,
    preco_entrada: view.preco_entrada,
    anoZero: 0,
    preco_fipe: 0,
    faturamento: view.faturamento,
    chassi: view.chassi,
    marca: view.marca,
    status: view.status,
    ano_fab: view.ano_fab,
    fk_fornecedor: view.fk_fornecedor,
    estoque_site: view.estoque_site,
  });

  setTimeout(() => {
    $("#precoCusto").val(parseFloat(form.preco_custo).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
    $("#precoVenda").val(parseFloat(form.preco_venda).toLocaleString('pt-br', { minimumFractionDigits: 2 }))
    $("#precoEntrada").val(parseFloat(form.preco_entrada).toLocaleString('pt-br', { minimumFractionDigits: 2 }))

  }, 200);

  const [SelectedSite, setSelectedSite] = useState<BooleanOption | null>(
    { id: form.estoque_site, name: form.estoque_site }
  );

  const filterFornecedor = fornecedor
    .filter((data: any) => data.id === parseFloat(form.fk_fornecedor))
    .map((b: any) => b.nome);
  const nameFornecedor = { nome: filterFornecedor.toString() };

  const filterMarca = fmarcas
    .filter((data: any) => data.id === parseFloat(form.fk_marca))
    .map((b: any) => b.fipe_name);
  const nameMarca = { fipe_name: filterMarca.toString() };

  const filterModelo = modelsFipe
    .filter((data: any) => data.id === parseFloat(form.fk_modelo))
    .map((b: any) => b.name);
  const nameModelo = { name: filterModelo.toString() };

  const nameVeiculo = { id: form.ano_modelo };

  const filterCor = cor
    .filter(
      (data: any) =>
        data.id === parseFloat(form.fk_cor.toString().split(",")[0])
    )
    .map((b: any) => b.nome);
  const nameCor = { nome: filterCor.toString() };

  const filterCambio = cambio
    .filter((data: any) => data.id === form.fk_cambio)
    .map((b: any) => b.descricao);
  const nameCambio = { descricao: filterCambio.toString() };

  const filterCombustivel = combustivel
    .filter((data: any) => data.id === form.fk_combustivel)
    .map((b: any) => b.descricao);
  const nameCombustivel = { descricao: filterCombustivel.toString() };

  let filterFaturamento = {};
  if (form?.faturamento === "1") {
    filterFaturamento = { name: "AF" };
  } else if (form?.faturamento === "2") {
    filterFaturamento = { name: "FR" };
  } else {
    filterFaturamento = { name: "Licenciado" };
  }

  const filterStatusCar = { name: form?.status };

  const filterAnoFab = { name: form?.ano_fab };

  const filterSite = { name: form?.estoque_site };

  const btnDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.setAttribute("disabled", "{true}");
    }
  };

  const btnNoDisabled = () => {
    const btn = document.getElementById("btn_submit");
    if (btn) {
      btn.removeAttribute("disabled");
    }
  };

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      btnDisabled();
      if (btnEmail === "mailSave") {
        toast.info("Enviando, aguarde");

      } else {

        toast.info("Carregando, aguarde");
      }
      const { id, chassi, preco_fipe, opcionais } = form;

      const descritivo = form.descritivo;

      //@ts-ignore
      form.preco_venda = document.getElementById("precoVenda").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      form.preco_custo = document.getElementById("precoCusto").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      form.preco_entrada = document.getElementById("precoEntrada").value.replaceAll(".", "").replaceAll(",", ".");


      //@ts-ignore
      const preco_venda = document.getElementById("precoVenda").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const preco_custo = document.getElementById("precoCusto").value.replaceAll(".", "").replaceAll(",", ".");
      //@ts-ignore
      const preco_entrada = document.getElementById("precoEntrada").value.replaceAll(".", "").replaceAll(",", ".");

      const ano_modelo = selectedOptionVeiculo?.id || form.ano_modelo;

      const nome = selectedOptionModelo?.name || form.nome;
      const fk_marca = parseFloat(selectedOptionMarca?.id) || form.fk_marca;
      const marca = selectedOptionMarca?.fipe_name || form.marca;
      const fk_cor =
        selectedOptionCor?.id || form.fk_cor.toString().split(",")[0];
      const fk_modelo = parseFloat(selectedOptionModelo?.id) || form.fk_modelo;
      const fk_cambio = selectedOptionCambio?.id || form.fk_cambio;
      const fk_combustivel =
        selectedOptionCombustivel?.id || form.fk_combustivel;
      const status = SelectedStatusCar?.id || form.status;
      const faturamento = selectedFaturamento?.id;
      const ano_contrato = new Date().getFullYear();
      const ano_fab = SelectedAnoFab?.id;
      const fk_fornecedor = selectedOptionProvider?.id || form.fk_fornecedor;
      const estoque_site = SelectedSite?.id;

      const profileEmail = profileUser.email;
      const profileWhats = profileUser.whatsapp;
      const profileNome = profileUser.nome;

      if (
        !fk_cor ||
        !ano_modelo ||
        !fk_marca ||
        !fk_modelo ||
        !fk_cambio ||
        !fk_combustivel
      ) {
        toast.error("Digite todos os campos obrigatórios");
        btnNoDisabled();
        return;
      }

      if (!fk_fornecedor) {
        toast.error("Digite todos os campos obrigatórios");
        setFocusFornecedor(true);
        btnNoDisabled();
        setTimeout(() => { setFocusFornecedor(false) }, 300)
        return;
      }

      if (!status) {
        toast.error("Digite todos os campos obrigatórios");
        setFocusStatus(true);
        btnNoDisabled();
        setTimeout(() => { setFocusStatus(false) }, 300)
        return;
      }

      dispatch(
        putCarRequest({
          id,
          nome,
          preco_fipe,
          preco_custo,
          preco_venda,
          descritivo,
          fk_marca,
          fk_cor,
          fk_modelo,
          fk_cambio,
          fk_combustivel,
          ano_modelo,
          ano_contrato,
          chassi,
          marca,
          status,
          faturamento,
          ano_fab,
          fk_fornecedor,
          estoque_site,
          btnEmail,
          profileEmail,
          profileWhats,
          profileNome,
          opcionais,
          preco_entrada
        })
      );
    },
    [
      dispatch,
      form,
      selectedOptionCambio,
      selectedOptionCombustivel,
      selectedOptionCor,
      selectedOptionMarca,
      selectedOptionModelo,
      selectedOptionVeiculo,
      SelectedStatusCar,
      selectedFaturamento,
      SelectedAnoFab,
      selectedOptionProvider,
      SelectedSite,
      btnEmail,
      profileUser
    ]
  );

  const notify = useCallback(
    (event) => {
      if (event === 200) {
        toast.success("Carro alterado com sucesso");
        setTimeout(() => history.push("/carros"), 2000);
      }

      if (event === 404) {
        toast.error("Preço maior que o permitido");
        btnNoDisabled();
      }
    },
    [history]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const getLeilaoValue = useCallback(
    (leilao: BooleanOption): number => leilao.id,
    []
  );
  const onLeilaoChange = useCallback(
    (leilao: BooleanOption | null): any => setSelectedFaturamento(leilao),
    []
  );
  const getLeilaoLabel = useCallback(
    (leilao: BooleanOption): string => `${leilao.name}`,
    []
  );

  const getAnoFabValue = useCallback(
    (anoFab: BooleanOption): number => anoFab.id,
    []
  );
  const onAnoFabChange = useCallback(
    (anoFab: BooleanOption | null): any => setSelectedAnoFab(anoFab),
    []
  );
  const getAnoFabLabel = useCallback(
    (anoFab: BooleanOption): string => `${anoFab.name}`,
    []
  );

  const getModelo = useCallback(
    (event) => {
      dispatch(fmodelosRequest(event));
    },
    [dispatch]
  );

  const getVeiculos = useCallback(
    (event) => {
      dispatch(fanoVeiculoRequest(event));
    },
    [dispatch]
  );

  const getCarro = useCallback(
    (event) => {
      dispatch(fcarroRequest(event));
    },
    [dispatch]
  );

  const getOptionLabelMarca = useCallback(
    (option: IFipe): string => `${option.fipe_name}`,
    []
  );
  const getOptionValueMarca = useCallback(
    (option: IFipe): string => option.id,
    []
  );
  const onOptionChangeMarca = useCallback(
    (option: IFipe | null): void => {
      setSelectedOptionMarca(option);
      getModelo(option?.id);
    },
    [getModelo]
  );

  const getOptionLabelModelo = useCallback(
    (modelo: IFipeModel): string => `${modelo.name}`,
    []
  );
  const getOptionValueModelo = useCallback(
    (option: IFipeModel): string => option.id,
    []
  );
  const onOptionChangeModelo = useCallback(
    (modelo: IFipeModel | null): void => {
      setSelectedOptionModelo(modelo);
      const idMarca = selectedOptionMarca?.id || form.fk_marca;
      getVeiculos(idMarca + "/" + modelo?.id);
    },
    [form.fk_marca, getVeiculos, selectedOptionMarca]
  );

  const getOptionLabelVeiculo = useCallback(
    (anoModel: BooleanOption): number => anoModel.id,
    []
  );
  const getOptionValueVeiculo = useCallback(
    (anoModel: BooleanOption): string => anoModel.id,
    []
  );
  const onOptionChangeVeiculo = useCallback(
    (veiculo: BooleanOption | null): void => {
      setSelectedOptionVeiculo(veiculo);
    },
    []
  );

  const getOptionLabelCor = useCallback(
    (color: any): string => `${color.nome}`,
    []
  );
  const getOptionValueCor = useCallback(
    (color: IColor): string => color.id,
    []
  );
  const onOptionChangeCor = useCallback((color: IColor | null): void => {
    setSelectedOptionCor(color);
  }, []);

  const getOptionLabelCambio = useCallback(
    (cambio: IGearshift): string => `${cambio.descricao}`,
    []
  );
  const getOptionValueCambio = useCallback(
    (cambio: IGearshift): string => cambio.id,
    []
  );
  const onOptionChangeCambio = useCallback(
    (cambio: IGearshift | null): void => {
      setSelectedOptionCambio(cambio);
    },
    []
  );

  const getOptionLabelCombustivel = useCallback(
    (fuel: IFuel): string => `${fuel.descricao}`,
    []
  );
  const getOptionValueCombustivel = useCallback(
    (fuel: IFuel): string => fuel.id,
    []
  );
  const onOptionChangeCombustivel = useCallback((fuel: IFuel | null): void => {
    setSelectedOptionCombustivel(fuel);
  }, []);

  const getStatusCarValue = useCallback(
    (newUsed: BooleanOption): number => newUsed.id,
    []
  );
  const onStatusCarChange = useCallback(
    (newUsed: BooleanOption | null): any => setSelectedStatusCar(newUsed),
    []
  );
  const getStatusCarLabel = useCallback(
    (newUsed: BooleanOption): string => `${newUsed.name}`,
    []
  );

  const getProviderValue = useCallback(
    (fornecedor: any): number => fornecedor.id,
    []
  );
  const onProviderChange = useCallback(
    (fornecedor: any): any => setSelectedOptionProvider(fornecedor),
    []
  );
  const getProviderLabel = useCallback(
    (fornecedor: any): string => `${fornecedor.nome}`,
    []
  );

  const getSiteValue = useCallback(
    (site: any): string => site.id,
    []
  );
  const onSiteChange = useCallback(
    (site: any): any => setSelectedSite(site),
    []
  );
  const getSiteLabel = useCallback(
    (site: any): string => `${site.name}`,
    []
  );

  const toastLoading = useCallback(() => {
    if (isLoading) {
      toast.info("Carregando informações do carro");
    }
  }, []);

  setTimeout(() => {
    setIsLoading(false);
    toast.dismiss()
  }, 1500);


  useEffect(() => {
    getModelo(form.fk_marca);
    getVeiculos(form.fk_marca + "/" + form.fk_modelo);
    getCarro(
      form.fk_marca + "/" + form.fk_modelo + "/" + form.ano_modelo + "-1"
    );


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getCarro,
    getModelo,
    getVeiculos,
    form.fk_marca,
    form.id,
    form.fk_modelo,
    form.ano_modelo,
  ]);

  const cleanView = useCallback(() => {
    dispatch(cleanViewCarResp());
  }, [dispatch]);

  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    if (isLoading) {
      toastLoading();
    }
    notify(responseApi.response.code);

    getProfile();
  }, [notify, responseApi.response, cleanView, getProfile, getLoja, isLoading]);

  const valorCompraKey = useCallback((e) => {

    const value = e.target.value.replaceAll(".", "").replaceAll(",", ".");
    form.preco_custo = value

  }, [])

  const valorVendaKey = useCallback((e) => {

    const value = e.target.value.replaceAll(".", "").replaceAll(",", ".");
    form.preco_venda = value

  }, [])

  const valorEntradaKey = useCallback((e) => {

    const value = e.target.value.replaceAll(".", "").replaceAll(",", ".");
    form.preco_entrada = value

  }, [])

  return (
    <div id="wrapper" className="wrapper bg-ash">
      <DispatchTables />
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Carro</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>Editar carro</li>
            </ul>
          </div>

          <div className="card height__formMaster">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">
                  {form.nome ? <h3>Alterar Carro</h3> : ""}
                  <span className="label-form-input">* Campos obrigatório</span>
                </div>
              </div>
              {isLoading ? (
                ""
              ) : (
                <form onSubmit={submitForm} className="new-added-form">
                  <div className="row">
                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputPassword1"
                      >
                        Marca *
                      </label>

                      <Select
                        options={fmarcas}
                        addClassNames={true}
                        placeholder="Selecione"
                        onOptionChange={onOptionChangeMarca}
                        getOptionLabel={getOptionLabelMarca}
                        getOptionValue={getOptionValueMarca}
                        initialValue={nameMarca}
                      />
                    </div>

                    <div className="col-xl-9 col-lg-9 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputPassword1"
                      >
                        Modelo *
                      </label>
                      <Select
                        options={modelsFipe}
                        addClassNames={true}
                        onOptionChange={onOptionChangeModelo}
                        getOptionValue={getOptionValueModelo}
                        getOptionLabel={getOptionLabelModelo}
                        placeholder="Selecione"
                        initialValue={nameModelo}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label className="label-form-input" htmlFor="ano_fab">
                        Ano Fabricação
                      </label>
                      <Select
                        options={BOOL_OPTIONS2}
                        onOptionChange={onAnoFabChange}
                        getOptionValue={getAnoFabValue}
                        getOptionLabel={getAnoFabLabel}
                        addClassNames={true}
                        placeholder="Selecione"
                        initialValue={filterAnoFab}
                      />
                    </div>

                    <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputPassword1"
                      >
                        Ano Modelo *
                      </label>
                      <Select
                        options={BOOL_OPTIONS2}
                        addClassNames={true}
                        onOptionChange={onOptionChangeVeiculo}
                        getOptionValue={getOptionValueVeiculo}
                        getOptionLabel={getOptionLabelVeiculo}
                        placeholder="Selecione"
                        initialValue={nameVeiculo}
                      />
                    </div>

                    <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                      <label className="label-form-input" htmlFor="precoEntrada">
                        Valor Entrada
                      </label>
                      <input
                        name="preco_entrada"
                        type="text"
                        className="form-control dinheiro"
                        id="precoEntrada"
                        placeholder="Valor Entrada"
                        onKeyUp={(e) => valorEntradaKey(e)}

                      />
                    </div>

                    <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                      <label className="label-form-input" htmlFor="precoVenda">
                        Preço Venda
                      </label>
                      <input
                        name="preco_venda"
                        type="text"
                        className="form-control dinheiro"
                        id="precoVenda"
                        placeholder="Preço Venda"
                        onKeyUp={(e) => valorVendaKey(e)}

                      />
                    </div>

                    <div className={`col-xl-3 col-lg-6 col-12 form-group`}>
                      <label className="label-form-input" htmlFor="precoCusto">
                        Preço Compra*
                      </label>
                      <input
                        name="preco_custo"
                        type="text"
                        className="form-control dinheiro"
                        id="precoCusto"
                        placeholder="Preço Compra"
                        onKeyUp={(e) => valorCompraKey(e)}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Cor *
                      </label>
                      <Select
                        options={cor}
                        addClassNames={true}
                        onOptionChange={onOptionChangeCor}
                        getOptionValue={getOptionValueCor}
                        getOptionLabel={getOptionLabelCor}
                        placeholder="Selecione"
                        initialValue={nameCor}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Cambio *
                      </label>
                      <Select
                        options={cambio}
                        addClassNames={true}
                        onOptionChange={onOptionChangeCambio}
                        getOptionValue={getOptionValueCambio}
                        getOptionLabel={getOptionLabelCambio}
                        placeholder="Selecione"
                        initialValue={nameCambio}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="exampleInputEmail1"
                      >
                        Combustivel *
                      </label>
                      <Select
                        options={combustivel}
                        addClassNames={true}
                        onOptionChange={onOptionChangeCombustivel}
                        getOptionValue={getOptionValueCombustivel}
                        getOptionLabel={getOptionLabelCombustivel}
                        placeholder="Selecione"
                        initialValue={nameCombustivel}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label className="label-form-input" htmlFor="chassi">
                        Chassi
                      </label>
                      <input
                        value={form.chassi}
                        name="chassi"
                        onChange={handleChange}
                        className="form-control"
                        id="chassi"
                        placeholder="Chassi"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="inputCarNovo"
                      >
                        Fornecedor *
                      </label>
                      <Select
                        options={fornecedor}
                        onOptionChange={onProviderChange}
                        getOptionValue={getProviderValue}
                        getOptionLabel={getProviderLabel}
                        addClassNames={true}
                        initialValue={nameFornecedor}
                        placeholder="Selecione"
                        autoFocus={focusFornecedor}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="inputCarNovo"
                      >
                        Opções Faturamento
                      </label>
                      <Select
                        options={BOOL_OPTIONS}
                        onOptionChange={onLeilaoChange}
                        getOptionValue={getLeilaoValue}
                        getOptionLabel={getLeilaoLabel}
                        addClassNames={true}
                        initialValue={filterFaturamento}
                        placeholder="Selecione"
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="inputCarNovo"
                      >
                        Status *
                      </label>
                      <Select
                        options={BOOL_OPTIONS1}
                        onOptionChange={onStatusCarChange}
                        getOptionValue={getStatusCarValue}
                        getOptionLabel={getStatusCarLabel}
                        addClassNames={true}
                        initialValue={filterStatusCar}
                        placeholder="Selecione"
                        autoFocus={focusStatus}
                      />
                    </div>

                    <div className="col-xl-3 col-lg-6 col-12 form-group">
                      <label className="label-form-input" htmlFor="inputCarNovo">
                        Estoque Site
                      </label>
                      <Select
                        options={BOOL_OPTIONS3}
                        onOptionChange={onSiteChange}
                        getOptionValue={getSiteValue}
                        getOptionLabel={getSiteLabel}
                        addClassNames={true}
                        initialValue={filterSite}
                        placeholder="Estoque"
                      />
                    </div>


                    <div className="col-xl-12 col-lg-12 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="descritivoCarro"
                      >
                        Descrição
                      </label>
                      <textarea
                        value={form.descritivo}
                        name="descritivo"
                        onChange={handleChangeText}
                        className="form-control textarea"
                        id="descritivoCarro"
                        rows={6}
                        placeholder="Descreva o carro poucas palavras"
                      />
                    </div>

                    <div className="col-xl-12 col-lg-12 col-12 form-group">
                      <label
                        className="label-form-input"
                        htmlFor="opcionaisCarro"
                      >
                        Opcionais
                      </label>
                      <textarea
                        value={form.opcionais}
                        name="opcionais"
                        onChange={handleChangeText}
                        className="form-control textarea"
                        id="opcionaisCarro"
                        rows={6}
                        placeholder="Descreva as opções"
                      />
                    </div>
                    <div className="col-12 form-group mg-t-8 buttonFlutuante">
                      <button
                        id="btn_submit"
                        type="submit"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btn--onlySave"
                        onClick={() => setBtnEmail("onlySave")}
                      >
                        <i className="fa fa-download"></i>
                      </button>

                      <button
                        id="btn_submit"
                        type="submit"
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark btn--save"
                        onClick={() => setBtnEmail("mailSave")}
                        data-toggle="tooltip" data-placement="top" title="Salvar e enviar"
                      >
                        <i className="fas fa-envelope"></i>
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditCarros;
