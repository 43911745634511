import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Header from "../../template/header";
import Sidebar from "../../template/sidebar";

import { ApplicationState } from "../../store";
import { IEmployee } from "../../store/ducks/employee/types";
import { putEmployeeRequest, } from "../../store/ducks/employee/actions";
import { ToastContainer, toast } from "react-toastify";
import { cleanViewAResp } from "../../store/ducks/employee/actions";
import MaskedInput from "react-text-mask";
import { Select } from "react-functional-select";


type BooleanOption = {
  readonly id: any;
  readonly name: string;
};

const OPTIONS_PERMISSAO: BooleanOption[] = [
  { id: "super_admin", name: "Master" },
  { id: "vendedor", name: "vendedor" },
];

const EditFunc: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const responseApi: any = useSelector<ApplicationState>((state) => state.employee);

  const view = useSelector<ApplicationState, IEmployee>(
    (state) => state.employee.view
  );

  const [selectedPermissao, setSelectedPermissao] = useState<BooleanOption | null>(
    null
  );


  function usePersistedState(key: any, defaultValue: any) {
    const [state, setState] = useState(
      () => JSON.parse(localStorage.getItem(key)!) || defaultValue
    );
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);
    return [state, setState];
  }

  const [form, setForm] = usePersistedState("usuarioFunc", {
    id: view.id,
    email: view.email,
    permissao: view.permissao,
    nome: view.nome,
    senha: view.senha,
    whatsapp: view.whatsapp,
  });

  let arrPermissao = {};
  if (form.permissao === "super_admin") {
    arrPermissao = { id: "super_admin", name: "Master" }
  } else {
    arrPermissao = { id: "vendedor", name: "vendedor" }
  }

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { id, email, nome, senha, whatsapp } = form;

      const permissao = selectedPermissao?.id;


      let btn = document.getElementById('btn_submit')
      if (btn)
        btn.setAttribute("disabled", "{true}")

      dispatch(
        putEmployeeRequest({
          id,
          email,
          permissao,
          nome,
          senha,
          whatsapp
        })
      );
    },
    [dispatch, form, selectedPermissao]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form, setForm]
  );

  const getPermissaoValue = useCallback(
    (permissao: BooleanOption): number => permissao.id,
    []
  );
  const onPermissaoChange = useCallback(
    (permissao: BooleanOption | null): any => setSelectedPermissao(permissao),
    []
  );
  const getPermissaoLabel = useCallback(
    (permissao: BooleanOption): string => `${permissao.name}`,
    []
  );

  // const handleChangeSelect = useCallback(
  //   (event: React.ChangeEvent<HTMLSelectElement>) => {
  //     const target = event.target;
  //     const permissao = target.name;

  //     setForm({
  //       ...form,
  //       [permissao]: target.value,
  //     });
  //   },
  //   [form]
  // );

  const notify = useCallback((event) => {


    if (event === 200) {
      toast.success("Usuario cadastrado com sucesso");
      setTimeout(() => history.push("/usuarios"), 2000);
    }
  }, [history])

  const cleanView = useCallback(() => {
    dispatch(cleanViewAResp());
  }, [dispatch]);

  useEffect(() => {
    if (responseApi.response.code !== "") {
      cleanView();
    }
    notify(responseApi.response.code);
  }, [cleanView, notify, responseApi.response]);

  console.log(responseApi.response)


  return (
    <div id="wrapper" className="wrapper bg-ash">
      <Header />
      <div className="dashboard-page-one">
        <Sidebar />
        <div className="dashboard-content-one">
          <div className="breadcrumbs-area">
            <h3>Usuario</h3>
            <ul>
              <li>
                <Link to="/#/">Home</Link>
              </li>
              <li>  Editar Usuario </li>

            </ul>
          </div>

          <div className="card height-auto">
            <div className="card-body">
              <div className="heading-layout1 mt-2 mb-4">
                <div className="item-title">

                  <h3>Editar Usuario</h3>
                </div>
              </div>

              <form onSubmit={submitForm} className="new-added-form">
                <div className="row">
                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label className="label-form-input" htmlFor="inputCarNovo">
                      Permissao
                    </label>
                    <Select
                      options={OPTIONS_PERMISSAO}
                      onOptionChange={onPermissaoChange}
                      getOptionValue={getPermissaoValue}
                      getOptionLabel={getPermissaoLabel}
                      addClassNames={true}
                      placeholder="Permissao"
                      initialValue={arrPermissao}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="nome"
                    >
                      Nome
                    </label>
                    <input
                      value={form.nome}
                      name="nome"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="nome"
                      placeholder="Nome"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="exampleInputPassword1"
                    >
                      Whatsapp
                    </label>
                    <MaskedInput
                      mask={[
                        "(",
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      guide={false}
                      value={form.whatsapp}
                      name="whatsapp"
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Whatsapp"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      value={form.email}
                      name="email"
                      onChange={handleChange}
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required={true}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-6 col-12 form-group">
                    <label
                      className="label-form-input"
                      htmlFor="email"
                    >
                      Nova Senha
                    </label>
                    <input
                      value={form.senha}
                      name="senha"
                      onChange={handleChange}
                      type="password"
                      className="form-control"
                      id="senha"
                      placeholder="Nova Senha"
                    />
                  </div>

                  <div className="col-12 form-group buttonFlutuante">
                    <button
                      type="submit"
                      className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      id="btn_submit"
                    >
                      <i className="fa fa-download"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default EditFunc;
