import React, { useCallback, useEffect } from "react";
import { colorRequest } from "../../store/ducks/color/actions";
import { gearshiftRequest } from "../../store/ducks/gearshift/actions";
import { fuelRequest } from "../../store/ducks/fuel/actions";
import { useDispatch } from "react-redux";
import { yearRequest } from "../../store/ducks/year/actions";
import { carRequest } from "../../store/ducks/car/actions";
import { fmarcasRequest } from "../../store/ducks/fipe/actions";

import { employeeRequest } from "../../store/ducks/employee/actions";
const DispatchTables = () => {
  const dispatch = useDispatch();

  const getFuncionarios = useCallback(() => {
    dispatch(employeeRequest());
  }, [dispatch]);

  const getFMarcas = useCallback(() => {
    dispatch(fmarcasRequest());
  }, [dispatch]);

  const getCarros = useCallback(() => {
    dispatch(carRequest("?pp=1000"));
  }, [dispatch]);

  const getAnos = useCallback(() => {
    dispatch(yearRequest("?pp=1000"));
  }, [dispatch]);

  const getCor = useCallback(() => {
    dispatch(colorRequest("?pp=1000"));
  }, [dispatch]);

  const getCambio = useCallback(() => {
    dispatch(gearshiftRequest("?pp=1000"));
  }, [dispatch]);

  const getCombustivel = useCallback(() => {
    dispatch(fuelRequest("?pp=1000"));
  }, [dispatch]);

  useEffect(() => {
    getFuncionarios();

    getFMarcas();
    getCarros();
    getAnos();
    getCor();
    getCambio();
    getCombustivel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default DispatchTables;
