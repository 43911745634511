export enum GearshiftTypes {
  GEARSHIFT_REQUEST = "@gearshift/GEARSHIFT_REQUEST",
  GEARSHIFT_SUCCESS = "@gearshift/GEARSHIFT_SUCCESS",
  GEARSHIFT_FAILURE = "@gearshift/GEARSHIFT_FAILURE",
  POST_GEARSHIFT_REQUEST = "@gearshift/POST_GEARSHIFT_REQUEST",
  POST_GEARSHIFT_SUCCESS = "@gearshift/POST_GEARSHIFT_SUCCESS",
  POST_GEARSHIFT_FAILURE = "@gearshift/POST_GEARSHIFT_FAILURE",
  PUT_GEARSHIFT_REQUEST = "@gearshift/PUT_GEARSHIFT_REQUEST",
  PUT_GEARSHIFT_SUCCESS = "@gearshift/PUT_GEARSHIFT_SUCCESS",
  PUT_GEARSHIFT_FAILURE = "@gearshift/PUT_GEARSHIFT_FAILURE",
  VIEW_GEARSHIFT = "@gearshift/VEIW_MODEL",
  CLEAN_VIEW_GEARSHIFT = "@gearshift/CLEAN_VIEW_MODEL",
  DELETE_GEARSHIFT_REQUEST = "@gearshift/DELETE_GEARSHIFT_REQUEST",
  DELETE_GEARSHIFT_SUCCESS = "@gearshift/DELETE_GEARSHIFT_SUCCESS",
  DELETE_GEARSHIFT_FAILURE = "@gearshift/DELETE_GEARSHIFT_FAILURE",
}

export interface IpostGearshift {
  descricao:string;
}

export interface IputGearshift {
  id:string;
  descricao:string;
}

export interface IGearshift {
  id:string;
  descricao:string;
}

export interface IResponse {
  code: string;
  message: string;
}

export interface GearshiftState {
  readonly data: IGearshift[];
  readonly view: IGearshift;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
