import { Reducer } from "redux";
import producer from "immer";

import { EmployeeState, EmployeeTypes } from "./types";

const INITIAL_STATE: EmployeeState = {
  data: [],
  view: {
    id: "",
    email: "",
    permissao: "",
    nome: "",
    senha: "",
    whatsapp: "",
  },
  response: {
    code: "",
    message: "",
  },
  loading: true,
  error: false,
};

const reducer: Reducer<EmployeeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeeTypes.EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EmployeeTypes.EMPLOYEE_SUCCESS:
      return producer(state, (draft) => {
        draft.data = action.payload.data;
        draft.loading = false;
      });
    case EmployeeTypes.EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case EmployeeTypes.POST_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EmployeeTypes.POST_EMPLOYEE_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
      });
    case EmployeeTypes.POST_EMPLOYEE_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loading = false;
      });

    case EmployeeTypes.VIEW_EMPLOYEE:
      return {
        ...state,
        view: action.payload.view,
      };
    case EmployeeTypes.CLEAN_VIEW_EMPLOYEE:
      return {
        ...state,
        view: {
          id: "",
          email: "",
          permissao: "",
          nome: "",
          senha: "",
          whatsapp: "",

        },
      };
    case EmployeeTypes.PUT_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case EmployeeTypes.PUT_EMPLOYEE_SUCCESS:
      return producer(state, (draft) => {
        draft.loading = false;
        draft.response = action.payload.data;
        draft.view = {
          id: "",
          email: "",
          permissao: "",
          nome: "",
          senha: "",
          whatsapp: "",

        };
      });
    case EmployeeTypes.PUT_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case EmployeeTypes.CLEAN_EMPLOYEE_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };
    default:
      return state;
  }
};

export default reducer;
