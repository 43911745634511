import axios from "axios";

//  const api = axios.create({
//   baseURL: "http://localhost:8000",
// });

const api = axios.create({
  baseURL: "https://apigestor.thaimotors.com.br/",
});



export default api;
