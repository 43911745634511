import { action } from "typesafe-actions";
import {
  ProviderTypes,
  IProvider,
  IpostProvider,
  IputProvider,
  IResponse,
} from "./types";

//client PF
export const providerRequest = (data: any) =>
  action(ProviderTypes.PROVIDER_REQUEST, { data });

export const providerSuccess = (data: IProvider) =>
  action(ProviderTypes.PROVIDER_SUCCESS, { data });

export const providerFailure = () => action(ProviderTypes.PROVIDER_FAILURE, {});

export const postProviderRequest = (ipostProvider: IpostProvider) =>
  action(ProviderTypes.POST_PROVIDER_REQUEST, { ipostProvider });

export const postProviderSuccess = (data: IResponse) =>
  action(ProviderTypes.POST_PROVIDER_SUCCESS, { data });

export const postProviderFailure = (data: IResponse) =>
  action(ProviderTypes.POST_PROVIDER_FAILURE, { data });

export const viewProvider = (view: IProvider) =>
  action(ProviderTypes.VIEW_PROVIDER, { view });

export const cleanViewProvider = () =>
  action(ProviderTypes.CLEAN_VIEW_PROVIDER, {});


export const putProviderRequest = (iputProvider: IputProvider) =>
  action(ProviderTypes.PUT_PROVIDER_REQUEST, { iputProvider });

export const putProviderSuccess = (data: IResponse) =>
  action(ProviderTypes.PUT_PROVIDER_SUCCESS, { data });

export const putProviderFailure = () =>
  action(ProviderTypes.PUT_PROVIDER_FAILURE, {});

export const deleteProviderRequest = (id: string) =>
  action(ProviderTypes.DELETE_PROVIDER_REQUEST, { id });

export const deleteProviderSuccess = () =>
  action(ProviderTypes.DELETE_PROVIDER_SUCCESS, {});

export const deleteProviderFailure = () =>
  action(ProviderTypes.DELETE_PROVIDER_FAILURE, {});

export const cleanViewARespProvider = () =>
  action(ProviderTypes.CLEAN_PROVIDER_RESPONSE, {});

//BANK
export const deleteBankRequest = (id: string) =>
  action(ProviderTypes.DELETE_BANK_REQUEST, { id });

export const deleteBankSuccess = () =>
  action(ProviderTypes.DELETE_BANK_SUCCESS, {});

export const deleteBankFailure = () =>
  action(ProviderTypes.DELETE_BANK_FAILURE, {});

//ADDRESS
export const deleteAddressRequest = (id: string) =>
  action(ProviderTypes.DELETE_ADDRESS_REQUEST, { id });

export const deleteAddressSuccess = () =>
  action(ProviderTypes.DELETE_ADDRESS_SUCCESS, {});

export const deleteAddressFailure = () =>
  action(ProviderTypes.DELETE_ADDRESS_FAILURE, {});
