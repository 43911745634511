import { call, put, all, takeLatest } from "redux-saga/effects";

import api from "../../../services/api";

import { AuthTypes } from "./types";
import { authRequest, authSuccess, authFailure } from "./actions";

function* auth({ payload }: ReturnType<typeof authRequest>) {
  const { email, password } = payload.req;

  try {
    const { data } = yield call(api.post, "/auth", {
      email,
      password,
    });
    localStorage.setItem("Auth-Token", data.params.token);
    localStorage.setItem("Access-Level", data.params.access_level);
    localStorage.setItem("fk_user", data.params.id);

    yield put(authSuccess(data));
  } catch (error) {
    yield put(authFailure({ ...error.response.data.response }));
  }
}

export function setToken(payload: any) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `${token}`;
  }
}

export default all([
  takeLatest("persit/REHYDRATE", setToken),
  takeLatest(AuthTypes.AUTH_REQUEST, auth),
]);
