export enum EmployeeTypes {
  EMPLOYEE_REQUEST = "@employee/EMPLOYEE_REQUEST",
  EMPLOYEE_SUCCESS = "@employee/EMPLOYEE_SUCCESS",
  EMPLOYEE_FAILURE = "@employee/EMPLOYEE_FAILURE",

  POST_EMPLOYEE_REQUEST = "@employee/POST_EMPLOYEE_REQUEST",
  POST_EMPLOYEE_SUCCESS = "@employee/POST_EMPLOYEE_SUCCESS",
  POST_EMPLOYEE_FAILURE = "@employee/POST_EMPLOYEE_FAILURE",

  PUT_EMPLOYEE_REQUEST = "@employee/PUT_EMPLOYEE_REQUEST",
  PUT_EMPLOYEE_SUCCESS = "@employee/PUT_EMPLOYEE_SUCCESS",
  PUT_EMPLOYEE_FAILURE = "@employee/PUT_EMPLOYEE_FAILURE",

  VIEW_EMPLOYEE = "@employee/VEIW_EMPLOYEE",
  CLEAN_VIEW_EMPLOYEE = "@employee/CLEAN_VIEW_EMPLOYEE",

  DELETE_EMPLOYEE_REQUEST = "@employee/DELETE_EMPLOYEE_REQUEST",
  DELETE_EMPLOYEE_SUCCESS = "@employee/DELETE_EMPLOYEE_SUCCESS",
  DELETE_EMPLOYEE_FAILURE = "@employee/DELETE_EMPLOYEE_FAILURE",

  CLEAN_EMPLOYEE_RESPONSE = "@employee/CLEAN_EMPLOYEE_RESPONSE",
}

export interface IpostEmployee {
  email: string;
  permissao: string;
  nome: string;
  senha: string;
  whatsapp: string;
}

export interface IputEmployee {
  id: any;
  email: string;
  permissao: string;
  nome: string;
  senha: string;
  whatsapp: string;
}

export interface IEmployee {
  id: string;
  nome?: string;
  email: string;
  permissao: string;
  senha: string;
  whatsapp: string;

}
export interface IResponse {
  code: string;
  message: string;
}

export interface EmployeeState {
  readonly data: IEmployee[];
  readonly view: IEmployee;
  readonly response: IResponse;
  readonly loading: boolean;
  readonly error: boolean;
}
