import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { userRequest } from "../../store/ducks/user/actions";
import { useLocation } from "react-router-dom";
import { IRegisterUser } from "../../store/ducks/user/types";
import MaskedInput from "react-text-mask";
import { toast, ToastContainer } from "react-toastify";
import logo from "../SignIn/logo-app.png";

const CadastroSenha: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();

  const token = location.pathname.substring(10);

  const [form, setForm] = useState<IRegisterUser>({
    senha: "",
    nome: "",
    whatsapp: "",
    permissao: "dono",
    auth_token: token,
  });

  const login = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const btn = document.getElementById("btn_submit");
      if (btn) {
        btn.setAttribute("disabled", "{true}");
      }

      dispatch(
        userRequest({
          senha: form.senha,
          nome: form.nome,
          whatsapp: form.whatsapp,
          permissao: form.permissao,
          auth_token: form.auth_token,
        })
      );
      toast.success("Usuario cadastrado com sucesso");
      setTimeout(() => history.push("/"), 2000);
    },
    [
      dispatch,
      form.auth_token,
      form.nome,
      form.permissao,
      form.senha,
      form.whatsapp,
      history,
    ]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = target.name;

      setForm({
        ...form,
        [name]: target.value,
      });
    },
    [form]
  );

  return (
    <div className="login-page-warp">
      <div className="login-page-content">
        <div className="login-box">
          <div className="item-logo">
            <img src={logo} alt=".." />
          </div>

          <form onSubmit={login} className="login-form">
            <div className="form-group">
              <input
                value={form.nome}
                onChange={handleChange}
                name="nome"
                type="name"
                className="form-control"
                placeholder="Nome do Usuario"
                id="exampleInputSenha1"
                aria-describedby="emailHelp"
              />
              <i className="fa fa-user"></i>
            </div>
            <div className="form-group">
              <MaskedInput
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                value={form.whatsapp}
                onChange={handleChange}
                name="whatsapp"
                type="name"
                className="form-control"
                placeholder="Whatsapp"
                id="exampleInputWhatsapp1"
                aria-describedby="emailHelp"
              />
              <i className="fa fa-phone"></i>
            </div>

            <div className="form-group">
              <input
                value={form.senha}
                onChange={handleChange}
                name="senha"
                type="password"
                placeholder="Senha"
                className="form-control"
                id="exampleInputPassword1"
              />
              <i className="fa fa-lock"></i>
            </div>

            <div className="form-group">
              <button type="submit" className="login-btn" id="btn_submit">
                Salvar
              </button>
            </div>
          </form>
          <ToastContainer autoClose={5000} position="top-right" />
        </div>
      </div>
    </div>
  );
};

export default CadastroSenha;
