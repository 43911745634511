import { Reducer } from "redux";
import producer from "immer";

import { ClientStoreState, ClientStoreTypes } from "./types";

const INITIAL_STATE: ClientStoreState = {
  dataPF: [],
  viewPF: {
    id: "",
    nome: "",
    RG: "",
    CPF: "",
    telefone: "",
    celular: "",
    CEP: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade: "",
    complemento: "",
    estado: "",
    fk_user: "",
    email: "",
  },

  loadingPF: true,
  errorPF: false,
  dataPJ: [],
  viewPJ: {
    id: "",
    nome_contato: "",
    razao_social: "",
    CNPJ: "",
    telefone: "",
    celular: "",
    CEP: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade: "",
    complemento: "",
    estado: "",
    fk_user: "",
    email: "",
    iestadual: "",
  },
  response: {
    code: "",
    message: "",
  },
  loadingPJ: true,
  errorPJ: false,
};

const reducer: Reducer<ClientStoreState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //client PF
    case ClientStoreTypes.CLIENTPF_REQUEST:
      return {
        ...state,
        loadingPF: true,
        errorPF: false,
      };

    case ClientStoreTypes.CLIENTPF_SUCCESS:
      return producer(state, (draft) => {
        draft.dataPF = action.payload.dataPF;
        draft.loadingPF = false;
      });

    case ClientStoreTypes.CLIENTPF_FAILURE:
      return {
        ...state,
        loadingPF: false,
        errorPF: true,
      };

    case ClientStoreTypes.POST_CLIENTPF_REQUEST:
      return {
        ...state,
        loadingPF: true,
        errorPF: false,
      };

    case ClientStoreTypes.POST_CLIENTPF_SUCCESS:
      return producer(state, (draft) => {
        draft.loadingPF = false;
        draft.response = action.payload.data;
      });

    case ClientStoreTypes.POST_CLIENTPF_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loadingPF = false;
      });

    case ClientStoreTypes.VIEW_CLIENTPF:
      return {
        ...state,
        viewPF: action.payload.view,
      };

    case ClientStoreTypes.CLEAN_VIEW_CLIENTPF:
      return {
        ...state,
        viewPF: {
          id: "",
          nome: "",
          RG: "",
          CPF: "",
          telefone: "",
          celular: "",
          CEP: "",
          rua: "",
          numero: "",
          bairro: "",
          cidade: "",
          complemento: "",
          estado: "",
          fk_user: "",
          email: "",
        },
      };

    case ClientStoreTypes.PUT_CLIENTPF_REQUEST:
      return {
        ...state,
        loadingPF: true,
      };

    case ClientStoreTypes.PUT_CLIENTPF_SUCCESS:
      return producer(state, (draft) => {
        draft.loadingPF = false;
        draft.response = action.payload.data;
        draft.viewPF = {
          id: "",
          nome: "",
          RG: "",
          CPF: "",
          telefone: "",
          celular: "",
          CEP: "",
          rua: "",
          numero: "",
          bairro: "",
          cidade: "",
          complemento: "",
          estado: "",
          fk_user: "",
          email: "",
        };
      });

    case ClientStoreTypes.PUT_CLIENTPF_FAILURE:
      return {
        ...state,
        loadingPF: false,
        errorPF: true,
      };

    case ClientStoreTypes.CLEAN_CLIENTPF_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };

    //client PJ
    case ClientStoreTypes.CLIENTPJ_REQUEST:
      return {
        ...state,
        loadingPJ: true,
        errorPJ: false,
      };

    case ClientStoreTypes.CLIENTPJ_SUCCESS:
      return producer(state, (draft) => {
        draft.dataPJ = action.payload.dataPJ;
        draft.loadingPJ = false;
      });

    case ClientStoreTypes.CLIENTPJ_FAILURE:
      return {
        ...state,
        loadingPJ: false,
        errorPJ: true,
      };

    case ClientStoreTypes.POST_CLIENTPJ_REQUEST:
      return {
        ...state,
        loadingPJ: true,
        errorPJ: false,
      };

    case ClientStoreTypes.POST_CLIENTPJ_SUCCESS:
      return producer(state, (draft) => {
        draft.loadingPJ = false;
        draft.response = action.payload.data;
      });

    case ClientStoreTypes.POST_CLIENTPJ_FAILURE:
      return producer(state, (draft) => {
        draft.response = action.payload.data;
        draft.loadingPJ = false;
      });

    case ClientStoreTypes.VIEW_CLIENTPJ:
      return {
        ...state,
        viewPJ: action.payload.view,
      };

    case ClientStoreTypes.CLEAN_VIEW_CLIENTPJ:
      return {
        ...state,
        viewPJ: {
          id: "",
          nome_contato: "",
          razao_social: "",
          CNPJ: "",
          telefone: "",
          celular: "",
          CEP: "",
          rua: "",
          numero: "",
          bairro: "",
          cidade: "",
          complemento: "",
          estado: "",
          fk_user: "",
          email: "",
          iestadual: "",
        },
      };

    case ClientStoreTypes.PUT_CLIENTPJ_REQUEST:
      return {
        ...state,
        loadingPJ: true,
      };

    case ClientStoreTypes.PUT_CLIENTPJ_SUCCESS:
      return producer(state, (draft) => {
        draft.loadingPJ = false;
        draft.response = action.payload.data;
        draft.viewPJ = {
          id: "",
          nome_contato: "",
          razao_social: "",
          CNPJ: "",
          telefone: "",
          celular: "",
          CEP: "",
          rua: "",
          numero: "",
          bairro: "",
          cidade: "",
          complemento: "",
          estado: "",
          fk_user: "",
          email: "",
          iestadual: "",
        };
      });

    case ClientStoreTypes.PUT_CLIENTPJ_FAILURE:
      return {
        ...state,
        loadingPJ: false,
        errorPJ: true,
      };

    case ClientStoreTypes.CLEAN_CLIENTPJ_RESPONSE:
      return {
        ...state,
        response: {
          code: "",
          message: "",
        },
      };

    default:
      return state;
  }
};

export default reducer;
